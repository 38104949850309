import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { logout } from "../service/dataService";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/solfin-logo.svg";
import ChangePassword from "./ChangePassword";

const Header = () => {
  const navigate = useNavigate();
  const [activeItem, setActiveItem] = useState("Applications");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const Logout = async () => {
    // console.log("Logout")
    const response = await logout();
    //  console.log(response)
    if (response.status === 200) {
      // console.log("navigate")
      navigate("/login");
    }
  };

  const handleItemClick = (item) => {
    setActiveItem(item);
    setIsMenuOpen(false);
  };
  const handleItemClick1 = (item) => {
    setActiveItem(item);
  };

  return (
    <>
      <header className="inner-header smooth position-absolute start-0 top-0 w-100 pt-md-3 pt-2 mt-1">
        <div className="container custom-container">
          <div className="header-wrap bg-white rounded-20">
            <div className="top-header text-white bg-black pt-1 pb-1">
              <div className="row mt-2 mb-1">
                <div className="col-md-12 text-end">
                  <a
                    className="text-decoration-none text-theme-green fw-bold"
                    href="mailto:info@solfin.co.in"
                  >
                    info@solfin.co.in
                  </a>
                  <span className="devider ms-5 me-5">|</span>

                  <a
                    className="text-decoration-none text-theme-green fw-bold"
                    href="tel:+919599446877"
                  >
                    +91 9599446877
                  </a>
                </div>
                {/* <!-- /col-md-6 --> */}
              </div>
              {/* <!-- /row --> */}
            </div>
            {/* <!-- /top-header --> */}
            <div className="mein-header consumer-header">
              <div className="row align-items-lg-center justify-content-between">
                <div className="col">
                  <div className="solfin-logo">
                    <Link to="/customer-dashboard">
                      <img src={logo} alt="Solfin" title="Solfin" />
                    </Link>
                  </div>
                  {/* <!-- /solfin-logo --> */}
                  <div className="touch-in-solfin d-none align-items-center">
                    <Link
                      to="/login"
                      className="btn btn-primary btn-header rounded-5 text-nowrap"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      Login
                    </Link>
                  </div>
                  <button
                    className="navbar-toggler d-xl-none d-xl-block"
                    type="button"
                    // data-bs-toggle="collapse"
                    // data-bs-target="#navbarSupportedContent"
                    // aria-controls="navbarSupportedContent"
                    // aria-expanded="false"
                    // aria-label="Toggle navigation"
                    onClick={() => setIsMenuOpen((prevState) => !prevState)}
                    aria-controls="navbarSupportedContent"
                    aria-expanded={isMenuOpen ? "true" : "false"}
                    aria-label="Toggle navigation"
                  >
                    <span className="fa fa-bars"></span>
                  </button>
                </div>
                {/* <!-- /col --> */}
                <div className="col">
                  <nav className="navbar navbar-expand-xl p-0" id="solfin-nav">
                    <div className="container-fluid">
                      <div
                        className={`collapse navbar-collapse ${
                          isMenuOpen ? "show" : ""
                        }`}
                        id="navbarSupportedContent"
                      >
                        <ul className="navbar-nav me-auto mb-2 mb-xl-0">
                          <li
                            className={`nav-item dropdown ${
                              activeItem === "Applications" ? "active" : ""
                            }`}
                          >
                            <Link
                              className="nav-link  dropdown-toggle"
                              href="#"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              onClick={() => handleItemClick1("Applications")}
                            >
                              Applications
                            </Link>
                            <ul className="dropdown-menu">
                              <li>
                                <NavLink
                                  className="dropdown-item"
                                  to="/customer-dashboard"
                                  onClick={() => setIsMenuOpen(false)}
                                >
                                  Applied
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  className="dropdown-item"
                                  to="/customer/new-application"
                                  onClick={() => setIsMenuOpen(false)}
                                >
                                  New
                                </NavLink>
                              </li>
                            </ul>
                          </li>
                          <li
                            className={`nav-item ${
                              activeItem === "Projects" ? "active" : ""
                            }`}
                          >
                            <Link
                              className="nav-link"
                              to="/customer/projects"
                              onClick={() => handleItemClick("Projects")}
                            >
                              Projects
                            </Link>
                          </li>
                          <li
                            className={`nav-item ${
                              activeItem === "Monitoring" ? "active" : ""
                            }`}
                          >
                            <Link
                              className="nav-link"
                              to="/customer/monitoring"
                              onClick={() => handleItemClick("Monitoring")}
                            >
                              Monitoring
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </nav>
                  {/* <!-- /nav --> */}
                </div>
                {/* <!-- /col --> */}
                <div className="col right-col">
                  <div className="right-header d-flex align-items-center justify-content-end">
                    <div className="touch-in-solfin ms-xxl-5 ms-3 d-flex align-items-center justify-content-end">
                      {/* <div className="solfin-notifications">
                        <div className="notification-top position-relative">
                          <Link to="#">
                            <img
                              src="../images/notification.svg"
                              alt="Notifications"
                              title="Notifications"
                            />
                          </Link>
                          <span className="badge position-absolute top-0 translate-middle badge rounded-pill bg-danger p-0 d-flex align-items-center justify-content-center fw-medium">
                            3
                          </span>
                        </div>
                      </div> */}
                      {/* <!-- /notification --> */}

                      <div className="solfin-header-profile position-relative ms-lg-5 ms-4">
                        <Link
                          to="#"
                          className="d-flex align-items-center"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <span className="top-profile-img overflow-hidden rounded-pill d-flex">
                            <img
                              src="/images/default_user.png"
                              alt="User"
                              title="User"
                            />
                          </span>
                          <span className="down-arrow ms-2 text-dark">
                            <i className="fa-solid fa-chevron-down"></i>
                          </span>
                        </Link>
                        <ul className="dropdown-menu end-0">
                          <li>
                            <Link
                              className="dropdown-item"
                              to="/customer/profile"
                            >
                              Profile
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal"
                              href="javascript:void(0);"
                            >
                              Change Password
                            </Link>
                          </li>
                          <li>
                            <button
                              className="dropdown-item"
                              to="/login"
                              onClick={Logout}
                            >
                              Logout
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <ChangePassword />
    </>
  );
};

export default Header;
