import React from "react";
import * as dataquery from "../service/DataServices";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { saveAs } from "file-saver";

const LeadsDetailPage = () => {
  const { id } = useParams();

  const getMyLeadsDetails = useQuery(["getDsaLeadsDetail", +id], () =>
    dataquery.getSingleApplication(id)
  );

  if (!getMyLeadsDetails.data) {
    return <div>Loading...</div>;
  }
  if (Object.keys(getMyLeadsDetails.data.data).length === 0) {
    return <div>No Data</div>;
  }

  const downloadElecBillImage = (e) => {
    const image_url =
      getMyLeadsDetails &&
      getMyLeadsDetails.data &&
      getMyLeadsDetails.data.data &&
      getMyLeadsDetails.data.data.elec_bill_image;
    saveAs(image_url, "elec_bill_image");
  };

  const coordinates =
    getMyLeadsDetails?.data &&
    getMyLeadsDetails?.data?.data &&
    getMyLeadsDetails.data.data.google_coordinates;

  let formattedLatitude = "";
  let formattedLongitude = "";
  if (coordinates) {
    const [latitude, longitude] = coordinates.split(",").map(parseFloat);
    formattedLatitude = latitude.toFixed(2);
    formattedLongitude = longitude.toFixed(2);
  }

  return (
    <>
      <div className="content-body">
        <div className="page-title">
          <Link className="back-to-page" to="/dsa-dashboard/my-leads">
            <i className="fa-solid fa-chevron-left"></i>
          </Link>
          <div className="dash-breadcrumb">
            <ul>
              <li>
                <Link to="/dsa-dashboard/my-leads">Application</Link>
              </li>
              <li>Details</li>
            </ul>
          </div>
        </div>
        {/* <!-- /page title --> */}
        <div className="dash-card">
          {/* <!-- /card header --> */}
          <div className="dash-card-body">
            <div className="solfin-tabs">
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                  tabindex="0"
                >
                  <div
                    className="tab-box"
                    id="tab-1"
                    style={{ display: "block" }}
                  >
                    <form action="#" method="get">
                      <div className="solfin-application-details">
                        <ul>
                          <li>
                            <span className="detail-label">
                              Registrant Type
                            </span>
                            <span className="detail-value">
                              {getMyLeadsDetails.data.data.customer_type}
                            </span>
                          </li>
                          <li>
                            <span className="detail-label">Full Name</span>
                            <span className="detail-value">
                              {getMyLeadsDetails.data.data.customer_name}
                            </span>
                          </li>
                          <li>
                            <span className="detail-label">Email</span>
                            <span className="detail-value">
                              {getMyLeadsDetails.data.data.email}
                            </span>
                          </li>
                          <li>
                            <span className="detail-label">Phone</span>
                            <span className="detail-value">
                              {getMyLeadsDetails.data.data.phone}
                            </span>
                          </li>

                          <li>
                            <span className="detail-label">Address</span>
                            <span className="detail-value">
                              {getMyLeadsDetails.data.data.address1}
                            </span>
                          </li>
                          <li>
                            <span className="detail-label">City</span>
                            <span className="detail-value">
                              {getMyLeadsDetails.data.data.city}
                            </span>
                          </li>
                          <li>
                            <span className="detail-label">State</span>
                            <span className="detail-value">
                              {getMyLeadsDetails.data.data.state}
                            </span>
                          </li>

                          <li>
                            <span className="detail-label">Pincode</span>
                            <span className="detail-value">
                              {getMyLeadsDetails.data.data.pincode}
                            </span>
                          </li>

                          <li>
                            <span className="detail-label">PAN Number</span>
                            <span className="detail-value">
                              {getMyLeadsDetails.data.data.pan}
                            </span>
                          </li>

                          <li>
                            <span className="detail-label">
                              Electricity Bill
                            </span>
                            <span className="detail-value img-uploaded">
                              {getMyLeadsDetails.data.data.elec_bill_image ? (
                                <a
                                  href={
                                    getMyLeadsDetails.data.data.elec_bill_image
                                  }
                                  target="_blank"
                                  rel="noreferrer"
                                  onClick={downloadElecBillImage}
                                >
                                  <i className="fa-solid fa-download text-dark"></i>
                                  Download
                                </a>
                              ) : (
                                ""
                              )}
                            </span>
                          </li>

                          <li>
                            <span className="detail-label">
                              Electricity Consumer No
                            </span>
                            <span className="detail-value">
                              {
                                getMyLeadsDetails.data.data
                                  .electricity_consumer_no
                              }
                            </span>
                          </li>

                          <li>
                            <span className="detail-label">
                              Financing Required
                            </span>
                            <span className="detail-value">
                              {getMyLeadsDetails.data.data
                                .is_finance_required === 1
                                ? "Yes"
                                : "No"}
                            </span>
                          </li>

                          <li>
                            <span className="detail-label">
                              Location on Map
                            </span>
                            <span className="detail-value">
                              {formattedLatitude} , {formattedLongitude}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- /dash-card --> */}
      </div>
      {/* <!-- /content body --> */}
    </>
  );
};

export default LeadsDetailPage;
