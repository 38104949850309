import React from "react";

const Lease = () => {
  return (
    <section>
      <div className="about-solfin loan-products header-space  pt-1">
        <div className="container custom-container">
          <div className="row">
            <div className="col-12 mb-4">
              <div className="solfin-heading">
                <h2 className="fw-bold heading-color">
                  Lease <span className="text-theme-green">Products</span>
                </h2>
              </div>
            </div>
            {/* <!-- /col-12 --> */}
            <div className="loan-products">
              <ul className="list-unstyled d-flex justify-content-center">
                <li>
                  <div className="loan-product rounded-4 overflow-hidden">
                    <div className="product-name d-flex align-items-center justify-content-center">
                      <h6 className="fw-bold m-0">
                        {" "}
                        <span className="text-theme-green">Finance </span>Lease
                      </h6>
                    </div>
                    <div className="uses end-use">
                      <strong>End Use</strong>
                      <span>Solar</span>
                    </div>
                    <div className="uses">
                      <strong>Tenure</strong>
                      <span>Upto 10 Years</span>
                    </div>
                    <div className="uses interest-computation">
                      <strong>Ownership</strong>
                      <span>Mandatory Transfer to Lessee</span>
                    </div>
                    <div className="uses">
                      <strong>Lease Installment</strong>
                      <span>Monthly</span>
                    </div>
                    <div className="uses">
                      <strong>GST</strong>
                      <span>Added on Instalments</span>
                    </div>
                    <div className="uses rate-interst">
                      <strong>Lease Amount</strong>
                      <span>Fixed/Variable as per Units generated</span>
                    </div>
                    <div className="uses rate-interst">
                      <strong>Residual Value</strong>
                      <span>
                        Optional at the time of Closure of Leasing contract
                      </span>
                    </div>
                    <div className="uses rate-interst">
                      <strong>Benefit to Consumer</strong>
                      <span>Pay Per Use Option</span>
                    </div>

                    <div className="uses">
                      <strong>Interested</strong>
                      <span>contact@solfin.co.in</span>
                    </div>
                    <div className="loan-action text-center">
                      <a
                        href="#"
                        className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn disabled"
                      >
                        Coming Soon
                      </a>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="loan-product rounded-4 overflow-hidden">
                    <div className="product-name d-flex align-items-center justify-content-center">
                      <h6 className="fw-bold m-0">
                        {" "}
                        <span className="text-theme-green">Operating </span>
                        Lease
                      </h6>
                    </div>
                    <div className="uses end-use">
                      <strong>End Use</strong>
                      <span>Solar</span>
                    </div>
                    <div className="uses">
                      <strong>Tenure</strong>
                      <span>Upto 10 Years</span>
                    </div>
                    <div className="uses interest-computation">
                      <strong>Ownership</strong>
                      <span>Optional transfer To Lessee</span>
                    </div>
                    <div className="uses">
                      <strong>Lease Installment</strong>
                      <span>Monthly</span>
                    </div>
                    <div className="uses">
                      <strong>GST</strong>
                      <span>Added on Instalments</span>
                    </div>
                    <div className="uses rate-interst">
                      <strong>Lease Amount</strong>
                      <span>Fixed/Variable as per Units generated</span>
                    </div>
                    <div className="uses rate-interst">
                      <strong>Residual Value</strong>
                      <span>
                        Optional at the time of Closure of Leasing contract
                      </span>
                    </div>
                    <div className="uses rate-interst">
                      <strong>Benefit to Consumer</strong>
                      <span>Pay Per Use Option</span>
                    </div>

                    <div className="uses">
                      <strong>Interested</strong>
                      <span>contact@solfin.co.in</span>
                    </div>
                    <div className="loan-action text-center">
                      <a
                        href="#"
                        className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn disabled"
                      >
                        Coming Soon
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            {/* <!-- /row --> */}
          </div>
          {/* <!-- /row --> */}
        </div>
        {/* <!-- /container --> */}
      </div>
      {/* <!-- /saving-calculator --> */}
    </section>
  );
};

export default Lease;
