import React from "react";
import { Link } from "react-router-dom";
import * as queryData from "../service/DataServices";
import { useQuery } from "react-query";
import { saveAs } from "file-saver";
import { Tooltip } from "react-tooltip";
import AreaServedModal from "../../components/AreaServedModal";

const Details = () => {
  
  const { data, isLoading, error } = useQuery("myDetails", queryData.myDetails);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!data) {
    return <div>No data fetched.</div>;
  }

  const onButtonClick = () => {
    const image_url = data && data.data.document_file;
    saveAs(image_url, "file1");
  };
  return (
    <>
      <div className="content-body">
        <div className="page-title">
          <h3>My Details</h3>
        </div>
        {/* <!-- /page title --> */}
        <div className="dash-card">
          <div className="dash-card-header border-0">
            <div className="tab-menu mb-0">
              <ul className="nav nav-pills mb-3" id="detail-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    Enrollment Details
                  </button>
                </li>
                {/* <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    Publishing Details
                  </button>
                </li> */}
              </ul>
              <div className="tab-content mt-3" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                  tabindex="0"
                >
                  <div className="solfin-application-details">
                    <ul className="d-block">
                      {/* <li
                        className="d-flex align-items-center pb-3"
                        style={{ width: "100%" }}
                      >
                        <span
                          className="fw-bold mx-2"
                          style={{ marginRight: "50px", width: "45%" }}
                        >
                          Partner Id:
                        </span>{" "}
                        <span></span>
                      </li> */}
                      <li
                        className="d-flex align-items-center pb-3"
                        style={{ width: "100%" }}
                      >
                        <span
                          className="fw-bold mx-2"
                          style={{ marginRight: "50px", width: "45%" }}
                        >
                          Name:
                        </span>{" "}
                        <span>
                          {(data && data.data.dsa_name) || "DSA Name"}
                        </span>
                      </li>
                      <li className="d-flex align-items-center pb-3">
                        <span
                          className="fw-bold mx-2"
                          style={{ marginRight: "50px", width: "45%" }}
                        >
                          Address:
                        </span>{" "}
                        <span>
                          {(data && data.data.address1) || "DSA Address"}
                        </span>
                      </li>
                      <li className="d-flex align-items-center pb-3">
                        <span
                          className="fw-bold mx-2"
                          style={{ marginRight: "50px", width: "45%" }}
                        >
                          Pin Code:
                        </span>{" "}
                        <span>{(data && data.data.pin_code) || "PinCode"}</span>
                      </li>
                      <li className="d-flex align-items-center pb-3">
                        <span
                          className="fw-bold mx-2"
                          style={{ marginRight: "50px", width: "45%" }}
                        >
                          City:
                        </span>{" "}
                        <span>{(data && data.data.city) || "DSA City"}</span>
                      </li>
                      <li className="d-flex align-items-center pb-3">
                        <span
                          className="fw-bold mx-2"
                          style={{ marginRight: "50px", width: "45%" }}
                        >
                          State:
                        </span>
                        <span>{(data && data.data.state) || "DSA State"}</span>
                      </li>
                      <li className="d-flex align-items-center pb-3">
                        <span
                          className="fw-bold mx-2"
                          style={{ marginRight: "50px", width: "45%" }}
                        >
                          Phone Number:
                        </span>
                        <span>{(data && data.data.phone) || "DSA Phone"}</span>
                      </li>
                      <li className="d-flex align-items-center pb-3">
                        <span
                          className="fw-bold mx-2"
                          style={{ marginRight: "50px", width: "45%" }}
                        >
                          Email:
                        </span>
                        <span>{(data && data.data.email) || "DSA email"}</span>
                      </li>
                     
                      <li className="d-flex align-items-center pb-3">
                        <span className="fw-bold mx-2" style={{ width: "45%" }}>
                          Document Type:
                        </span>
                        <span>{data && data.data.document_file ? (
                              <a
                                href={data.data.document_file}
                                target="_blank"
                                rel="noreferrer"
                                onClick={onButtonClick}
                                className="text-dark"
                              >
                                
                                  <i className="fa-solid fa-download"></i>{" "}
                                  Download
                              
                              </a>
                            ) : (
                              ""
                            )}</span>
                      </li>

                      <li className="d-flex align-items-center pb-3">
                        <span className="fw-bold mx-2" style={{ width: "45%" }}>
                          Profile:
                        </span >
                        <span style={{ width: "45%" }}>
                          {(data && data.data.profile) || "DSA Profile"}
                        </span>
                      </li>

                      <li className="d-flex align-items-center pb-3">
                            <span className="fw-bold mx-2" style={{ width: "45%" }}>Area Served</span>
                            <span>
                              <div className="state-served d-block">
                                {data.serviceArea.map((area, index) => (
                                  <React.Fragment key={index}>
                                    <div
                                      className="btn text-dark text-decoration-underline py-0 px-1"
                                      data-bs-toggle="modal"
                                      data-bs-target={`#stateId${area.state_id}`}
                                    >
                                      {area.state}
                                      <AreaServedModal area={area} />{" "}
                                    </div>
                                    {index !== data.serviceArea.length - 1 &&
                                      ","}
                                  </React.Fragment>
                                ))}
                              </div>
                            </span>
                          </li>

                      <li className="d-flex align-items-center pb-3">
                        <span
                          className="fw-bold mx-2"
                          style={{ marginRight: "50px", width: "45%" }}
                        >
                          Experience:
                        </span>
                        <span>
                          {(data && data.data.experience) || "DSA Experience"}
                        </span>
                      </li>
                      <li className="d-flex align-items-center pb-3">
                        <span
                          className="fw-bold mx-2"
                          style={{ marginRight: "50px", width: "45%" }}
                        >
                          Type of Activity:
                        </span>
                        <span>
                          {(data && data.data.dsa_activities) || "DSA Activity"}
                        </span>
                      </li>
                      
                    </ul>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                  tabindex="0"
                >
                  <div className="solfin-application-details">
        
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- /card header --> */}
          
        </div>
        {/* <!-- /dash-card --> */}
      </div>

      {/* <!-- /modal --> */}

      <div className="modal" role="alert">
        <div className="modal-content">
          <div className="modal-header">
            <h4>Estimator Details</h4>
            <button className="close" role="button">
              <i className="fa fa-close"></i>
            </button>
          </div>
          <div className="modal-body">
            <ul className="points-list">
              <li>Avg. units consume/month</li>
              <li>Discome rate</li>
              <li>Rooftop area available</li>
              <li>
                %age of consumption to be shifted to solar - connect to grid -{" "}
                <strong>Yes/No</strong>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Details;
