import axios from "axios";
const apikey = process.env.REACT_APP_HOSTNAME;
const apikeyV2 = process.env.REACT_APP_HOSTNAME_v2;

//Get new and rejected  EPC data
export const getNewAndRejectedEpc = async () => {
  try {
    const { data } = await axios.get(`${apikey}/epc/newandrejected`, {
      withCredentials: true,
    });
    // console.log(data);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//my-details
export const myDetails = async () => {
  try {
    const { data } = await axios.get(`${apikey}/epc-dashboard/my-details`, {
      withCredentials: true,
    });
    // console.log(data);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getMyPendingInvoices =async()=>{
  try {
    const { data } = await axios.get(`${apikeyV2}/distributor/invoices/pending`, {
      withCredentials: true,
    });
    // console.log(data);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
}

export const getMyVerifiedInvoices =async()=>{
  try {
    const { data } = await axios.get(`${apikeyV2}/distributor/invoices/verified`, {
      withCredentials: true,
    });
    // console.log(data);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
}

export const getDocumentPresignedUrl = async(documentCode) =>{
  try {
    const { data } = await axios.get(`${apikeyV2}/documents/${documentCode}`, {
      withCredentials: true,
    });
    // console.log(data);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
}

export const getMyLoanSummary = async()=>{
  try {
    const { data } = await axios.get(`${apikey}/distributor-dashboard/loan-summary`, {
      withCredentials: true,
    });
    // console.log(data);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
}

//publishing details
export const insertEpcPublishingDetails = async (formData) => {
  // console.log(formData,"....")
  try {
    const { data } = await axios.post(
      `${apikey}/epc-dashboard/insert-publish-details`,
      formData,
      {
        withCredentials: "true",
      }
    );

    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};



export const reuploadKycDocument = async (requestBody) => {
  try {
    console.log(requestBody);
    const { data } = await axios.put(`${apikey}/distributor/kyc/docs`, 
      requestBody
      ,{
      withCredentials: true,
    });
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//Get deals in data
export const getSectors = async () => {
  try {
    const { data } = await axios.get(`${apikey}/epc-dashboard/deals-sector`, {
      withCredentials: true,
    });
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//get publishing details
export const getPublishingDetails = async () => {
  try {
    const { data } = await axios.get(
      `${apikey}/epc-dashboard/get-publishing-details`,
      {
        withCredentials: "true",
      }
    );
    // console.log(data);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//Logout
export const logout = async () => {
  try {
    const data = await axios.get(`${apikey}/user/logout`, {
      withCredentials: true,
    });
    return data;
  } catch (err) {
    // console.log(err.request.status);
    // return err.request.status;
    throw Error(err.response.data.message);
  }
};

//Get EPC data by its id
export const getEpc = async (id) => {
  try {
    const { data } = await axios.get(`${apikey}/epc/${id}`, {
      withCredentials: true,
    });
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const applicationStatusProvidedByEpc = async () => {
  try {
    const { data } = await axios.get(
      `${apikey}/fields/getApplicationStatusProvidedByEpc`,
      {
        withCredentials: true,
      }
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//change application status
export const changeApplicationStatus = async (updatedData) => {
  try {
    // console.log(updatedData);
    const { id, statusId } = updatedData;
    const { data } = await axios.put(
      `${apikey}/leads/updateApplicationStatus/${id}`,
      {
        statusId,
      },
      { withCredentials: true }
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const allAssignApplicationToEPC = async () => {
  try {
    const { data } = await axios.get(
      `${apikey}/epc-dashboard/assigned-applications`,
      {
        withCredentials: true,
      }
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//Fields
export const getEmpanelledStatus = async () => {
  try {
    const { data } = await axios.get(`${apikey}/fields/getEmpanelledStatus`, {
      withCredentials: true,
    });
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getAllStates = async () => {
  try {
    const { data } = await axios.get(`${apikey}/fields/getallstates`, {
      withCredentials: true,
    });
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};
export const getAllCities = async () => {
  try {
    const { data } = await axios.get(`${apikey}/fields/getallcities`, {
      withCredentials: true,
    });
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};
export const getCitiesById = async (id) => {
  try {
    const { data } = await axios.get(
      `${apikey}/fields/getfilteredcities/${id}`,
      {
        withCredentials: true,
      }
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};
export const getAllCustomerTypes = async () => {
  try {
    // console.log(`${apikey}`)
    const { data } = await axios.get(`${apikey}/fields/getallcustomertypes/`, {
      withCredentials: true,
    });
    // console.log(`${process.env.REACT_APP_HOST}`)
    //  console.log(data)
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//change application status
export const uploadQuoteInApplication = async (updatedData) => {
  try {
    const { id, formData } = updatedData;

    const { data } = await axios.put(
      `${apikey}/epc-dashboard/upload-quote/${id}`,

      formData,

      { withCredentials: true }
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//insert quote
export const insertLoanQuoteOfferByEpc = async (updatedData) => {
  try {
    const { id, formData } = updatedData;

    const { data } = await axios.post(
      `${apikey}/epc-dashboard/insert-quote-offer/${id}`,

      formData,

      { withCredentials: true }
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//get EPC Projects
export const getAllEpcProjects = async () => {
  try {
    // console.log(`${apikey}`)
    const { data } = await axios.get(
      `${apikey}/epc-dashboard/get-all-epc-projects`,
      {
        withCredentials: true,
      }
    );
    // console.log(`${process.env.REACT_APP_HOST}`)
    //  console.log(data)
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//get Project Details
export const getEpcProjectDetail = async (id) => {
  try {
    const { data } = await axios.get(
      `${apikey}/epc-dashboard/get-epc-project-detail/${id}`,
      {
        withCredentials: true,
      }
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//get loan offer data
export const getQuoteLoanOffer = async (id) => {
  try {
    const data = await axios.get(
      `${apikey}/admin-dashboard/get-quote-offer/${id}`
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//get loan offer data
export const getQuoteLoanOfferForEPC = async (id) => {
  try {
    const data = await axios.get(
      `${apikey}/epc-dashboard/get-quote-offer/${id}`
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getProjectStatusProvidedByAdmin = async () => {
  try {
    const data = await axios.get(
      `${apikey}/fields/projectStatusProvidedByAdmin`
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const updateProjectStatus = async (updatedData) => {
  const { id, statusId } = updatedData;
  try {
    const { data } = await axios.put(
      `${apikey}/epc-dashboard/update-project-status/${id}`,
      { statusId },
      {
        withCredentials: true,
      }
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};


export const getMyKycDocument = async()=>{
  try {
    const { data } = await axios.get(`${apikey}/distributor/kyc/docs`);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
}

export const getDistributorKycDocumentUrl = async(documentUploadId)=>{
  try {
    const { data } = await axios.get(`${apikey}/distributor/kyc-docs/${documentUploadId}`);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
}

export const getDistributorInvoiceDocumentUrl = async(documentUploadId)=>{
  try {
    const { data } = await axios.get(`${apikey}/distributor/kyc-docs/${documentUploadId}`);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
}

export const updateInstallationDate = async (updatedData) => {
  const { id, installationDate } = updatedData;
  console.log(installationDate)
  try {
    const { data } = await axios.put(
      `${apikey}/epc-dashboard/update-installation-date/${id}`,
      { installationDate },
      {
        withCredentials: true,
      }
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const uploadNewInvoiceForChannelFinancing = async (formData) => {
  try {
    const { data } = await axios.post(
      `${apikeyV2}/distributor/invoices`,
      formData ,
      {
        withCredentials: true,
      }
    );
    return data;
  } catch (err) {
    console.log(err);
    throw Error(err.response.data.message);
  }
};

export const isValidDistributor= async()=>{
  try {
    const data = await axios.get(`${apikey}/distributor/is-valid`, {
      withCredentials: true,
    });
    return data;
  } catch (err) {
    // console.log(err.request.status);
    // return err.request.status;
    throw Error(err.response.data.message);
  }
} 