import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import {logout} from '../service/DataService'
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from "react-router-dom";

const SideBarItems = ({ data }) => {
  const [isToggle, setIsToggle] = useState(false);
  return (
    <div>
    <li className={!data.subItems ? "" : "sub-menu"}>
      {!data.subItems ? (
        <NavLink to={data.path} end>
          <i className={data.icon}></i> <span>{data.name}</span>
        </NavLink>
      ) : (
        <>
          <button
            onClick={() => setIsToggle((prev) => !prev)}
            className="sidebar-toggle-div cursor-pointer "
          >
            <i className="fa-solid fa-file-lines"></i> <span>{data.name}<i className={`fa right ${isToggle?"fa-angle-up":"fa-angle-down"}`}></i></span>{" "}
            <div className="down-arrow">
              
            </div>
          </button>
          <ul className={isToggle ? "" : "d-none active"}>
            {data.subItems.map((sub) => (
              <li key={sub.subId}>
                <NavLink to={sub.path} end>
                  <span>{sub.subItemsName}</span>
                </NavLink>
              </li>
            ))}
          </ul>
        </>
      )}
    </li>
    </div>
  );
};

const SideBar = () => {
  const [isToggle, setIsToggle] = useState(false);
  // console.log(isToggle);
var sideBarData = [];
  const navigate = useNavigate();

  const Logout = async () => {
    // console.log("Logout")
    const response =await logout()
    //  console.log(response)
     if(response.status===200){
      // console.log("navigate")
       navigate("/login")
     }
   }
 



 
    sideBarData =[
      // { id: 1, name: "My Details",
      //    icon: "fa fa-user", path: "/distributor-dashboard" },
      
      { id: 3, name: "Comfort Letters", icon: "fa-solid fa-file-lines", path:"/manufacturer-dashboard" },
      //{ id: 3, name: "Signed Comfort Letters", icon: "fa-solid fa-file-lines", path:"manufacturer-dashboard/comfort-letters/signed" }
      
    ];
 

  return (
    <>
      <div className="solfin-sidebar">
        <div className="solfin-logo">
          <span>
            <img
              src="/images/solfin-logo.svg"
              alt="Solfin"
              title="Solfin"
            />
          </span>
        </div>
        {/* <!-- /logo --> */}

        <div className="dashboard-navs">
          <ul>
            {sideBarData.map((data) => (
              <SideBarItems key={data.id} data={data} />
            ))}

          </ul>
        </div>
        {/* <div className="logout">
          <button onClick={Logout}>
            <i className="fa-solid fa-right-from-bracket"></i>{" "}
            <span>Logout</span>
          </button>
        </div> */}
      </div>
      {/* <!-- /solfin-siderbar --> */}
    </>
  );
};

export default SideBar;
