import React from "react";
import InvoiceTable from "../Distributor/components/InvoiceTable";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import exportToExcel from "../../components/ExportToExcel";
import {getMyPendingInvoices, getDocumentPresignedUrl} from "../service/DataService";

const PendingInvoices = () => {
  const [isFilterActive, setIsFilterActive] = useState(false);
  const { data, isLoading, error } = useQuery(
    "getMyPendingInvoices",
    getMyPendingInvoices
  );


  const viewInvoice = async( documentCode) =>{
  
    try {
      // Replace with your actual API endpoint
      const response = await getDocumentPresignedUrl(documentCode);
      if (response.data && response.data.preSignedUrl) {
        window.open(response.data.preSignedUrl, '_blank');
      } else {
        toast.error('Error fetching file details, Please try again.');
      }
    } catch (error) {
      console.error('Error fetching the file URL:', error);
    }
  }

  const columns = React.useMemo(
    () => [
      {
        Header: "Sr. No",
        Cell: (row, index) => {
          return index + 1;
        },
      },
      { Header: "Invoice Code", accessor: "invoiceCode" },
      { Header: "Loan Amount Required", accessor: "amountRequired" },
      { Header: "Uploaded At", accessor: "uploadDate" },
      {Header:"View Invoice" ,
          Cell: ({ row }) => (
            <td>
              
              <div className="text-nowrap">   
                <button
                  onClick={() => viewInvoice(row.original.documentCode)}
                  className="ms-2 btn btn-sm green-btn"
                  title="View Invoice"  
                >
                View
                </button></div>
                </td>)
      }
    ],
    [data]
  );
  // Export To Excel
  const handleExport = () => {
    //console.log(data.data.data);
    exportToExcel(
      data.data,
      [
        "Invoice Code",
        "Loan Amount Required",
        "Uploaded At"
      ],
      ["Invoice Code", "Loan Amount Required", "Uploaded At"]
    );
  };
  //const [epcData, setEpcData] = useState([{}])
  const [modalData, setModalData] = useState();

  const navigate = useNavigate();

  if (!data || !data.data) {
    return <>Loading...</>;
  }

  // useEffect(() => {
  //   setEpcData(data)
  // },[data.data])
  // console.log(data)

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!data) {
    return <div>No data fetched.</div>;
  }
  if (!data.data) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="content-body">
        <div className="page-title">
          <h3>Pending Invoices</h3>
        </div>
        <InvoiceTable
          pageNumber={0}
          tableName={"List of Pending Invoices"}
          columns={columns}
          tableData={data && data.data && data.data.invoices}
          handleExport={handleExport}
          isFilterActive={isFilterActive}
          cityallowed
          stateallowed
          startandenddateallowed
          dataQuery ={"getMyPendingInvoices"}
          pepcstatusallowed
          // areaServedallowed
        />
        {/* <!-- /dash-card --> */}
      </div>
      {/* <!-- /content body --> */}
    </>
  );
};

export default PendingInvoices;
