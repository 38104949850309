const ITRDetails = ({values, errors, handleChange, setIsITRConsentChecked, isITRConsentChecked}) => {
    const handleCheckboxChange = (e) => {
        setIsITRConsentChecked(e.target.checked);
      };
    return (
      <>
        <div className="heading mb-xl-4 mb-3">
          <span className="fw-semibold saving-title">Company ITR Details</span>
        </div>
        <div className="row">
            
            <div className="col-lg-6">
            {/* <!-- /form-group --> */}
         
          <div className="">
            <div className="form-group mb-4">
              <label
                htmlFor="itrusername"
                className="border rounded-4 w-100 position-relative"
              >
                <span className="fw-bold">ITR portal user name</span>
                <input
                  type="text"
                  id="itrusername"
                  className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                  placeholder="ITR user name"
                  name="itrUserName"
                  value={values.itrUserName}
                  onChange={handleChange}
                />
              </label>
             
            </div>
            {/* <!-- /form-group --> */}
          </div>
          <div className="">
            <div className="form-group mb-4">
              <label
                htmlFor="itrPassword"
                className="border rounded-4 w-100 position-relative"
              >
                <span className="fw-bold">ITR Password</span>
                <input
                  type="password"
                  id="itrPassword"
                  className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                  placeholder="Password"
                  name="itrPassword"
                  value={values.itrPassword}
                  onChange={handleChange}
                />
              </label>
        
            </div>
            {/* <!-- /form-group --> */}
          </div>
        
          </div>
          <div
        id="hideformcheckbox"
        className="check-conditions d-flex justify-content-between align-items-center hideform"
      >
        <label
          htmlFor="AllowITRTerms"
          className="checkboxes-view p-0 position-relative d-flex"
        >
          <input
            type="checkbox"
            checked={isITRConsentChecked}
            onChange={handleCheckboxChange}
            name=""
            className="position-absolute top-0"
            id="AllowITRTerms"
          />
          <div className="now-check position-relative mt-0"></div>
          <p className="mb-0" style={{ "line-height": "25px" }}>
          I hereby give my consent to Solfin, its NBFC partner- Dhanvikas Fiscal Services Private Limited and its third party service providers, to fetch my firm's ITR Form from Income Tax Portal for the purpose of credit underwriting for availing credit limit.
          </p>
        </label>
      </div>
        </div>
      </>
    );
  };
  
  export default ITRDetails;
  