import React from "react";

const Pagination = ({ activePage, totalPages, onPageChange }) => {
  const handlePageClick = (pageNumber) => {
    onPageChange(pageNumber);
  };

  const generatePageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={`page-item me-2 ${activePage === i ? "active" : ""}`}
        >
          <a className="page-link" href="#" onClick={() => handlePageClick(i)}>
            {i}
          </a>
        </li>
      );
    }
    return pageNumbers;
  };

  return (
    <nav aria-label="Page navigation example">
      <ul className="pagination mt-lg-4 mt-3">
        <li className="page-item me-2">
          <button
            className="page-link"
            onClick={() => handlePageClick(activePage - 1)}
            disabled={activePage === 1}
          >
            <i className="fa fa-angle-left"></i>
          </button>
        </li>
        {generatePageNumbers()}
        <li className="page-item me-2">
          <button
            className="page-link"
            onClick={() => handlePageClick(activePage + 1)}
            disabled={activePage === totalPages}
          >
            <i className="fa fa-angle-right"></i>
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
