import { Route, Routes, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import AppliedApplications from "../pages/applications/AppliedApplications";
import NewApplication from "../pages/applications/NewApplication";
import Projects from "../pages/projects/Projects";
import Status from "../pages/status/Status";
import ComingSoon from "../pages/comingSoon/ComingSoon";
import ChangePassword from "../pages/profile/ChangePassword";
import Profile from "../pages/profile/Profile";
import RegistrationComplete from "../pages/registrationComplete/RegistrationComplete";

const CustomerRoutes = () => {
  return (
    <>
      <Routes>
        <Route
          path="/customer-dashboard"
          element={
            <>
              <Header />
              <AppliedApplications />
            </>
          }
        />
        <Route
          path="/customer/new-application"
          element={
            <>
              <Header />
              <NewApplication />
            </>
          }
        />
        <Route
          path="/customer/projects"
          element={
            <>
              <Header />
              <Projects />
            </>
          }
        />
        <Route
          path="/customer/status/:id"
          element={
            <>
              <Header />
              <Status />
            </>
          }
        />
        <Route
          path="/customer/monitoring"
          element={
            <>
              <Header />
              <ComingSoon />
            </>
          }
        />
        <Route
          path="/customer/profile"
          element={
            <>
              <Header />
              <Profile />
            </>
          }
        />
        <Route
          path="/customer/change-password"
          element={
            <>
              <ChangePassword />
            </>
          }
        />
        <Route
          path="/customer/registration-complete"
          element={
            <>
              <RegistrationComplete />
            </>
          }
        />
      </Routes>
    </>
  );
};

export default CustomerRoutes;
