import React, { useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import * as queryData from "../../service/dataService";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import backpage from "../../../assets/images/backpage.svg";
import appreceived from "../../../assets/images/app-received.svg";
import location from "../../../assets/images/location.svg";
import proposal from "../../../assets/images/proposal.svg";
import rocket from "../../../assets/images/rocket.svg";
import shared from "../../../assets/images/shared.svg";
import scheduled from "../../../assets/images/scheduled.svg";
import { Tooltip } from "react-tooltip";

const Status = () => {
  const fileInputRef = useRef(null);
  const { id } = useParams();
  const queryClient = useQueryClient();
  // variable for file upload
  const [contract, setContract] = useState("");
  const [agreement, setAgreement] = useState("");
  const [isContractUploaded, setContractUploaded] = useState(false);
  const [isAgreementUploaded, setAgreementUploaded] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  // const { values, handleBlur, handleChange, handleSubmit, errors, touched } =
  // useFormik({
  //   onSubmit: (values, action) => {
  const formData = new FormData();
  formData.append("roofTopSignImage", contract);
  formData.append("loanAgreementSignImage", agreement);
  // insertSignedProposal.mutate({id, formData});
  //     // action.resetForm();
  //   },
  // });
  const handleSubmit = () => {
    if (document.getElementById("contract")) {
      document.getElementById("contract").value = "";
    }

    if (document.getElementById("agreement")) {
      document.getElementById("agreement").value = "";
    }

    insertSignedProposal.mutate({ id, formData });

    // if (isContractUploaded) {
    //   insertSignedProposal.mutate({ id, formData });
    //   setIsSubmit(true);
    // } else {
    //   toast.error("Please upload document before submitting.");
    // }
  };

  const insertSignedProposal = useMutation(
    queryData.insertSignedProposalDocuments,
    {
      onError: (error) => {
        toast.error(error.message);
        // console.log(error);
      },
      onSuccess: (data) => {
        toast.success("Documents uploaded Successfully");
        queryClient.invalidateQueries("getQuoteLoanOffer");
      },
    }
  );

  const onButtonClick = () => {
    const image_url =
      data && data.data && data.data.data && data.data.data.elec_bill_image;
    saveAs(image_url, "file");
  };

  const getQuoteLoanOffer = useQuery(["getQuoteLoanOffer", +id], () =>
    queryData.getQuoteLoanOffer(id)
  );

  // console.log(getQuoteLoanOffer, "quoteloanoffer")
  const applicationStatus = useQuery(
    "getApplicationStatus",
    queryData.getApplicationStatus
  );

  const { data, isLoading, error } = useQuery(
    ["getSingleApplicationOfLoggedInUser", +id],
    () => queryData.getSingleApplicationOfLoggedInUser(id)
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!data || !data.data || !data.data.data) {
    return <div>No data fetched.</div>;
  }

  //Status
  let currentStatusCode = "";

  if (data && data.data && data.data.data) {
    currentStatusCode =
      data && data.data && data.data.data && data.data.data.status_code;
  }

  const listItems = [
    {
      status: "Application received",
      iconSrc: appreceived,
      statusCode: "OPEN",
    },
    {
      status: "Site visit scheduled",
      iconSrc: scheduled,
      statusCode: "SITEVISITSCHEDULED",
    },
    { status: "Survey Done", iconSrc: location, statusCode: "SURVEYDONE" },
    { status: "Proposal Shared", iconSrc: shared, statusCode: "PROPOSALSENT" },
    {
      status: "Contract Signed",
      iconSrc: proposal,
      statusCode: "PROPOSALACCEPTED",
    },
    { status: "Project initiated", iconSrc: rocket, statusCode: "INITIATED" },
    // Add more status and icons here
  ];

  // Function to handle special status code 'QUOTESHARED' as 'SURVEYDONE'
  function getEquivalentStatusCode(statusCode) {
    if (statusCode === "QUOTESHARED") {
      return "SURVEYDONE";
    } else if (statusCode === "PASSEDTOEPC") {
      return "OPEN";
    } else if (statusCode === "SITEVISITDECLINED") {
      return "OPEN";
    }
    return statusCode;
  }

  // Assuming you have a variable named 'currentStatusCode' with the current status code
  const equivalentStatusCode = getEquivalentStatusCode(currentStatusCode);

  const statusIndex = listItems.findIndex(
    (item) => item.statusCode === equivalentStatusCode
  );
  const isStatusFound = statusIndex !== -1;

  //Loan Status
  const loanStatusItems = [
    {
      status: "Loan Applied",
      iconSrc: appreceived,
      statusCode: "LOANAPPLIED",
    },
    {
      status: "Application Approved",
      iconSrc: appreceived,
      statusCode: "LOANAPPROVED",
    },
    {
      status: "Application Disbursed",
      iconSrc: appreceived,
      statusCode: "LOANDISBURSED",
    },
  ];

  const currentStatusItem = loanStatusItems.find(
    (item) => item.statusCode === currentStatusCode
  );

  const downloadContract = () => {
    const doc_url1 =
      getQuoteLoanOffer &&
      getQuoteLoanOffer.data &&
      getQuoteLoanOffer.data.data &&
      getQuoteLoanOffer.data.data.data &&
      getQuoteLoanOffer.data.data.data.rooftop_solar_contract;
    //  console.log(doc_url1, "contract")
    saveAs(doc_url1, "Rooftop_Solar_Contract");
  };

  const downloadAgreement = () => {
    const doc_url2 =
      getQuoteLoanOffer &&
      getQuoteLoanOffer.data &&
      getQuoteLoanOffer.data.data &&
      getQuoteLoanOffer.data.data.data &&
      getQuoteLoanOffer.data.data.data.loan_agreement;

    saveAs(doc_url2, "Loan_Agreement");
  };

  const handleContract = (e) => {
    setContract(e.target.files[0]);
    // console.log(e.target.files[0]);
    document.getElementById("contract").value = e.target.files[0].name || "";
    setContractUploaded(true);
  };
  const handleAgreement = (e) => {
    setAgreement(e.target.files[0]);
    // console.log(e.target.files[0]);
    document.getElementById("agreement").value = e.target.files[0].name || "";
    setAgreementUploaded(true);
  };

  const coordinates =
    data && data.data && data.data.data && data.data.data.google_coordinates;
  let formattedLatitude = "";
  let formattedLongitude = "";
  if (coordinates) {
    const [latitude, longitude] = coordinates.split(",").map(parseFloat);
    formattedLatitude = latitude.toFixed(2);
    formattedLongitude = longitude.toFixed(2);
  }
  //Status message
  const statusMessages = [
    {
      status: "IN_PROGRESS",
      displayStatus: "In Progress",
      loanStatus: "Form Incomplete",
      buttonText: "Complete form",
      message: "Please fill the loan application form.",
    },
    {
      status: "PENDING",
      displayStatus: "KYC Processing",
      loanStatus: "Form Completed",
      buttonText: "View Form",
      message: "KYC approval in process.",
    },
    {
      status: "APPROVED",
      displayStatus: "Loan Processing",
      loanStatus: "Form Completed",
      buttonText: "View Form",
      message: "KYC is complete, loan amount approval in process.",
    },
    {
      status: "PRE_DISBURSED",
      displayStatus: "Loan Approved",
      loanStatus: "Form Completed",
      buttonText: "View Form",
      message: "Loan is approved and agreement is shared.",
    },
    {
      status: "REJECTED",
      displayStatus: "Rejected",
      loanStatus: "Form Completed",
      buttonText: "View Form",
      message: "Loan is not approved.",
    },
    {
      status: "READY_FOR_DISPERSAL",
      displayStatus: "Loan Processed",
      loanStatus: "Form Completed",
      buttonText: "View Form",
      message: "Loan formalities are complete.",
    },
    {
      status: "DISPERSED",
      displayStatus: "Loan Disbursed",
      loanStatus: "Form Completed",
      buttonText: "View Form",
      message: "Loan amount is disbursed.",
    },
    {
      status: "NO_STATUS",
      displayStatus: "Loan Not Applied",
      loanStatus: "Form Incomplete",
      buttonText: "View Form",
      message: "Please fill the loan application form.",
    },
    {
      status: "NOTAPPLIED",
      displayStatus: "Loan Not Applied",
      loanStatus: "You havn't applied for loan",
      buttonText: "Apply Now",
      message: "Please fill the loan application form.",
    },
    {
      status: "DEFAULT_STATUS",
      displayStatus: "Error Fetching",
      loanStatus: "Error While Fetching Loan Status ",
      buttonText: "Apply Now",
      message: "Please fill the loan application form.",
    },
  ];

  const loanStatus =
    data.data.response.records.length === 0
      ? "NO_STATUS"
      : data.data.response.records[0].status;

  const statusMessageObject = statusMessages.find(
    (record) => record.status === loanStatus
  );
  const currentStatusIndex =
    applicationStatus &&
    applicationStatus.data &&
    applicationStatus.data.map((app) => app.status === data.data.data.status);

  if (!getQuoteLoanOffer.data) {
    <div>Loading...</div>;
  }
  console.log("getQuoteLoanOffer");
  console.log(getQuoteLoanOffer);
  return (
    <section>
      <div className="solfin-profile cunsumer-top-space header-space pt-1 pb-3">
        <div className="container-md">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center mb-2 ms-ng-5">
                <div className="backtopage me-lg-5 me-4">
                  <Link to="/customer-dashboard">
                    <img
                      src={backpage}
                      alt="Back To Page"
                      title="Back To Page"
                    />
                  </Link>
                </div>
                <h5 className="fw-bold mb-0">Status</h5>
              </div>

              <div className="tab-steps-wrap flex-row justify-content-center">
                {/* <ul className="tab-steps--list d-flex align-items-center w-100 p-0 m-0 position-relative justify-content-between">
                    <li className="current">
                        <div className="profile-step-icon rounded-pill d-flex align-items-center justify-content-center m-auto">
                            <img src={appreceived} title="App Received" alt="App Received"/>
                        </div>
                        <span className="text-nowrap pt-4 d-flex align-items-center justify-content-center text-center">Application<br/>received</span>
                    </li>
                    <li className="current">
                        <div className="profile-step-icon rounded-pill d-flex align-items-center justify-content-center m-auto">
                            <img src={scheduled} title="Scheduled" alt="Scheduled"/>
                        </div>
                        <span className="text-nowrap pt-4 d-flex align-items-center justify-content-center text-center">Site visit<br/>scheduled</span>
                    </li>
                    <li className="current">
                      <div className="profile-step-icon rounded-pill d-flex align-items-center justify-content-center m-auto">
                          <img src={location} title="Scheduled" alt="Scheduled"/>
                      </div>
                      <span className="text-nowrap pt-4 d-flex align-items-center justify-content-center text-center">Survey Done</span>
                  </li>
                  <li className="current">
                        <div className="profile-step-icon rounded-pill d-flex align-items-center justify-content-center m-auto">
                            <img src={shared} title="Location" alt="Location"/>
                        </div>
                        <span className="text-nowrap pt-4 d-flex align-items-center justify-content-center text-center">Proposal shared</span>
                    </li>
                    <li className="current">
                      <div className="profile-step-icon rounded-pill d-flex align-items-center justify-content-center m-auto">
                          <img src={proposal} title="Location" alt="Location"/>
                      </div>
                      <span className="text-nowrap pt-4 d-flex align-items-center justify-content-center text-center">Contract Signed</span>
                  </li>
                  <li className="current">
                        <div className="profile-step-icon rounded-pill d-flex align-items-center justify-content-center m-auto">
                            <img src={rocket} title="Proposal" alt="Proposal"/>
                        </div>
                        <span className="text-nowrap pt-4 d-flex align-items-center justify-content-center text-center">Project initiated</span>
                    </li>
                </ul> */}
                <ul className="tab-steps--list d-flex align-items-center w-100 p-0 m-0 position-relative justify-content-between">
                  {listItems.map((app, index) => {
                    const isCurrent = !isStatusFound || index <= statusIndex;
                    return (
                      <li key={index} className={isCurrent ? "current" : ""}>
                        <div className="profile-step-icon rounded-pill d-flex align-items-center justify-content-center m-auto">
                          <img
                            src={app.iconSrc}
                            title={app.label}
                            alt={app.label}
                          />
                        </div>
                        <span className="text-nowrap pt-2 d-flex align-items-center justify-content-center text-center">
                          {app.status}
                        </span>
                      </li>
                    );
                  })}
                </ul>

                <ul className="tab-steps position-relative list-unstyled ps-0 mt-2">
                  <li className="tab-steps-one current">
                    <nav
                      className="nav nav-tabs border-0"
                      id="myTab"
                      role="tablist"
                    >
                      <a
                        className="nav-link active"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#home-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="home-tab-pane"
                        aria-selected="true"
                      >
                        Application
                      </a>
                      { getQuoteLoanOffer.data.data.data || data.data.data.status_id=='16' ||data.data.data.status_id=='17' ? 
                      <a
                        className="nav-link"
                        id="profile-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#profile-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="profile-tab-pane"
                        aria-selected="false"
                      >
                        Proposal
                      </a>
                      : '' }
                    </nav>

                    <div className="tab-content mt-1" id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="home-tab-pane"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                        tabindex="0"
                      >
                        <div className="card rounded-4 d-none">
                          <div className="card-header ps-4 pe-4 pt-3 pb-3 rounded-top-4">
                            <span className="fw-semibold">
                              Loan Application Form
                            </span>
                          </div>
                          {/* <!-- /card-header --> */}
                          <div className="card-body ps-4 pe-4">
                            <div className="d-flex justify-content-between align-items-center pt-xl-4 pt-3 pb-xl-4 pb-3">
                              <div className="completed w-100">
                                You have declined the Site Visit! Want to
                                schedule it again?
                              </div>
                              {/* <!-- /completed --> */}
                              <div className="view-form">
                                <a
                                  href="#"
                                  className="btn btn-primary btn-header rounded-3 w-100 text-nowrap text-capitalize green-btn calculate-btn pt-2 pb-2"
                                >
                                  Contact Us
                                </a>
                              </div>
                            </div>
                          </div>
                          {/* <!-- /card-body --> */}
                        </div>
                        {/* <!-- /card -->  */}

                        <div className="card rounded-4 mt-2">
                          <div className="card-header ps-4 pe-4 pt-3 pb-3 rounded-top-4">
                            <span className="fw-semibold">
                              Loan Application Form
                            </span>
                          </div>
                          {/* <!-- /card-header --> */}
                          <div className="card-body ps-4 pe-4">
                            <div className="pt-1 pb-1">
                              <div className="row justify-content-between">
                                <div className="col-md-8">
                                  <div className="completed w-100 d-flex align-items-center">
                                    {/* <i className="fa-regular fa-circle-check"></i>  */}
                                    <span className="fw-medium ms-2">
                                      {statusMessageObject &&
                                      statusMessageObject.loanStatus
                                        ? statusMessageObject.loanStatus
                                        : ""}
                                    </span>
                                    <div className="view-application-form fw-semibold ms-lg-5 ms-4">
                                      <a
                                        href={
                                          "https://finance.solfin.co.in/onboard/#/login"
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {statusMessageObject &&
                                        statusMessageObject.buttonText
                                          ? statusMessageObject.buttonText
                                          : ""}
                                      </a>
                                    </div>
                                  </div>
                                  {/* <!-- /completed --> */}
                                </div>
                                {/* <!-- /col-md-7 --> */}
                                <div className="col-md-4">
                                  <div className="loan-status-view d-flex align-items-center justify-content-end">
                                    <div className="load-progress">
                                      {/* <ul className="list-unstyled m-0 p-0 d-flex align-items-center justify-content-between position-relative">
                                              {loanStatusItems.map((items,index)=>{
                                                const isCurrent = index <= loanStatusItems.findIndex(item => item.statusCode === currentStatusCode);
                                                return <li className={isCurrent?"completed":""}><span className="rounded-pill d-flex"></span></li>
                                              })}
                                             
                                            </ul> */}
                                      <strong>Status: </strong>{" "}
                                      {statusMessageObject &&
                                      statusMessageObject.displayStatus
                                        ? statusMessageObject.displayStatus
                                        : ""}
                                      <a
                                        className="info icon-info-sign"
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content={
                                          statusMessageObject &&
                                          statusMessageObject.message
                                            ? statusMessageObject.message
                                            : ""
                                        }
                                      >
                                        <i className="fa fa-circle-info"></i>
                                      </a>
                                      <Tooltip id="my-tooltip" />
                                    </div>
                                    <span className="text-nowrap ms-lg-5"></span>
                                  </div>
                                </div>
                                {/* <!-- /col-md-4 --> */}
                              </div>
                              {/* <!-- /row -->                                       */}
                            </div>
                          </div>
                          {/* <!-- /card-body --> */}
                        </div>
                        {/* <!-- /card -->  */}

                        <div className="card rounded-4 mt-4  d-none">
                          <div className="card-header ps-4 pe-4 pt-3 pb-3 rounded-top-4">
                            <span className="fw-semibold">
                              Registration Details
                            </span>
                          </div>
                          {/* <!-- /card-header --> */}
                          <div className="card-body ps-4 pe-4">
                            <div className="d-flex justify-content-between align-items-center pt-xl-4 pt-3 pb-xl-4 pb-3">
                              <div className="completed w-100">
                                <img
                                  src="../images/warning.svg"
                                  alt="Warning"
                                  title="Warning"
                                  height="26"
                                />{" "}
                                <span className="fw-medium ms-2">
                                  Incomplete
                                </span>
                              </div>
                              {/* <!-- /completed --> */}
                              <div className="view-form">
                                <a
                                  href="#"
                                  className="btn btn-primary btn-header rounded-3 w-100 text-nowrap text-capitalize green-btn calculate-btn pt-2 pb-2"
                                >
                                  Complete the form
                                </a>
                              </div>
                            </div>
                          </div>
                          {/* <!-- /card-body --> */}
                        </div>
                        {/* <!-- /card --> */}

                        <div className="card rounded-4 mt-4 d-none">
                          <div className="card-body ps-4 pe-4">
                            <div className="d-flex justify-content-between align-items-center pt-xl-4 pt-3 pb-xl-4 pb-3">
                              <div className="completed w-100">
                                <span className="fw-medium ms-2">
                                  You haven't applied for financing. If you have
                                  changed your mind, apply now!
                                </span>
                              </div>
                              {/* <!-- /completed --> */}
                              <div className="view-form">
                                <a
                                  href="#"
                                  className="btn btn-primary btn-header rounded-3 w-100 text-nowrap text-capitalize green-btn calculate-btn pt-2 pb-2"
                                >
                                  Fill loan application form
                                </a>
                              </div>
                            </div>
                          </div>
                          {/* <!-- /card-body --> */}
                        </div>
                        {/* <!-- /card --> */}
                        {data && data.data && data.data.data ? (
                          <div className="card rounded-4 mt-4">
                            <div className="card-header ps-4 pe-4 pt-3 pb-3 rounded-top-4">
                              <span className="fw-semibold">
                                Registration Details
                              </span>
                            </div>
                            {/* <!-- /card-header --> */}
                            <div className="card-body ps-4 pe-4">
                              {/* {data.data.data.map((data) => ( */}
                              <div className="registration-details d-flex justify-content-between align-items-center pt-0 pb-4">
                                <ul className="list-unstyled p-0 w-100 d-flex flex-wrap justify-content-between align-items-center">
                                  <li>
                                    <div className="border-bottom pt-1 pb-1 d-flex justify-content-between align-items-center">
                                      <span className="opacity-75 mt-1 mb-1">
                                        Registered on{" "}
                                      </span>
                                      <span className="fw-medium mt-1 mb-1">
                                        {data &&
                                        data.data &&
                                        data.data.data &&
                                        data.data.data.created_on
                                          ? data.data.data.created_on
                                          : ""}
                                      </span>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="border-bottom pt-1 pb-1 d-flex justify-content-between align-items-center">
                                      <span className="opacity-75 mt-1 mb-1">
                                        Registrant Type
                                      </span>
                                      <span className="fw-medium mt-1 mb-1">
                                        {data &&
                                        data.data &&
                                        data.data.data &&
                                        data.data.data.customer_type
                                          ? data.data.data.customer_type
                                          : ""}
                                      </span>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="border-bottom pt-1 pb-1 d-flex justify-content-between align-items-center">
                                      <span className="opacity-75 mt-1 mb-1">
                                        Full Name
                                      </span>
                                      <span className="fw-medium mt-1 mb-1">
                                        {data &&
                                        data.data &&
                                        data.data.data &&
                                        data.data.data.customer_name
                                          ? data.data.data.customer_name
                                          : ""}
                                      </span>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="border-bottom pt-1 pb-1 d-flex justify-content-between align-items-center">
                                      <span className="opacity-75 mt-1 mb-1">
                                        Email
                                      </span>
                                      <span className="fw-medium mt-1 mb-1">
                                        {data &&
                                        data.data &&
                                        data.data.data &&
                                        data.data.data.email
                                          ? data.data.data.email
                                          : ""}
                                      </span>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="border-bottom pt-1 pb-1 d-flex justify-content-between align-items-center">
                                      <span className="opacity-75 mt-1 mb-1">
                                        Phone
                                      </span>
                                      <span className="fw-medium mt-1 mb-1">
                                        {data &&
                                        data.data &&
                                        data.data.data &&
                                        data.data.data.phone
                                          ? data.data.data.phone
                                          : ""}
                                      </span>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="border-bottom pt-1 pb-1 d-flex justify-content-between align-items-center">
                                      <span className="opacity-75 mt-1 mb-1">
                                        Address
                                      </span>
                                      <span className="fw-medium mt-1 mb-1">
                                        {data &&
                                        data.data &&
                                        data.data.data &&
                                        data.data.data.address1
                                          ? data.data.data.address1
                                          : ""}
                                      </span>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="border-bottom pt-1 pb-1 d-flex justify-content-between align-items-center">
                                      <span className="opacity-75 mt-1 mb-1">
                                        State
                                      </span>
                                      <span className="fw-medium mt-1 mb-1">
                                        {data &&
                                        data.data &&
                                        data.data.data &&
                                        data.data.data.state
                                          ? data.data.data.state
                                          : ""}
                                      </span>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="border-bottom pt-1 pb-1 d-flex justify-content-between align-items-center">
                                      <span className="opacity-75 mt-1 mb-1">
                                        City
                                      </span>
                                      <span className="fw-medium mt-1 mb-1">
                                        {data &&
                                        data.data &&
                                        data.data.data &&
                                        data.data.data.city
                                          ? data.data.data.city
                                          : ""}
                                      </span>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="border-bottom pt-1 pb-1 d-flex justify-content-between align-items-center">
                                      <span className="opacity-75 mt-1 mb-1">
                                        Pincode
                                      </span>
                                      <span className="fw-medium mt-1 mb-1">
                                        {data &&
                                        data.data &&
                                        data.data.data &&
                                        data.data.data.pincode
                                          ? data.data.data.pincode
                                          : ""}
                                      </span>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="border-bottom pt-1 pb-1 d-flex justify-content-between align-items-center">
                                      <span className="opacity-75">
                                        Location on map
                                      </span>
                                      <span className="fw-medium d-flex align-items-center">
                                        {formattedLatitude} ,{" "}
                                        {formattedLongitude}
                                        {/* <a href="#">
                                            <img className="ms-2" src="../images/place.svg" alt="Location" title="Location" height="20"/>
                                            </a> */}
                                      </span>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="border-bottom pt-1 pb-1 d-flex justify-content-between align-items-center">
                                      <span className="opacity-75 mt-1 mb-1">
                                        PAN
                                      </span>
                                      <span className="fw-medium mt-1 mb-1">
                                        {data &&
                                        data.data &&
                                        data.data.data &&
                                        data.data.data.pan
                                          ? data.data.data.pan
                                          : ""}
                                      </span>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="border-bottom pt-1 pb-1 d-flex justify-content-between align-items-center">
                                      <span className="opacity-75 mt-1 mb-1">
                                        Electricity Bill
                                      </span>
                                      <span className="fw-medium d-flex align-items-center mt-1 mb-1">
                                        {data &&
                                        data.data &&
                                        data.data.data &&
                                        data.data.data.elec_bill_image ? (
                                          <Link
                                            to={data.data.data.elec_bill_image}
                                            target="_blank"
                                          >
                                            <button
                                              className="btn text-dark text-green"
                                              onClick={onButtonClick}
                                            >
                                              <i className="fa-solid fa-download"></i>{" "}
                                              Download
                                            </button>
                                          </Link>
                                        ) : (
                                          ""
                                        )}
                                      </span>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="border-bottom pt-1 pb-1 d-flex justify-content-between align-items-center">
                                      <span className="opacity-75 mt-1 mb-1">
                                        Financing Required?
                                      </span>
                                      <span className="fw-medium mt-1 mb-1">
                                        {data &&
                                        data.data &&
                                        data.data.data &&
                                        data.data.data.is_finance_required === 1
                                          ? "Yes"
                                          : "No"}
                                      </span>
                                    </div>
                                  </li>
                                </ul>
                              </div>

                              {/* ))} */}
                            </div>
                            {/* <!-- /card-body --> */}
                          </div>
                        ) : (
                          "No data to Show"
                        )}
                        {/* <!-- /card --> */}
                      </div>
                      {/* <!-- /first tab --> */}

                      <div
                        className="tab-pane fade"
                        id="profile-tab-pane"
                        role="tabpanel"
                        aria-labelledby="profile-tab"
                        tabindex="0"
                      >
                        <div className="card rounded-4 mt-4">
                          <div className="card-header ps-4 pe-4 pt-3 pb-3 rounded-top-4">
                            <span className="fw-semibold">
                              Proposal Details
                            </span>
                          </div>
                          {/* <!-- /card-header --> */}
                          {/* {getQuoteLoanOffer &&
                                      getQuoteLoanOffer.data &&
                                      getQuoteLoanOffer.data.data &&
                                      getQuoteLoanOffer.data.data.data ? ( */}
                          <div className="card-body ps-4 pe-4">
                            <div className="registration-details d-flex flex-wrap justify-content-between align-items-center pt-0 pb-xl-5 pb-4">
                              {/* {getQuoteLoanOffer && getQuoteLoanOffer.data && getQuoteLoanOffer.data.data && getQuoteLoanOffer.data.data.data && getQuoteLoanOffer.data.data.data.map((data)=>(
<> */}
                              <ul className="list-unstyled p-0 w-100 d-flex flex-wrap justify-content-between align-items-center">
                                <li>
                                  <div className="border-bottom pt-1 pb-1 d-flex justify-content-between align-items-center">
                                    <span className="opacity-75 mt-1 mb-1">
                                      Project Name
                                    </span>
                                    <span className="fw-medium mt-1 mb-1">
                                      {getQuoteLoanOffer &&
                                      getQuoteLoanOffer.data &&
                                      getQuoteLoanOffer.data.data &&
                                      getQuoteLoanOffer.data.data.data &&
                                      getQuoteLoanOffer.data.data.data
                                        .project_name
                                        ? getQuoteLoanOffer.data.data.data
                                            .project_name
                                        : ""}
                                    </span>
                                  </div>
                                </li>
                                <li>
                                  <div className="border-bottom pt-1 pb-1 d-flex justify-content-between align-items-center">
                                    <span className="opacity-75 mt-1 mb-1">
                                      Solution
                                    </span>
                                    <span className="fw-medium mt-1 mb-1">
                                      {getQuoteLoanOffer &&
                                      getQuoteLoanOffer.data &&
                                      getQuoteLoanOffer.data.data &&
                                      getQuoteLoanOffer.data.data.data &&
                                      getQuoteLoanOffer.data.data.data.solution
                                        ? getQuoteLoanOffer.data.data.data
                                            .solution
                                        : ""}
                                    </span>
                                  </div>
                                </li>
                                <li>
                                  <div className="border-bottom pt-1 pb-1 d-flex justify-content-between align-items-center">
                                    <span className="opacity-75 mt-1 mb-1">
                                      Capacity
                                    </span>
                                    <span className="fw-medium mt-1 mb-1">
                                      {getQuoteLoanOffer &&
                                      getQuoteLoanOffer.data &&
                                      getQuoteLoanOffer.data.data &&
                                      getQuoteLoanOffer.data.data.data &&
                                      getQuoteLoanOffer.data.data.data.capacity
                                        ? getQuoteLoanOffer.data.data.data
                                            .capacity
                                        : ""}{" "}
                                      KwH
                                    </span>
                                  </div>
                                </li>
                                <li>
                                  <div className="border-bottom pt-1 pb-1 d-flex justify-content-between align-items-center">
                                    <span className="opacity-75 mt-1 mb-1">
                                      Units generated per day
                                    </span>
                                    <span className="fw-medium mt-1 mb-1">
                                      {getQuoteLoanOffer &&
                                      getQuoteLoanOffer.data &&
                                      getQuoteLoanOffer.data.data &&
                                      getQuoteLoanOffer.data.data.data &&
                                      getQuoteLoanOffer.data.data.data
                                        .estimated_unit_generated
                                        ? getQuoteLoanOffer.data.data.data
                                            .estimated_unit_generated
                                        : ""}
                                    </span>
                                  </div>
                                </li>
                                <li>
                                  <div className="border-bottom pt-1 pb-1 d-flex justify-content-between align-items-center">
                                    <span className="opacity-75 mt-1 mb-1">
                                      Type of Panel
                                    </span>
                                    <span className="fw-medium mt-1 mb-1">
                                      {getQuoteLoanOffer &&
                                      getQuoteLoanOffer.data &&
                                      getQuoteLoanOffer.data.data &&
                                      getQuoteLoanOffer.data.data.data &&
                                      getQuoteLoanOffer.data.data.data
                                        .type_of_panel
                                        ? getQuoteLoanOffer.data.data.data
                                            .type_of_panel
                                        : ""}
                                    </span>
                                  </div>
                                </li>
                                <li>
                                  <div className="border-bottom pt-1 pb-1 d-flex justify-content-between align-items-center">
                                    <span className="opacity-75 mt-1 mb-1">
                                      Panel Warranty
                                    </span>
                                    <span className="fw-medium mt-1 mb-1">
                                      {getQuoteLoanOffer &&
                                      getQuoteLoanOffer.data &&
                                      getQuoteLoanOffer.data.data &&
                                      getQuoteLoanOffer.data.data.data &&
                                      getQuoteLoanOffer.data.data.data
                                        .panel_warranty
                                        ? getQuoteLoanOffer.data.data.data
                                            .panel_warranty
                                        : ""}
                                    </span>
                                  </div>
                                </li>
                                <li>
                                  <div className="border-bottom pt-1 pb-1 d-flex justify-content-between align-items-center">
                                    <span className="opacity-75 mt-1 mb-1">
                                      Inverter Type
                                    </span>
                                    <span className="fw-medium mt-1 mb-1">
                                      {getQuoteLoanOffer &&
                                      getQuoteLoanOffer.data &&
                                      getQuoteLoanOffer.data.data &&
                                      getQuoteLoanOffer.data.data.data &&
                                      getQuoteLoanOffer.data.data.data
                                        .inverter_type
                                        ? getQuoteLoanOffer.data.data.data
                                            .inverter_type
                                        : ""}
                                    </span>
                                  </div>
                                </li>
                                <li>
                                  <div className="border-bottom pt-1 pb-1 d-flex justify-content-between align-items-center">
                                    <span className="opacity-75 mt-1 mb-1">
                                      Inverter Warranty
                                    </span>
                                    <span className="fw-medium mt-1 mb-1">
                                      {getQuoteLoanOffer &&
                                      getQuoteLoanOffer.data &&
                                      getQuoteLoanOffer.data.data &&
                                      getQuoteLoanOffer.data.data.data &&
                                      getQuoteLoanOffer.data.data.data
                                        .inverter_warranty
                                        ? getQuoteLoanOffer.data.data.data
                                            .inverter_warranty
                                        : ""}
                                    </span>
                                  </div>
                                </li>
                                <li>
                                  <div className="border-bottom pt-1 pb-1 d-flex justify-content-between align-items-center">
                                    <span className="opacity-75 mt-1 mb-1">
                                      Total Cost
                                    </span>
                                    <span className="fw-medium mt-1 mb-1">
                                      {" "}
                                      ₹ {""}
                                      {getQuoteLoanOffer &&
                                      getQuoteLoanOffer.data &&
                                      getQuoteLoanOffer.data.data &&
                                      getQuoteLoanOffer.data.data.data &&
                                      getQuoteLoanOffer.data.data.data
                                        .total_cost
                                        ? getQuoteLoanOffer.data.data.data
                                            .total_cost
                                        : ""}
                                    </span>
                                  </div>
                                </li>
                                <li>
                                  <div className="border-bottom pt-1 pb-1 d-flex justify-content-between align-items-center">
                                    <span className="opacity-75 mt-1 mb-1">
                                      EPC
                                    </span>
                                    <span className="fw-medium mt-1 mb-1">
                                      {getQuoteLoanOffer &&
                                      getQuoteLoanOffer.data &&
                                      getQuoteLoanOffer.data.data &&
                                      getQuoteLoanOffer.data.data.data &&
                                      getQuoteLoanOffer.data.data.data
                                        .company_name
                                        ? getQuoteLoanOffer.data.data.data
                                            .company_name
                                        : ""}
                                    </span>
                                  </div>
                                </li>
                                <li>
                                  <div className="border-bottom pt-1 pb-1 d-flex justify-content-between align-items-center">
                                    <span className="opacity-75 mt-1 mb-1">
                                      DSA
                                    </span>
                                    <span className="fw-medium mt-1 mb-1">
                                      {getQuoteLoanOffer &&
                                      getQuoteLoanOffer.data &&
                                      getQuoteLoanOffer.data.data &&
                                      getQuoteLoanOffer.data.data.data &&
                                      getQuoteLoanOffer.data.data.data.dsa
                                        ? getQuoteLoanOffer.data.data.data.dsa
                                        : ""}
                                    </span>
                                  </div>
                                </li>
                                <li>
                                  <div className="border-bottom pt-1 pb-1 d-flex justify-content-between align-items-center">
                                    <span className="opacity-75 mt-1 mb-1">
                                      Financed By
                                    </span>
                                    <span className="fw-medium mt-1 mb-1">
                                      {getQuoteLoanOffer &&
                                      getQuoteLoanOffer.data &&
                                      getQuoteLoanOffer.data.data &&
                                      getQuoteLoanOffer.data.data.data &&
                                      getQuoteLoanOffer.data.data.data
                                        .financed_by
                                        ? getQuoteLoanOffer.data.data.data
                                            .financed_by
                                        : ""}
                                    </span>
                                  </div>
                                </li>
                              </ul>
                              <div className="proposal-download opacity-75 mt-3 mb-2">
                                <span>
                                  Please download the documents and upload a
                                  signed copy :
                                </span>
                              </div>
                              <ul className="list-unstyled p-0 w-100 d-flex flex-wrap justify-content-between align-items-center">
                                <li>
                                  <div className="border-bottom pt-1 pb-1 d-flex justify-content-between align-items-center">
                                    <span className="opacity-75 mt-1 mb-1">
                                      Rooftop Solar Contract
                                    </span>
                                    {getQuoteLoanOffer &&
                                    getQuoteLoanOffer.data &&
                                    getQuoteLoanOffer.data.data &&
                                    getQuoteLoanOffer.data.data.data &&
                                    getQuoteLoanOffer.data.data.data
                                      .rooftop_solar_contract ? (
                                      <span className="fw-medium d-flex align-items-center mt-1 mb-1">
                                        <a
                                          href={
                                            getQuoteLoanOffer.data.data.data
                                              .rooftop_solar_contract
                                          }
                                          className="btn text-dark text-green"
                                          onClick={downloadContract}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          <i className="fa-solid fa-download"></i>{" "}
                                          Download
                                        </a>

                                        <div className="upload-btn btn btn-outline-dark custom-file-uploader">
                                          <input
                                            type="file"
                                            name="contract"
                                            onChange={handleContract}
                                          />
                                          Upload
                                        </div>
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                    <input
                                      type="text"
                                      name="contract"
                                      id="contract"
                                      className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                                      readOnly
                                      ref={fileInputRef}
                                    />
                                    {getQuoteLoanOffer &&
                                    getQuoteLoanOffer.data &&
                                    getQuoteLoanOffer.data.data &&
                                    getQuoteLoanOffer.data.data.data &&
                                    getQuoteLoanOffer.data.data.data
                                      .sign_rooftop_solar_contract ? (
                                      <span className="fw-medium d-flex align-items-center mt-1 mb-1">
                                        <a
                                          className=""
                                          data-tooltip-id="my-tooltip1"
                                          data-tooltip-content="Signed and Submitted"
                                        >
                                          <i className="text-success fa fa-check "></i>
                                        </a>
                                        <Tooltip id="my-tooltip1" />
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </li>
                                <li>
                                  <div className="border-bottom pt-1 pb-1 d-flex justify-content-between align-items-center">
                                    <span className="opacity-75 mt-1 mb-1">
                                      Loan Agreement
                                    </span>
                                    {getQuoteLoanOffer &&
                                    getQuoteLoanOffer.data &&
                                    getQuoteLoanOffer.data.data &&
                                    getQuoteLoanOffer.data.data.data &&
                                    getQuoteLoanOffer.data.data.data
                                      .loan_agreement ? (
                                      <span className="fw-medium d-flex align-items-center mt-1 mb-1">
                                        <a
                                          href={
                                            getQuoteLoanOffer.data.data.data
                                              .loan_agreement
                                          }
                                          onClick={downloadAgreement}
                                          className="btn text-dark text-green"
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          <i className="fa-solid fa-download"></i>{" "}
                                          Download
                                        </a>
                                        <div className="upload-btn btn btn-outline-dark custom-file-uploader">
                                          <input
                                            type="file"
                                            name="agreement"
                                            onChange={handleAgreement}
                                            ref={fileInputRef}
                                          />
                                          Upload
                                        </div>{" "}
                                        <input
                                          type="text"
                                          name="agreement"
                                          id="agreement"
                                          className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                                          readOnly
                                        />
                                        {getQuoteLoanOffer &&
                                        getQuoteLoanOffer.data &&
                                        getQuoteLoanOffer.data.data &&
                                        getQuoteLoanOffer.data.data.data &&
                                        getQuoteLoanOffer.data.data.data
                                          .sign_loan_agreement ? (
                                          <span className="h5">
                                            <a
                                              className=""
                                              data-tooltip-id="my-tooltip1"
                                              data-tooltip-content="Signed and Submitted"
                                            >
                                              <i className="text-success fa fa-check "></i>
                                            </a>
                                            <Tooltip id="my-tooltip1" />
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                          {/* // ): ("No Proposal to show")} */}
                          {/* <!-- /card-body --> */}
                        </div>
                        {/* <!-- /card --> */}

                        <div className="submit-proposal solfin-action text-end mt-lg-5">
                          {getQuoteLoanOffer &&
                          getQuoteLoanOffer?.data &&
                          getQuoteLoanOffer?.data?.data?.data ? (
                            <>
                              {getQuoteLoanOffer &&
                              getQuoteLoanOffer.data &&
                              getQuoteLoanOffer.data.data &&
                              getQuoteLoanOffer.data.data.data &&
                              getQuoteLoanOffer.data.data.data
                                .is_signed_document_approved &&
                              getQuoteLoanOffer.data.data.data
                                .is_signed_document_approved ? (
                                <button
                                  onClick={handleSubmit}
                                  className="btn btn-primary text-capitalize calculate-btn fw-semibold rounded-3 w-100"
                                  disabled="true"
                                >
                                  {" "}
                                  Submitted
                                </button>
                              ) : getQuoteLoanOffer &&
                                getQuoteLoanOffer.data &&
                                getQuoteLoanOffer.data.data &&
                                getQuoteLoanOffer.data.data.data &&
                                getQuoteLoanOffer.data.data.data
                                  .rooftop_solar_contract &&
                                !getQuoteLoanOffer.data.data.data
                                  .sign_rooftop_solar_contract ? (
                                <>
                                  {insertSignedProposal.isLoading ? (
                                    <button
                                      class="ms-auto btn btn-primary text-capitalize calculate-btn fw-semibold rounded-3 w-100 opacity-100 spinner-btn2"
                                      type="button"
                                      disabled
                                    >
                                      <span
                                        class="spinner-border spinner-border-sm"
                                        aria-hidden="true"
                                      ></span>
                                      <span
                                        class="visually-hidden"
                                        role="status"
                                      >
                                        Loading...
                                      </span>
                                    </button>
                                  ) : (
                                    <button
                                      onClick={handleSubmit}
                                      className="btn btn-primary text-capitalize calculate-btn fw-semibold rounded-3 w-100"
                                    >
                                      {" "}
                                      Submit
                                    </button>
                                  )}
                                </>
                              ) : getQuoteLoanOffer &&
                                getQuoteLoanOffer.data &&
                                getQuoteLoanOffer.data.data &&
                                getQuoteLoanOffer.data.data.data &&
                                getQuoteLoanOffer.data.data.data
                                  .loan_agreement &&
                                !getQuoteLoanOffer.data.data.data
                                  .sign_loan_agreement ? (
                                <>
                                  {insertSignedProposal.isLoading ? (
                                    <button
                                      class="ms-auto btn btn-primary text-capitalize calculate-btn fw-semibold rounded-3 w-100 opacity-100 spinner-btn2"
                                      type="button"
                                      disabled
                                    >
                                      <span
                                        class="spinner-border spinner-border-sm"
                                        aria-hidden="true"
                                      ></span>
                                      <span
                                        class="visually-hidden"
                                        role="status"
                                      >
                                        Loading...
                                      </span>
                                    </button>
                                  ) : (
                                    <button
                                      onClick={handleSubmit}
                                      className="btn btn-primary text-capitalize calculate-btn fw-semibold rounded-3 w-100"
                                    >
                                      {" "}
                                      Submit
                                    </button>
                                  )}
                                </>
                              ) : getQuoteLoanOffer &&
                                getQuoteLoanOffer.data &&
                                getQuoteLoanOffer.data.data &&
                                getQuoteLoanOffer.data.data.data &&
                                getQuoteLoanOffer.data.data.data
                                  .rooftop_solar_contract &&
                                getQuoteLoanOffer.data.data.data
                                  .loan_agreement &&
                                !getQuoteLoanOffer.data.data.data
                                  .sign_rooftop_solar_contract &&
                                !getQuoteLoanOffer.data.data.data
                                  .sign_loan_agreement ? (
                                <>
                                  {insertSignedProposal.isLoading ? (
                                    <button
                                      class="ms-auto btn btn-primary text-capitalize calculate-btn fw-semibold rounded-3 w-100 opacity-100 spinner-btn2"
                                      type="button"
                                      disabled
                                    >
                                      <span
                                        class="spinner-border spinner-border-sm"
                                        aria-hidden="true"
                                      ></span>
                                      <span
                                        class="visually-hidden"
                                        role="status"
                                      >
                                        Loading...
                                      </span>
                                    </button>
                                  ) : (
                                    <button
                                      onClick={handleSubmit}
                                      className="btn btn-primary text-capitalize calculate-btn fw-semibold rounded-3 w-100"
                                    >
                                      {" "}
                                      Submit
                                    </button>
                                  )}
                                </>
                              ) : getQuoteLoanOffer &&
                                getQuoteLoanOffer.data &&
                                getQuoteLoanOffer.data.data &&
                                getQuoteLoanOffer.data.data.data &&
                                !getQuoteLoanOffer.data.data.data
                                  .rooftop_solar_contract &&
                                !getQuoteLoanOffer.data.data.data
                                  .loan_agreement ? (
                                <>
                                  {insertSignedProposal.isLoading ? (
                                    <button
                                      class="ms-auto btn btn-primary text-capitalize calculate-btn fw-semibold rounded-3 w-100 opacity-100 spinner-btn2"
                                      type="button"
                                      disabled
                                    >
                                      <span
                                        class="spinner-border spinner-border-sm"
                                        aria-hidden="true"
                                      ></span>
                                      <span
                                        class="visually-hidden"
                                        role="status"
                                      >
                                        Loading...
                                      </span>
                                    </button>
                                  ) : (
                                    <button
                                      onClick={handleSubmit}
                                      className="btn btn-primary text-capitalize calculate-btn fw-semibold rounded-3 w-100"
                                    >
                                      {" "}
                                      Submit
                                    </button>
                                  )}
                                </>
                              ) : getQuoteLoanOffer &&
                                getQuoteLoanOffer.data &&
                                getQuoteLoanOffer.data.data &&
                                !getQuoteLoanOffer.data.data.data ? (
                                <>
                                  {insertSignedProposal.isLoading ? (
                                    <button
                                      class="ms-auto btn btn-primary text-capitalize calculate-btn fw-semibold rounded-3 w-100 opacity-100 spinner-btn2"
                                      type="button"
                                      disabled
                                    >
                                      <span
                                        class="spinner-border spinner-border-sm"
                                        aria-hidden="true"
                                      ></span>
                                      <span
                                        class="visually-hidden"
                                        role="status"
                                      >
                                        Loading...
                                      </span>
                                    </button>
                                  ) : (
                                    <button
                                      onClick={handleSubmit}
                                      className="btn btn-primary text-capitalize calculate-btn fw-semibold rounded-3 w-100"
                                    >
                                      {" "}
                                      Submit
                                    </button>
                                  )}
                                </>
                              ) : (
                                <button
                                  onClick={handleSubmit}
                                  className="btn btn-primary text-capitalize calculate-btn fw-semibold rounded-3 w-100"
                                  disabled="true"
                                >
                                  {" "}
                                  Submitted
                                </button>
                              )}
                            </>
                          ) : (
                            ""
                          )}

                          {/*                           
                            if(getQuoteLoanOffer.data.data.data.is_signed_document_approved){
                               <button
                               onClick={handleSubmit}
                               className="btn btn-primary text-capitalize calculate-btn fw-semibold rounded-3 w-100"
                               disabled ="true"
                             > Submitted</button>
                            }
                            else if (getQuoteLoanOffer.data.data.data.rooftop_solar_contract && !getQuoteLoanOffer.data.data.data.sign_rooftop_solar_contract){
                              <button
                              onClick={handleSubmit}
                              className="btn btn-primary text-capitalize calculate-btn fw-semibold rounded-3 w-100"
                            > Submit</button>
                            }
                            else if (getQuoteLoanOffer.data.data.data.loan_agreement && !getQuoteLoanOffer.data.data.data.sign_loan_agreement){
                              <button
                              onClick={handleSubmit}
                              className="btn btn-primary text-capitalize calculate-btn fw-semibold rounded-3 w-100"
                            > Submit</button>
                            }
                            else if(getQuoteLoanOffer.data.data.data.rooftop_solar_contract && getQuoteLoanOffer.data.data.data.loan_agreement && !getQuoteLoanOffer.data.data.data.sign_rooftop_solar_contract && !getQuoteLoanOffer.data.data.data.sign_loan_agreement){
                              <button
                              onClick={handleSubmit}
                              className="btn btn-primary text-capitalize calculate-btn fw-semibold rounded-3 w-100"
                            > Submit</button>
                            } 
                            else if(!getQuoteLoanOffer.data.data.data.rooftop_solar_contract && !getQuoteLoanOffer.data.data.data.loan_agreement){
                             <button
                             onClick={handleSubmit}
                             className="btn btn-primary text-capitalize calculate-btn fw-semibold rounded-3 w-100"
                           > Submit</button>
                            }
                            else{
                               <button
                               onClick={handleSubmit}
                               className="btn btn-primary text-capitalize calculate-btn fw-semibold rounded-3 w-100"
                               disabled ="true"
                             > Submitted</button>
                            } */}
                        </div>
                      </div>
                      {/* <!-- /second tab --> */}
                    </div>
                    {/* <!-- /tab-content --> */}
                  </li>

                  {/* <li className="tab-steps-two">
                        2345
                    </li>
                    
                    <li className="tab-steps-three">
                        3456
                    </li>
                    <li className="tab-steps--four">
                        4567
                    </li>
                    <li className="tab-steps--five">
                      45895
                  </li>
                  <li className="tab-steps--six">
                    85985
                </li> */}
                </ul>
              </div>
            </div>
            {/* <!-- /col-12 --> */}
          </div>
          {/* <!-- /row --> */}
        </div>
        {/* <!-- /container --> */}
      </div>
      {/* <!-- /my-products --> */}
    </section>
  );
};

export default Status;
