import React from 'react'
import { useQuery } from 'react-query';
import * as querydata from "../dataService/DataService"
import axios from "axios";
const apikey=process.env.REACT_APP_HOSTNAME
const CitySelect = (props) => {
  // //console.log("stateid")
  // //console.log(props.parameter.stateId)
  //console.log("In City component")
  //console.log(props.selectedState)
  const getFilteredCities = useQuery(
    ["getFilteredCities", +props.selectedState],
    () => querydata.getFilteredCities(props.selectedState)
  );
  // const getFilteredCitieslist = async (id) => {
  //   try {
  //     // console.log(id)
  //     //console.log(`${apikey}/fields/getfilteredcities/${id}`)
  //     if(props.selectedState>0){
  //     const { data } = await axios.get(
  //       `${apikey}/fields/getfilteredcities/${id}`
  //     );
  //    // console.log(data)
  //     return data;}
  //   } catch (err) {
  //     throw Error(err.response.data.message);
  //   }
  // };
  // const getFilteredCities=getFilteredCitieslist(props.selectedState)
  // async function fetchDataAndPopulateDropdown() {
  //   try {
  //     if(props.selectedState>0){
  //     const result = await getFilteredCitieslist(props.selectedState);
  
  //     const dropdown = document.getElementById('City');
  //     // Clear existing options in the dropdown if needed
  //     dropdown.innerHTML = '';
  // if(dropdown){
  //     // Populate the dropdown with options based on the fetched data
  //     result.data.forEach(item => {
  //       const option = document.createElement('option');
  //       option.value = item.city_id;
  //       option.textContent = item.city;
  //       if (props.isregister && props.customerValues) {
  //         if (props.selectedState == props.customerValues.stateId) {
  //           if(props.customerValues.cityId==item.city_id){
  //           option.selected = true; }
  //           //props.values.cityId = props.customerValues.cityId;
  //         }
  //       }
  //       dropdown.appendChild(option);
  //     });
  //   }
      
  //     console.log("Data fetched and dropdown populated");}
  //   } catch (error) {
  //     console.error('Error:', error);
  //   }
  // }
  
  // // Call the function to fetch data and populate the dropdown
  // fetchDataAndPopulateDropdown();
  
  // getFilteredCities
  // .then((result) => {
  //   // This callback will execute when the promise is resolved (successfully).
  //   // console.log('Promise resolved with result:', result);
  //   // Perform your action here.
  //   // performActionAfterPromiseCompletion();
  //   const dropdown = document.getElementById('City');
    
  //   // Populate the dropdown with options based on the fetched data
  //   result.forEach(item => {
  //     const option = document.createElement('option');
  //     option.value = item.city_id;
  //     option.textContent = item.city;
  //     dropdown.appendChild(option);
  //   });


  //   if(props.isregister && props.customerValues){
  //     if(props.selectedState==props.customerValues.stateId){
  //       props.values.cityId=props.customerValues.cityId;
  //     }
  //   }
  //   console.log("getFilteredCities")
  //   console.log(getFilteredCities)
  // })
  // .catch((error) => {
  //   // This callback will execute if the promise encounters an error.
  //   // console.error('Promise rejected with error:', error);
  // });
  // //validations

  // if (getFilteredCities.isLoading) {
  //   return <div>Loading...</div>;
  // }

  // if (getFilteredCities.error) {
  //   return <div>Error: {getFilteredCities.error.message}</div>;
  // }

  // if (!getFilteredCities.data) {
  //   return <div>No data fetched.</div>;
  // }

  return (
    <>
      <label
        htmlFor="City"
        className="border rounded-4 w-100 position-relative"
      >
        <span className="fw-bold">City</span>
        <select
          id="city-select-option"
          className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
          name="cityId"
          value={props.values && props.values.cityId}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          disabled={props.disabled}

        >
          <option value="" defaultValue="Select City">
            Select City
          </option>
          {getFilteredCities && getFilteredCities.data && getFilteredCities.data.data &&
            getFilteredCities.data.data.map(
              (cityoption) => {
                return (
                  <option
                    key={cityoption.city_id}
                    value={cityoption.city_id}
                    selected={props.selectedCity ==cityoption.city_id ||(props.customerValues && props.customerValues.cityId==cityoption.city_id)?true:false}
                  >
                    {cityoption.city}
                    
                  </option>
                );
              }
            )}

        </select>
      </label>
      {/* {props.errors.cityId && props.touched.cityId ? (
        <p className="form-error text-danger">
          {props.errors.cityId}
        </p>
      ) : null} */}
    </>
  )
}

export default CitySelect