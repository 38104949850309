import React from "react";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import Export from "../../components/ExportToExcel";
import {getRequestedComfortLetters} from "../service/DataService";
import { toast } from "react-toastify";
import ComfortLetterTable from "../components/ComfortLetterTable";

const RequestedComfortLetters = () => {
  const [isFilterActive, setIsFilterActive] = useState(false);
  const { data, isLoading, error } = useQuery(
    "getRequestedComfortLetters",
    getRequestedComfortLetters
  );

  const viewComfortLetter = (letterCode) => {
    navigate(`/manufacturer-dashboard/comfort-letters/details/${letterCode}`);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Sr. No",
        Cell: (row, index) => {
          return index + 1;
        },
      },
      { Header: "Letter Code", accessor: "letterCode" },
      { Header: "Distributor Name", accessor: "distributorDetails.companyName" },
      { Header: "Requested At", accessor: "requestedDate" },
      {Header:"Generate Letter" ,
          Cell: ({ row }) => (
            <td>
              
              <div className="text-nowrap">   
                <button
                  onClick={() => viewComfortLetter(row.original.letterCode)}
                  className="ms-2 btn btn-sm green-btn"
                  title="Generate Letter"  
                >
                Generate Letter
                </button></div>
                </td>)
      }

    ],
    [data]
  );
  // Export To Excel
  const handleExport = () => {
    //console.log(data.data.data);
    Export(
      data.data.data,
      [
        "letterCode",
       "distributorDetails.companyName",
        "requestedDate",
      ],
      ["Letter Code", "Distributor Name","Requested Date"]
    );
  };
  //const [epcData, setEpcData] = useState([{}])
  const [modalData, setModalData] = useState();

  const navigate = useNavigate();

  if (!data || !data.data) {
    return <>Loading...</>;
  }

  // useEffect(() => {
  //   setEpcData(data)
  // },[data.data])
  // console.log(data)

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!data) {
    return <div>No data fetched.</div>;
  }
  if (!data.data) {
    return <div>Loading...</div>;
  }
  
  return (
    <>
      <div className="content-body">
        <div className="page-title">
          <h3>Comfort Letter Requests</h3>
        </div>
        <ComfortLetterTable
          pageNumber={0}
          tableName={"List of Comfort Letter Requests"}
          columns={columns}
          tableData={data && data.data && data.data.data}
          handleExport={handleExport}
          isFilterActive={isFilterActive}
          dataQuery ={"getRequestedComfortLetters"}
          // areaServedallowed
        />
        {/* <!-- /dash-card --> */}
      </div>
      {/* <!-- /content body --> */}
    </>
  );
};

export default RequestedComfortLetters;
