import React, { useEffect, useState, useRef } from "react";
import DocumentYear from "../../../components/DocumentYear";
const CompanyDocuments = ({requiredCompanyDocuments, error,companyDocs, setErrors, values})=>{
  const getLastThreeCompletedFinancialYears = () => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1; // getMonth() returns 0-11
    let endYear = currentYear;

    // Assume financial year ends on March 31
    if (currentMonth < 4) {
      endYear -= 1;
    }
    const financialYears = [];
    for (let i = 0; i < 3; i++) {
      const startYear = endYear - 1;
      financialYears.push(`${startYear}-${endYear}`);
      endYear -= 1;
    }

    return financialYears;
  };

  const financialYears = getLastThreeCompletedFinancialYears();
    const [file, setfile] = useState("");
    const handlefilechange = (e, fileCode, elementId, fileNumber) => {
      const selectedFile = e.target.files[0];
      const allowedFormats = ["pdf", "jpg", "jpeg", "png"]; // Allowed file formats
      const maxFileSizeInBytes = 2 * 1024 * 1024; // 2 MB
  
      if (selectedFile) {
        const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
        const fileSize = selectedFile.size;
        // Check if the file format is allowed
        if (!allowedFormats.includes(fileExtension)) {
          setfile(null);
          document.getElementById(elementId).value = "";
          error[fileCode] =
            "Invalid file format. Allowed formats: " + allowedFormats.join(", ");
          return;
        }
  
        // Check if the file size exceeds the limit
        if (fileSize > maxFileSizeInBytes) {
          setfile(null);
          document.getElementById(elementId).value = "";
          error[fileCode] = "File size exceeds the limit of 2 MB.";
          return;
        }
  
        // The file format and size are allowed, so set it in the state
  
        console.log("fileNumner  " + fileNumber);
        if (fileNumber != null) {
          const year = document.getElementById(
            "filetextid_" + fileCode + "_" + fileNumber + "_year"
          );
  
          if (year.value == "") {
            error[fileCode] = "Please select year for the file.";
  
            return;
          }
          companyDocs[fileCode + "_" + fileNumber] = selectedFile;
        } else {
          companyDocs[fileCode] = selectedFile;
        }
        setfile(selectedFile);
        document.getElementById(elementId).value = selectedFile.name;
        setErrors({});
      } else {
        setfile(null);
        document.getElementById(elementId).value = "";
        error[fileCode] = "Please Upload Document";
      }
    };
    return(
    <>
    <div className="heading mb-xl-4 mb-3">
      <span className="fw-semibold saving-title">
        Company Documents
      </span>
    </div>
    <div>
      <span className="fw-semibold saving-title">
        <div className="mt-3 mb-3">
          Note: File should be of type pdf, jpg,
          jpeg, png with size less than 2MB.
        </div>
      </span>
    </div>
    <div className="row">
      {requiredCompanyDocuments.map(
        (doc, index) =>
          doc.mode=='AUTOMATIC' || doc.userInputNeeded == false ?'':(doc.documentTimeNeeded === false ? (
            doc.inputType == "TEXT"?   <div className="col-md-12">
            <div className="">
              <label
                htmlFor={
                  "filetextid_" +
                  doc.documentCode
                }
                className="border rounded-4 w-100 position-relative"
              >
                <span className="fw-bold">{doc.documentName}{doc.mandatory == false
                      ? ""
                      : "*"}</span>
                <input
                  type="text"
                  id={
                    "filetextid_" +
                    doc.documentCode
                  }
                  className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                  placeholder={doc.documentName}
                  name={doc.documentName}
                  
                />
              </label>

            </div>
            {/* <!-- /form-group --> */}
          </div>:
            <div className="col-md-12">
              <div className="form-group mb-4">
                <label className="border rounded-4 w-100 position-relative">
                  <span className="fw-bold">
                    {doc.documentName}{" "}
                    {doc.mandatory == false
                      ? ""
                      : "*"}
                  </span>
                  <div className="form-group">
                    <input
                      type="text"
                      name={
                        doc.documentCode + "File"
                      }
                      id={
                        "filetextid_" +
                        doc.documentCode
                      }
                      className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                      placeholder="No file selected"
                      readOnly
                    />
                    <span className="input-group-btn unit-label upload-docs position-absolute">
                      <div className="btn btn-primary btn-header green-btn custom-file-uploader">
                        <input
                          type={"file"}
                          name={
                            doc.documentCode +
                            "File"
                          }
                          // onchange="this.form.filename.value = this.files.length ? this.files[0].name : ''"
                          onChange={(e) => {
                            handlefilechange(
                              e,
                              doc.documentCode,
                              "filetextid_" +
                                doc.documentCode,
                              null
                            );
                          }}
                         
                        />
                        Upload
                      </div>
                    </span>
                  </div>
                </label>

                {error[doc.documentCode] ? (
                  <p className="form-error text-danger">
                    {error[doc.documentCode]}
                  </p>
                ) : null}
                
              </div>
              {/* <!-- /form-group --> */}
            </div>
          ) : (
            Array.from({
              length: doc.numberOfFiles,
            }).map((_, index) => (
              <>
                <div
                  className="row mb-4 padding-right-0"
                  key={index}
                >
                  <div className="col-md-4 form-group mb-3">
                    <label
                      htmlFor="Documenttype"
                      className="border rounded-4 w-100 position-relative"
                    >
                      <span className="fw-bold">
                        {doc.documentName} Year
                      </span>

                      <DocumentYear
                        yearList={financialYears}
                        valueId={
                          "filetextid_" +
                          doc.documentCode +
                          "_" +
                          index +
                          "_year"
                        }
                        valueName={
                          doc.documentCode +
                          "FileYear_" +
                          index
                        }
                      />
                    </label>
                  </div>
                  <div className="col-md-8 form-group padding-right-0">
          
                    <label className="border rounded-4 w-100 position-relative">
                      <span className="fw-bold">
                        {doc.documentName}
                        {doc.mandatory === false
                          ? ""
                          : "*"}
                      </span>
                      <input
                        type="text"
                        name={
                          doc.documentCode +
                          "File_" +
                          index
                        }
                        id={
                          "filetextid_" +
                          doc.documentCode +
                          "_" +
                          index
                        }
                        className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                        placeholder="No file selected"
                        readOnly
                      />
                      <span className="input-group-btn unit-label upload-docs position-absolute">
                        <div className="btn btn-primary btn-header green-btn custom-file-uploader">
                          <input
                            type="file"
                            name={
                              doc.documentCode +
                              "File_" +
                              index
                            }
                            onChange={(e) =>
                              handlefilechange(
                                e,
                                doc.documentCode,
                                "filetextid_" +
                                  doc.documentCode +
                                  "_" +
                                  index,
                                index
                              )
                            }
                            
                          />
                          Upload
                        </div>
                      </span>
                    </label>
                  </div>

                  {error[doc.documentCode] && (
                    <p className="form-error text-danger">
                      {error[doc.documentCode]}
                    </p>
                  )}
                </div>
              </>
            ))
          ))
      )}
      {/* <!-- /col-lg-4 col-md-6 --> */}

      {/* <!-- /col-lg-4 col-md-6 --> */}
    </div>
  </>
    );
}

export default CompanyDocuments;