import React, { useEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "react-query";
import * as querydata from "../../dataService/DataService";
import {
  saveDistirbutorLinkState,
  saveUserConsent,
} from "../../dataService/DataService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../epc/components/multiselect-dropdown.css";
import { useNavigate } from "react-router-dom";

import RegistrationType from "./components/RegistrationType";
import BasicDetails from "./components/BasicDetails";
import PANDetails from "./components/PANDetails";
import GSTDetails from "./components/GSTDetails";
import CompanyDocuments from "./components/CompanyDocuments";
import TermsAndConditions from "./components/TermsAndConditions";
import SignatoryDocument from "./components/SignatoryDocuments";
import ITRDetails from "./components/ITRDetails";
import BankStatementAnalyzer from "./components/BankStatementAnalyzer";
const apikey = process.env.REACT_APP_HOSTNAME;

//client Validation
export const epcRegisterSchema = Yup.object({
  epcName: Yup.string().required("Please enter company name"),
  address: Yup.string().required("Please enter your address"),
  phone: Yup.string()
    .required("Mobile number is required")
    .matches(/^\d{10}$/, "Invalid mobile number"),
  pinCode: Yup.string()
    .required("PinCode is required")
    .length(6, "Pincode must be exactly 6 digits")
    .matches(/^\d+$/, "Pincode must only contain digits"),
  cityId: Yup.number().required("Please select city"),
  registrationTypeId: Yup.number()
    .notOneOf([-1], "Please select Registration Type")
    .required("Please select Registration Type"),
  stateId: Yup.number()
    .notOneOf([-1], "Please select state")
    .required("Please select state"),
});

const DistributorJoinV2 = () => {
  const [selectedStates, setSelectedStates] = useState("-1");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [signatories, setSignatories] = useState(1);
  const [signatoryFiles, setSignatoryFiles] = useState([{}]);
  const [formDataSignatoryFiles, setFormDataSignatoryFiles] = useState([{}]);
  const [companyDocs, setCompanyDocs] = useState({});
  const [formDatacompanyDocs, setFormDataCompanyDocs] = useState({});
  const formRef = useRef(null);
  const [selectedState, setSelectedState] = useState("-1");
  const queryParams = new URLSearchParams(window.location.search);
  const [error, setErrors] = useState({});
  const [isLoading, setLoading] = useState(false);

  // Get specific query parameters
  const link = queryParams.get("link");
  if (!link) {
    return (
      <>
        <div className="saving-calculator header-space pt-1">
          <div className="container-md">
            <div className="row">
              <div className="col-12">
                <div className="mob-pe-1 mob-ps-1">
                  <div className="page-title title-border pb-4">
                    <h2 className="fw-bold heading-color">
                      Invalid Link, Please Try with correct url.
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  //initialize Navigate method
  const navigate = useNavigate();
  const [linkDetails, setLinkDetails] = useState({});
  const [invalidLink, setInvalidLink] = useState(false);
  const [currentTabName, setCurrentTabName] = useState("");
  const [requiredCompanyDocuments, setRequiredCompanyDocuments] = useState([]);
  const setSignatoryNotesAccordingToRegistrationType = async (
    registrationTypeId
  ) => {
    if (registrationTypeId == "4") {
      setSignatories(2);
      if (signatoryFiles.length == 1) {
        signatoryFiles.push({});
      }
      setSignatoryNotes([
        "1. Please fill in details of authorised signatory and a co-applicant",
        "2. Email Id mentioned in the details will be used for initiating video KYC, after the documents are verified",
        "3. Uploaded files should be of type pdf, jpeg, jpg, png with size less than 2 MB",
      ]);
    }

    if (registrationTypeId == "2") {
      setSignatories(1);
      setSignatoryNotes([
        "1. Please fill in details of All the Authoised Signatories/ Directors having combined stake of more than 51% in the company",
        "2. Email Id mentioned in the details will be used for initiating video KYC, after the documents are verified",
        "3. Uploaded files should be of type pdf, jpeg, jpg, png with size less than 2 MB",
      ]);
    }
    if (registrationTypeId == "3" || registrationTypeId == "9") {
      setSignatories(1);
      setSignatoryNotes([
        "1. Please fill in details of All the Partners",
        "2. Email Id mentioned in the details will be used for initiating video KYC, after the documents are verified",
        "3. Uploaded files should be of type pdf, jpeg, jpg, png with size less than 2 MB",
      ]);
    }

    if (registrationTypeId == "10") {
      setSignatories(1);
      setSignatoryNotes([
        "1. Please fill in details of All the Authoised Signatories/ Directors authorised to execute lending arrangements with a RBI registered entity as per AOA/ BR",
        "2. Email Id mentioned in the details will be used for initiating video KYC, after the documents are verified",
        "3. Uploaded files should be of type pdf, jpeg, jpg, png with size less than 2 MB",
      ]);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await querydata.getDistributorInviteLinkDetails(link);
        setLinkDetails(data.data);
        if (data.data.registrationTypeId) {
          setSignatoryNotesAccordingToRegistrationType(
            data.data.registrationTypeId
          );
          setRequiredDocumentsForKyc(
            data.data.registrationTypeId,
            data.data.expectedLimitInLac
          );
        }
        setCurrentTabName(data.data.defaultTab || "");
      } catch (error) {
        console.error("Error fetching link details:", error);
        setInvalidLink(true);
      }
    };
    fetchData();
  }, [link]);
  if (invalidLink) {
    return (
      <>
        <div>Error fetching link details</div>
      </>
    );
  }

  const currentTabIndex =
    linkDetails &&
    linkDetails.tabs &&
    linkDetails.tabs.findIndex((tab) => tab.tabNameEnum === currentTabName);

  // values defined for formik
  const initialValues = {
    epcName: "",
    email: linkDetails.email ? linkDetails.email : "",
    address: "",
    phone: "",
    pinCode: "",
    cityId: "",
    documentId: "",
    areaServed: [],
    stateId: selectedState,
    registrationTypeId: !linkDetails.registrationTypeId
      ? -1
      : linkDetails.registrationTypeId,
    optionId: "",
    companyDocs: [],
    panNumber: "",
    gstUserName: "",
    gstPassword: "",
    gstIn: "",
    itrUserName: "",
    itrPassword: "",
    expectedLimitInLac: !linkDetails.expectedLimitInLac
      ? ""
      : linkDetails.expectedLimitInLac,
  };

  const handleSubmitForm = (e, tabNumber) => {
    e.preventDefault();
    if (
      currentTabName == "SIGNATORY_DOCUMENTS" &&
      validateSignatoryDetailsBeforeSubmit() != true
    ) {
      return;
    }
    const formData = new FormData();
    formData.append("email", linkDetails.email);
    formData.append("v2", true);
    formData.append("link", link);
    for (let [key, value] of formDatacompanyDocs.entries()) {
      formData.append(key, value);
    }

    const currformData = new FormData(formRef.current);
    const signatoryDocs = {};
    for (let [key, value] of currformData.entries()) {
      formData.append(key, value);
    }
    setLoading(true);
    updateStatusMutation.mutate(formData);
  };

  //for validating area served
  const areaServedDropdowns = document.querySelectorAll(".areaservedstates");
  const openAreaServedStates = [];
  areaServedDropdowns.forEach((data) => openAreaServedStates.push(data.value));
  const newErrors = {};
  const saveRegisrationDetails = async () => {
    if (!values.registrationTypeId || values.registrationTypeId == -1) {
      toast.error("Please select Registration Type");
      document.getElementById("registrationTypeId").focus();
      return false;
    }
    if ((await saveConsentForBasicDetails()) == false) {
      toast.error("Unable to save consent please try again");
      return false;
    }
    const formData = {};
    formData["registrationTypeId"] = values.registrationTypeId;
    await saveDistirbutorLinkState(link, formData, currentTabName);
    return true;
  };

  const saveBasicDetails = async () => {
    if ((await validateBasicDetails()) == true) {
      const formData = {};
      formData["name"] = values.epcName;
      formData["address"] = values.address;
      formData["phone"] = values.phone;
      formData["pinCode"] = values.pinCode;
      formData["cityId"] = values.cityId;
      formData["stateId"] = values.stateId;
      formData["expectedLimitInLac"] = values.expectedLimitInLac;
      await saveDistirbutorLinkState(link, formData, currentTabName);
      initialValues.expectedLimitInLac = values.expectedLimitInLac;
      return true;
    }
    return false;
  };

  const saveConsentForBasicDetails = async () => {
    const response = await saveUserConsent(
      link,
      "distributor_Onboarding",
      "t&c_privacy_cookie_policy"
    );
    if (response && response.success == true) {
      return true;
    }
    return false;
  };

  const saveConsentForGSTFetch = async () => {
    const response = await saveUserConsent(
      link,
      "distributor_Onboarding",
      "gst_fetch"
    );
    if (response && response.success == true) {
      return true;
    }
    return false;
  };

  const saveConsentForITRFetch = async () => {
    const response = await saveUserConsent(
      link,
      "distributor_Onboarding",
      "itr_fetch"
    );
    if (response && response.success == true) {
      return true;
    }
    return false;
  };

  const savePanDetails = async () => {
    if (await customerAlreadyExists(values.panNumber, initialValues.email||values.email) == true) {
      return false;
    }
    if ((await validatePanNumber()) == true) {
      if (
        values.registrationTypeId == "4" ||
        initialValues.registrationTypeId == "4"
      ) {
        const formData = {};
        formData["panNumber"] = values.panNumber;
        await saveDistirbutorLinkState(link, formData, currentTabName);
        return true;
      } else {
        const response = await querydata.validateBusinessPanNumber(
          values.panNumber
        );
        if (!response.success) {
          toast.error(response.data.message);
          return false;
        }
        if (!response.data.companyNameOnPan) {
          toast.error("Please enter valid pan details");
          return false;
        }
        const formData = {};
        formData["panNumber"] = values.panNumber;
        formData["name"] = response.data.companyNameOnPan;
        await saveDistirbutorLinkState(link, formData, currentTabName);
        return true;
      }
    }
    return false;
  };

  const validatePanNumber = async () => {
    if (!errors.panNumber && !touched.panNumber) {
      if (!values.panNumber) {
        newErrors.panNumber = "Please enter company PAN";
        toast.error("Please enter company PAN");
        return false;
      }
    } else {
      if (!values.panNumber) {
        toast.error("Please enter company PAN");
        touched.panNumber = "Please enter company PAN";
        return false;
      }
    }
    return true;
  };

  const validateBasicDetails = async () => {
    if (
      initialValues.registrationTypeId == "4" ||
      values.registrationTypeId == "4"
    ) {
      if (!errors.epcName && !touched.epcName) {
        if (!values.epcName) {
          newErrors.epcName = "Please enter company name";
          toast.error("Please enter company name");
          document.getElementById("CompanyName").focus();
          return false;
        }
      } else {
        if (!values.epcName) {
          toast.error("Please enter company name");
          touched.epcName = "Please enter company name";
          newErrors.customerNames = "Please enter full name";
          document.getElementById("CompanyName").focus();
          return false;
        }
      }
    }
    if (!values.address) {
      toast.error("Please enter your address");
      touched.address = "Please enter your address";
      newErrors.customerNames = "Please enter full name";
      document.getElementById("Address").focus();
      return false;
    }

    if (!values.expectedLimitInLac || values.expectedLimitInLac == 0) {
      toast.error("Please enter your expected limit");
      touched.expectedLimitInLac = "Please enter your expected limit";
      newErrors.expectedLimitInLac = "Please enter your expected limit";
      document.getElementById("expectedLimitInLac").focus();
      return false;
    }

    if (values.phone.length === 0) {
      newErrors.phone = "Mobile number is required";
      toast.error("Mobile number is required");
      document.getElementById("Phone").focus();
      return false;
    } else if (!/^\d{10}$/.test(values.phone)) {
      newErrors.phone = "Invalid mobile number";
      toast.error("Invalid mobile number");
      document.getElementById("Phone").focus();
      return false;
    }

    if (!values.pinCode) {
      toast.error("PinCode is required");
      newErrors.customerNames = "Please enter full name";
      document.getElementById("Pincode").focus();
      return false;
    } else if (!/^\d+$/.test(values.pinCode)) {
      toast.error("Pincode must only contain digits");
      newErrors.customerNames = "Please enter full name";
      document.getElementById("Pincode").focus();
      return false;
    } else if (values.pinCode.length != 6) {
      toast.error("Pincode must be exactly 6 digits");
      newErrors.customerNames = "Please enter full name";
      document.getElementById("Pincode").focus();
      return false;
    }

    if (values.stateId == -1) {
      toast.error("Please select State");
      touched.stateId = "Please select State";
      newErrors.customerNames = "Please enter full name";
      document.getElementById("State").focus();
      return false;
    }

    if (!values.cityId) {
      newErrors.city_Id = "Please select City";
      toast.error("Please select City");
      document.getElementById("City").focus();
      return false;
    }

    return true;
  };

  //build Model and fill data in formik model
  const { values, handleBlur, handleChange, handleSubmit, errors, touched } =
    useFormik({
      initialValues: initialValues,
      validationSchema: epcRegisterSchema,
      onSubmit: (values, action) => {
        const formData = new FormData();
        //console.log('rateMatrixform')
        //console.log(rateMatrix)
        //console.log('rateMatrixOLDform')
        //console.log(rateMatrixOld)
        //console.log('statematrixarrayform')
        //console.log(statematrixarray)
        //console.log('statematrixarrayOLDform')
        //console.log(statematrixarrayOLD)

        var options = document.getElementById("matrixstateId"),
          option,
          i;
        let error = false;
        for (let k = 0; k <= options.length - 1; k++) {
          option = options[k];

          let matrixstring = JSON.stringify(rateMatrixOld);
          let index = matrixstring.includes(option.textContent);
          if (!index) {
            error = true;
          }
        }

        if (!error) {
          formData.append("registrationTypeId", values.registrationTypeId);
          formData.append("epcName", values.epcName);
          formData.append("email", values.email);
          formData.append("address", values.address);
          formData.append("phone", values.phone);
          formData.append("pinCode", values.pinCode);
          formData.append("cityId", values.cityId);
          formData.append("documentId", values.documentId);
          formData.append("documentImage", file);
          formData.append("panImage", panFile);
          formData.append("areaServed", JSON.stringify(selectedOptions));
          formData.append("rateMatrix", JSON.stringify(rateMatrixOld));

          updateStatusMutation.mutate(formData, action);
          // action.resetForm();
        } else {
          toast.error(
            "Please Fill and Save Rate Matrix Data for all the States"
          );
        }
      },
    });

  ////////console.log(registerationIdForDocument2);

  // enable submit button checkbox click
  const [isChecked, setIsChecked] = useState(false);
  const [isGSTConsentChecked, setIsGSTConsentChecked] = useState(false);
  const [isITRConsentChecked, setIsITRConsentChecked] = useState(false);
  const getEpcRegistrationType = useQuery(
    "RegistrationType",
    querydata.getEpcRegistrationTypeNew
  );

  const setRequiredDocumentsForKyc = async (
    registrationTypeId,
    expectedLimitInLac
  ) => {
    const data = await querydata.getRequiredDocsForKyc(
      registrationTypeId,
      expectedLimitInLac
    );
    if (data.data && data.data.documents) {
      setRequiredCompanyDocuments(data.data.documents);
    }
  };

  const [signatoryNotes, setSignatoryNotes] = useState(
    !initialValues || initialValues.registrationTypeId == -1 ? [] : []
  );

  useEffect(() => {
    if (values.registrationTypeId) {
      setRequiredDocumentsForKyc(values.registrationTypeId, null);
      setSignatoryNotesAccordingToRegistrationType(values.registrationTypeId);
    }
  }, [values.registrationTypeId]);

  //form post on Submit
  const updateStatusMutation = useMutation(querydata.insertDistributor, {
    onError: (error) => {
      toast.error(error.message);
      ////////console.log(error.message);
    },
    onSuccess: (data) => {
      navigate("/RegistrationComplete");
    },
  });

  const validateSignatoryDetailsBeforeSubmit = () => {
    const finalSignatoryFiles = [];
    if (signatories > signatoryFiles.length) {
      toast.error("Please provide all signatory details");
      return false;
    }
    var totalShareHolding = 0;
    for (var i = 0; i < signatories; i++) {
      if (validateAllSignatoryDetails(i) == true) {
        if (signatoryFiles[i].networth) {
          totalShareHolding =
            totalShareHolding + signatoryFiles[i].shareHolding;
        }
        finalSignatoryFiles.push(signatoryFiles[i]);
      } else {
        return false;
      }
    }
    if (totalShareHolding < 51 && (initialValues.expectedLimitInLac || values.expectedLimitInLac) >20) {
      toast.error(
        "Please provide Networth certificate for all signatories having total percentage holding greater than 51%"
      );
      return false;
    }
    values.signatoryFiles = finalSignatoryFiles;
    return true;
  };

  const customerAlreadyExists = async (panNumber, email) => {
    const response = await querydata.checkifDistributorExists(panNumber, email);
    if (response && response.success == true) {
      return false;
    }
    if (response.data.message) {
      toast.error(response.data.message);
      return true;
    } 
      toast.error("Epc Already exists with email or PAN is already used");
      return true;

  };

  const validateAllSignatoryDetails = (signatoryIndex) => {
    const index =
      signatoryIndex || signatoryIndex == 0 ? signatoryIndex : signatories - 1;
    const elementId =
      signatoryIndex || signatoryIndex == 0
        ? String(signatoryIndex)
        : String(signatories - 1);
    console.log("validating for signatoy " + elementId);
    const error = {};
    if (!signatoryFiles[index].name) {
      const newError = {
        ...error,
        ["signatoryName_" + elementId]: "Please provide signatory name",
      };
      setErrors(newError);
    } else if (!signatoryFiles[index].email) {
      const newError = {
        ...error,
        ["signatoryEmail_" + elementId]: "Please enter your email",
      };
      setErrors(newError);
      return false;
    } else if (!/\S+@\S+\.\S+/.test(signatoryFiles[index].email)) {
      const newError = {
        ...error,
        ["signatoryEmail_" + elementId]: "Invalid email format",
      };
      setErrors(newError);
    } else {
      return true;
    }
  };

  const fetchGST = async () => {
    if (!values.gstIn || values.gstIn == "") {
      toast.error("Please enter GSTIN");
      return false;
    }
    if (!values.gstUserName || values.gstUserName == "") {
      toast.error("Please enter GST user name");
      return false;
    }
    if (!values.gstPassword || values.gstPassword == "") {
      toast.error("Please enter GST password");
      return false;
    }
    setLoading(true);
    if ((await saveConsentForGSTFetch()) == false) {
      toast.error("Unable to save consent please try again");
      setLoading(false);
      return false;
    }
    const response = await querydata.fetchGSTCertificate(
      values.gstUserName,
      values.gstPassword,
      link
    );
    if (!response || !response.success) {
      setLoading(false);
      toast.error(response.data.message);
      return false;
    }
    if (response && response.success == true) {
      const reportResponse = await querydata.fetchGSTReport(
        values.gstUserName,
        values.gstPassword,
        link,
        values.gstIn
      );
      if (!reportResponse && reportResponse.success !== true) {
        setLoading(false);
        toast.error("Failed to fetch GST Details, Please try again.");
        return false;
      }
    }
    const formData = {};
    formData["gstIn"] = values.gstIn;
    await saveDistirbutorLinkState(link, formData, currentTabName);
    setLoading(false);
    return true;
  };

  const fetchITRForm = async () => {
    if (!values.itrUserName || values.itrUserName == "") {
      toast.error("Please enter ITR user name");
      return false;
    }
    if (!values.itrPassword || values.itrPassword == "") {
      toast.error("Please enter ITR password");
      return false;
    }
    if ((await saveConsentForITRFetch()) == false) {
      toast.error("Unable to save consent please try again");
      return false;
    }
    setLoading(true);
    const response = await querydata.fetchITRForm(
      values.itrUserName,
      values.itrPassword,
      link
    );
    if (!response || !response.success) {
      setLoading(false);
      toast.error(response.data.message);
      return false;
    }
    const formData = {};
    formData["itrFetched"] = true;
    await saveDistirbutorLinkState(link, formData, currentTabName);
    setLoading(false);
    return true;
  };

  const goToNextPage = async () => {
    if (currentTabName == "REGISTRATION_TYPE") {
      if ((await saveRegisrationDetails()) == true) {
        goToNextTab();
      }
    } else if (currentTabName == "BASIC_DETAILS") {
      const formData = {};
      if ((await saveBasicDetails(link, formData, currentTabName)) == true) {
        goToNextTab();
      }
    } else if (currentTabName == "PAN_DETAILS") {
      if ((await savePanDetails()) == true) {
        goToNextTab();
      }
    } else if (currentTabName == "GST_DETAILS") {
      if ((await fetchGST()) == true) {
        goToNextTab();
      }
    } else if (currentTabName == "COMPANY_DOCUMENTS") {
      if (!validateCompanyDocs()) {
        return;
      }
      const formData = new FormData(formRef.current);
      values.companyDocs = companyDocs;
      setFormDataCompanyDocs(formData);
      console.log("setting company docs...");
      console.log(values);
      goToNextTab();
    } else if (currentTabName == "ITR_DETAILS") {
      if ((await fetchITRForm()) == true) {
        goToNextTab();
      }
    }
  };
  const goToNextTab = async () => {
    const tabIndex = linkDetails.tabs.findIndex(
      (tab) => tab.tabNameEnum === currentTabName
    );
    if (linkDetails.tabs[tabIndex + 1].tabNameEnum== "COMPANY_DOCUMENTS"){
      setRequiredDocumentsForKyc(initialValues.registrationTypeId || values.registrationTypeId , initialValues.expectedLimitInLac || values.expectedLimitInLac )
    }
    setCurrentTabName(linkDetails.tabs[tabIndex + 1].tabNameEnum);
  };

  const validateCompanyDocs = () => {
    if (requiredCompanyDocuments) {
      for (var i = 0; i < requiredCompanyDocuments.length; i++) {
        var doc = requiredCompanyDocuments[i];
        if (doc.mode == "AUTOMATIC") {
          continue;
        }
        if (doc.inputType == "TEXT") {
          if (
            !document.getElementById("filetextid_" + doc.documentCode).value &&
            doc.mandatory == true
          ) {
            error[doc.documentCode] =
              "Please provide value for " + doc.documentName;
            toast.error("Please provide value for " + doc.documentName);
            return false;
          }
          values[doc.documentCode] = document.getElementById(
            "filetextid_" + doc.documentCode
          ).value;
          continue;
        }
        if (doc.numberOfFiles > 1 || doc.documentTimeNeeded == true) {
          for (var j = 0; j < doc.numberOfFiles; j++) {
            if (
              !document.getElementById(
                "filetextid_" + doc.documentCode + "_" + j
              ).value &&
              doc.mandatory == true
            ) {
              error[doc.documentCode] = "Please provide document";
              toast.error("Please provide all mandatory documents");
              return false;
            }
          }
        } else if (
          !document.getElementById("filetextid_" + doc.documentCode).value &&
          doc.mandatory == true
        ) {
          error[doc.documentCode] = "Please provide document";
          toast.error("Please provide all mandatory documents");
          return false;
        }
      }
    }
    return true;
  };

  return (
    <>
      <style>
        <link rel="stylesheet" href="css/multiple-select.css"></link>
      </style>
      <section>
        <div className="saving-calculator header-space pt-1">
          <div className="container-md">
            <div className="row">
              <div className="col-12">
                <div className="mob-pe-1 mob-ps-1">
                  <div className="page-title title-border pb-4">
                    <h2 className="fw-bold heading-color">
                      Channel <span className="text-theme-green">Partners</span>
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="distributor-join-steps-div mob-pe-1 mob-ps-1 mt-lg-4">
                  <div className="partners-tab pb-4">
                    <ul className="tab-steps--list d-flex align-items-center w-100 p-0 m-0 position-relative justify-content-between">
                      {linkDetails &&
                        linkDetails.tabs &&
                        linkDetails.tabs.map((tab, index) => {
                          return (
                            <li
                              key={index}
                              className={
                                tab.tabNameEnum == currentTabName ||
                                index <= currentTabIndex
                                  ? "current"
                                  : ""
                              }
                              disabled="true"
                            >
                              <div className="distributor-join-step-icon rounded-pill d-flex align-items-center justify-content-center m-auto">
                                <b> {index + 1}</b>
                              </div>
                              <span className="text-nowrap pt-2 d-flex align-items-center justify-content-center text-center">
                                {tab.name}
                              </span>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                  {/* <!-- /partners-tab --> */}
                  <div className="partner-tab-data pt-2 mt-lg-4">
                    <div className="tab-content" id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="Enrollment-tab-pane"
                        role="tabpanel"
                        aria-labelledby="Enrollment-tab"
                        tabIndex="0"
                      >
                        <div className="registration-form">
                          <form id="distributor-form" ref={formRef}>
                            <div className="saving-calculate-form registration-form hideform">
                              {currentTabName == "REGISTRATION_TYPE" ? (
                                <>
                                  <RegistrationType
                                    values={values}
                                    requiredCompanyDocuments={
                                      requiredCompanyDocuments
                                    }
                                    getEpcRegistrationType={
                                      getEpcRegistrationType
                                    }
                                    handleChange={handleChange}
                                    errors={errors}
                                    setIsChecked={setIsChecked}
                                  />
                                  <TermsAndConditions
                                    isChecked={isChecked}
                                    setIsChecked={setIsChecked}
                                  />
                                </>
                              ) : (
                                ""
                              )}

                              {currentTabName == "BASIC_DETAILS" ? (
                                <BasicDetails
                                  values={values}
                                  selectedState={selectedState}
                                  setSelectedState={setSelectedState}
                                  handleChange={handleChange}
                                  errors={errors}
                                  registrationTypeId={
                                    values.registrationTypeId == -1
                                      ? initialValues.registrationTypeId
                                      : values.registrationTypeId
                                  }
                                />
                              ) : (
                                ""
                              )}
                              {currentTabName == "BANK_STATEMENT" ? (
                                <BankStatementAnalyzer link={link} />
                              ) : (
                                ""
                              )}
                              {currentTabName == "PAN_DETAILS" ? (
                                <PANDetails
                                  values={values}
                                  errors={errors}
                                  handleChange={handleChange}
                                />
                              ) : (
                                ""
                              )}
                              {currentTabName == "GST_DETAILS" ? (
                                <GSTDetails
                                  values={values}
                                  errors={errors}
                                  handleChange={handleChange}
                                  isGSTConsentChecked={isGSTConsentChecked}
                                  setIsGSTConsentChecked={
                                    setIsGSTConsentChecked
                                  }
                                />
                              ) : (
                                ""
                              )}

                              {currentTabName == "ITR_DETAILS" ? (
                                <ITRDetails
                                  values={values}
                                  errors={errors}
                                  handleChange={handleChange}
                                  isITRConsentChecked={isITRConsentChecked}
                                  setIsITRConsentChecked={
                                    setIsITRConsentChecked
                                  }
                                />
                              ) : (
                                ""
                              )}
                              {currentTabName == "COMPANY_DOCUMENTS" ? (
                                <CompanyDocuments
                                  requiredCompanyDocuments={
                                    requiredCompanyDocuments
                                  }
                                  error={error}
                                  companyDocs={companyDocs}
                                  setErrors={setErrors}
                                  values={values}
                                />
                              ) : (
                                ""
                              )}

                              {currentTabName == "SIGNATORY_DOCUMENTS" ? (
                                <SignatoryDocument
                                  values={values}
                                  signatoryNotes={signatoryNotes}
                                  signatoryFiles={signatoryFiles}
                                  signatories={signatories}
                                  setSignatories={setSignatories}
                                  error={error}
                                  registrationTypeId={
                                    values.registrationTypeId == -1
                                      ? initialValues.registrationTypeId
                                      : values.registrationTypeId
                                  }
                                  expectedLimit={linkDetails.expectedLimitInLac}
                                  validateAllSignatoryDetails={
                                    validateAllSignatoryDetails
                                  }
                                />
                              ) : (
                                ""
                              )}

                              {/* <!-- /row --> */}
                            </div>

                            <div className="bottom-actions">
                              <div className="solfin-action mt-4 mb-4">
                                {/* <input
                                    type="reset"
                                    value="previous"
                                    onClick={goToBackPage}
                                    className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase ms-1 light-btn calculate-btn gray-border"
                                  />
                                 */}
                                {currentTabName == "ITR_DETAILS" ? (
                                  <input
                                    type="button"
                                    disabled={!isITRConsentChecked || isLoading}
                                    value={isLoading? "Loading....":"next"}
                                    className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn"
                                    onClick={goToNextPage}
                                  />
                                ) : currentTabName == "GST_DETAILS" ? (
                                  <input
                                    type="button"
                                    disabled={!isGSTConsentChecked || isLoading}
                                    value={isLoading? "Loading....":"next"}
                                    className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn"
                                    onClick={goToNextPage}
                                  />
                                ) : currentTabName != "SIGNATORY_DOCUMENTS" ? (
                                  currentTabName == "BANK_STATEMENT" ? (
                                    ""
                                  ) : (
                                    <input
                                      type="button"
                                      disabled={
                                        (!isChecked &&
                                        currentTabName == "REGISTRATION_TYPE")|| isLoading
                                      }
                                      value={isLoading? "Loading....":"next"}
                                      className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn"
                                      onClick={goToNextPage}
                                    />
                                  )
                                ) : (
                                  <input
                                    type="button"
                                    value={isLoading? "Loading....": "Submit"}
                                    disabled={isLoading}
                                    className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn"
                                    onClick={(e) => handleSubmitForm(e, 4)}
                                  />
                                )}
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>

                    {/* <!-- /tab-content --> */}
                  </div>
                  {/* <!-- /partner-data --> */}
                </div>
                {/* <!-- /registration-form --> */}
              </div>
              {/* <!-- /col-lg-8 --> */}
            </div>
            {/* <!-- /row --> */}
          </div>
          {/* <!-- /container --> */}
        </div>
        {/* <!-- /saving-calculator --> */}
      </section>
    </>
  );
};

export default DistributorJoinV2;
