import React, { useState, useEffect } from "react";
import LeadTable from "./components/LeadTable";
import * as dataquery from "../../service/dataService";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  changeCustomerStatus,
  changeLeadsStatus,
  getAllCustomers,
  getAllEstimatedLeads,
  getLeadsStatus,
} from "../../service/dataService";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Export from "../../../components/ExportToExcel";
import DeleteModal from "../../components/DeleteModal";
import DeleteLeadsModal from "../../components/DeleteLeadsModal";

const EstimatorLeads = () => {
  const queryClient = useQueryClient();
  //Export To Excel
  const handleExport = () => {
    //console.log(data.data.data);
    Export(
      data.data.data,
      [
        "customer_name",
        "email",
        "phone",
        "pincode",
        "city",
        "state",
        "avg_consumption",
        "consumption_to_shift",
        "area_available",
        "estimated_project_cost",
        "electricity_rate",
        "location_type",
        "connect_to_grid",
        "status",
        "created_on",
      ],
      [
        "CustomerName",
        "Email",
        "Phone",
        "Pincode",
        "City",
        "State",
        "AvgConsumption",
        "ConsumptionShift",
        "Area Available",
        "Estimated Project Cost",
        "ElectricityRate",
        "LocationType",
        "ConnectToGrid",
        "Status",
        "CreatedOn",
      ]
    );
  };

  const navigate = useNavigate();
  const [isFilterActive, setIsFilterActive] = useState(false);
  const { id } = useParams();
  const handleNavigate = (row) => {
    // console.log(row.original.customer_id);
    const customerId = row.original.customer_id;
    navigate(`/customers/details/${id}`);
  };

  const changeLeadsStatusMutate = useMutation(changeLeadsStatus, {
    onError: (error) => {
      toast.error(error.message);
    },
    onSuccess(data) {
      // Wiconsole.log(data);ll be called 3 times
      toast.success(data.message);
      // console.log("Succesful",{data});
    },
  });
  const deleteLeadMutate = useMutation(dataquery.deleteEstimatorLeads, {
    onError: (error) => {
      toast.error(error.message);
    },
    onSuccess: (data) => {
      toast.success(data.message);
      queryClient.invalidateQueries("getAllEstimatedLeads");
      // data.refetch();
    },
  });

  const leadStatus = useQuery("getLeadsStatus", getLeadsStatus);
  useEffect(() => {
    leadStatus.refetch();
  }, []);

  const handleChange = (e, row) => {
    // console.log(e.target.value);
    // console.log(row.original.customer_id);
    const statusId = e.target.value;
    const id = row.original.lead_id;
    row.original.status_id = statusId;
    const data = changeLeadsStatusMutate.mutate({ id, statusId });
    // console.log(data);
  };

  const handleDeleteLead = (row) => {
    const id = row.original.lead_id;
    deleteLeadMutate.mutate(id);
  };

  const columns = React.useMemo(
    () => [
      { Header: "Sr. No", accessor: "srNo" },
      // { Header: "Customer Id", accessor: "customer_id" },
      {
        Header: "Name",
        accessor: "customer_name",
        // Cell: ({ row }) => (
        //   <div>
        //     <button className="btn ms-n3" onClick={() => handleNavigate(row)}>
        //       {row.original.customer_name}
        //     </button>
        //   </div>
        // ),
      },
      { Header: "State", accessor: "state" },
      { Header: "City", accessor: "city" },
      { Header: "Email", accessor: "email" },
      { Header: "Phone", accessor: "phone" },
      { Header: "Pincode", accessor: "pincode" },
      { Header: "Date & Time", accessor: "created_on" },
      // { Header: "No Of Projects" },
      { Header: "Electricity Rate (Per Unit)", accessor: "electricity_rate" },
      { Header: "% to be shifted to solar", accessor: "consumption_to_shift" },
      { Header: "Connect to Grid", accessor: "connect_to_grid" },
      { Header: "Location Type", accessor: "location_type" },
      { Header: "Avg. Units Consumed/Month", accessor: "avg_consumption" },
      { Header: "Rooftop Area Available", accessor: "area_available" },
      //{Header : "Status", Cell: (row) => { return <span class="badge bg-label-primary me-1">{row.row.original.status}</span> }}
      {
        Header: "Status",
        Cell: ({ row }) => (
          <select
            onChange={(e) => handleChange(e, row)}
            value={row.original.status_id}
            className="form-select"
            style={{ width: "200px" }}
          >
            {leadStatus.data &&
              leadStatus.data.map((data) => (
                <option key={data.status_id} value={data.status_id}>
                  {data.status}
                </option>
              ))}
          </select>
        ),
      },
      {
        Header: "Actions",
        Cell: ({ row }) => (
          <>
            <button
              data-bs-toggle="modal"
              data-bs-target={`#id${row.original.lead_id}`}
              className="ms-2 btn btn-sm btn-danger"
              title="Delete"
            >
              <i className="fa fa-box-archive"></i>
              <DeleteLeadsModal row={row} />
            </button>
          </>
        ),
      },
    ],
    [leadStatus.data]
  );

  const { data, isLoading, error } = useQuery(
    "getAllEstimatedLeads",
    getAllEstimatedLeads
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!data || !data.data || !data.data.data) {
    return <div>No data fetched.</div>;
  }
  // console.log(data);
  return (
    <>
      <div className="content-body">
        <div className="page-title">
          <h3>Estimator Leads</h3>
        </div>
        <LeadTable
          tableName={"List of Leads"}
          columns={columns}
          tableData={data && data.data && data.data.data}
          handleExport={handleExport}
          isFilterActive={isFilterActive}
          cityallowed
          stateallowed
          connecttoGridAllowed
          startandenddateallowed
          locationtypeallowed
          esstatusallowed
        />{" "}
        {/* <!-- /dash-card --> */}
      </div>
      {/* <!-- /content body --> */}
    </>
  );
};

export default EstimatorLeads;
