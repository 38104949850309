import { useEffect, useState, useRef } from "react";
import {
  initializeBankStatementAnalyzer,
  saveDistirbutorLinkState,
} from "../../../dataService/DataService";
const BankStatementAnalyzer = ({ link }) => {
  const [analyzerLink, setAnalyzerLink] = useState("");
  const iframeRef = useRef(null);
    const [selectedBank, setSelectedBank] = useState(null);
  const setBankAnalyzerLink = async (bankName) => {
    const response = await initializeBankStatementAnalyzer(
      null,
      link,
      12,
      bankName
    );
    if (!response || response.success == false) {
      toast.error(
        "Unable to initiate bank statement analyzer, Please refresh the page"
      );
      return;
    }
    setAnalyzerLink(response.data && response.data.redirectUrl);
  };


  useEffect(() => {
    setBankAnalyzerLink(selectedBank);
  }, [link, selectedBank]);


  useEffect(() => {
    // Function to handle the message event
    const handleMessage = async (event) => {
      // Log the event for debugging purposes
      console.log("Event is here-> ", event);
      // Optionally, check the origin
      if (
        event.origin === "https://bankconnectclient.finbox.in" &&
        event.data.type == "finbox-bankconnect" &&
        event.data.payload.linkId == link
      ) {
        console.log("Message from trusted origin:", event.data);
        if (event.data.status == "success") {
          const formData = {};
          formData["bankStatementEntityId"] = event.data.payload.entityId;
          formData["bankStatementAnalyserName"] = "FINBOX";
          const response = await saveDistirbutorLinkState(
            link,
            formData,
            "BANK_STATEMENT"
          );
          if (response && response.success) {
            window.location.reload();
          }
        } 
        if (event.data.payload.event_name == "bank_selected"){
            setSelectedBank(event.data.payload.message);
            //setBankAnalyzerLink(event.data.payload.message);
        }
      } else {
        console.warn("Message from untrusted origin:", event.data);
      }
    };

    // Add the event listener
    window.addEventListener("message", handleMessage);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return (
    <>
      <div className="heading mb-xl-4 mb-3">
        <span className="fw-semibold saving-title">Upload Bank Statement</span>
      </div>
      <div className="row">
        <iframe
          src={analyzerLink}
          ref={iframeRef}
          title="Bank Statement Analyzer"
          width={"100%"}
          height={"800px"}
          style={{ border: "none" }}
        />
      </div>
    </>
  );
};

export default BankStatementAnalyzer;
