import { Route, Routes, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Layout from "../components/Layout";
import Details from "../pages/Details";
import AssignedLeads from "../pages/AssignedLeads";
import RateMatrix from "../pages/RateMatrix";
import AddQuote from "../pages/AddQuote";
import Projects from "../pages/projects/Projects";
import ProjectDetails from "../pages/projects/ProjectDetail";
import DistributorDetails from "../Distributor/Details"

const EpcRoutes = () => {


  return (
    <>
      
        <Routes>
      
          {/* <Route path="/epc-dashboard" element={<><Layout></Layout></>} /> */}
          <Route path="/epc-dashboard" element={<><Layout><Details/></Layout></>} />
          {/* <Route path="/epc/my-leads" element={<><Layout><MyLeads/></Layout></>} /> */}
          <Route path="/epc-dashboard/assigned-leads" element={<><Layout><AssignedLeads/></Layout></>} />
          <Route path="/epc-dashboard/assigned-leads/add-quote/:id" element={<><Layout><AddQuote/></Layout></>} />
          <Route path="/epc-dashboard/rate-matrix" element={<><Layout><RateMatrix/></Layout></>} />
          <Route path="/epc-dashboard/projects" element={<><Layout><Projects/></Layout></>} />
          <Route path="/epc-dashboard/projects/project-detail/:id" element={<><Layout><ProjectDetails/></Layout></>} />



        </Routes>
    </>
  );
};

export default EpcRoutes;
