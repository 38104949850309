import { Outlet, Route, Routes, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Layout from "../components/Layout";
import Contracts from "../pages/Contracts";
import Leads from "../pages/Leads";
import Details from "../pages/Details";
import Projects from "../pages/Projects";
import LeadsDetailPage from "../pages/LeadsDetailPage";


const DsaRoutes = () => {

  return (
    <>
      
        <Routes>
      
          <Route path="/dsa-dashboard" element={<><Layout><Details/></Layout></>} />
          <Route path="/dsa-dashboard/my-leads" element={<><Layout><Leads/></Layout></>} />
          <Route path="/dsa-dashboard/projects" element={<><Layout><Projects/></Layout></>} />
          <Route path="/dsa-dashboard/leads-detail/:id" element={<><Layout><LeadsDetailPage/></Layout></>} />

        </Routes>
    </>
  );
};

export default DsaRoutes;
