import React from "react";
import { Link, NavLink } from "react-router-dom";
import { logout } from "../service/dataService";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

const Header = ({ setIsSideBarCollapsed }) => {
  const navigate = useNavigate();

  const Logout = async () => {
    // console.log("Logout")
    const response = await logout();
    //  console.log(response)
    if (response.status === 200) {
      // console.log("navigate")
      navigate("/login");
    }
  };

  return (
    <>
      <div className="top-header">
        <div className="sidebar-toggle">
          <button
            className="btn"
            onClick={() => setIsSideBarCollapsed((prev) => !prev)}
            id="menu-toggle"
          >
            <i className="fa-solid fa-bars"></i>
          </button>
        </div>
        {/* <!-- /sidebar toggle --> */}
        <div className="solfin-profile">
          <div className="sub-menu dropdown">
            <a href="#Dropnav" data-bs-toggle="dropdown">
              <div className="profile-image">
                <img
                  src="/images/default_user.png"
                  alt="Profile"
                  title="Profile"
                />
              </div>
              <span className="uername">Admin</span>
              <div className="down-arrow">
                <i className="fa right fa-angle-down"></i>
              </div>
            </a>
            <ul className="dropdown-menu profile-navs">
              {/* <li>
                <a href="#">
                  <span>
                    <i className="fa fa-user"></i> Profile
                  </span>
                </a>
              </li> */}
              {/* <li>
                <a href="#">
                  <span>
                    <i className="fa fa-lock"></i> Change Password
                  </span>
                </a>
              </li> */}
              {/* <li>
                <a href="#">
                  <span>
                    <i className="fa fa-gear"></i> Settings
                  </span>
                </a>
              </li> */}
              <li>
                <span>
                  <a type="button" onClick={Logout}>
                    <i className="fa fa-right-from-bracket"></i> Logout
                  </a>
                </span>
              </li>
            </ul>
          </div>
          {/* <!-- /sub menu --> */}
        </div>
        {/* <!-- /solfin profile --> */}
      </div>
      {/* <!-- /top header --> */}
    </>
  );
};

export default Header;
