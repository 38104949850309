import React from "react";
import { Route, Routes } from "react-router-dom";
import Register from "../pages/register/Register";
import Epc from "../pages/epc/Epc";
import Dsa from "../pages/dsa/Dsa";
import Home from "../pages/home/Home";
import Aboutus from "../pages/aboutus/AboutUs";
import Estimator from "../pages/estimator/Estimator";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Termsandconditions from "../pages/termsandconditions/Termsandconditions";
import RegistrationComplete from "../pages/registrationcomplete/RegistrationComplete";
import PartnerRegistrationComplete from "../pages/partnerregistrationcomplete/PartnerRegistrationComplete";
import HomePageAdditions from "../components/HomePageAdditions";
import MySavings from "../pages/estimator/MySavings";
import ComingSoon from "../pages/comingSoon/ComingSoon";
import Login from "../pages/login/Login";
import Mission from "../pages/others/Mission";
import PremiumPartners from "../pages/premiumPartners/PremiumPartners";
import ResidentialLoanProducts from "../pages/loan/LoanProducts";
import Knowledge from "../pages/knowledge/Knowledge";
import ForgotPassword from "../pages/login/ForgotPassword";
import ResetPassword from "../pages/login/ResetPassword";
import NewCustomerRegisterComplete from "../pages/registrationcomplete/NewCustomerRegisterComplete";
import OldCustomerRegisterComplete from "../pages/registrationcomplete/OldCustomerRegisterComplete";
import CustomerHeader from "../components/CustomerHeader";
import Lease from "../pages/loan/Lease";
import CookiePolicy from "../pages/cookiePolicy/cookiePolicy";
import RefundPolicy from "../pages/refundpolicy/RefundPolicy";
import WantFinance from "../pages/finance/WantFinance";
import DhanvikasPrivacyPolicy from "../pages/privacypolicy/DhanvikasPrivacypolicy";
import XsPrivacyPolicy from "../pages/privacypolicy/XSFinanacePrivacypolicy";
import DistributorJoinV2 from "../pages/distributor/DistributorJoin";
import FairPracticeCode from "../pages/fairPracticeCode/FairPracticeCode";
import GrievanceRedressalPolicy from "../pages/grievanceRedressalPolicy/GrievanceRedressalPolicy";
import ContactSales from '../pages/contactSales/ContactSales';
import ContactSalesForLease from '../pages/contactSales/ContactSalesLease';
const AllRoutes = (props) => {
  //console.log(props.location)
  //   function Header() {
  //     const location = useLocation();
  //     const hideHeaderForPaths = ['/Estimator','/pathname2'];

  //     if(hideHeaderForPaths.includes(location.pathname)) {
  //        return <Header/>;
  //     }

  //     return (
  //       <Header/>
  //     )
  // }

  return (
    <>
      {/* {Header()} */}
      {/* //props.location.pathname!=='/Estimator' ? <Header/>:null */}

      {/* <Header/> */}
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Header />
              <Home />
              <HomePageAdditions />
              <Footer />
            </>
          }
        />
        <Route
          exact
          path="/Register"
          element={
            <>
              <Header />
              <Register />
              <Footer />
            </>
          }
        />
        <Route
          path="/finance"
          element={
            <>
              <Header />
              <WantFinance />
              <Footer />
            </>
          }
        />
        <Route
          exact
          path="/RegisterCustomer"
          element={
            <>
              <CustomerHeader />
              <Register />
            </>
          }
        />
        <Route
          path="/PremiumPartners"
          element={
            <>
              <Header />
              <PremiumPartners />
              <Footer />
            </>
          }
        />
        <Route
          path="/CookiePolicy"
          element={
            <>
              <Header />
              <CookiePolicy />
              <Footer />
            </>
          }
        />

        <Route
          path="/Epc"
          element={
            <>
              <Header />
              <Epc />
              <Footer />
            </>
          }
        />
        <Route
          path="/Dsa"
          element={
            <>
              <Header />
              <Dsa />
              <Footer />
            </>
          }
        />
        <Route
          path="/Aboutus"
          element={
            <>
              <Header />
              <Aboutus />
              <Footer />
            </>
          }
        />
        <Route
          path="/Estimator"
          element={
            <>
              <Header />
              <Estimator />
              <Footer />
            </>
          }
        />
        <Route
          path="/MySavings"
          element={
            <>
              <Header />
              <MySavings />
              <Footer />
            </>
          }
        />
        <Route
          path="/loan-products/residential"
          element={
            <>
              <Header />
              <ResidentialLoanProducts />
              <Footer />
            </>
          }
        />
        <Route
          path="/knowledge"
          element={
            <>
              <Header />
              <Knowledge />
              <Footer />
            </>
          }
        />

        <Route
          path="/Privacypolicy"
          element={
            <>
              <Header />
              <XsPrivacyPolicy />
              <Footer />
            </>
          }
        />
        <Route
          path="/DhanvikasPrivacypolicy"
          element={
            <>
              <Header />
              <DhanvikasPrivacyPolicy />
              <Footer />
            </>
          }
        />
        <Route
          path="/Refundpolicy"
          element={
            <>
              <Header />
              <RefundPolicy />
              <Footer />
            </>
          }
        />
        <Route
          path="/FairPracticeCode"
          element={
            <>
              <Header />
              <FairPracticeCode />
              <Footer />
            </>
          }
        />
        
        <Route
          path="/GrievanceRedressalPolicy"
          element={
            <>
              <Header />
              <GrievanceRedressalPolicy />
              <Footer />
            </>
          }
        />
        <Route
          path="/Termsandconditions"
          element={
            <>
              <Header />
              <Termsandconditions />
              <Footer />
            </>
          }
        />
        <Route path="/distributor/join" element={
          <>
          <Header />
          <DistributorJoinV2/>
        </>}>

        </Route>
        <Route
          path="/RegistrationComplete"
          element={<RegistrationComplete />}
        />
        <Route
          path="/cust-registration-complete"
          element={<OldCustomerRegisterComplete />}
        />
        <Route
          path="/cust-registered"
          element={<NewCustomerRegisterComplete />}
        />
        <Route
          path="PartnerRegistrationComplete"
          element={<PartnerRegistrationComplete />}
        />
        <Route
          path="/login"
          element={
            <>
              <Header />
              <Login />
              <Footer />
            </>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <>
              <Header />
              <ForgotPassword />
              <Footer />
            </>
          }
        />
        <Route
          path="/reset-password/:token"
          element={
            <>
              <ResetPassword />
            </>
          }
        />

        <Route
          path="/lease"
          element={
            <>
              <Header />
              <Lease />
              <Footer />
            </>
          }
        />
        <Route
          path="/coming-soon"
          element={
            <>
              <Header />
              <ComingSoon />
              <Footer />
            </>
          }
        />
        <Route
          path="/mission"
          element={
            <>
              <Header />
              <Mission />
              <Footer />
            </>
          }
        />
        <Route
        path="/contact-sales"
        element = {
          <><Header/>
          <ContactSales/>
          <Footer/>
          </>
        }
        />
        <Route
        path="/lease-contact-sales"
        element = {
          <><Header/>
          <ContactSalesForLease/>
          <Footer/>
          </>
        }
        />
      </Routes>
      {/* <Footer/> */}
    </>
  );
};

export default AllRoutes;
