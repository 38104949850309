import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import * as querydata from "../../dataService/DataService";

// Forgot Password Validation
export const loginSchema = Yup.object({
  email: Yup.string().email().required("Please enter your email"),
  userTypeId: Yup.number()
    .notOneOf([-1], "Please select Registration Type")
    .required("Please select Registration Type"),
});

const ForgotPassword = () => {
  // Initialize Navigate method
  const navigate = useNavigate();

  // Values defined for Formik
  const initialValues = {
    email: "",
    userTypeId: 2,
  };
  const getAllUserTypes = useQuery("allUserTypes", querydata.getAllUserTypes);

  const { values, handleBlur, handleChange, handleSubmit, errors, touched } =
    useFormik({
      initialValues: initialValues,
      validationSchema: loginSchema,
      onSubmit: (values, action) => {
        console.log(values);
        forgotPasswordMutation.mutate(values);
        // action.resetForm();
      },
    });

  // Form post
  const forgotPasswordMutation = useMutation(querydata.forgotPassword, {
    onError: (error) => {
      toast.error(error.message);
    },
    onSuccess: (data) => {
      toast.success("Email has been sent to you with password reset link.");
    },
  });

  return (
    <>
      <section>
        <div class="solfin-login pb-5 cunsumer-top-space header-space">
          <div class="container-md">
            <div class="row">
              <div className="col-lg-6">
                <div className="right-side-news rounded-4 position-relative overflow-hidden border-0">
                  <div className="text-ad-news">
                    <h5 className="fw-bold mb-2">Go solar with Solfin</h5>
                    <p>
                      A transition to clean energy is about making an investment
                      in our future
                    </p>
                    <div className="solfin-action mt-lg-4 mt-3 d-none">
                      <a href="#" className="text-decoration-none">
                        <span className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn">
                          Learn More
                        </span>{" "}
                        <img
                          className="ms-3"
                          src="/assets/images/long-next-arrow.svg"
                          alt="Learn More"
                          title="Learn More"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="bottom-image text-end ps-4">
                    <img
                      className="w-100"
                      src="/images/rooftop-area.png"
                      alt="Rooftop Area"
                      title="Rooftop Area"
                    />
                  </div>
                </div>
                {/* <!-- /right-side-news --> */}
              </div>
              <div class="col-lg-6">
                <form onSubmit={handleSubmit}>
                  <div class="login-form forgot-password m-auto mt-4">
                    <h5 class="fw-bold">Forgot Password</h5>
                    <p>
                      Enter the email associated with your{" "}
                      <strong>Solfin Account</strong>.
                    </p>
                    <div class="form-fields">
                      <div class="mb-4">
                        <label for="Email">Email</label>
                        <input
                          type="email"
                          name="email"
                          value={values.email}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          required
                          id="Email"
                          class="form-control"
                        />
                      </div>
                      {/* <!-- /mb-4 --> */}
                      <div class="mb-4">
                        <label for="AccountType">Account Type</label>
                        <select
                          name="userTypeId"
                          value={values.userTypeId}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          class="form-control form-select"
                          id="AccountType"
                        >
                          {getAllUserTypes &&
                            getAllUserTypes.data &&
                            getAllUserTypes.data.data.map((option, index) => {
                              return (
                                <option key={index} value={option.user_type_id}>
                                  {option.user_type}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      {/* <!-- /mb-4 --> */}
                      <div class="mb-4 pt-xl-4 pt-3">
                        {forgotPasswordMutation.isLoading ? ( // Check if the mutation is loading
                          <button
                            class="btn btn-primary btn-header rounded-3 w-100 text-nowrap text-uppercase green-btn calculate-btn"
                            type="button"
                            disabled=""
                          >
                            <span
                              class="spinner-grow spinner-grow-sm"
                              aria-hidden="true"
                            ></span>
                            <span role="status">Loading...</span>
                          </button>
                        ) : (
                          <button
                            type="submit"
                            class="btn btn-primary btn-header rounded-3 w-100 text-nowrap text-uppercase green-btn calculate-btn"
                          >
                            Submit
                          </button>
                        )}
                      </div>
                      {/* <!-- /mb-4 --> */}
                      {/* <div class="user-log-option text-center pt-1">
                        <p>
                          Email has been sent to you with your new credentials.
                        </p>
                      </div>
                      <div class="user-log-option text-center pt-1">
                        <p>
                          No account is associated with this email. Please check
                          the email address entered by you.
                        </p>
                      </div> */}
                    </div>
                    {/* <!-- /form-fields --> */}
                  </div>
                </form>
              </div>
            </div>
            {/* <!-- /row --> */}
          </div>
          {/* <!-- /container --> */}
        </div>
        {/* <!-- /my-products --> */}
      </section>
    </>
  );
};

export default ForgotPassword;
