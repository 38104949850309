const PANDetails = ({values, errors, handleChange}) => {
  return (
    <>
      <div className="heading mb-xl-4 mb-3">
        <span className="fw-semibold saving-title">Company PAN Details</span>
      </div>
      <div className="row">
        <div className="col-xl-4 col-md-6">
          <div className="form-group mb-4">
            <label
              htmlFor="pancard"
              className="border rounded-4 w-100 position-relative"
            >
              <span className="fw-bold">PAN Number</span>
              <input
                type="text"
                id="pancard"
                className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                placeholder="PAN Number"
                name="panNumber"
                value={values.pan}
                onChange={handleChange}
              />
            </label>
            {errors.pan ? (
              <p className="form-error text-danger">{errors.pan}</p>
            ) : null}
          </div>
          {/* <!-- /form-group --> */}
        </div>
      </div>
    </>
  );
};

export default PANDetails;
