import React from "react";
import { Link } from "react-router-dom";

const PartnerRegistrationComplete = () => {
  return (
    <>
      <section>
        <div className="success-wrapper header-space">
          <div className="container custom-container">
            <div className="row">
              <div className="col-lg-12">
                <div className="sucess-complete-wrapper">
                  <h2>
                    registration <br />{" "}
                    <span className="text-theme-green">Successful!</span>
                  </h2>
                </div>
                <div className="sucess-text-wrapper">
                  <p>
                    {" "}
                    Application received. We will contact you soon for the next
                    steps.
                  </p>
                  <Link
                    to="/"
                    className="btn btn-primary text-capitalize calculate-btn fw-semibold rounded-5"
                  >
                    Back to Home
                  </Link>
                </div>
              </div>
            </div>
            {/* <!-- success complete --> */}
          </div>
          {/* <!-- /container --> */}
        </div>
        {/* <!-- /saving-calculator --> */}
      </section>
    </>
  );
};

export default PartnerRegistrationComplete;
