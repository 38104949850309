import React, { useState } from "react";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { getCustomerDetail } from "../../service/dataService";
// import documentImage from '../../assets/images/EAAZCncU8AA7WFV.png'
import { saveAs } from "file-saver";

const CustomerDetailPage = () => {
  const { id } = useParams();
  const [selectedProjectIndex, setSelectedProjectIndex] = useState(0);

  const { data, isLoading, error } = useQuery(["getCustomerDetail", +id], () =>
    getCustomerDetail(id)
  );
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!data) {
    return <div>No data fetched.</div>;
  }

  const downloadContract = () => {
    const image_url =
      projects && projects[selectedProjectIndex].rooftop_solar_contract;
    saveAs(image_url, "rooftop_solar_contract.jpg");
  };

  const downloadSanctionLetter = () => {
    const image_url =
      projects && projects[selectedProjectIndex].loan_sanction_letter;
    saveAs(image_url, "loan_sanction_letter.jpg");
  };

  const downloadAgreement = () => {
    const image_url = projects && projects[selectedProjectIndex].loan_agreement;
    saveAs(image_url, "loan_agreement.jpg");
  };

  const downloadDisbursalLetter = () => {
    const image_url =
      projects && projects[selectedProjectIndex].loan_disbursal_letter;
    saveAs(image_url, "loan_disbursal_letter.jpg");
  };

  const projects = data.data[1].projectsDetail
    ? data.data[1].projectsDetail
    : "";

  const handleProjectSelect = (event) => {
    const selectedIndex = event.target.value;
    setSelectedProjectIndex(selectedIndex);
  };

  // console.log(data.data[1].projectsDetail, "////");
  return (
    //   <div className="container-xxl container-p-y">
    //   <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Applicants /</span> Details</h4>
    //   <div className="card">
    //       <div className="card-header flex-column flex-md-row">
    //           {data.data.data.map(data => (
    //               <div className="head-label">
    //                   <h5 className="card-title mb-0">Details</h5>

    //                   <div className="card-body">

    //                       <ul className="list-unstyled mb-4 mt-3">
    //                       <li className="d-flex align-items-center mb-3 pb-2 border-bottom" style={{ width: '100%' }}>
    //                               <span className="fw-bold mx-2" style={{ marginRight: "50px", width: '45%' }}>Type:</span> <span>{data.customer_name}</span>
    //                           </li>
    //                           <li className="d-flex align-items-center mb-3 pb-2 border-bottom" style={{ width: '100%' }}>
    //                               <span className="fw-bold mx-2" style={{ marginRight: "50px", width: '45%' }}>Name:</span> <span>{data.customer_name}</span>
    //                           </li>
    //                           <li className="d-flex align-items-center mb-3 pb-2 border-bottom">
    //                               <span className="fw-bold mx-2" style={{ marginRight: "50px", width: '45%' }}>Address:</span> <span>{data.address1}</span>
    //                           </li>
    //                           <li className="d-flex align-items-center mb-3 pb-2 border-bottom">
    //                               <span className="fw-bold mx-2" style={{ marginRight: "50px", width: '45%' }}>Pin Code:</span> <span>{data.pincode}</span>
    //                           </li>
    //                           <li className="d-flex align-items-center mb-3 pb-2 border-bottom">
    //                               <span className="fw-bold mx-2" style={{ marginRight: "50px", width: '45%' }}>City:</span> <span>Mohali</span>
    //                           </li>
    //                           <li className="d-flex align-items-center mb-3 pb-2 border-bottom">
    //                               <span className="fw-bold mx-2" style={{ marginRight: "50px", width: '45%' }}>State:</span>
    //                               <span>Punjab</span>
    //                           </li>
    //                           <li className="d-flex align-items-center mb-3 pb-2 border-bottom">
    //                               <span className="fw-bold mx-2" style={{ marginRight: "50px", width: '45%' }}>Phone Number:</span>
    //                               <span>{data.phone}</span>
    //                           </li>
    //                           <li className="d-flex align-items-center mb-3 pb-2 border-bottom">
    //                               <span className="fw-bold mx-2" style={{ marginRight: "50px", width: '45%' }}>Email:</span>
    //                               <span>{data.email}</span>
    //                           </li>
    //                           <li className="d-flex align-items-center mb-3 pb-2 border-bottom">
    //                               <span className="fw-bold mx-2" style={{ marginRight: "50px", width: '45%' }}>Financing Required: </span>
    //                               <span>Yes</span>
    //                           </li>

    //                           <li className="d-flex align-items-center mb-3 pb-2 border-bottom">
    //                               <span className="fw-bold mx-2" style={{ marginRight: "50px", width: '45%' }}>PAN: </span>
    //                               <span>{data.pan}</span>
    //                           </li>

    //                           {/* <li className="d-flex align-items-center mb-3 pb-2 border-bottom">
    //                               <span className="fw-bold mx-2" style={{ width: '45%' }}>Document Type:</span>
    //                               <span>{data.document_name ? data.document_name : "No file is uploaded"}</span>
    //                           </li> */}
    //                           <li className="d-flex align-items-center mb-3 pb-2 border-bottom">
    //                               <span className="fw-bold mx-2" style={{ width: '73%' }}>Electriciy Bill:</span>
    //                               <span>
    //                                   {/* <a href={data.document_file} target="_blank" rel="noreferrer"> */}
    //                                   <Link to={data.elec_bill_image} target="_blank" download>
    //                                       <img alt="electriciy-bill"  className='w-25 me-3' />Download <i className="ti ti-arrow-down"></i>
    //                                   </Link>
    //                               </span>

    //                           </li>
    //                       </ul>

    //                   </div>

    //                   {/* <!-- /card header --> */}

    //               </div>
    //           ))}
    //       </div>

    //   </div>
    // </div>
    <>
      <div className="content-body">
        <div className="page-title">
          <Link className="back-to-page" to="/admin/customers">
            <i className="fa-solid fa-chevron-left"></i>
          </Link>
          <div className="dash-breadcrumb">
            <ul>
              <li>
                <Link to="/admin/customers">Customers</Link>
              </li>
              <li>Details</li>
            </ul>
          </div>
        </div>
        <div className="dash-card">
          <div className="dash-card-header border-0">
            <div className="tab-menu mb-0">
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    Projects
                  </button>
                </li>
                {/* <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                   Customer Details
                  </button>
                </li> */}
              </ul>
            </div>
          </div>
          <div className="dash-card-body">
            <div className="solfin-tabs">
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade  show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                  tabindex="0"
                >
                  <div className="tab-main-box">
                    <div
                      className="tab-box"
                      id="tab-1"
                      style={{ display: "block" }}
                    >
                      {projects.length > 0 ? (
                        <div className="choose-project">
                          <select
                            className="solfin-input"
                            onChange={handleProjectSelect}
                            value={selectedProjectIndex || ""}
                          >
                            {/* <option selected="Project 1">Project 1</option>
                                        <option>Project 2</option>
                                        <option>Project 3</option>
                                        <option>Project 4</option>
                                        <option>Project 5</option>
                                        <option>Project 6</option> */}
                            {projects.map((project, index) => (
                              <option key={index} value={index}>
                                {projects && projects[index]
                                  ? projects && projects[index].project_name
                                  : ""}
                              </option>
                            ))}
                          </select>
                          <hr />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {/* <!-- /choose-project --> */}
                    {projects.length > 0
                      ? selectedProjectIndex !== null && (
                          <div className="solfin-application-details">
                            <h6>
                              Unit Installed -{" "}
                              {projects && projects[selectedProjectIndex]
                                ? projects &&
                                  projects[selectedProjectIndex].project_name
                                : ""}
                            </h6>
                            <ul className="w-100 two-columns">
                              <li>
                                <span className="detail-label">
                                  Type of Panel
                                </span>
                                <span className="detail-value">
                                  {projects && projects[selectedProjectIndex]
                                    ? projects &&
                                      projects[selectedProjectIndex]
                                        .type_of_panel
                                    : ""}
                                </span>
                              </li>
                              <li>
                                <span className="detail-label">
                                  Installation Date
                                </span>
                                <span className="detail-value">
                                  {projects && projects[selectedProjectIndex]
                                    ? projects &&
                                      projects[selectedProjectIndex]
                                        .installation_date
                                    : ""}
                                </span>
                              </li>
                              <li>
                                <span className="detail-label">Financed</span>
                                <span className="detail-value">
                                  {projects && projects[selectedProjectIndex]
                                    ? projects &&
                                      projects[selectedProjectIndex]
                                        .is_financed === true
                                      ? "Yes"
                                      : "No"
                                    : ""}
                                </span>
                              </li>
                              <li>
                                <span className="detail-label">
                                  Finance Partner
                                </span>
                                <span className="detail-value">
                                  {projects && projects[selectedProjectIndex]
                                    ? projects &&
                                      projects[selectedProjectIndex]
                                        .finance_partner
                                    : ""}
                                </span>
                              </li>
                              <li>
                                <span className="detail-label">
                                  Installed Capacity
                                </span>
                                <span className="detail-value">
                                  {projects && projects[selectedProjectIndex]
                                    ? projects &&
                                      projects[selectedProjectIndex].capacity
                                    : ""}
                                </span>
                              </li>
                              <li>
                                <span className="detail-label">
                                  Estimated Unit Generated
                                </span>
                                <span className="detail-value">
                                  {projects && projects[selectedProjectIndex]
                                    ? projects &&
                                      projects[selectedProjectIndex]
                                        .estimated_unit_generated
                                    : ""}
                                </span>
                              </li>
                              <li>
                                <span className="detail-label">
                                  Type Of Solution
                                </span>
                                <span className="detail-value">
                                  {projects && projects[selectedProjectIndex]
                                    ? projects &&
                                      projects[selectedProjectIndex].solution
                                    : ""}
                                </span>
                              </li>
                              <li>
                                <span className="detail-label">EPC</span>
                                <span className="detail-value">
                                  {projects && projects[selectedProjectIndex]
                                    ? projects &&
                                      projects[selectedProjectIndex]
                                        .company_name
                                    : ""}
                                </span>
                              </li>
                              <li>
                                <span className="detail-label">DSA</span>
                                <span className="detail-value">
                                  {projects && projects[selectedProjectIndex]
                                    ? projects &&
                                      projects[selectedProjectIndex].dsa
                                    : ""}
                                </span>
                              </li>
                              <li>
                                <span className="detail-label">
                                  Project Cost
                                </span>
                                <span className="detail-value">
                                  ₹{" "}
                                  {projects && projects[selectedProjectIndex]
                                    ? projects &&
                                      projects[selectedProjectIndex]
                                        .project_cost
                                    : ""}
                                </span>
                              </li>
                              <li>
                                <span className="detail-label">
                                  Loan Sanction Letter
                                </span>
                                {projects &&
                                projects[selectedProjectIndex] &&
                                projects[selectedProjectIndex]
                                  .loan_sanction_letter ? (
                                  <span className="detail-value">
                                    <Link
                                      to={
                                        projects &&
                                        projects[selectedProjectIndex]
                                          ? projects &&
                                            projects[selectedProjectIndex]
                                              .loan_sanction_letter
                                          : "No"
                                      }
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {/* <img class="ms-2" src={projects[selectedProjectIndex]?projects[selectedProjectIndex].loan_sanction_letter:""} alt="" title="loan_sanction_letter" height="25"/> */}
                                      <button
                                        className="btn text-dark text-green"
                                        onClick={downloadSanctionLetter}
                                      >
                                        {" "}
                                        <i className="fa-solid fa-download"></i>{" "}
                                        Download{" "}
                                      </button>
                                    </Link>
                                  </span>
                                ) : (
                                  ""
                                )}
                              </li>
                              <li>
                                <span className="detail-label">
                                  Loan Disbursal Letter
                                </span>
                                {projects &&
                                projects[selectedProjectIndex] &&
                                projects[selectedProjectIndex]
                                  .loan_disbursal_letter ? (
                                  <span className="detail-value">
                                    <Link
                                      to={
                                        projects &&
                                        projects[selectedProjectIndex]
                                          ? projects &&
                                            projects[selectedProjectIndex]
                                              .loan_disbursal_letter
                                          : ""
                                      }
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {/* <img class="ms-2" src={projects[selectedProjectIndex]?projects[selectedProjectIndex].loan_disbursal_letter:""} alt="" title="LoanDisbursalLetter" height="25"/> */}
                                      <button
                                        className="btn text-dark text-green"
                                        onClick={downloadDisbursalLetter}
                                      >
                                        {" "}
                                        <i className="fa-solid fa-download"></i>{" "}
                                        Download
                                      </button>
                                    </Link>
                                  </span>
                                ) : (
                                  ""
                                )}
                              </li>
                            </ul>
                          </div>
                        )
                      : "There is no project associated with you "}
                  </div>
                </div>
              </div>

              <div
                className="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
                tabindex="0"
              >
                <div
                  className="tab-box"
                  id="tab-2"
                  style={{ display: "block" }}
                >
                  <form action="#" method="get">
                    <div className="solfin-application-details">
                      <ul>
                        <li>
                          <span className="detail-label">Name:</span>{" "}
                          <span className="detail-value">
                            {data &&
                              data.data[0] &&
                              data.data[0].customerInfo[0] &&
                              data.data[0].customerInfo[0].customer_name}
                          </span>
                        </li>
                        <li>
                          <span className="detail-label">Phone:</span>
                          <span className="detail-value">
                            {data &&
                              data.data[0] &&
                              data.data[0].customerInfo[0] &&
                              data.data[0].customerInfo[0].phone}
                          </span>
                        </li>
                        <li>
                          <span className="detail-label">Email:</span>
                          <span className="detail-value">
                            {data &&
                              data.data[0] &&
                              data.data[0].customerInfo[0] &&
                              data.data[0].customerInfo[0].email}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CustomerDetailPage;
