import React from "react";
import { useState, useEffect } from "react";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useFilters,
  useSortBy,
} from "react-table";
import { Input } from "reactstrap";
import Filter from "../../components/Filter";
import UploadNewInvoice from "./NewInvoiceUploadModal";

const InvoiceTable = ({tableName="List Table",tableData, columns,handleExport,cityallowed, stateallowed,dataQuery, customertypeallowed, statusallowed, startandenddateallowed, areaServedallowed,connecttoGridAllowed}) => {
  //filter table data
  const [filteredData, setFilteredData] = useState(tableData);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [isUploading , setIsUploading]  = useState(false);
  // Create an instance of the table
  // const data = React.useMemo(() => filteredData, [filteredData]);
  const data1 = React.useMemo(() => tableData, [tableData]);
  // const data1 = React.useMemo(() => rows, [rows]);
  const data = tableData

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    prepareRow,
    setFilter,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    state: { filters },
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      {/* <!-- /page title --> */}
      <div className="dash-card">
        <div className="dash-card-header flex-wrap card-flexbox">
          <h6>{tableName}</h6>
          <div className="table-right-side card-flexbox ms-auto">
         
            <button href="#" className="btn btn-info" onClick={handleExport}>
              Export
            </button>
            <button className="btn btn-info filter-btn" id="button"  onClick={() => setIsFilterActive((prev) => !prev)}>
              Filter
            </button>
            <button data-bs-toggle="modal"
                data-bs-target={`#${"new-invoice-upload"}`}
                // onClick={() => handleArchive(row)}
                className="ms-2 btn btn-sm filter-btn green-btn"
                title="New"
                disabled={isUploading}>
              { isUploading==true ? "Uploading.." : "Upload New Invoice"}
            </button>
            <UploadNewInvoice setIsUploading={setIsUploading} dataQuery={dataQuery} />
            <form action="#" method="get" className="card-flexbox">
              <div className="form-group">
              <input
                      type="text"
                      placeholder="Search"
                      className="solfin-input"
                      value={state.globalFilter || ""}
                      onChange={(e) => setGlobalFilter(e.target.value)}
                    />
                <button
                  type="submit"
                  className="btn btn-sm btn-info search-btn"
                >
                  <i className="fa fa-search"></i>
                </button>
              </div>
            </form>
          </div>
          <Filter setFilter={setFilter} isFilterActive={isFilterActive} setFilteredData={setFilteredData} tableData={tableData} cityallowed={cityallowed} stateallowed={stateallowed}  startandenddateallowed={startandenddateallowed} />
        </div>
        {/* <!-- /card header --> */}
        <div className="dash-card-body">
          <div className="table-responsive auto-scroll-table">
            <table {...getTableProps()} className="table">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render("Header")}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? " 🔽"
                              : " 🔼"
                            : ""}
                        </span>
                        {/* <input type="checkbox" name="" id="" /> */}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              {page.length === 0 ? (
                <tbody>
                  <span>No Data Found</span>{" "}
                </tbody>
              ) : (
                <tbody {...getTableBodyProps()}>
                  {page.map((row) => {
                    prepareRow(row);

                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell, index) => (
                          <td {...cell.getCellProps()}>
                            {index === 0 ? row.index + 1 : cell.render("Cell")}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
          </div>
          {/* <!-- /table responsive --> */}
          <div className="solfin-pagination">
            <ul className="pagination-solfin">
              <li className="">
                <button
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              <i className="fa-solid fa-angle-left"></i>
            </button>
            </li>
            {Array.from({ length: pageCount }, (_, index) => (
              <li>
          <button
            key={index}
            onClick={() => gotoPage(index)}
            disabled={pageIndex === index}
          >
            {index + 1}
          </button>
          </li>
        ))}

              <li>
                <button
              className="btn m-n3"
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              <i className="fa-solid fa-angle-right"></i>
            </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoiceTable;
