import React, { useEffect } from "react";
import {
 Link,
 useLocation,
 useNavigate,
 useParams,
 useSearchParams,
} from "react-router-dom";
import { acceptEpc, getEpc, updateEpc, getDistributorKycDocument, getDocumentPresignedUrl, getDistributorKycDocumentUrl, updateDistributorKycDocumentStatus, verifyDistributorKYC, getComfortLettersForDistributor } from "../../service/dataService";
import { saveAs } from "file-saver";
import { useState } from "react";
import { toast } from "react-toastify";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { filter } from "rxjs";
import Select from "../../components/Select";
import * as dataquery from "../../service/dataService";
import AreaServedModal from "../../../components/AreaServedModal";
import DistributorRejectionRemarks from "../../components/DistributorRejectionRemarks";
import DistributorComfortLetterAction from "./components/DistributorComfortLetterAction";


const ApplicantsDetailPage = () => {
 const queryClient = useQueryClient();
 const location = useLocation();
 const queryParams = new URLSearchParams(location.search);
 const originalPage = queryParams.get("page");
 console.log("originalPage");
 console.log(originalPage);
 let counter = 1;


 let signatoryCounter=1;
 const [page] = useSearchParams();
 console.log("page");
 console.log(page);


 //get epc data
 const { id } = useParams();
 // console.log(id)
 const navigate = useNavigate();
 const [epcData, setEpcData] = useState([{}]);
 const { data, isLoading, error } = useQuery(["getEpc", +id], () =>
   getEpc(id)
 );

  const comfortLetters = useQuery(
  ["getComfortLettersForDistributor", +id],
  () => getComfortLettersForDistributor(id),
  {
    enabled: !!data?.data?.is_distributor,
  }
); 
 const approveDistributorKYC = async()=>{
    const response = await verifyDistributorKYC(id);
    if (response){
    toast.success("Distributor kyc verified successfully.")
    }
 }
 const kycDocuments = useQuery(
   ["getDistributorKycDocument", +id],
   () => getDistributorKycDocument(id),
   {
     enabled: !!data?.data?.is_distributor,
   }
 );


 const verifyKycDocument= async(documentId)=>{
   const response = await updateDistributorKycDocumentStatus({documentId: documentId, status:"VERIFIED"});
   if (response){
     toast.success("Document verified successfully.")
     queryClient.invalidateQueries("getDistributorKycDocument");
   }
 }


 const rejectDocumentKyc = async(documentId, remarks)=>{
   const response = await updateDistributorKycDocumentStatus({documentId: documentId, status:"REJECTED", remarks: remarks});
   if (response){
     toast.success("Document rejected successfully.")
     queryClient.invalidateQueries("getDistributorKycDocument");
   }
 }


 useEffect(() => {
   if (data && data.data.is_distributor) {
    
     queryClient.invalidateQueries(["getDistributorKycDocument","getComfortLettersForDistributor"]);
     
   }

 }, [data, queryClient]);
 


 const [filteredData, setFilteredData] = useState([]);


 // useEffect(()=>{
 //   const initialStateId =
 //   data && data.rateMatrix && data.rateMatrix[0]?.state_id;


 // setFilteredData(
 //   data &&
 //     data.rateMatrix &&
 //     data.rateMatrix.filter((obj) => obj.state_id == initialStateId)
 // );
 // },[])

console.log(comfortLetters);
 useEffect(() => {
   const initialStateId =
     data && data.rateMatrix && data.rateMatrix[0]?.state_id;
   // console.log("initialStateId");
   // console.log(initialStateId);
   setFilteredData(
     data &&
       data.rateMatrix &&
       data.rateMatrix.filter(
         (obj) => obj.state_id == MatrixStates[0].option_id
       )
   );
 }, [data]);


const viewDocument = async(e, documentId) =>{
  try {
   // Replace with your actual API endpoint
   const response = await getDistributorKycDocumentUrl(documentId);
   if (response.data && response.data.link) {
     window.open(response.data.link, '_blank');
   } else {
     toast.error('Error fetching file details, Please try again.');
   }
 } catch (error) {
   console.error('Error fetching the file URL:', error);
 }
}





 const acceptEpc = useMutation(dataquery.acceptEpc, {
   onError: (error) => {
     toast.error(error.message);
   },
   onSuccess(data) {
     queryClient.invalidateQueries("approvedEpc");
     toast.success(data.message);
     navigate("/admin/epc-empanelled");
   },
 });


 // useEffect(()=>{
 //     setFilteredData(data && data.rateMatrix && data.rateMatrix.filter(obj => obj.state_id =="5"));
 //   },[])


 //     const filteredRateMatrix = data.rateMatrix.filter(obj => obj.component_name === 'Solar Modules');
 //    console.log(filteredRateMatrix);
 const updateStatusMutation = useMutation(dataquery.updateEpcApplicantStatus, {
   onError: (error) => {
     toast.error(error.message);
   },
   onSuccess(data) {
     // Wiconsole.log(data);ll be called 3 times
     toast.success(data.message);
     navigate(`/admin/epc-applicants?page=${originalPage}`);
   },
 });


 if (isLoading) {
   return <div>Loading...</div>;
 }


 if (error) {
   return <div>Error: {error.message}</div>;
 }


 if (!data) {
   return <div>No data fetched.</div>;
 }

 let allDistributorDocsVerified = kycDocuments && kycDocuments.data && kycDocuments.data.data && kycDocuments.data.data.documents && kycDocuments.data.data.documents.every(
  outerObj => outerObj.kycStatusEnum === "VERIFIED"
);
 const handleChangeStatus = async (id, statusCode) => {
   const updataData = { id: id, statusCode: statusCode };
   const data1 = await updateStatusMutation.mutateAsync(updataData);
 };


 const handleAssignedChange = () => {
   acceptEpc.mutate(id);
 };
 const componentslist = data.allMatrixComponents;
 const wattagelist = data.allWattageComponents;
 const MatrixStates = data.allMatrixStates;


 //filter epc rate matrix based on state


 const onButtonClick = () => {
   const image_url = data.documents[1].documentAddress;
   saveAs(image_url, "file");
 };
 const onButtonClick1 = () => {
   const image_url1 = data.documents[0].documentAddress;
   saveAs(image_url1, "file");
 };


 const filterMatrixData = (stateId) => {
   setFilteredData(data.rateMatrix.filter((obj) => obj.state_id == stateId));
 };

 

 return (
   <>
     <div className="content-body">
       <div className="page-title">
       { data.data.is_distributor ? 
       <Link className="back-to-page" to="/admin/distributor/kyc">
           <i className="fa-solid fa-chevron-left"></i>
         </Link>:
         <Link className="back-to-page" to="/admin/epc-applicants">
           <i className="fa-solid fa-chevron-left"></i>
         </Link>
}
         <div className="dash-breadcrumb">
           <ul>
             <li>
             { data.data.is_distributor ? 
             '':
               <Link to="/admin/epc-applicants">Applicants</Link>
             }
             </li>
             <li>Details</li>
           </ul>
         </div>
       </div>
       <div className="dash-card">
         <div className="dash-card-header">
           <h6>Details</h6>
         </div>


         <div className="dash-card-body">
           {/* <form action="#" method="get"> */}
           {data.data ? (
             <>
               <div className="solfin-application-details">
                 <ul>
                   <li>
                     <span className="detail-label">Registration Type</span>
                     <span className="detail-value">
                       {data.data.customer_type}
                     </span>
                   </li>


                   <li>
                     <span className="detail-label">Name</span>
                     <span className="detail-value">
                       {data.data.company_name}
                     </span>
                   </li>
                   <li>
                     <span className="detail-label">Address</span>
                     <span className="detail-value">{data.data.address1}</span>
                   </li>
                   <li>
                     <span className="detail-label">Pincode</span>
                     <span className="detail-value">{data.data.pin_code}</span>
                   </li>
                   <li>
                     <span className="detail-label">City</span>
                     <span className="detail-value">{data.data.city}</span>
                   </li>
                   <li>
                     <span className="detail-label">State</span>
                     <span className="detail-value">{data.data.state}</span>
                   </li>
                   <li>
                     <span className="detail-label">Phone</span>
                     <span className="detail-value">{data.data.phone}</span>
                   </li>
                   <li>
                     <span className="detail-label">Email</span>
                     <span className="detail-value">{data.data.email}</span>
                   </li>
                   {/* <li>
                                       <span className="detail-label">Company Name</span>
                                       <span className="detail-value">{data.data.company_name}</span>
                                   </li> */}
                  {data.documents &&
                       data.documents[1] &&
                         data.documents[1].status_id!=22 ?
                         <>
                   <li>
                     <span className="detail-label">Document 1 Type</span>
                     <span className="detail-value">
                       {data.documents &&
                       data.documents[1] &&
                       data.documents[1].documentName
                         ? data.documents[1].documentName
                         : "No file is uploaded"}
                     </span>
                   </li>
                   <li>
                     <span className="detail-label">Document 1 Image</span>
                     <span className="detail-value img-uploaded">
                       {data.documents &&
                       data.documents[1] &&
                       data.documents[1].documentAddress ? (
                         <a
                           href={data.documents[1].documentAddress}
                           target="_blank"
                         >
                           <button
                             className="btn text-dark"
                             onClick={onButtonClick}
                           >
                             {" "}
                             <i className="fa-solid fa-download"></i> Download
                           </button>
                         </a>
                       ) : (
                         ""
                       )}
                     </span>
                   </li>
                   <li>
                     <span className="detail-label">Document 2 Type</span>
                     <span className="detail-value">
                       {data &&
                       data.documents[0] &&
                       data.documents[0].documentName
                         ? data.documents[0].documentName
                         : "No file is uploaded"}
                     </span>
                   </li>
                   <li>


                     <span className="detail-label">Document 2 Image</span>
                     <span className="detail-value img-uploaded">
                       {data &&
                       data.documents[0] &&
                       data.documents[0].documentAddress ? (
                         <a
                           href={data.documents[0].documentAddress}
                           target="_blank"
                         >
                           {/* <img
                           alt="document"
                           src={data.documents[0].documentAddress}
                           className="w-25 me-3"
                         /> */}
                           {/* Download <i className="ti ti-arrow-down"></i> */}


                           <button
                             className="btn text-dark"
                             onClick={onButtonClick1}
                           >
                             {" "}
                             <i className="fa-solid fa-download"></i> Download
                           </button>
                         </a>
                       ) : (
                         ""
                       )}
                     </span>
                   </li>
                   </>: ''
}


                   <li>
                     <span className="detail-label">Status</span>
                     <span className="detail-value">{data.data.status}</span>
                   </li>


                   <li>
                     <span className="detail-label">Area Served</span>
                     <span className="detail-value">
                       <div className="state-served">
                         {data.serviceArea.map((area, index) => (
                           <React.Fragment key={index}>
                             <button
                               className="btn text-dark text-decoration-underline py-0 px-1"
                               data-bs-toggle="modal"
                               data-bs-target={`#stateId${area.state_id}`}
                             >
                               {area.state}
                               <AreaServedModal area={area} />{" "}
                             </button>
                             {index !== data.serviceArea.length - 1 && ","}
                           </React.Fragment>
                         ))}
                       </div>
                     </span>
                   </li>
                   {/* <li>
                                       <span className="detail-label">Turn Around Time</span>
                                       <span className="detail-value">45-90 Days</span>
                                   </li> */}
                   {/* <li>
                                       <span className="detail-label">Document Type</span>
                                       <span className="detail-value">
                                           Aadhar Card
                                       </span>
                                   </li>
                                   <li>
                                       <span className="detail-label">Document Image</span>
                                       <span className="detail-value img-uploaded">
                                           <img className="lightbox" src="../assets/images/aadhar-card.jpg" alt="Aadhar Card" title="Aadhar Card"/>
                                           <a href="#" className="download-doc">Download <i className="fa-solid fa-arrow-down"></i></a>
                                       </span>
                                   </li> */}
                   <li>
                     <span className="detail-label">Premium Partner Code</span>
                     <span className="detail-value">
                       {data.data.premium_partner_code}
                     </span>
                   </li>
                   <li>
                     <span className="detail-label">Created On</span>
                     <span className="detail-value">
                       {data.data.created_on}
                     </span>
                   </li>
                   <li>
                     <span className="detail-label">Published On</span>
                     <span className="detail-value">
                       {data.data.published_on}
                     </span>
                   </li>
                   { data.data.is_distributor==true ?
                   <li>
                   <span className="detail-label">Comfort letter</span>
                   <span className="detail-value"><DistributorComfortLetterAction distributorId={id} comfortLettersList={comfortLetters && comfortLetters.data && comfortLetters.data.data} getComfortLettersForDistributor="getComfortLettersForDistributor" /></span>
                  </li>
                  : 
                  ""}
                   
                 </ul>
               </div>
               {kycDocuments.data && kycDocuments.data.data && kycDocuments.data.data.documents ? (
                 <>
 <div className="rate-matix-details">
   <div className="flex-wrap card-flexbox admin-epc-detail">
     <h6 className="mb-0">Kyc Documents</h6>
   </div>
   <div className="table-responsive">
     <table className="table">
       <thead>
         <tr>
           <th>Category</th>
           <th>Document Type</th>
           <th>Document Status</th>
           <th>Old Download/View</th>
           <th>Download/View</th>
           <th>Remarks</th>
           <th>Action</th>
           <th>Signatory Name</th>
         <th>Signatory Email</th>
         <th>Signatory Phone</th>
         <th>ShareHolding Percentage</th>
         </tr>
       </thead>
       <tbody>
         {kycDocuments.data.data.documents.map((outerObj, index) => (
           <tr key={outerObj.epcId+outerObj.documenId}>
            <td>{outerObj.documentCategory}</td>
             <td>{outerObj.documentType} {outerObj.documentTime? "("+outerObj.documentTime+")":''}</td>
             <td>{outerObj.kycStatus}</td> {/* Added documentStatus */}
             <td> {outerObj.previousDocId?
             


                           <button
                             className="btn text-dark"
                             onClick={(e)=>{viewDocument(e, outerObj.previousDocId)}}
                           >
                             {" "}
                             <i className="fa-solid fa-download"></i> Download
                           </button>
                         :'Not Available'}
                        
                         </td> {/* Added oldDownloadView */}
                         <td>
                         {outerObj.uploadedDocId?
             


                           <button
                             className="btn text-dark"
                             onClick={(e)=>{viewDocument(e, outerObj.uploadedDocId)}}
                           >
                             {" "}
                             <i className="fa-solid fa-download"></i> Download
                           </button>
                         :''}
                           </td>
             <td>{outerObj.remark}</td> {/* Added signatoryName */}
        
             {/* Added downloadView */}
             <td>{outerObj.kycStatusEnum=='PENDING'?
             <div className="text-nowrap">
             <button
               onClick={() => verifyKycDocument(outerObj.documentId)}
               className="btn btn-sm btn-info icon-btn"
               title="Details"
               href="applicant-detail.html"
             >
             Verify
             </button>
             <button
               data-bs-toggle="modal"
               data-bs-target={`#reject-document-${outerObj.documentId}`}
               // onClick={() => handleArchive(row)}
               className="ms-2 btn btn-sm btn-danger"
               title="Reject"
             >
               Reject
              
             </button>{" "}
             <DistributorRejectionRemarks documentId={outerObj.documentId} rejectionHandler={rejectDocumentKyc} />
           </div>
             :''}</td> {/* Added verifyDocument */}
             <td>{outerObj.signatoryDetails.name}</td>
                         <td>{outerObj.signatoryDetails.email}</td>
                         <td>{outerObj.signatoryDetails.phone}</td>
                         <td>{outerObj.signatoryDetails.shareHoldingPercentage}</td>
           </tr>
         ))}
       </tbody>
     </table>
   </div>
 </div>
</>
) : (
 ''
)}


           { MatrixStates && MatrixStates.lenght>0?
               <div className="rate-matix-details">
                 <div className="flex-wrap card-flexbox admin-epc-detail">
                   <h6 className="mb-0">Rate Matrix List</h6>
                   <div className="table-right-side card-flexbox">
                     <div className="form-group">
                       <Select
                         dropdownidText="ratematrixId"
                         dropdownName="ratematrixstateId"
                         dropdowndata={MatrixStates}
                         filterMatrixData={filterMatrixData}
                       />
                     </div>
                   </div>
                 </div>
                 <div className="table-responsive">
                   <table className="table">
                     <thead>
                       <tr>
                         <th>Sr. No.</th>
                         <th>Components</th>
                         {wattagelist.map((wattagelistObj) => (
                           <>
                             <th>{wattagelistObj.wattage}</th>
                           </>
                         ))}
                       </tr>
                     </thead>
                     <tbody>
                       {componentslist.map((outerObj, index) => {
                         const rowData = wattagelist.map((innerObj) => {
                           const filteredObj =
                             filteredData &&
                             filteredData.find(
                               (obj) =>
                                 obj.rate_matrix_component_id ===
                                   outerObj.rate_matrix_component_id &&
                                 obj.rate_matrix_wattage_component_id ===
                                   innerObj.rate_matrix_wattage_component_id
                             );
                           return filteredObj ? filteredObj.amount : "";
                         });


                         return (
                           <tr key={outerObj.rate_matrix_component_id}>
                             <td>{index + 1}</td>
                             <td>{outerObj.component_name}</td>
                             {rowData.map((amount, idx) => (
                               <td key={idx}>
                                 {outerObj.rate_matrix_component_id === 7
                                   ? +amount
                                   : `₹ ${amount}`}
                               </td>
                             ))}
                           </tr>
                         );
                       })}
                       <tr>
                         <td></td>
                         <td>Total cost</td>
                         {wattagelist.map((innerObj, idx) => {
                           const totalCost =
                             filteredData &&
                             filteredData
                               .filter(
                                 (obj) =>
                                   obj.rate_matrix_wattage_component_id ===
                                   innerObj.rate_matrix_wattage_component_id
                               )
                               .filter(
                                 (obj) => obj.rate_matrix_component_id !== 7
                               )
                               .reduce(
                                 (sum, filteredObj) =>
                                   sum + +filteredObj.amount,
                                 0
                               );


                           return (
                             <td key={idx}>
                               {" "}
                               ₹ {totalCost && totalCost.toFixed(2)}
                             </td>
                           );
                         })}
                       </tr>
                     </tbody>
                   </table>
                 </div>
               </div>
               :''}


{data.data.is_distributor==false ?        
<div className="form-actions d-flex">
                  {acceptEpc.isLoading ? (
                    <button
                      class="opacity-100 btn btn-info spinner-btn2"
                      type="button"
                      disabled
                    >
                      <span
                        class="spinner-border spinner-border-sm"
                        aria-hidden="true"
                      ></span>
                      <span class="visually-hidden" role="status">
                        Loading...
                      </span>
                    </button>
                  ) : (
                    <button
                      onClick={handleAssignedChange}
                      className={`btn btn-info`}
                    >
                      Approve
                    </button>
                  )}
                  {updateStatusMutation.isLoading ? (
                    <button
                      class="opacity-100 btn btn-info spinner-btn2"
                      type="button"
                      disabled
                    >
                      <span
                        class="spinner-border spinner-border-sm"
                        aria-hidden="true"
                      ></span>
                      <span class="visually-hidden" role="status">
                        Loading...
                      </span>
                    </button>
                  ) : (
                    <button
                      onClick={() =>
                        handleChangeStatus(data.data.epc_id, "Rejected")
                      }
                      className="btn btn-info filter-btn"
                    >
                      Reject
                    </button>
                  )}
                </div>:''}
              
                {data.data.is_distributor==true && data.data.status_id=='22' && allDistributorDocsVerified==true?        
<div className="form-actions d-flex">
                    <button
                      onClick={approveDistributorKYC}
                      className={`btn btn-info`}
                    >
                      Approve
                    </button>
                 
                </div>:''}
             </>
           ) : (
             ""
           )}
           {/* </form> */}
         </div>
       </div>
     </div>
   </>
 );
};


export default ApplicantsDetailPage;
