import React, { useState, useEffect } from "react";
import { NavLink, useLocation, props, Link } from "react-router-dom";
import $ from "jquery";
import HomeSlider from "./components/HomeSlider";
import Cookies from "js-cookie";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import * as querydata from "../../dataService/DataService";
import TestimonialSlider from "./components/TestimonialSlider";



const Register = () => {
//   const navigate = useNavigate();

//   const token= Cookies.get('token');

// useEffect(() => {
//   const tokenAuth= Cookies.get('token');

//   if(tokenAuth){
//     setToken(tokenAuth);
//   }

// },[])

// var btnClass = classNames({
//   'btn': true,
 
// });
// useEffect(() => {
  // window.onload = function() {
    //document.addEventListener("DOMContentLoaded", function() {
      // var headers = document.body.querySelectorAll(".calculate-saving.calculatebtn-move");
    
      // window.addEventListener("scroll", function() {
      //   var scroll = window.pageYOffset || document.documentElement.scrollTop;
    
      //   headers.forEach(function(header) {
      //     if (scroll >= 500 && header) {
      //       header.classList.add("btnmoveddown");
      //     } else if (header) {
      //       header.classList.remove("btnmoveddown");
      //     }
      //   });
      // });
    // };
  // },[])
    
  //   document.addEventListener("DOMContentLoaded", function() {
  //     var headers = document.querySelectorAll(".calculate-saving.calculatebtn-move");
    
  //     window.addEventListener("scroll", function() {
  //       var scroll = window.pageYOffset || document.documentElement.scrollTop;
    
  //       headers.forEach(function(header) {
  //         if (scroll >= 500 && header) {
  //           header.classList.add("btnmoveddown");
  //         } else if (header) {
  //           header.classList.remove("btnmoveddown");
  //         }
  //       });
      
  // });
  // })
 
  // //window.onload = function() {
  //   document.addEventListener("DOMContentLoaded", function() {
  //      var headers = document.body.querySelectorAll(".calculate-saving.calculatebtn-move");
     
  //      window.addEventListener("scroll", function() {
  //        var scroll = window.pageYOffset || document.documentElement.scrollTop;
     
  //        headers.forEach(function(header) {
  //          if (scroll >= 500 && header) {
  //            header.classList.add("btnmoveddown");
  //          } else if (header) {
  //            header.classList.remove("btnmoveddown");
  //          }
  //        });
  //      });
  //    });

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setIsScrolled(scrollTop >=500);
    };

    window.addEventListener('scroll', handleScroll);
 
    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const images = [
    'https://via.placeholder.com/600x400?text=Slide+1',
    'https://via.placeholder.com/600x400?text=Slide+2',
    'https://via.placeholder.com/600x400?text=Slide+3'
  ];
  

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };

  return (
    <>
      <section>
        <div className="solfin-video position-relative overflow-hidden">
          <div className="video-container">
            <video
              className="position-absolute start-0 top-0"
              id="BgVideo"
              poster="/images/video-cover.jpg"
               preload="auto"
               loop
               muted
               autoPlay
               playsInline
            >
              <source
                preload="auto"
                src="video/rooftop-solfin.mp4"
                type="video/mp4"
              />
            </video>
            <div className="overlay h-100"></div>
            <div className="solfin-caption position-relative z-3 d-flex align-items-center justify-content-center flex-wrap pb-lg-5 ">
              <div className="caption-text text-center">
                {/* <div className="solfin-caption-logo">
                  <img
                    src="images/footer-logo.svg"
                    alt="Solfin"
                    title="Solfin"
                  />
                </div> */}
                <div className="hero-heading">
                  <h1 className="text-white fw-bold m-0" >
                    <span className="text-theme-green animate__animated animate__bounce">
                     <span className="text-white fw-bold m-0">Your</span> trusted  <span className="text-white fw-bold m-0">partner for a </span>sustainable <span className="text-white fw-bold m-0">future... </span></span><span className="text-theme-green">Go Solar, Go Green.</span>
                     {" "}
                     </h1>
                 
                </div>
              </div>
              <div className="container custom-container">
                <div className={`calculate-saving calculatebtn-move d-flex align-items-center justify-content-between rounded-20 ps-xl-5 ps-lg-4 ps-3 pe-xl-5 pe-lg-4 pe-3 pt-md-4 pt-3 pb-md-4 pb-3 mb-4 ${isScrolled ? 'btnmoveddown' : ''}`}  >
                  <div className="left-text-calculative text-white w-100">
                    <h5 className="fw-bold text-white">
                      {" "}
                     Solar Roof Top </h5> <h5 className=" text-white"> Commercial, Industrial, Residential, Greenfield
                    </h5>
                    <p className="mb-0 mt-md-3 mt-2 mb-md-0 mb-3 fw-medium d-none">
                      Roof Top, Greenfield, Residential, Commercial, Industrial
                    </p>
                  </div>
                  {/* <!-- /left text --> */}
                  <div className="right-action-btn mt-2 mt-md-0">
          
                    <NavLink to="/Estimator"
                      className="btn btn-primary text-uppercase calculate-btn fw-semibold rounded-5 text-nowrap"
                    >
                     <span>Calculate my savings</span><i className="fa fa-calculator"></i>
                    </NavLink>
                  </div>
                  {/* <!-- /right action --> */}
                </div>
              </div>
              {/* <!-- /calculate-saving --> */}
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="what-solfin-do bg-white section-padding">
          <div className="container custom-container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="what-we-do-left">
                  <div className="solfin-heading">
                    <div className="small-heading mb-3 fw-semibold position-relative text-uppercase">
                      WHAT WE DO
                    </div>
                    <h2 className="fw-bold heading-color">
                      Lets <span className="text-theme-green">Build</span> our
                      Sustainable Future
                    </h2>
                  </div>
                  {/* <!-- /solfin-heading --> */}
                  <div className="text-para mt-4 pb-lg-1 pe-lg-5">
                    <p>
                      Solfin is building a state of the art end-to-end platform to deliver best in class solar projects through a network of trusted EPC partners and in-house financing/leasing arm. {" "}
                    </p>
                  </div>
                  {/* <div className="solfin-action mt-2">
                    <a href="#" className="text-decoration-none">
                      <span className="btn btn-primary text-capitalize calculate-btn fw-semibold rounded-5">
                        Learn More
                      </span>{" "}
                      <img
                        className="ms-3"
                        src="images/long-next-arrow.svg"
                        alt="Learn More"
                        title="Learn More"
                      />
                    </a>
                  </div> */}
                </div>
              </div>
              {/* <!-- /col-lg-6 --> */}
              <div className="col-lg-6">
                <div className="what-we-do-right">
                  <div className="our-features d-flex flex-wrap">
                    <div className="row g-3">
                      <div className="col-md-6">
                        <div
                          className="feature h-100"
                          uk-scrollspy="cls: uk-animation-slide-left-medium; delay: 500;"
                        >
                          <div className="feature-wrap theme-green-bg pt-4 pb-4 pe-4 ps-4 h-100">
                            <div className="feature-icon d-flex align-items-center">
                              <img
                                src="/images/energy-solutions.svg"
                                alt="Energy Solutions"
                                title="Energy Solutions"
                              />
                            </div>
                            <div className="feature-text mt-4">
                              <h6 className="fw-bold heading-color">
                                Trusted EPC partners
                              </h6>
                            </div>
                          </div>
                        </div>
                        {/* <!-- /feature --> */}
                      </div>
                      {/* <!-- /col-lg-6 --> */}
                      <div className="col-md-6">
                        <div
                          className="feature h-100"
                          uk-scrollspy="cls: uk-animation-slide-right-medium; delay: 500;"
                        >
                          <div className="feature-wrap bs-body-bg pt-4 pb-4 pe-4 ps-4 h-100">
                            <div className="feature-icon d-flex align-items-center">
                              <img
                                src="/images/end-to-end-service.svg"
                                alt="Energy Solutions"
                                title="Energy Solutions"
                              />
                            </div>
                            <div className="feature-text mt-4">
                              <h6 className="fw-bold heading-color">
                                End-to-end <br />
                                Services
                              </h6>
                            </div>
                          </div>
                        </div>
                        {/* <!-- /feature --> */}
                      </div>
                      {/* <!-- /col-lg-6 --> */}
                      <div className="col-md-6">
                        <div
                          className="feature h-100"
                          uk-scrollspy="cls: uk-animation-slide-top-medium; delay: 500;"
                        >
                          <div className="feature-wrap bs-body-bg pt-4 pb-4 pe-4 ps-4 h-100">
                            <div className="feature-icon d-flex align-items-center">
                              <img
                                src="/images/end-to-end-service.svg"
                                alt="Energy Solutions"
                                title="Energy Solutions"
                              />
                            </div>
                            <div className="feature-text mt-4">
                              <h6 className="fw-bold heading-color">
                                Lightening 
                                financing per your needs
                              </h6>
                            </div>
                          </div>
                        </div>
                        {/* <!-- /feature --> */}
                      </div>
                      {/* <!-- /col-lg-6 --> */}
                      <div className="col-md-6">
                        <div
                          className="feature h-100"
                          uk-scrollspy="cls: uk-animation-slide-bottom-medium; delay: 500;"
                        >
                          <div className="feature-wrap theme-green-bg pt-4 pb-4 pe-3 ps-3 h-100">
                            <div className="feature-icon d-flex align-items-center">
                              <img
                                src="/images/energy-solutions.svg"
                                alt="Energy Solutions"
                                title="Energy Solutions"
                              />
                            </div>
                            <div className="feature-text mt-4">
                              <h6 className="fw-bold heading-color">
                                Best in Class Solar Panels backed by Manufacturer Warranty
                              </h6>
                            </div>
                          </div>
                        </div>
                        {/* <!-- /feature --> */}
                      </div>
                      {/* <!-- /col-lg-6 --> */}
                    </div>
                    {/* <!-- /row --> */}
                  </div>
                  {/* <!-- /our-features --> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
    <div className="how-we-work  section-padding">
      <div className="container custom-container">
        <div className="row">
          <div className="col-12">
            <div className="solfin-heading">
              <div className="small-heading mb-3 fw-semibold position-relative text-uppercase text-white">
                how it works
              </div>
              <h2 className="fw-bold heading-color text-white">A few <span className="text-theme-green">simple</span> steps</h2>
            </div>
             {/* <!-- /solfin-heading --> */}
          </div>
           {/* <!-- /col-12 --> */}

          <div className="col-12">
            <div className="solfin-simple-steps">
              <ul className="timeline list-unstyled m-0 position-relative text-white d-flex">
                <li className="solfin-step completed">
                  <div className="step w-100">
                    <div className="step-title">
                      <h6 className="text-white fw-bold">System Design</h6>
                    </div>
                     {/* <!-- /step-title --> */}
                    <div className="step-text">
                      <p>EPC would conduct a site visit and design a system</p>
                    </div>
                     {/* <!-- /step-text --> */}
                  </div>
                   {/* <!-- /step --> */}
                  <div className="step-icon d-flex align-items-center justify-content-center rounded-circle position-relative">
                    <span className="w-100 h-100 d-flex align-items-center justify-content-center rounded-circle"><img src="/images/solar-panel.svg" alt="System Design" title="System Design"/></span>
                  </div>
                   {/* <!-- /"step-icon --> */}
                </li>
                <li className="solfin-step completed">
                  <div className="step w-100">
                    <div className="step-title">
                      <h6 className="text-white fw-bold">Consultation</h6>
                    </div>
                     {/* <!-- /step-title --> */}
                    <div className="step-text">
                      <p>Discuss with EPC, the project in detail on Kwh requirement, Net Metering, Storage etc.</p>
                    </div>
                     {/* <!-- /step-text --> */}
                  </div>
                   {/* <!-- /step --> */}
                  <div className="step-icon d-flex align-items-center justify-content-center rounded-circle position-relative">
                    <span className="w-100 h-100 d-flex align-items-center justify-content-center rounded-circle"><img src="/images/consultation.svg" alt="Consultation" title="Consultation"/></span>
                  </div>
                   {/* <!-- /"step-icon --> */}
                </li>
                <li className="solfin-step completed">
                  <div className="step w-100">
                    <div className="step-title">
                      <h6 className="text-white fw-bold">Flexible Financing</h6>
                    </div>
                     {/* <!-- /step-title --> */}
                    <div className="step-text">
                      <p>Choose best finance options basis interest rate, tenure, via state of art Digital loan processing system</p>
                    </div>
                     {/* <!-- /step-text --> */}
                  </div>
                   {/* <!-- /step --> */}
                  <div className="step-icon d-flex align-items-center justify-content-center rounded-circle position-relative">
                    <span className="w-100 h-100 d-flex align-items-center justify-content-center rounded-circle"><img src="/images/payment-plan.svg" alt="Payment Plan" title="Payment Plan"/></span>
                  </div>
                   {/* <!-- /"step-icon --> */}
                </li>
                <li className="solfin-step completed">
                  <div className="step w-100">
                    <div className="step-title">
                      <h6 className="text-white fw-bold">Installation</h6>
                    </div>
                     {/* <!-- /step-title --> */}
                    <div className="step-text">
                      <p>Customer can get the project installed  after closure of EPC and Financing contracts.</p>
                    </div>
                     {/* <!-- /step-text --> */}
                  </div>
                   {/* <!-- /step --> */}
                  <div className="step-icon d-flex align-items-center justify-content-center rounded-circle position-relative">
                    <span className="w-100 h-100 d-flex align-items-center justify-content-center rounded-circle"><img src="/images/installation.svg" alt="Installation" title="Installation"/></span>
                  </div>
                   {/* <!-- /"step-icon --> */}
                </li>
                <li className="solfin-step completed">
                  <div className="step w-100">
                    <div className="step-title">
                      <h6 className="text-white fw-bold">Monitoring &amp; Maintenance</h6>
                    </div>
                     {/* <!-- /step-title --> */}
                    <div className="step-text">
                      <p>Low cost maintenance through EPC and special maintenance partners</p>
                    </div>
                     {/* <!-- /step-text --> */}
                  </div>
                   {/* <!-- /step --> */}
                  <div className="step-icon d-flex align-items-center justify-content-center rounded-circle position-relative">
                    <span className="w-100 h-100 d-flex align-items-center justify-content-center rounded-circle"><img src="/images/monitoring-maintance.svg" alt="Monitoring & Maintenance" title="Monitoring & Maintenance"/></span>
                  </div>
                   {/* <!-- /"step-icon --> */}
                </li>
                <li className="solfin-step completed">
                  <div className="step w-100">
                    <div className="step-title">
                      <h6 className="text-white fw-bold">Plant Optimization & Expansion</h6>
                    </div>
                     {/* <!-- /step-title --> */}
                    <div className="step-text">
                      <p>Storage Options and utilization options </p>
                    </div>
                     {/* <!-- /step-text --> */}
                  </div>
                   {/* <!-- /step --> */}
                  <div className="step-icon d-flex align-items-center justify-content-center rounded-circle position-relative">
                    <span className="w-100 h-100 d-flex align-items-center justify-content-center rounded-circle"><img src="/images/battery-optimization.svg" alt="Battery Optimization" title="Battery Optimization"/></span>
                  </div> {/* <!-- /"step-icon --> */}
                </li>
              </ul>
            </div>
          </div>

        </div>
         {/* <!-- /row --> */}
      </div>
    </div>
  </section>

      <section>
        <div className="go-solar-with-solfin overflow-hidden">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="row">
                <div className="left-solar-panel">
                  <div
                    id="carouselExampleDark"
                    className="carousel slide overflow-hidden"
                    data-bs-ride="carousel"
                  >
                    <div className="carousel-indicators">
                      <button
                        type="button"
                        data-bs-target="#carouselExampleDark"
                        data-bs-slide-to="0"
                        className="active rounded-circle bg-transparent"
                        aria-current="true"
                        aria-label="Slide 1"
                      ></button>
                      <button
                        type="button"
                        data-bs-target="#carouselExampleDark"
                        data-bs-slide-to="1"
                        className="rounded-circle bg-transparent"
                        aria-label="Slide 2"
                      ></button>
                      <button
                        type="button"
                        data-bs-target="#carouselExampleDark"
                        data-bs-slide-to="2"
                        className="rounded-circle bg-transparent"
                        aria-label="Slide 3"
                      ></button>
                    </div>
                    {/* <!-- /carousel-indicators --> */}
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <img
                          src="/images/solar-panel-happy-family.jpg"
                          className="d-block w-100"
                          alt="Go Solar with Solfin"
                          title="Go Solar with Solfin"
                        />
                      </div>
                      {/* <!-- /carousel-item --> */}
                      <div className="carousel-item">
                        <img
                          src="/images/solar-panel-happy-mother-daughter.jpg"
                          className="d-block w-100"
                          alt="Go Solar with Solfin"
                          title="Go Solar with Solfin"
                        />
                      </div>
                      {/* <!-- /carousel-item --> */}
                      <div className="carousel-item">
                        <img
                          src="/images/solar-panel-happy-family.jpg"
                          className="d-block w-100"
                          alt="Go Solar with Solfin"
                          title="Go Solar with Solfin"
                        />
                      </div>
                      {/* <!-- /carousel-item --> */}
                    </div>
                    {/* <!-- /carousel-inner --> */}
                  </div>
                  {/* <!-- /carousel --> */}
                </div>
                {/* <!-- /left-solar --> */}
              </div>
            </div>
            {/* <!-- /col-6 --> */}

            <div className="col-lg-6">
              <div className="right-rooftop-area overflow-hidden">
                <h2 className="fw-bold">Go Green with Solfin</h2>
                <div className="right-rooftop-text mt-xl-4 pt-xl-3 pt-2">
                  <h6 className="fw-bold">
                    Creating a greener and better tomorrow
                  </h6>
                  <p>Click to get a Solar roof top installed and financed</p>
                </div>
                {/* <!-- /right-rooftop-text --> */}
                <div
                  className="solfin-action mt-xl-5 mt-4"
                  uk-scrollspy="cls: uk-animation-slide-left-medium; delay: 500;"
                >
                  <Link to="/register" className="text-decoration-none">
                    <span className="btn btn-primary text-capitalize calculate-btn fw-semibold rounded-5">
                     Register
                    </span>{" "}
                    <img
                      className="ms-3"
                      src="/images/long-next-arrow.svg"
                      alt="Learn More"
                      title="Learn More"
                    />
                  </Link>
                </div>
                {/* <!-- /solfin-action --> */}
              </div>
              {/* <!-- /right-rooftop-areab --> */}
            </div>
            {/* <!-- /col-6 --> */}
          </div>
        </div>
      </section>

      <section>
        <div className="what-solfin-offers bg-white  section-padding">
          <div className="container custom-container">
            <div className="solfin-offers-wrap">
              <div className="row">
                <div className="col-12">
                  <div
                    className="solfin-heading text-center"
                    uk-scrollspy="cls: uk-animation-fade; delay: 800;"
                  >
                    <div className="small-heading mb-3 fw-semibold position-relative text-uppercase">
                      SOLFIN EDGE
                    </div>
                    <h2 className="fw-bold heading-color">
                      Building Grounds Up <br />
                      for a <span className="text-theme-green">Green Future</span>
                    </h2>
                  </div>
                  {/* <!-- /solfin-heading --> */}
                </div>
                {/* <!-- /col-12 --> */}
              </div>
              {/* <!-- /row --> */}

              <div className="we-offers">
                <div className="row align-items-center">
                  <div className="col-lg-4 col-md-6 order-lg-0 order-2">
                    <div className="offers-view">
                      <ul className="list-unstyled m-0 p-0">
                        <li>
                          <div
                            className="d-flex align-items-center flex-wrap flex-xl-nowrap"
                            uk-scrollspy="cls: uk-animation-slide-left-medium; delay: 800;"
                          >
                            <div className="service-icon text-center">
                              <img
                                src="/images/solar-plates.svg"
                                alt="Solar Plates"
                                title="Solar Plates"
                              />
                            </div>
                            <div className="service-text ps-lg-4 ps-3">
                              <h6 className="fw-bold">End to End Support</h6>
                              {/* <p>Best in class covered with Warranty</p> */}
                            </div>
                            {/* <!-- /service-text --> */}
                          </div>
                          {/* <!-- /d-flex align-items-center --> */}
                        </li>
                        <li>
                          <div
                            className="d-flex align-items-center flex-wrap flex-xl-nowrap"
                            uk-scrollspy="cls: uk-animation-slide-left-medium; delay: 950;"
                          >
                            <div className="service-icon text-center">
                              <img
                                src="/images/solar-water-heat.svg"
                                alt="Solar Water Heater"
                                title="Solar Water Heater"
                              />
                            </div>
                            <div className="service-text ps-lg-4 ps-3">
                              <h6 className="fw-bold">Financing Reengineered</h6>
                              {/* <p>Performance tracking on App</p> */}
                            </div>
                            {/* <!-- /service-text --> */}
                          </div>
                          {/* <!-- /d-flex align-items-center --> */}
                        </li>
                        <li>
                          <div
                            className="d-flex align-items-center flex-wrap flex-xl-nowrap"
                            uk-scrollspy="cls: uk-animation-slide-left-medium; delay: 1100;"
                          >
                            <div className="service-icon text-center">
                              <img
                                src="/images/solar-plates.svg"
                                alt="Solar Wind"
                                title="Solar Wind"
                              />
                            </div>
                            <div className="service-text ps-lg-4 ps-3">
                              <h6 className="fw-bold">Platform tested & backed products</h6>
                              {/* <p>Enhanced Life and much Safer</p> */}
                            </div>
                            {/* <!-- /service-text --> */}
                          </div>
                          {/* <!-- /d-flex align-items-center --> */}
                        </li>
                      </ul>
                    </div>
                    {/* <!-- /offer-view --> */}
                  </div>
                  {/* <!-- /col-lg-4 --> */}
                  <div className="col-lg-4 order-lg-0 order-1">
                    <div
                      className="panel-center-img text-center ps-lg-5 pe-lg-5"
                      uk-scrollspy="cls: uk-animation-slide-bottom-medium; delay: 800;"
                    >
                      <img
                        src="/images/center-panel.png"
                        className="w-100"
                        alt="Solar Panel"
                        title="Solar Panel"
                      />
                    </div>
                    {/* <!-- /panel-center-img --> */}
                  </div>
                  {/* <!-- /col-lg-4 --> */}
                  <div className="col-lg-4 col-md-6 order-lg-0 order-3">
                    <div className="offers-view right-side-offer">
                      <ul className="list-unstyled m-0 p-0">
                        <li>
                          <div
                            className="d-flex align-items-center flex-wrap flex-xl-nowrap"
                            uk-scrollspy="cls: uk-animation-slide-right-medium; delay: 800;"
                          >
                            <div className="service-icon text-center">
                              <img
                                src="/images/solar-plates.svg"
                                alt="Solar Plates"
                                title="Solar Plates"
                              />
                            </div>
                            <div className="service-text ps-lg-4 ps-3">
                              <h6 className="fw-bold">Digital Payments</h6>
                              {/* <p>Make all your payments on App</p> */}
                            </div>
                            {/* <!-- /service-text --> */}
                          </div>
                          {/* <!-- /d-flex align-items-center --> */}
                        </li>
                        <li>
                          <div
                            className="d-flex align-items-center flex-wrap flex-xl-nowrap"
                            uk-scrollspy="cls: uk-animation-slide-right-medium; delay: 950;"
                          >
                            <div className="service-icon text-center">
                              <img
                                src="/images/solar-water-heat.svg"
                                alt="Solar Water Heater"
                                title="Solar Water Heater"
                              />
                            </div>
                            <div className="service-text ps-lg-4 ps-3">
                              <h6 className="fw-bold">Super App</h6>
                              {/* <p>
                                Super App tracking performance of all your Solar
                                installations{" "}
                              </p> */}
                            </div>
                            {/* <!-- /service-text --> */}
                          </div>
                          {/* <!-- /d-flex align-items-center --> */}
                        </li>
                        <li>
                          <div
                            className="d-flex align-items-center flex-wrap flex-xl-nowrap"
                            uk-scrollspy="cls: uk-animation-slide-right-medium; delay: 1100;"
                          >
                            <div className="service-icon text-center">
                              <img
                                src="/images/solar-plates.svg"
                                alt="Solar Wind"
                                title="Solar Wind"
                              />
                            </div>
                            <div className="service-text ps-lg-4 ps-3">
                              <h6 className="fw-bold">Optimization</h6>
                              {/* <p>
                                Utilisation of IOT and new technologies to save
                                energy
                              </p> */}
                            </div>
                            {/* <!-- /service-text --> */}
                          </div>
                          {/* <!-- /d-flex align-items-center --> */}
                        </li>
                      </ul>
                    </div>
                    {/* <!-- /offer-view --> */}
                  </div>
                  {/* <!-- /col-lg-4 --> */}
                </div>
                {/* <!-- /row --> */}
              </div>
              {/* <!-- /we-offers --> */}
            </div>
            {/* <!-- /container --> */}
          </div>
        </div>
        {/* <!-- /what-solfin-offers --> */}
      </section>

      {/* <section>
        <div className="solfin-projects bg-white pt-0 pb-0">
          <div className="container custom-container">
            <div className="row">
              <div className="col-12">
                <div className="d-flex align-items-end justify-content-between flex-md-nowrap flex-wrap">
                  <div className="solfin-heading">
                    <div className="small-heading mb-3 fw-semibold position-relative text-uppercase">
                      OUR PROJECTS
                    </div>
                    <h2 className="fw-bold heading-color">
                      Our Latest{" "}
                      <span className="text-theme-green">Installations</span>
                    </h2>
                  </div>
                  
                </div>
                
              </div>
              
            </div>
           
            <div className="our-projects">
              <div className="project-slides">
              <HomeSlider/>
              </div>
              
            </div>
            
          </div>
         
        </div>
       
      </section> */}

      {/* <section>
        <div className="why-solar-panel section-padding">
          <div className="container custom-container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-7">
                <div className="solfin-heading">
                  <div className="small-heading mb-3 fw-semibold position-relative text-uppercase">
                    Why SOLAR PANELS
                  </div>
                  <h2 className="fw-bold heading-color">
                    Why do you have to <br />
                    use <span className="text-theme-green">solar</span> panels?
                  </h2>
                </div>
                <div className="that-why-solar-panel pt-4 mt-xxl-5 mt-4">
                  <ul className="list-unstyled m-0 ps-md-4">
                    <li>
                      <div className="why-solar-point d-flex align-items-center">
                        <div className="reson-icon rounded-circle d-flex align-items-center justify-content-center">
                          <img
                            src="images/go-solar.svg"
                            alt="Go Green"
                            title="Go Green"
                          />
                        </div>
                        <div className="reason-text pe-lg-4 ps-4">
                          <h6 className="fw-bold">Save Earth</h6>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="why-solar-point d-flex align-items-center">
                        <div className="reson-icon rounded-circle d-flex align-items-center justify-content-center">
                          <img
                            src="images/go-solar.svg"
                            alt="Go Green"
                            title="Go Green"
                          />
                        </div>
                        <div className="reason-text pe-lg-4 ps-4">
                          <h6 className="fw-bold">Alternate Energy</h6>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-5">
                <div className="right-of-why-solar-panel">
                  <div className="solar-panel-img text-center">
                    <img
                      src="images/solar-panel.png"
                      alt="Solar Panel"
                      title="Solar Panel"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      

    
    {/* <section>
      <div className="solfin-testimonials pb-2 pt-md-0 pt-4">
          <div className="container custom-container">
              <div className="row">
                  <div className="col-12">
                      <div className="d-flex align-items-end justify-content-between">
                          <div className="solfin-heading">
                              <div className="small-heading mb-3 fw-semibold position-relative text-uppercase">TESTIMONIAL</div>
                              <h2 className="fw-bold heading-color">What our <span className="text-theme-green">clients</span> say</h2>
                          </div>
                        
                          <div className="testimonial-quote">
                              <img src="images/quote.svg" alt="Testimonials" title="Testimonials"/>
                          </div>
                       
                      </div>
                      
                  </div>
                  
              </div>
              
              <div className="clients-testimonials project-slides mt-xl-3 mt-2 pt-3">
                <TestimonialSlider/>
              </div>
              
          </div>
      </div>
  </section> */}
    
    


      <section>
        {/* <div className="overflow-hidden">
          <div
            className="save-your-energy pt-4 pb-4"
            uk-scrollspy="cls: uk-animation-slide-bottom-medium; delay: 500;"
          >
            <div className="container custom-container">
              <div className="row align-items-center justify-content-between flex-md-nowrap flex-wrap">
                <div className="col-md-8">
                  <div className="save-energy-headline ps-xl-5 ps-4">
                    <h2 className="fw-bold m-0">
                      Want a <span className="text-theme-green">Cleaner</span> &{" "}
                      <span className="text-theme-green">Greener</span> tomorrow?
                    </h2>
                  </div>
                </div>
               
                <div className="col-md-4"> 
                  <div className="right-energy-action">
                    <a
                      href="calculate-my-saving.html"
                      className="text-decoration-none"
                    >
                      <span className="btn btn-primary calculate-btn fw-bold rounded-pill">
                        Let’s talk
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              
             </div>  
           
          </div>
        </div>  */}
        
      </section>
    </>
  );
};

export default Register;
