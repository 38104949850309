import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as querydata from "../service/DataService";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { saveAs } from "file-saver";
import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { toast } from "react-toastify";

//client Validation
export const quoteDetailSchema = Yup.object({
  projectName: Yup.string().required("Please enter Project name"),
  solution: Yup.string().required("Please enter Solution"),
  capacity: Yup.string()
    .required("Please enter Capacity")
    .min(0, "Capacity must be greater than or equal to 0")
    .typeError("Capacity must be a valid number"),
  panelType: Yup.string().required("Please enter Panel Type"),
  totalCost: Yup.string()
    .required("Please enter Total Cost")
    .min(0, "Total Cost must be greater than or equal to 0")
    .typeError("Total Cost must be a valid number"),
  inverterType: Yup.string().required("Please enter Inverter Type"),
  unitsGenerated: Yup.string()
    .required("Please enter Units Generated")
    .min(0, "Units Generated must be greater than or equal to 0")
    .typeError("Units Generated must be a valid number"),
  panelWarranty: Yup.string().required("Please enter Panel Warranty"),
  inverterWarranty: Yup.string().required("Please enter Inverter Warranty"),
});

const AddQuote = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { id } = useParams();
  const [isApproved, setIsApproved] = useState(false);

  // variable for file upload
  const [quote, setQuote] = useState("");
  // const handleQuote = (e) => {
  //     setQuote(e.target.files[0]);
  //     // document.getElementById("LoanAgreement").value = e.target.files[0].name;
  //   };
  const handleQuote = (e) => {
    const selectedFile = e.target.files[0];
    const allowedFormats = ["pdf", "jpg", "jpeg", "png"]; // Allowed file formats

    if (selectedFile) {
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

      // Check if the file format is allowed
      if (allowedFormats.includes(fileExtension)) {
        // The file format is allowed, so set it in the state
        setQuote(selectedFile);
      } else {
        // The file format is not allowed, you can display an error message or take other actions as needed
        toast.error(
          "Invalid file format. Allowed formats: " + allowedFormats.join(", ")
        );
        // Optionally, clear the input field
        e.target.value = "";
      }
    }
  };

  //post request to submit data
  const insertLoanQuoteOffer = useMutation(
    querydata.insertLoanQuoteOfferByEpc,
    {
      onError: (error) => {
        toast.error(error.message);
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries("allAssignApplicationToEPC");
        toast.success(data.message);
        navigate("/epc-dashboard/assigned-leads");
      },
    }
  );

  const getQuoteLoanOffer = useQuery(["getQuoteLoanOffer", +id], () =>
    querydata.getQuoteLoanOfferForEPC(id)
  );

  const quoteData = getQuoteLoanOffer.data?.data?.data;

  // const quoteData =
  //   getQuoteLoanOffer &&
  //   getQuoteLoanOffer.data &&
  //   getQuoteLoanOffer.data.data &&
  //   getQuoteLoanOffer.data.data.data === undefined
  //     ? ""
  //     : getQuoteLoanOffer &&
  //       getQuoteLoanOffer.data &&
  //       getQuoteLoanOffer.data.data &&
  //       getQuoteLoanOffer.data.data.data;

  let initialValues = {
    projectName: quoteData && quoteData.project_name,
    solution: quoteData && quoteData.solution,
    capacity: quoteData && quoteData.capacity,
    panelType: quoteData && quoteData.type_of_panel,
    totalCost: quoteData && quoteData.total_cost,
    inverterType: quoteData && quoteData.inverter_type,
    unitsGenerated: quoteData && quoteData.estimated_unit_generated,
    panelWarranty: quoteData && quoteData.panel_warranty,
    inverterWarranty: quoteData && quoteData.inverter_warranty,
  };

  // values defined for formik
  const { values, handleBlur, handleChange, handleSubmit, errors, touched } =
    useFormik({
      initialValues: initialValues,
      enableReinitialize: true,
      validationSchema: quoteDetailSchema,
      onSubmit: (values, action) => {
        const formData = new FormData();
        formData.append("projectName", values.projectName);
        formData.append("solution", values.solution);
        formData.append("capacity", values.capacity);
        formData.append("typeOfPanel", values.panelType);
        formData.append("totalCost", values.totalCost);
        formData.append("inverterType", values.inverterType);
        formData.append("roofTopImage", quote);
        formData.append("unitGeneratedPerDay", values.unitsGenerated);
        formData.append("panelWarranty", values.panelWarranty);
        formData.append("inverterWarranty", values.inverterWarranty);
        insertLoanQuoteOffer.mutate({ id, formData });
        // action.resetForm();
      },
    });

  // Effect to refetch data when 'id' changes
  useEffect(() => {
    getQuoteLoanOffer.refetch();
  }, [id]);

  const handleDownloadQuote = () => {
    const image_url = quoteData && quoteData.rooftop_solar_contract;
    // console.log(image_url,"..")
    saveAs(image_url, "quote");
  };

  return (
    <>
      <div className="content-body">
        <div className="page-title">
          <Link className="back-to-page" to="/epc-dashboard/assigned-leads">
            <i className="fa-solid fa-chevron-left"></i>
          </Link>
          <div className="dash-breadcrumb">
            <ul>
              <li>
                <Link to="/epc-dashboard/assigned-leads">Assigned Leads</Link>
              </li>
              <li>Quote</li>
            </ul>
          </div>
        </div>
        {/* <!-- /page title --> */}
        <div className="dash-card">
          <div className="dash-card-header border-0">
            <div className="tab-menu mb-0">
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    Add Quote
                  </button>
                </li>
              </ul>
            </div>
          </div>

          <div className="dash-card-body">
            <div className="solfin-tabs">
              <div className="tab-content" id="pills-tabContent">
                {/* <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0"> */}
                {/* <div className="tab-box" id="tab-2"> */}
                <div className="solfin-application-details">
                  <form onSubmit={handleSubmit}>
                    <div className="form-wrap">
                      <div className="form-group">
                        <label htmlFor="Project">Project Name</label>
                        <input
                          type="text"
                          id="Project"
                          placeholder="Please Enter Project Name"
                          // value={quoteData.project_name === null ? values.projectName : values.projectName=quoteData.project_name }
                          name="projectName"
                          // value={values.projectName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="solfin-input"
                          value={values.projectName}
                        />
                        {errors.projectName && touched.projectName ? (
                          <p className="form-error text-danger">
                            {errors.projectName}
                          </p>
                        ) : null}
                      </div>
                      {/* <!-- /form-group --> */}
                      <div className="form-group">
                        <label htmlFor="Solution">Solution</label>
                        <input
                          type="text"
                          id="Solution"
                          placeholder="Please Enter Solution"
                          name="solution"
                          value={values.solution}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="solfin-input"
                        />
                        {errors.solution && touched.solution ? (
                          <p className="form-error text-danger">
                            {errors.solution}
                          </p>
                        ) : null}
                      </div>
                      {/* <!-- /form-group --> */}
                      <div className="form-group">
                        <label htmlFor="Capacity">Capacity (kwH)</label>
                        <input
                          type="number"
                          id="Capacity"
                          placeholder="Please Enter Capacity"
                          name="capacity"
                          value={values.capacity}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="solfin-input"
                        />
                        {errors.capacity && touched.capacity ? (
                          <p className="form-error text-danger">
                            {errors.capacity}
                          </p>
                        ) : null}
                      </div>
                      {/* <!-- /form-group --> */}
                      <div className="form-group">
                        <label htmlFor="TypePanel">Type of Panel</label>
                        <input
                          type="text"
                          id="TypePanel"
                          placeholder="Please Enter Panel Type"
                          name="panelType"
                          value={values.panelType}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="solfin-input"
                        />
                        {errors.panelType && touched.panelType ? (
                          <p className="form-error text-danger">
                            {errors.panelType}
                          </p>
                        ) : null}
                      </div>
                      {/* <!-- /form-group --> */}
                      <div className="form-group">
                        <label htmlFor="TotalCost">Total Cost (₹)</label>
                        <input
                          type="number"
                          id="TotalCost"
                          placeholder="Please Enter Total Cost"
                          name="totalCost"
                          value={values.totalCost}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="solfin-input"
                        />
                        {errors.totalCost && touched.totalCost ? (
                          <p className="form-error text-danger">
                            {errors.totalCost}
                          </p>
                        ) : null}
                      </div>
                      <div className="form-group">
                        <label htmlFor="InverterType">Inverter Type</label>
                        <input
                          type="text"
                          id="InverterType"
                          placeholder="Please Enter Inverter Type"
                          name="inverterType"
                          value={values.inverterType}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="solfin-input"
                        />
                        {errors.inverterType && touched.inverterType ? (
                          <p className="form-error text-danger">
                            {errors.inverterType}
                          </p>
                        ) : null}
                      </div>

                      <div className="form-group">
                        <label htmlFor="Unitsgenerated">
                          Units generated per day
                        </label>
                        <input
                          type="number"
                          id="Unitsgenerated"
                          placeholder="Please Enter Units generated per day"
                          name="unitsGenerated"
                          value={values.unitsGenerated}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="solfin-input"
                        />
                        {errors.unitsGenerated && touched.unitsGenerated ? (
                          <p className="form-error text-danger">
                            {errors.unitsGenerated}
                          </p>
                        ) : null}
                      </div>
                      <div className="form-group">
                        <label htmlFor="PanelWarranty">
                          Panel Warranty (in Yrs)
                        </label>
                        <input
                          type="text"
                          id="PanelWarranty"
                          placeholder="Please Enter Panel Warranty"
                          name="panelWarranty"
                          value={values.panelWarranty}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="solfin-input"
                        />
                        {errors.panelWarranty && touched.panelWarranty ? (
                          <p className="form-error text-danger">
                            {errors.panelWarranty}
                          </p>
                        ) : null}
                      </div>

                                            
                    </div>
                    {/* /form-wrap */}

                    <div className="form-wrap align-items-start">
                    <div className="form-group">
                        <label htmlFor="InverterWarranty">
                          Inverter Warranty (in Yrs)
                        </label>
                        <input
                          type="text"
                          id="InverterWarranty"
                          placeholder="Please Enter Inverter Warranty"
                          name="inverterWarranty"
                          value={values.inverterWarranty}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="solfin-input"
                        />
                        {errors.inverterWarranty && touched.inverterWarranty ? (
                          <p className="form-error text-danger">
                            {errors.inverterWarranty}
                          </p>
                        ) : null}
                      </div>

                      {/* <!-- /form-group --> */}

                      <div className="form-group">
                        <label for="Dealsin">Upload Quote</label>

                        <input
                          type="file"
                          name="solarContract"
                          id="RooftopSolarContract"
                          onChange={handleQuote}
                          className="form-control solfin-input choose-file-input"
                          placeholder={
                            quoteData && quoteData.rooftop_solar_contract
                              ? "Quote submitted"
                              : "No file selected"
                          }
                        />
                        <div className="uploaded-quote mt-2">
                          {quoteData && quoteData.rooftop_solar_contract ? (
                            <a
                              className="h1"
                              href={quoteData.rooftop_solar_contract}
                              target="_blank"
                              rel="noreferrer"
                              onClick={handleDownloadQuote}
                            >
                              <i className="fa-solid fa-file opacity-50"></i>
                            </a>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                    <div className="form-group w-100 mb-0">
                        <div className="submit-filter">
                          <button
                            type="submit"
                            className="btn btn-info"
                            // disabled={
                            //   quoteData &&
                            //   (quoteData.is_epc_quote_rejected === undefined ||
                            //     quoteData.is_epc_quote_rejected === null) &&
                            //   quoteData.is_epc_quote_rejected
                            //     ? false
                            //     : true
                            // }
                            disabled = { (quoteData && quoteData.is_epc_quote_rejected === undefined) ||
                              (quoteData && quoteData.is_epc_quote_rejected === null)
                                ? true
                                : quoteData && quoteData.is_epc_quote_rejected === undefined
                                ? true
                                : false}
                            // disabled={quoteData !== null ||  quoteData.is_epc_quote_rejected === null ? true : false}
                          >
                            {quoteData &&
                            quoteData.is_epc_quote_rejected === null
                              ? "Submitted"
                              : "Submit"}
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* /form-wrap */}
                  </form>
                </div>
              </div>
              {/* <!-- /end tab --> */}
            </div>
            {/* </div> */}
            {/* </div> */}
          </div>
        </div>
        {/* <!-- /dash-card --> */}
      </div>
      {/* <!-- /content body --> */}
    </>
  );
};

export default AddQuote;
