import { useFormik } from "formik";
import Layout from "./AdminDashboard/components/Layout";
import DashBoard from "./AdminDashboard/pages/dashboard/DashBoard";
import AdminRoutes from "./AdminDashboard/routes/AdminRoutes";
import "./App.css";
import AllRoutes from "./FrontEnd/routes/AllRoutes";
import { ToastContainer } from "react-toastify";
import * as yup from "yup";
import EpcRoutes from "./EpcDashboard/routes/EpcRoutes";
import CustomerRoutes from "./CustomerDashboard/routes/CustomerRoutes";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { auth } from "./FrontEnd/dataService/DataService";
import Cookies from "js-cookie";

const validationSchema = yup.object().shape({
  checkboxes: yup.array().min(1, "Select at least one checkbox"),
});

function CustomerProtectedRoutes(props) {
  const { Component } = props;
  
  const navigate = useNavigate();

  useEffect(() => {
    async function usedLocation() {
      const response = await auth();
      const searchText = "customer-dashboard"; // Replace 'example' with the text you want to check for
      console.log('response')
      console.log(response)
      const urlContainsText = window.location.href.includes(searchText);
      const searchText1 = "customer"; // Replace 'example' with the text you want to check for
      const urlContainsText1 = window.location.href.includes(searchText1);
      if (
        response.data &&
        response.data.data === "CUSTOMER" &&
        (urlContainsText ||urlContainsText1)
      ) {
        
      } else if (
        response.data &&
        response.data.data != "EPC" &&
        response.data.data != "ADMIN" && response.data.data === "CUSTOMER"
      ) {
        navigate("/customer-dashboard");
      }else if((urlContainsText ||urlContainsText1) && response=="400"){
        navigate('/login')
      }
      
     
    }
    usedLocation();
  }, []);

  return (
    <>
      <Component />
    </>
  );
}

export default CustomerProtectedRoutes;
