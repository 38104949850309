import React, { useState } from "react";
import SideBar from "./SideBar";
import Header from "./Header";
import $ from "jquery";
import { useEffect } from "react";
import { auth } from "../../FrontEnd/dataService/DataService";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";




const Layout = ({ children }) => {
  useEffect(()=>{
$(window).scroll(function () {
  if ($(this).scrollTop() > 100) {
      $('header').addClass("sticky");
  } else {
      $('header').removeClass("sticky");
  }
});

// $(".project-slides").slick({
//   infinite: true,
//   dots: true,
//   arrows: false,
//   slidesToShow: 2,
//   autoplay: true,
//   autoplaySpeed: 3000,
//   slidesToScroll: 1,
//   responsive: [{
//       breakpoint: 992,
//       settings: {
//           slidesToShow: 2,
//       }
//   }, {
//       breakpoint: 768,
//       settings: {
//           slidesToShow: 1,
//       }
//   }]
// });

$(document).ready(function(){ 
  $('.tab-steps--list li').click(function(){    
      $(this).addClass('current');
      if($('.tab-steps--list li').hasClass('current')){
          $(".tab-steps--list li").eq($(this).parents().index() + 1).addClass("current"); 
          $(".tab-steps--list li").eq($(this).index() + 1).removeClass("current"); 
          $(".tab-steps--list li").eq($(this).index() + 2).removeClass("current");
          $(".tab-steps--list li").eq($(this).index() + 3).removeClass("current");
          $(".tab-steps > li").removeClass("current");
          $(".tab-steps > li").eq($(this).index() ).addClass("current");
          $(".tab-steps > li").eq($(this).index() + 1).removeClass("current");
      } 
  })




  //custome-js
  
// $('.sub-menu ul').hide();
// $(".sub-menu a").click(function () {
//     $(this).parent(".sub-menu").children("ul").slideToggle("100");
//     $(this).find(".right").toggleClass("fa-angle-down fa-angle-up");
// });

// $("#menu-toggle").click(function(e) {
//     e.preventDefault();
//     $(".solfin-dashboard").toggleClass("sidebar-collapse");
// });

// Click function for show the Modal
$(".show").on("click", function(){
$(".mask").addClass("active");
});
// Function for close the Modal
function closeModal(){
$(".mask").removeClass("active");
}
// Call the closeModal function on the clicks/keyboard
$(".close, .mask").on("click", function(){
closeModal();
});
$(document).keyup(function(e) {
if (e.keyCode == 27) {
    closeModal();
}
});


$(document).ready(function () {
    "use strict";
    $(".lightbox").click(function () {
        var imgsrc = $(this).attr('src');
        $("body").append("<div class='img-popup'><span class='close-lightbox'>&times;</span><img src='" + imgsrc + "'></div>");
        $(".close-lightbox, .img-popup").click(function () {
            $(".img-popup").fadeOut(500, function () {
                $(this).remove();
            }).addClass("lightboxfadeout");
        });

    });
    $(".lightbox").click(function () {
        $(".img-popup").fadeIn(500);
    });

});
      $(function() {
        var b = $("#button");
        var w = $("#filter-wrapper");
        var l = $("#filters");
        
        w.height(l.outerHeight(true));

        b.click(function() {
        
            if(w.hasClass('open')) {
            w.removeClass('open');
            w.height(0);
            } else {
            w.addClass('open');
            w.height(l.outerHeight(true));
            }
        
        });
        });

        $(function() {
            var b = $("#addleadbtn");
            var w = $("#leadform");
            var l = $("#lead-form-fields");
            
            w.height(l.outerHeight(true));
    
            b.click(function() {
            
                if(w.hasClass('open')) {
                w.removeClass('open');
                w.height(0);
                } else {
                w.addClass('open');
                w.height(l.outerHeight(true));
                }
            
            });
            });

            $('.tab-menu li a').on('click', function(){
                var target = $(this).attr('data-rel');
                $('.tab-menu li a').removeClass('active');
                $(this).addClass('active');
                $("#"+target).fadeIn('slow').siblings(".tab-box").hide();
                return false;
            });

})
  },[])


  const token= Cookies.get('token');
    const navigate = useNavigate();


// undo these changes 

//   useEffect(()=>{
//  async function usedLocation(){
//    const response =await auth()
//     console.log(response)
//     if(response===400){
//       navigate("/login")
//     }
//   }
//   usedLocation()
// },[]

//   )

  const [isSideBarCollapsed,setIsSideBarCollapsed] = useState(false);
  return (
    <>
      <div className={`solfin-dashboard ${!isSideBarCollapsed?"":"sidebar-collapse"}`}>
        <SideBar />

        <div className="solfin-content">
          <Header setIsSideBarCollapsed={setIsSideBarCollapsed}/>
          {children}
        </div>
      </div>
    </>
  );
};

export default Layout;
