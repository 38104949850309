import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const OldCustomerRegisterComplete = () => {
  const location = useLocation();
  const data = location.state.isFinanceRequired;
  const [applyloanUrl, setApplyLoanUrl] = useState(''); 
  
  useEffect(() => {
    const currentDomain = window.location.hostname;
    if (currentDomain.includes("stg") || currentDomain.includes("localhost")){
      setApplyLoanUrl("https://stg.finance.solfin.co.in");
    } else {
      setApplyLoanUrl("https://finance.solfin.co.in");
    }
  }, []);
  return (
    <>
      <section>
        <div className="success-wrapper header-space">
            <div className="container custom-container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="sucess-complete-wrapper">
                          <div className="completed-img mb-4">
                            <img src="images/completed.svg" alt="Registration Successfully"/>
                          </div>
                            <h1 className="fw-bold text-capitalize">registration <span className="text-theme-green">Successful!</span></h1>
                        </div>
                        <div className="sucess-text-wrapper m-auto">
                            <p>Thank you for registering for Solar Rooftop. You will receive an email with credentials for your dashboard where you can see the status of your application and receive further updates. Our empanelled EPC will get in touch with you shortly. For any queries, please contact us on <a href="mailto:support@solfin.co.in">support@solfin.co.in</a></p>
                            {data === '1' ? (
                              <div className="start-loan-process  mt-5">
                              <p>To start your Loan Process <a href={applyloanUrl+ "/onboard/#/login"}
                            target="_blank" >Click Here</a></p>
                            </div>
                              ) : ("")}
                            <div className="bottom-action-btns mt-5">
                              <Link to="/" className="btn btn-primary btn-header rounded-pill text-nowrap text-uppercase green-btn calculate-btn">Back To Home</Link>
                          </div>
                        </div>
                    </div>
                </div>             
            </div>         
        </div>    
    </section>
    
    
    
    </>
  );
};

export default OldCustomerRegisterComplete;
