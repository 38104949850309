import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { calculations } from "./utils/Calculation";
import { useCookies } from 'react-cookie';


import Chart from "./components/Chart";

const MySavings = () => {
  const navigate = useNavigate();

  const [cookies, setCookie] = useCookies([]);
 

  

  const calculationsVariable = JSON.parse(
    sessionStorage.getItem("Calculations")
  );
  const [loanAmt, setLoanAmt] = useState(
    calculationsVariable.tentativeSolutionCost
  );

  const [interestRate, setInterestRate] = useState(13);
  const [loanTenure, setLoanTenure] = useState(5);

  //const [cum_emi, setCum_emi] = useState([]);
  const locationTypeCode = JSON.parse(
    sessionStorage.getItem("selectedLocationTypeCode")
  );
  const formdata = JSON.parse(sessionStorage.getItem("formdata"));
  const customerValues = {
    customerName: formdata.customerName,
    email: formdata.email,
    phone:formdata.phone,
    salutation:formdata.salutation,
    cityId: formdata.cityId,
    pinCode: formdata.pinCode,
    stateId: formdata.stateId,
  };
  
  const setcokkieandnavigate = () => {
    // Your custom logic to be executed when the phone input loses focus
    setCookie("customerValues", customerValues, { path: "/" });

    sessionStorage.setItem("customerValues", JSON.stringify(customerValues));
    navigate("/register");
  };
  const installCapacity = calculationsVariable.installCapacity === "" ? ""  : calculationsVariable.installCapacity;
  const currentAverageBill = calculationsVariable.currentAverageBill === "" ? ""  : calculationsVariable.currentAverageBill;
  const tentativeSolutionCost = calculationsVariable.tentativeSolutionCost=== "" ? ""  : calculationsVariable.tentativeSolutionCost ;
  const installationTime = calculationsVariable.installationTime === "" ? ""  : calculationsVariable.installationTime;
  const unitsPerKwh = calculationsVariable.solarUnitsGeneratedPerMonth === "" ? ""  : calculationsVariable.solarUnitsGeneratedPerMonth;
  const isMaxInstallCapacity = calculationsVariable.isMaxInstallCapacity === "" ? ""  : calculationsVariable.isMaxInstallCapacity;
  const unitRate = formdata.electricityRate;

  //selectedLocationTypeCode
  const industryLocationCode = ["CMR", "IND"];
  function isIndustrySelected(selectedLocationType) {
    return industryLocationCode.includes(selectedLocationType);
  }

  const isIndustry = isIndustrySelected(locationTypeCode);
  const maintenanceCostRate = 1.6;
  const solarUnitsGeneratedPerMonth = unitsPerKwh * installCapacity;
  const percentageUnitsDepreciate = 1;
  const percentageOnWhichRateIncrease = 3.5;
  //calculations(5941358, 13, 5, 1.6, 16975.31, 1, 8.1, 3.5);

  //Calculate Emi
  const monthlyInterestRate = interestRate / 12 / 100;
  const totalMonths = loanTenure * 12;
  const calculateEmi =
    (loanAmt *
      monthlyInterestRate *
      Math.pow(1 + monthlyInterestRate, totalMonths)) /
    (Math.pow(1 + monthlyInterestRate, totalMonths) - 1);

  const [emi, setEmi] = useState(Math.round(calculateEmi));
  useEffect(() => {
    setEmi(Math.round(calculateEmi));
  }, [interestRate, loanTenure, loanAmt]);

  const data = calculations(
    loanAmt,
    interestRate,
    loanTenure,
    maintenanceCostRate,
    solarUnitsGeneratedPerMonth,
    percentageUnitsDepreciate,
    +unitRate,
    percentageOnWhichRateIncrease,
    isIndustry
  );
  const [lifeTimeBenifits, setLifeTimeBenifits] = useState(
    Math.round(data[2][24])
  );

  useEffect(() => {
    setLifeTimeBenifits(Math.round(data[2][24]));
  }, [data[2][24]]);

  const chartData = [];
  for (let i = 1; i <= 25; i++) {
    chartData.push({
      year: i,
      Cum_EMI: data[0][i - 1],
      Cum_Savings: data[1][i - 1],
      Cum_Net_CashFlow: data[2][i - 1],
    });
  }

  useEffect(() => {
    setSliderValue(loanAmt);
  }, [loanAmt]);

  const setSliderValue = (value) => {
    const slider = document.querySelector('input[type="range"]');
    if (slider) {
      slider.value = value;
    }
  };

  const handleRangeChange = (e) => {
    setLoanAmt(parseFloat(e.target.value));
  };

  const handleInputChange = (e) => {
    const enteredValue = parseFloat(e.target.value.replace(/[^0-9.-]/g, ''));
    const maxLoanAmt = parseFloat(tentativeSolutionCost);
    if (!isNaN(enteredValue) && enteredValue <= maxLoanAmt ) {
      setLoanAmt(enteredValue);
    }
  };
  return (
    <>
      <section>
        <div className="saving-calculator header-space pt-1">
          <div className="container custom-container">
            <div className="row">
              <div className="col-12">
                <div className="saving-header mb-3 d-flex align-items-end justify-content-between">
                  <h5 className="fw-bold">My Savings</h5>
                  <div className="close-page p-0 text-end">
                    {/* <Link to="/" className="close-now-page">
                      <img src="images/close.svg" alt="Close" title="Close" />
                    </Link> */}
                    <div className="saving-right-actions mt-4">
                      <a
                        onClick={setcokkieandnavigate}
                        className="btn btn-primary btn-header rounded-5 text-nowrap green-btn"
                      >
                        I am Interested
                      </a>
                      <Link
                        to="/estimator?recalculate=true"
                        className="btn btn-primary btn-header rounded-5 text-nowrap ms-1 light-btn"
                      >
                        Recalculate
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- /col-12 --> */}
              <div className="col-lg-9">
                <div className="saving-calculate-form">
                  <div className="row">
                    <div className="col-lg-4 col-md-6 mb-4">
                      <div className="card rounded-4">
                        <div className="card-body p-4 d-flex justify-content-between">
                          <div className="card-icon">
                            <img
                              src="/images/avg-bill.svg"
                              alt="Average Bill"
                              title="Average Bill"
                            />
                          </div>
                          <div className="card-text text-end">
                            <div className="card-title mt-3 mb-3 mb-md-5">
                              <h6 className="fw-semibold m-0">
                                ₹ {calculationsVariable.currentAverageBill.toLocaleString('en-IN')}
                              </h6>
                            </div>
                            {/* <!-- /card-title --> */}
                            <span>Current Average Bill per Month</span>
                          </div>
                          {/* <!-- /card-text --> */}
                        </div>
                        {/* <!-- /card-body --> */}
                      </div>
                      {/* <!-- /form-group --> */}
                    </div>
                    {/* <!-- /col-lg-4 col-md-6 --> */}
                    <div className="col-lg-4 col-md-6 mb-4">
                      <div className="card rounded-4">
                        <div className="card-body p-4 d-flex justify-content-between">
                          <div className="card-icon">
                            <img
                              src="/images/solution-cost.svg"
                              alt="Tentative Solution Cost"
                              title="Tentative Solution Cost"
                            />
                          </div>
                          <div className="card-text text-end">
                            <div className="card-title mt-3 mb-3 mb-md-5">
                              <h6 className="fw-semibold m-0">
                                ₹ {tentativeSolutionCost.toLocaleString('en-IN')}
                              </h6>
                            </div>
                            {/* <!-- /card-title --> */}
                            <span>Tentative Solution Cost</span>
                          </div>
                          {/* <!-- /card-text --> */}
                        </div>
                        {/* <!-- /card-body --> */}
                      </div>
                      {/* <!-- /form-group --> */}
                    </div>
                    {/* <!-- /col-lg-4 col-md-6 --> */}
                    <div className="col-lg-4 col-md-6 mb-4">
                      <div className="card rounded-4">
                        <div className="card-body p-4 d-flex justify-content-between">
                          <div className="card-icon">
                            <img
                              src="/images/lifetime-benefit.svg"
                              alt="Lifetime Benefit"
                              title="Lifetime Benefit"
                            />
                          </div>
                          <div className="card-text text-end">
                            <div className="card-title mt-3 mb-3 mb-md-5">
                              <h6 className="fw-semibold m-0">
                                {" "}
                                ₹ {lifeTimeBenifits.toLocaleString('en-IN')}
                              </h6>
                            </div>
                            {/* <!-- /card-title --> */}
                            <span>Estimated Savings over 25 years</span>
                          </div>
                          {/* <!-- /card-text --> */}
                        </div>
                        {/* <!-- /card-body --> */}
                      </div>
                      {/* <!-- /form-group --> */}
                    </div>
                    {/* <!-- /col-lg-4 col-md-6 --> */}

                    {/* <!-- /col-lg-4 col-md-6 --> */}
                    <div className="col-lg-4 col-md-6 mb-2">
                      <div className="card rounded-4">
                        <div className="card-body p-4 d-flex justify-content-between">
                          <div className="card-icon">
                            <img
                              src="/images/installation-capacity.svg"
                              alt="Installation Capacity"
                              title="Installation Capacity"
                            />
                          </div>
                          <div className="card-text text-end">
                            <div className="card-title mt-3 mb-3 mb-md-5">
                              <h6
                                className={`fw-semibold m-0 ${
                                  isMaxInstallCapacity
                                    ? "text-success"
                                    : "text-danger"
                                } `}
                              >
                             {calculationsVariable.installCapacity} kwH
                                <span className="dragger-symbol">
                                  <sup>
                                    <svg
                                      viewBox="0 0 122 184"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path d="M73.6882 0V184H45.4412V0H73.6882ZM122 42V67.7656H0V42H122Z" />
                                    </svg>
                                  </sup>
                                </span>
                              </h6>
                            </div>
                            {/* <!-- /card-title --> */}
                            <span>Installation Capacity</span>
                          </div>
                          {/* <!-- /card-text --> */}
                        </div>
                        {/* <!-- /card-body --> */}
                      </div>
                      {/* <!-- /form-group --> */}
                    </div>
                    {/* <!-- /col-lg-4 col-md-6 --> */}
                    <div className="col-lg-4 col-md-6 mb-2">
                      <div className="card rounded-4">
                        <div className="card-body p-4 d-flex justify-content-between">
                          <div className="card-icon">
                            <img
                              src="/images/solfin-rooftop-area.svg"
                              alt="Installation Capacity"
                              title="Installation Capacity"
                            />
                          </div>
                          <div className="card-text text-end">
                            <div className="card-title mt-3 mb-3 mb-md-5">
                              <h6 className="fw-semibold m-0">{calculationsVariable.installCapacity*100} sq. ft.</h6>
                            </div>
                            {/* <!-- /card-title --> */}
                            <span>Rooftop Area Required</span>
                          </div>
                          {/* <!-- /card-text --> */}
                        </div>
                        {/* <!-- /card-body --> */}
                      </div>
                      {/* <!-- /form-group --> */}
                    </div>

                    <div className="col-lg-4 col-md-6 mb-2">
                      <div className="card rounded-4">
                        <div className="card-body p-4 d-flex justify-content-between">
                          <div className="card-icon">
                            <img
                              src="/images/installation-time.svg"
                              alt="Installation Time"
                              title="Installation Time"
                            />
                          </div>
                          <div className="card-text text-end">
                            <div className="card-title mt-3 mb-3 mb-md-5">
                              <h6 className="fw-semibold m-0">
                                {calculationsVariable.installationTime}
                              </h6>
                            </div>
                            {/* <!-- /card-title --> */}
                            <span>Installation Time</span>
                          </div>
                          {/* <!-- /card-text --> */}
                        </div>
                        {/* <!-- /card-body --> */}
                      </div>
                      {/* <!-- /form-group --> */}
                    </div>
                    {/* <!-- /col-lg-4 col-md-6 --> */}
                  </div>
                  {/* <!-- /row --> */}
                </div>
                {/* <!-- /saving-calculate-form --> */}
              </div>
              {/* <!-- /col-lg-9 --> */}

              <div className="col-lg-3">
                <div className="card rounded-4">
                  <div className="card-body p-4">
                    <div className="set-range d-flex align-items-center w-100 mb-3">
                      <div className="left-range-set">
                        <div className="range-label fw-light mb-4">
                          Loan Amount
                        </div>
                        <div className="range">
                          <input
                            type="range"
                            min={tentativeSolutionCost/ 10}
                            max={tentativeSolutionCost}
                            defaultValue={tentativeSolutionCost}
                            // step="100000"
                            onChange={handleRangeChange}
                            value={isNaN(loanAmt) ? tentativeSolutionCost : loanAmt}
                          />
                        </div>
                      </div>
                      {/* <!-- /left-range-set --> */}
                      <div className="right-range-value">
                        <span className="border rounded-2 w-100 d-flex p-2 fw-bold ps-4">
                         <span className="input-value">₹ </span> {" "} <input
                            type="text"
                            // className="border-0 w-100 fw-bold infocus"
                            className="border w-100 d-flex fw-bold infocus line-height"
                            min={tentativeSolutionCost/ 10}
                            max={tentativeSolutionCost}
                            defaultValue={tentativeSolutionCost}
                            value={isNaN(loanAmt) ? '' : (loanAmt.toLocaleString('en-IN'))}
                            onChange={handleInputChange}
                          />
                        </span>
                      </div>
                      {/* <!-- /right-range-value --> */}
                    </div>
                    {/* <!-- /set-range --> */}
                    <div className="set-range d-flex align-items-center w-100 mb-3">
                      <div className="left-range-set">
                        <div className="range-label fw-light mb-3">
                          Interest Rate
                        </div>
                        <div className="range">
                          <input
                            type="range"
                            min={calculationsVariable.minInterestRateOnLoan}
                            max={calculationsVariable.maxInterestRateOnLoan}
                            defaultValue={
                              calculationsVariable.defaultInterestRateOnLoan
                            }
                            step=".25"
                            onChange={(e) => [setInterestRate(e.target.value)]}
                            value={interestRate}
                          />
                        </div>
                      </div>
                      {/* <!-- /left-range-set --> */}
                      <div className="right-range-value">
                        <span className="border rounded-2 w-100 d-flex p-2 fw-bold ps-4">
                          {interestRate} %
                        </span>
                      </div>
                      {/* <!-- /right-range-value --> */}
                    </div>
                    {/* <!-- /set-range --> */}
                    <div className="set-range d-flex align-items-center w-100 mb-3">
                      <div className="left-range-set">
                        <div className="range-label fw-light mb-3">
                          Loan Tenure
                        </div>
                        <div className="range">
                          <input
                            type="range"
                            min={calculationsVariable.minLoanTenureRange}
                            max={calculationsVariable.maxLoanTenureRange}
                            defaultValue={
                              calculationsVariable.defaultLoanTenureRange
                            }
                            onChange={(e) => [setLoanTenure(e.target.value)]}
                            value={loanTenure}
                          />
                        </div>
                      </div>
                      {/* <!-- /left-range-set --> */}
                      <div className="right-range-value">
                        <span className="border rounded-2 w-100 d-flex p-2 fw-bold ps-4">
                          {loanTenure} yrs
                        </span>
                      </div>
                      {/* <!-- /right-range-value --> */}
                    </div>
                    {/* <!-- /set-range --> */}

                    <div className="loan-amount-emi">
                      <div className="card rounded-4">
                        <div className="card-body p-4 d-flex justify-content-between">
                          <div className="card-icon">
                            <img
                              src="/images/money.png"
                              alt="Installation Time"
                              title="Installation Time"
                            />
                          </div>
                          <div className="card-text text-end">
                            <div className="card-title mb-3">
                              <h6 className="fw-semibold m-0">₹ {emi.toLocaleString('en-IN')}</h6>
                            </div>
                            {/* <!-- /card-title --> */}
                            <span>EMI starting from</span>
                          </div>
                          {/* <!-- /card-text --> */}
                        </div>
                        {/* <!-- /card-body --> */}
                      </div>
                      {/* <!-- /form-group --> */}
                    </div>
                    {/* <!-- /loan-amount-emi --> */}
                  </div>
                  {/* <!-- /card-body --> */}
                </div>
                {/* <!-- /form-group --> */}
              </div>
              {/* <!-- /col-lg-3 --> */}
              <div className="col-12">
                <span className="info-bottom">*Net metering cost extra</span>
                <span className="info-bottom w-100 d-block">
                  <sup>
                    <svg
                      width="6"
                      viewBox="0 0 122 184"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M70.6882 0V184H48.4412V0H70.6882ZM122 46V65.7656H0V46H122Z"
                        fill="#5E5E5E"
                      />
                    </svg>
                  </sup>
                  {isMaxInstallCapacity
                    ? "This system will completely meet your requirements"
                    : " This the maximum capacity which can be installed for the rooftop area available"}
                </span>
              </div>
            </div>
            {/* <!-- /row --> */}
            <div className="row mt-4">
              <div className="col-12">
                {/* Chart  */}
                <Chart
                  chartData={chartData}
                  // handleEmi={handleEmi}
                  // handleSavings={handleSavings}
                  // calculateCashFlow={calculateCashFlow}
                  //chartCalculations={chartCalculations}
                />
                <div className="note mt-2">
                  <span className="info-bottom">
                    <strong>Note -</strong>
                    This is a tentative estimate. If you're interested, our EPC
                    partner will visit your place to analyze and prepare a
                    better estimate for your needs.
                  </span>
                </div>
                <div className="solfin-action mt-4 mb-4">
                  {/* <Link
                    to="/register"
                    className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn"
                  >
                    I am Interested
                  </Link> */}
                  <a
                    onClick={setcokkieandnavigate}
                    className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn"
                  >
                    I am Interested
                  </a>
                  {/* <Link
                    to="/"
                    className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase ms-1 light-btn calculate-btn gray-border"
                  >
                    Cancel
                  </Link> */}
                </div>
              </div>
            </div>
            {/* <!-- /row --> */}
          </div>
          {/* <!-- /container --> */}
        </div>
        {/* <!-- /saving-calculator --> */}
      </section>
    </>
  );
};

export default MySavings;
