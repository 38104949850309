import React from 'react'

const Pagination = ({page,setPage,filterData}) => {
    
    const selectPageHandler = (selectedPage) => {
        console.log("clicked select page handler ");
        console.log("selectedPage")
        console.log(selectedPage)
        if (
          selectedPage >= 1 &&
          selectedPage <= Math.ceil(filterData.length / 9) &&
          selectedPage !== page
        ) {
          setPage(Number(selectedPage));
        }
      };
  return (
    <>
     <div>
          <ul className="pagination mt-lg-4 mt-3">
            <li className="page-item me-2">
              <button
                className="page-link"
                onClick={() => selectPageHandler(page - 1)}
                disabled={page === 1}
              >
                <i className="fa fa-angle-left"></i>
              </button>
            </li>
            {[
              ...Array(Math.ceil(filterData.length / 10)),
            ].map((_, i) => {
              return (
                <li className="page-item me-2">
                <button
                  key={i}
                  className={page === i + 1 ? "page-link active" : "page-link"}
                  onClick={() => selectPageHandler(i + 1)}
                >
                  {i + 1}
                </button>
                </li>
              );
            })}
            <li className="page-item me-2">
              <button
                className="page-link"
                onClick={() => selectPageHandler(page + 1)}
                disabled={page === (Math.ceil(filterData.length/9))}
              >
                <i className="fa fa-angle-right"></i>
              </button>
            </li>
          </ul>
        </div>
    
    </>
  )
}

export default Pagination