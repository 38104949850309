import { useFormik } from "formik";
import Layout from "./AdminDashboard/components/Layout";
import DashBoard from "./AdminDashboard/pages/dashboard/DashBoard";
import AdminRoutes from "./AdminDashboard/routes/AdminRoutes";
import "./App.css";
import AllRoutes from "./FrontEnd/routes/AllRoutes";
import { ToastContainer } from "react-toastify";
import * as yup from "yup";
import EpcRoutes from "./EpcDashboard/routes/EpcRoutes";
import CustomerRoutes from "./CustomerDashboard/routes/CustomerRoutes";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { auth } from "./FrontEnd/dataService/DataService";
import Cookies from "js-cookie";

const validationSchema = yup.object().shape({
  checkboxes: yup.array().min(1, "Select at least one checkbox"),
});

function AdminProtectedRoutes(props) {
  const { Component } = props;
  const formik = useFormik({
    initialValues: {
      checkboxes: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // Handle form submission
      // console.log(values.checkboxes);
    },
  });

  let isAdmin = false;
  let isEpc = false;
  const token = Cookies.get("token");
  const navigate = useNavigate();

  useEffect(() => {
    async function usedLocation() {
      const response = await auth();
      const searchText = "admin"; // Replace 'example' with the text you want to check for
      console.log("response");
      console.log(response);
      const urlContainsText = window.location.href.includes(searchText);
      if (response.data && response.data.data === "ADMIN" && urlContainsText) {
      } else if (
        response.data &&
        response.data.data != "EPC" &&
        response.data.data != "Customer" &&
        response.data.data === "ADMIN"
      ) {
        navigate("/admin/application");
      } else if (urlContainsText && response == "400") {
        navigate("/login");
      }
      // if(response.data && response.data.data==='ADMIN'){
      //   isAdmin = true
      //   isEpc = false;
      //   console.log("isAdmin,isEpc")
      //   console.log(isAdmin,isEpc)
      // }
      // else if(response.data &&  response.data.data==="EPC"){
      //   isEpc = true
      //   isAdmin= false;
      //  // window.location.href = window.location.href;
      //   //navigate('/epc-dashboard')
      //   // const searchText = 'epc-dashboard'; // Replace 'example' with the text you want to check for

      //   // const urlContainsText = window.location.href.includes(searchText);
      //    navigate(window.location.href)
      // // if(urlContainsText){
      // //   window.location.reload()
      // // }else{
      // //         navigate('/epc-dashboard')
      // //       }
      // }
      // if (response.status === 400) {
      // //  navigate('/login')
      // }
    }
    usedLocation();
  }, []);

  return (
    <>
      <Component />
    </>
  );
}

export default AdminProtectedRoutes;
