import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "react-query";
import * as querydata from "../../dataService/DataService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Multiselect } from "multiselect-react-dropdown";
import "./components/multiselect-dropdown.css";
import { Link, useNavigate } from "react-router-dom";
import StateSelect from "../../components/StateSelect";
import MatrixStateSelect from "../../components/MatrixStateSelect";
import CitySelect from "../../components/CitySelect";
import AreaServed from "../../components/AreaServedRegionEPC";
import Select from "../../components/Select";
import Document2 from "../../components/Document2";
import RateMatrix from "./components/RateMatrix";
import axios from "axios";
import EpcPartnerList from "./EpcPartnerList";

const apikey = process.env.REACT_APP_HOSTNAME;

//client Validation
export const epcRegisterSchema = Yup.object({
  epcName: Yup.string().required("Please enter company name"),
  email: Yup.string().email().required("Please enter your email"),
  address: Yup.string().required("Please enter your address"),
  phone: Yup.string()
    .required("Mobile number is required")
    .matches(/^\d{10}$/, "Invalid mobile number"),
  pinCode: Yup.string()
    .required("PinCode is required")
    .length(6, "Pincode must be exactly 6 digits")
    .matches(/^\d+$/, "Pincode must only contain digits"),
  cityId: Yup.number().required("Please select city"),
  registerationTypeId: Yup.number()
    .notOneOf([-1], "Please select Registration Type")
    .required("Please select Registration Type"),
  documentId: Yup.number().required("Please select the document type"),
  stateId: Yup.number()
    .notOneOf([-1], "Please select state")
    .required("Please select state"),
  // panFile:Yup.string().required("Please Upload Pan file"),
  // rateMatrix:Yup.string().required("Please enter RateMatrix"),
});

const Epc = () => {
  //duplicate area Served compenent
  const [dropdowns, setDropdowns] = useState([]);
  const [ratematrixdropdown, setratematrixdropdown] = useState([]);
  const [editable, setEditable] = useState(true);
  const [showOtpDiv, setShowOtpDiv] = useState(false);
  const [otp, setOtp] = useState("");
  const [smsOtp, setSmsOtp] = useState("");

  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [isSmsOtpVerified, setIsSmsOtpVerified] = useState(false);

  // added for filter states in area served
  const [selectedAreaServedStates, setSelectedAreaServedStates] = useState([]);
  const areaServedStatesVariable = [];
  const [otpEditable, setOtpEditable] = useState(true);
  const [smsOtpEditable, setSmsOtpEditable] = useState(true);
  const [selectedStatesArray, setSelectedStatesArray] = useState([]);
  useEffect(() => {
    //setSelectedStatesArray('6,5');
  }, []);

  const getAllStatesAreaserved = useQuery(
    "StatesSelect",
    querydata.getAllStates
  );
  const verifyEpcEmail = (email) => {
    return axios
      .post(
        `${apikey}/epc/checkExistingEpc`,
        { email },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.data.message === "EPC Already Exists") {
          // Handle the case where EPC already exists, if needed
          // For now, just return the message
          return response.data.message;
        } else {
          return response.data.message;
        }
      })
      .catch((err) => {
        throw new Error(err.response?.data?.message || "An error occurred");
      });
  };

  // const verifyEpcEmail = async (email) => {
  //   try {
  //     const response = await axios.post(
  //       `${apikey}/epc/checkExistingEpc`,
  //       { email },
  //       {
  //         withCredentials: true,
  //       }
  //     );

  //     return response.data.message;
  //   } catch (err) {
  //     throw new Error(err.response?.data?.message || 'An error occurred');
  //   }
  // };

  // const verifyEpcEmail =  (email) => {

  //   try {
  //     const { data } =  axios.post(
  //       `${apikey}/epc/checkExistingEpc`,
  //       {email},
  //       {
  //         withCredentials: true,
  //       }
  //     );
  //     // if(data.message=="EPC Already Exists"){
  //     //   newErrors.email="EPC already exists. Please Login."
  //     // }
  //     return data.message;
  //   } catch (err) {
  //     // if(err.response.data.message=="EPC Already Exists"){
  //     //   newErrors.email = 'EPC already exists. Please Login.';
  //     // }else{
  //     //   newErrors.email ="";
  //     // }
  //     throw Error(err.response.data.message);
  //   }
  // };

  const filteredareadata =
    getAllStatesAreaserved &&
    getAllStatesAreaserved.data &&
    getAllStatesAreaserved.data.data &&
    getAllStatesAreaserved.data.data.filter(
      (data) => !selectedStatesArray.map(Number).includes(data.state_id)
    );
  //console.log("filtereddata")
  //console.log(filteredareadata)
  console.log("selectedStatesArray")
  console.log(selectedStatesArray)
  const handleAddDropdown = () => {
    const newDropdownKey = Date.now().toString();
    setDropdowns((prevDropdowns) => [
      ...prevDropdowns,
      {
        key: newDropdownKey,
        options: filteredareadata,
        onSelectionChange: (value) =>
          handleSelectionChange(newDropdownKey, value),
        onDelete: () => handleDeleteDropdown(newDropdownKey),
      },
    ]);
    const element = document.getElementsByClassName("save_rate_matrix");
    for (let i = 0; i < element.length; i++) {
      element[i].classList.remove("saved-rate-btn");
      //element[i].classList.toggle("save-btn");
    }
  };

  const handleSelectionChange = (dropdownKey, value) => {
    ////////console.log(`Dropdown with key ${dropdownKey} selected value: ${value}`);
    // Perform desired logic for selection change
  };

  const handleDeleteDropdown = (dropdownKey) => {
    ////////console.log(`Delete dropdown with key ${dropdownKey}`);
    setDropdowns((prevDropdowns) =>
      prevDropdowns.filter((dropdown) => dropdown.key !== dropdownKey)
    );
  };

  //manage state
  const [selectedState, setSelectedState] = useState("-1");
  const [selectedStates, setSelectedStates] = useState("-1");
  //initialize Navigate method
  const navigate = useNavigate();

  //served area
  // const [areaServedState, setAreaServedState] = useState(1);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [emailerror, setEmailerror] = useState([false]);
  const [registerationIdForDocument2, setRegisterationIdForDocument2] =
    useState(null);
  // values defined for formik
  const initialValues = {
    epcName: "",
    email: "",
    address: "",
    phone: "",
    pinCode: "",
    cityId: "",
    documentId: "",
    areaServed: [],
    stateId: selectedState,
    registerationTypeId: -1,
    optionId: "",
    stateIds: selectedStates,
  };

  const handleSelectedStateChange = (selectedStates) => {
    setSelectedStatesArray((prevSelectedStatesArray) => [
      ...prevSelectedStatesArray,
      selectedStates,
    ]);
  };

  const handleSubmit2 = (e) =>{
    console.log("submit 2");
    e.preventDefault();
    const formData = new FormData(e.target);
      console.log(formData);
  }

  const { handleSubmit1 } = useFormik({
    initialValues: initialValues,
    validationSchema: epcRegisterSchema,
    onSubmit: (values, action) => {
      const formData = new FormData();

      var options = document.getElementById("matrixstateId"),
        option,
        i;
      let error = false;
      for (let k = 0; k <= options.length - 1; k++) {
        option = options[k];

        let matrixstring = JSON.stringify(rateMatrixOld);
        let index = matrixstring.includes(option.textContent);
        if (!index) {
          error = true;
        }
      }

      if (!error) {
      } else {
        toast.error("Please Fill and Save Rate Matrix Data for all the States");
      }
    },
  });

  //for validating area served
  const areaServedDropdowns = document.querySelectorAll(".areaservedstates");
  const openAreaServedStates = [];
  console.log("areaServedDropdowns states");
  areaServedDropdowns.forEach((data) => openAreaServedStates.push(data.value));
  const isOpenState =
    openAreaServedStates.length > selectedOptions.length ? true : false;

  const [error, setErrors] = useState({});
  const newErrors = {};
  const validateForm = () => {
    
    if (isOpenState) {
      newErrors.areaServed = "Please Select Area Served";
      toast.error("Please select Area Served");
      document.getElementById("States").focus();
    }


    if (!errors.epcName && !touched.epcName) {
      if (!values.epcName) {
        newErrors.epcName = "Please enter company name";
        toast.error("Please enter company name");
        document.getElementById("CompanyName").focus();
      }
    } else {
      if (!values.epcName) {
        toast.error("Please enter company name");
        touched.epcName = "Please enter company name";
        newErrors.customerNames = "Please enter full name";
        document.getElementById("CompanyName").focus();
      }
    }

    if (!errors.email && !touched.email) {
      if (!values.email) {
        newErrors.email = "Please enter your email";
        toast.error("Please enter your email");
        document.getElementById("Email").focus();
      } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        newErrors.email = "Invalid email format";
        toast.error("Invalid email format");
        document.getElementById("Email").focus();
      } else {
        // var errormessage=  verifyEpcEmail(values.email)
        // if(errormessage=="EPC Already Exists"){
        //     newErrors.email="EPC already exists. Please Login."
        //   }
        //verifyEpcEmail.mutate(values.email,newErrors);
        // if(emailerror){
        //newErrors.emailVERIFY = 'EPC already exists. Please Login.';
      }
    } else {
      if (!values.email) {
        toast.error("Please enter your email");
        newErrors.customerNames = "Please enter full name";
        document.getElementById("Email").focus();
      } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        toast.error("Invalid email format");
        newErrors.customerNames = "Please enter full name";
        document.getElementById("Email").focus();
      } else {
        // var errormessage= verifyEpcEmail(values.email)
        // if(errormessage=="EPC Already Exists"){
        //   newErrors.email="EPC already exists. Please Login."
        // }
        //verifyEpcEmail.mutate(values.email);
        // newErrors.customerNames = 'Please enter full name';
      }
      touched.email = "Please enter your email";
    }

    if (!errors.registerationTypeId && !touched.registerationTypeId) {
      if (!values.registerationTypeId || values.registerationTypeId == -1) {
        newErrors.registerationTypeId = "Please select Registration Type";
        toast.error("Please select Registration Type");
        document.getElementById("registerationTypeId").focus();
      }
    } else {
      if (!values.registerationTypeId || values.registerationTypeId == -1) {
        toast.error("Please select Registration Type");
        touched.registerationTypeId = "Please select Registration Type";
        newErrors.customerNames = "Please enter full name";
        document.getElementById("registerationTypeId").focus();
      }
    }

    if (!errors.address && !touched.address) {
      if (!values.address) {
        newErrors.address = "Please enter your address";
        toast.error("Please enter your address");
        document.getElementById("Address").focus();
      }
    } else {
      if (!values.address) {
        toast.error("Please enter your address");
        touched.address = "Please enter your address";
        newErrors.customerNames = "Please enter full name";
        document.getElementById("Address").focus();
      }
    }

    if (!errors.phone && !touched.phone) {
      if (values.phone.length === 0) {
        newErrors.phone = "Mobile number is required";
        toast.error("Mobile number is required");
        document.getElementById("Phone").focus();
      } else if (!/^\d{10}$/.test(values.phone)) {
        newErrors.phone = "Invalid mobile number";
        toast.error("Invalid mobile number");
        document.getElementById("Phone").focus();
      }
    } else {
      if (values.phone.length === 0) {
        toast.error("Mobile number is required");
        newErrors.customerNames = "Please enter full name";
        document.getElementById("Phone").focus();
      } else if (!/^\d{10}$/.test(values.phone)) {
        toast.error("Invalid mobile number");
        newErrors.customerNames = "Please enter full name";
        document.getElementById("Phone").focus();
      }
      touched.phone = "Mobile number is required";
    }

    if (!errors.pinCode && !touched.pinCode) {
      if (!values.pinCode) {
        newErrors.pinCode = "PinCode is required";
        toast.error("PinCode is required");
        document.getElementById("Pincode").focus();
      } else if (!/^\d+$/.test(values.pinCode)) {
        newErrors.pinCode = "Pincode must only contain digits";
        toast.error("Pincode must only contain digits");
        document.getElementById("Pincode").focus();
      } else if (values.pinCode.length != 6) {
        newErrors.pinCode = "Pincode must be exactly 6 digits";
        toast.error("Pincode must be exactly 6 digits");
        document.getElementById("Pincode").focus();
      }
    } else {
      if (!values.pinCode) {
        toast.error("PinCode is required");
        newErrors.customerNames = "Please enter full name";
        document.getElementById("Pincode").focus();
      } else if (!/^\d+$/.test(values.pinCode)) {
        toast.error("Pincode must only contain digits");
        newErrors.customerNames = "Please enter full name";
        document.getElementById("Pincode").focus();
      } else if (values.pinCode.length != 6) {
        toast.error("Pincode must be exactly 6 digits");
        newErrors.customerNames = "Please enter full name";
        document.getElementById("Pincode").focus();
      }
      touched.pinCode = "PinCode is required";
    }

    if (!errors.stateId && !touched.stateId) {
      if (values.stateId == -1) {
        newErrors.state_Id = "Please select State";
        toast.error("Please select State");
        document.getElementById("State").focus();
      }
    } else {
      if (values.stateId == -1) {
        toast.error("Please select State");
        touched.stateId = "Please select State";
        newErrors.customerNames = "Please enter full name";
        document.getElementById("State").focus();
      }
    }

    if (!errors.cityId && !touched.cityId) {
      if (!values.cityId) {
        newErrors.city_Id = "Please select City";
        toast.error("Please select City");
        document.getElementById("City").focus();
      }
    } else {
      if (!values.cityId) {
        toast.error("Please select City");
        touched.cityId = "Please select City";
        newErrors.customerNames = "Please enter full name";
        document.getElementById("City").focus();
        // newErrors.city_Id = 'Please select City';
      }
    }

    if (!panFile || panFile.length == 0) {
      newErrors.panFile = "Please Upload Pan File";
      toast.error("Please Upload Pan File");
      document.getElementById("filetextid_Pan").focus();
    }
    if (!errors.documentId && !touched.documentId) {
      if (!values.documentId || values.documentId == -1) {
        newErrors.documentId = "Please select Document Type";
        toast.error("Please select Document Type");
        document.getElementById("filetextid").focus();
      }
    } else {
      if (!values.documentId || values.documentId == -1) {
        toast.error("Please select Document Type");
        touched.documentId = "Please select Document Type";
        newErrors.customerNames = "Please enter full name";
        document.getElementById("filetextid").focus();
      }
    }

    if (!file || file.length == 0) {
      newErrors.file = "Please Upload Document";
      toast.error("Please Upload Document");
      document.getElementById("filetextid").focus();
    }


    if (!selectedOptions || selectedOptions.length == 0) {
      newErrors.areaServed = "Please select your Served Area and Regions";
      toast.error("Please select your Served Area and Regions");
      document.getElementById("search_input").focus();
    }
    var options = document.getElementById("matrixstateId"),
      option,
      i;
    let error = false;
    for (let k = 0; k <= options.length - 1; k++) {
      option = options[k];

      let matrixstring = JSON.stringify(rateMatrixOld);
      let index = matrixstring.includes(option.textContent);
      if (!index) {
        error = true;
      }
    }
    if (error) {
      newErrors.rateMatrix =
        "Please Fill and Save Rate Matrix Data for all the States";
      toast.error("Please Fill and Save Rate Matrix Data for all the States");
      document.getElementById("ratematrixtable").focus();
    }
    // else{
    //   newErrors.rateMatrix="";
    // }
    return newErrors;
  };
  console.log("selectedOptions");
  console.log(selectedOptions);
  //build Model and fill data in formik model
  const { values, handleBlur, handleChange, handleSubmit, errors, touched } =
    useFormik({
      initialValues: initialValues,
      validationSchema: epcRegisterSchema,
      onSubmit: (values, action) => {
        const formData = new FormData();
        //console.log('rateMatrixform')
        //console.log(rateMatrix)
        //console.log('rateMatrixOLDform')
        //console.log(rateMatrixOld)
        //console.log('statematrixarrayform')
        //console.log(statematrixarray)
        //console.log('statematrixarrayOLDform')
        //console.log(statematrixarrayOLD)

        var options = document.getElementById("matrixstateId"),
          option,
          i;
        let error = false;
        for (let k = 0; k <= options.length - 1; k++) {
          option = options[k];

          let matrixstring = JSON.stringify(rateMatrixOld);
          let index = matrixstring.includes(option.textContent);
          if (!index) {
            error = true;
          }
        }

        if (!error) {
          formData.append("registerationTypeId", values.registerationTypeId);
          formData.append("epcName", values.epcName);
          formData.append("email", values.email);
          formData.append("address", values.address);
          formData.append("phone", values.phone);
          formData.append("pinCode", values.pinCode);
          formData.append("cityId", values.cityId);
          formData.append("documentId", values.documentId);
          formData.append("documentImage", file);
          formData.append("panImage", panFile);
          formData.append("areaServed", JSON.stringify(selectedOptions));
          formData.append("rateMatrix", JSON.stringify(rateMatrixOld));

          updateStatusMutation.mutate(formData, action);
          // action.resetForm();
        } else {
          toast.error(
            "Please Fill and Save Rate Matrix Data for all the States"
          );
        }
      },
    });

  // useEffect(() => {
  //   console.log("getEpcPublishingDetails:", EpcPublishingDetails);
  // }, [EpcPublishingDetails]);

  //   EpcPublishingDetails.data.data.map((a)=>{
  //     console.log(a.epc_id, "..")
  //   })
  //   {console.log("getEpcPublishingDetails:", EpcPublishingDetails)}
  // {console.log("getEpcPublishingDetails.data:", EpcPublishingDetails.data)}
  // {console.log("getEpcPublishingDetails.data.data:", EpcPublishingDetails.data.data)}
  const handleValidations = () => {
    // Your custom logic to be executed when the phone input loses focus
    const newErrors = validateForm();
    if (Object.keys(newErrors).length === 0) {
    } else {
      setErrors(newErrors);
    }
  };
  const sendOTP = (event) => {
    event.preventDefault();
    const newErrors = validateForm();
    console.log("newErrors")
    console.log(newErrors)
    if (Object.keys(newErrors).length === 0) {
      // Submit form data or perform further actions here
      setErrors(newErrors);
      const sendOtpData = {
        email: values.email,
        phone: values.phone,
      };
      sendOtp.mutate(sendOtpData);
    } else {
      setErrors(newErrors);
    }
    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // const phoneRegex = /^[0-9]{10}$/;

    // if (!values.registerationTypeId || !values.epcName || !values.email || !values.phone || !values.pinCode || !values.cityId || !values.address || !values.documentId ) {
    //   toast.error("Please fill all the required fields");
    //   return;
    // }
    //   if (!values.email || !emailRegex.test(values.email)) {
    //     toast.error("Please fill a valid email");
    //     errors.email="Please enter your email";
    //     return;
    //   }

    //  else if (!values.phone || !phoneRegex.test(values.phone)) {
    //     toast.error("Please fill a valid 10-digit phone number");
    //     return;
    //   }
    //     else{

    //   setEditable(false);
    //   setShowOtpDiv(true);
    //   const sendOtpData = {
    //     email: values.email,
    //     phone: values.phone,
    //   };
    //   sendOtp.mutate(sendOtpData);
    // }
  };

  const sendOtp = useMutation(querydata.sendPartnersOtpRequest, {
    onError: (error) => {
      if (error.message === "This email is already registered.") {
        toast.error("Partner already Exists. Please Login");
        const newErrors = {};
        newErrors.email = "Partner already exists. Please Login.";
        setErrors(newErrors);
      } else {
        // setEditable(false);
        // setShowOtpDiv(true);
        toast.error(error.message);
        setEditable(true);
        setShowOtpDiv(false);
      }
      // //console.log(error.message);
      // //console.log(error);
    },
    onSuccess: (data) => {
      toast.success("OTP Sent. Please Check your Email and Phone");
      var hideform = document.getElementsByClassName("hideform");
      // Loop through the elements and hide each one
      for (var i = 0; i < hideform.length; i++) {
        hideform[i].style.display = "none";
      }
      var hideform1 = document.getElementById("hideformcheckbox");
      hideform1.classList.remove("d-flex");
      hideform1.style.display = "none";
      // //console.log("Succesful",{data});
      setEditable(false);
      setShowOtpDiv(true);
    },
  });

  const handleOtpChange = (event) => {
    const emailOTP = event.target.value;
    const otpRegex = /^[0-9]{0,6}$/;

    if (otpRegex.test(emailOTP)) {
      setOtp(emailOTP);
      if (emailOTP.length === 6) {
        verifyEmailOtp.mutate(emailOTP);
      }
    }
  };

  const smsOtpChange = (event) => {
    const smsOTP = event.target.value;
    const otpRegex = /^[0-9]{0,6}$/;

    if (otpRegex.test(smsOTP)) {
      setSmsOtp(smsOTP);
      if (smsOTP.length === 6) {
        verifySmsOtp.mutate(smsOTP);
      }
    }
  };

  const verifySmsOtp = useMutation(querydata.verifySmsOTP, {
    onError: (error) => {
      toast.error(error.message);
    },
    onSuccess: (data) => {
      toast.success("SMS OTP Verified Successfully");
      setIsSmsOtpVerified(true);
      setSmsOtpEditable(false);
    },
  });

  const verifyEmailOtp = useMutation(querydata.verifyEmailOTP, {
    onError: (error) => {
      toast.error(error.message);
    },
    onSuccess: (data) => {
      toast.success("Email OTP Verified Successfully");
      setIsOtpVerified(true);
      setOtpEditable(false);
      // //console.log("Succesful",{data});
    },
  });

  // const verifyEpcEmail = useMutation(querydata.verifyEpcEmail, {
  //   onError: (error,newErrors) => {
  //    // toast.error(error.message)
  //    // const newErrors = {};
  //     if(error.message=="EPC Already Exists"){
  //      // newErrors.email = 'EPC already exists. Please Login.';
  //       toast.error("EPC already exists. Please Login.");
  //     }else{
  //      // newErrors.email ="";
  //     }
  //   },
  //   onSuccess: (data) => {
  //     console.log("verify email")
  //     console.log(data)
  //     //data.message="EPC Already Exists";
  //     //toast.success("Email OTP Verified Successfully")
  //   }
  // })

  const handleEdit = () => {
    setEditable(true); // Enable form editing when the edit button is clicked.
    setShowOtpDiv(false); // Hide OTP div on edit button click
    setOtp("");
    setSmsOtp("");
    setIsOtpVerified(false);
    setIsSmsOtpVerified(false);
    setOtpEditable(true);
    setSmsOtpEditable(true);
    var hideform = document.getElementsByClassName("hideform");
    // Loop through the elements and hide each one
    for (var i = 0; i < hideform.length; i++) {
      hideform[i].style.display = "block";
    }
    var hideform1 = document.getElementById("hideformcheckbox");
    hideform1.classList.add("d-flex");
    hideform1.style.display = "block";
  };

  //For Docuement2
  useEffect(() => {
    setRegisterationIdForDocument2(values.registerationTypeId);
    
  }, [values.registerationTypeId]);
  ////////console.log(registerationIdForDocument2);

  // enable submit button checkbox click
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  //clear all fields
  const closeerrormessage = () => window.location.reload();

  // ////////console.log(values)
  const [isDropdownDisabled, setDropdownDisabled] = useState(false);
  const [isCheckboxChecked, setCheckboxChecked] = useState(false);

  // variable for file upload
  const [file, setfile] = useState("");
  const [panFile, setPanFile] = useState("");

  // const handlefilechange = (e) => {
  //   setfile(e.target.files[0]);
  //   //////console.log(e.target.files[0].name);
  //   if(e.target.files[0]){
  //   document.getElementById("filetextid").value = e.target.files[0].name;
  //   error.file="";}else{
  //     document.getElementById("filetextid").value="";
  //     error.file="Please Upload Document";
  //   }
  // };
  // const handlefilechange = (e) => {
  //   const selectedFile = e.target.files[0];
  //   const allowedFormats = ["pdf", "jpg", "jpeg", "png"]; // Allowed file formats

  //   if (selectedFile) {
  //     const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

  //     // Check if the file format is allowed
  //     if (allowedFormats.includes(fileExtension)) {
  //       setfile(selectedFile);
  //       document.getElementById("filetextid").value = selectedFile.name;
  //       error.file = "";
  //     } else {
  //       setfile(null);
  //       document.getElementById("filetextid").value = "";
  //       error.file =
  //         "Invalid file format. Allowed formats: " + allowedFormats.join(", ");
  //     }
  //   } else {
  //     setfile(null);
  //     document.getElementById("filetextid").value = "";
  //     error.file = "Please Upload Document";
  //   }
  // };
  const handlefilechange = (e) => {
    const selectedFile = e.target.files[0];
    const allowedFormats = ["pdf", "jpg", "jpeg", "png"]; // Allowed file formats
    const maxFileSizeInBytes = 2 * 1024 * 1024; // 2 MB
  
    if (selectedFile) {
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
      const fileSize = selectedFile.size;
  
      // Check if the file format is allowed
      if (!allowedFormats.includes(fileExtension)) {
        setfile(null);
        document.getElementById("filetextid").value = "";
        error.file =
          "Invalid file format. Allowed formats: " + allowedFormats.join(", ");
        return;
      }
  
      // Check if the file size exceeds the limit
      if (fileSize > maxFileSizeInBytes) {
        setfile(null);
        document.getElementById("filetextid").value = "";
        error.file = "File size exceeds the limit of 2 MB.";
        return;
      }
  
      // The file format and size are allowed, so set it in the state
      setfile(selectedFile);
      document.getElementById("filetextid").value = selectedFile.name;
      error.file = "";
    } else {
      setfile(null);
      document.getElementById("filetextid").value = "";
      error.file = "Please Upload Document";
    }
  };
  
  // const handlefilechangePan = (e) => {
  //   const selectedFile = e.target.files[0];
  //   const allowedFormats = ["pdf", "jpg", "jpeg", "png"]; // Allowed file formats

  //   if (selectedFile) {
  //     const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

  //     // Check if the file format is allowed
  //     if (allowedFormats.includes(fileExtension)) {
  //       setPanFile(selectedFile);
  //       document.getElementById("filetextid_Pan").value = selectedFile.name;
  //       error.panFile = "";
  //     } else {
  //       setPanFile(null);
  //       document.getElementById("filetextid_Pan").value = "";
  //       error.panFile =
  //         "Invalid file format. Allowed formats: " + allowedFormats.join(", ");
  //     }
  //   } else {
  //     setPanFile(null);
  //     document.getElementById("filetextid_Pan").value = "";
  //     error.panFile = "Please Upload Pan File";
  //   }
  // };
  const handlefilechangePan = (e) => {
    const selectedFile = e.target.files[0];
    const allowedFormats = ["pdf", "jpg", "jpeg", "png"]; // Allowed file formats
    const maxFileSizeInBytes = 2 * 1024 * 1024; // 2 MB
  
    if (selectedFile) {
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
      const fileSize = selectedFile.size;
  
      // Check if the file format is allowed
      if (!allowedFormats.includes(fileExtension)) {
        setPanFile(null);
        document.getElementById("filetextid_Pan").value = "";
        error.panFile =
          "Invalid file format. Allowed formats: " + allowedFormats.join(", ");
        return;
      }
  
      // Check if the file size exceeds the limit
      if (fileSize > maxFileSizeInBytes) {
        setPanFile(null);
        document.getElementById("filetextid_Pan").value = "";
        error.panFile = "File size exceeds the limit of 2 MB.";
        return;
      }
  
      // The file format and size are allowed, so set it in the state
      setPanFile(selectedFile);
      document.getElementById("filetextid_Pan").value = selectedFile.name;
      error.panFile = "";
    } else {
      setPanFile(null);
      document.getElementById("filetextid_Pan").value = "";
      error.panFile = "Please Upload Pan File";
    }
  };
  

 
  const getEpcRegistrationType = useQuery(
    "RegistrationType",
    querydata.getEpcRegistrationTypeNew
  );

  const getallratewattagecomponents = useQuery(
    "wattagecomponents",
    querydata.getallratewattagecomponents
  );

  const getallratematrixcomponents = useQuery(
    "matrixcomponents",
    querydata.getallratematrixcomponents
  );

  //form post on Submit
  const updateStatusMutation = useMutation(querydata.insertepc, {
    onError: (error) => {
      toast.error(error.message);
      ////////console.log(error.message);
    },
    onSuccess: (data) => {
      navigate("/RegistrationComplete");
    },
  });

  // Create a reference to the table element
  const tableRef = React.useRef();

  // Extract data from the table
  let previousSelectedValue = "";
  let previousSelectedText = "";

  const [rateMatrix, setRateMatrix] = useState([]);
  const [rateMatrixOld, setRateMatrixOLD] = useState([]);
  const [statematrixarray, setStatematrixArray] = useState([]);
  const [statematrixarrayOLD, setStatematrixArrayOLD] = useState(statematrixarray);

  function updateTotals() {
    var table = document.getElementById("ratematrixbody");
    const element1 = table.querySelectorAll('.inputvalues[data-code="500KWP"]');

    const element2 = table.querySelectorAll('.inputvalues[data-code="100KWP"]');

    const element3 = table.querySelectorAll('.inputvalues[data-code="50KWP"]');

    const element4 = table.querySelectorAll('.inputvalues[data-code="10KWP"]');

    const element5 = table.querySelectorAll('.inputvalues[data-code="3KWP"]');
    let value1 = 0;
    let value2 = 0;
    let value3 = 0;
    let value4 = 0;
    let value5 = 0;

    element1.forEach((element) => {
      value1 = value1 + Number(element.value);
    });
    element2.forEach((element) => {
      value2 = value2 + Number(element.value);
    });
    element3.forEach((element) => {
      value3 = value3 + Number(element.value);
    });
    element4.forEach((element) => {
      value4 = value4 + Number(element.value);
    });
    element5.forEach((element) => {
      value5 = value5 + Number(element.value);
    });

    const Totalelement1 = (table.querySelector(
      '.totalvalues[data-code="500KWP"]'
    ).value = value1);

    const Totalelement2 = (table.querySelector(
      '.totalvalues[data-code="100KWP"]'
    ).value = value2);

    const Totalelement3 = (table.querySelector(
      '.totalvalues[data-code="50KWP"]'
    ).value = value3);

    const Totalelement4 = (table.querySelector(
      '.totalvalues[data-code="10KWP"]'
    ).value = value4);

    const Totalelement5 = (table.querySelector(
      '.totalvalues[data-code="3KWP"]'
    ).value = value5);

    //   for (let i = 0; i < table.rows.length - 1; i++) {
    //     const row = table.rows[i];
    // const value1=''
    // const value2=''
    // const value3=''
    // const value4=''
    // const value5=''
    //     // Iterate over each cell in the row
    //     for (let j = 0; j < row.cells.length; j++) {
    //       const cell = row.cells[j];
    //       const cell1=cell.querySelector('.inputvalues').value;
    //       const cell2=cell.querySelector('.inputvalues').value;
    //       const cell3=cell.querySelector('.inputvalues').value;
    //       const cell4=cell.querySelector('.inputvalues').value;
    //       const cell5=cell.querySelector('.inputvalues').value;

    //     }

    //   }
  }

  const getMatrixData = () => {
    // function getMatrixData() {

    let error = false;
    previousSelectedValue = document.getElementById("matrixstateId").value;
    previousSelectedText =
      document.getElementById("matrixstateId").selectedOptions[0].textContent;

    // {console.log(EpcPublishingDetails.data.data, "////")}

    if (isCheckboxChecked) {
      var options = document.getElementById("matrixstateId"),
        option,
        i;

      setStatematrixArray([]);
      setRateMatrix([]);

      for (let k = 0; k <= options.length - 1; k++) {
        option = options[k];

        var table = document.getElementById("ratematrixbody");
        // Initialize an empty array to store the extracted data

        // Iterate over each row in the table body
        for (let i = 0; i < table.rows.length - 1; i++) {
          const row = table.rows[i];

          // Iterate over each cell in the row
          for (let j = 0; j < row.cells.length; j++) {
            if (j >= 2) {
              const cell = row.cells[j];
              // Get the child element by class name
              const amount = cell.querySelector(".inputvalues").value;
              //////console.log('amount');
              //////console.log(amount);
              if (!amount) {
                // Access the value or perform operations with the child element
                //////console.log('Row:', i, 'Cell:', j, 'Value:', amount);
                cell
                  .querySelector(".inputvalues")
                  .classList.add("border-danger");
                error = true;
                break;
              }
              const childElement = cell.querySelector(".input_details");
              //<div data-component_name={outerObj.option} data-rate_matrix_component_id={outerObj.option_id} data-wattage={innerObj.option} data-rate_matrix_wattage_component_id={innerObj.option_id} className="input_details input-group">

              const component_name = childElement.getAttribute(
                "data-component_name"
              );
              const rate_matrix_component_id = childElement.getAttribute(
                "data-rate_matrix_component_id"
              );
              const wattage = childElement.getAttribute("data-wattage");
              const rate_matrix_wattage_component_id =
                childElement.getAttribute(
                  "data-rate_matrix_wattage_component_id"
                );
              cell
                .querySelector(".inputvalues")
                .classList.remove("border-danger");

              const data = {
                state_id: option.value,
                amount: amount,
                state: option.textContent,
                rate_matrix_component_id: rate_matrix_component_id,
                rate_matrix_wattage_component_id:
                  rate_matrix_wattage_component_id,
              };

              rateMatrix.push(data);
              setRateMatrixOLD([]);
              setRateMatrixOLD(rateMatrix);
            }
          }
        }
        if (!error) {
          statematrixarray.push(option.textContent);
          setStatematrixArrayOLD(statematrixarray);
          const element = document.getElementsByClassName("save_rate_matrix");
          for (let i = 0; i < element.length; i++) {
            element[i].classList.add("saved-rate-btn");
          }
        } else {
          toast.error("Please fill and save all Rate Matrix amounts");
        }
      }
    } else {
      let ratematrixfiltered = [];
      // const containsValue = statematrixarrayOLD.indexOf(previousSelectedText) !== -1;
      ///remove state from array
      var index = statematrixarrayOLD.indexOf(previousSelectedText);
      ratematrixfiltered = rateMatrixOld.filter(
        (item) => item.state_id !== previousSelectedValue
      );

      if (index != -1) {
        statematrixarrayOLD.splice(index, 1);

        //  console.log("previousSelectedValue")
        //  console.log(previousSelectedValue)
        //  console.log("ratematrixfiltered")
        //  console.log(ratematrixfiltered)
        // if(ratematrixfiltered){
        // setRateMatrix(ratematrixfiltered)}
      }
      const containsValue =
        statematrixarrayOLD.indexOf(previousSelectedText) !== -1;

      if (!containsValue) {
        var table = document.getElementById("ratematrixbody");
        // Initialize an empty array to store the extracted data

        // Iterate over each row in the table body
        for (let i = 0; i < table.rows.length - 1; i++) {
          const row = table.rows[i];

          // Iterate over each cell in the row
          for (let j = 0; j < row.cells.length; j++) {
            if (j >= 2) {
              const cell = row.cells[j];
              const cellvalue = cell.querySelectorAll(".inputvalues")[0];
              // Get the child element by class name
              const amount = cellvalue.value;
              if (!amount) {
                // Access the value or perform operations with the child element
                //////console.log('Row:', i, 'Cell:', j, 'Value:', amount);
                cell
                  .querySelector(".inputvalues")
                  .classList.add("border-danger");
                error = true;
                break;
              }
              const childElement = cell.querySelector(".input_details");

              const component_name = childElement.getAttribute(
                "data-component_name"
              );
              const rate_matrix_component_id = childElement.getAttribute(
                "data-rate_matrix_component_id"
              );
              const wattage = childElement.getAttribute("data-wattage");
              const rate_matrix_wattage_component_id =
                childElement.getAttribute(
                  "data-rate_matrix_wattage_component_id"
                );
              cell
                .querySelector(".inputvalues")
                .classList.remove("border-danger");
              setRateMatrix([]);
              const data = {
                state_id: previousSelectedValue,
                amount: amount,
                state: previousSelectedText,
                rate_matrix_component_id: rate_matrix_component_id,
                rate_matrix_wattage_component_id:
                  rate_matrix_wattage_component_id,
              };
              rateMatrix.push(data);

              // console.log("rateMatrix")
              // console.log(rateMatrix)

              let arraydata = [];
              arraydata.push(...rateMatrix);
              // console.log("arraydata")
              // console.log(arraydata)
              //  console.log("rateMatrixOld")
              // console.log(rateMatrixOld)
              let concstdata = arraydata.concat(ratematrixfiltered);
              setRateMatrixOLD(concstdata);
              // console.log("concstdata")
              // console.log(concstdata)
              // console.log("rateMatrixOld")
              // console.log(rateMatrixOld)
            }
          }
        }

        //  rateMatrix.push(ratematrixfiltered);
        // rateMatrixOld.push(rateMatrix);
        //   rateMatrixOld.push(ratematrixfiltered);
        if (!error) {
          statematrixarray.push(previousSelectedText);
          statematrixarrayOLD.push(previousSelectedText);
          //setStatematrixArrayOLD(statematrixarray);
          const element = document.getElementsByClassName("save_rate_matrix");
          for (let i = 0; i < element.length; i++) {
            element[i].classList.add("saved-rate-btn");
          }
        } else {
          toast.error("Please fill and save all Rate Matrix amounts");
        }
      }
    }
    //console.log('rateMatrix')
    //console.log(rateMatrix)
    //console.log('rateMatrixOLD')
    //console.log(rateMatrixOld)
    //console.log('statematrixarray')
    //console.log(statematrixarray)
    //console.log('statematrixarrayOLD')
    //console.log(statematrixarrayOLD)
  };
  const setdropdown = (event) => {
    handleChange(event);
    previousSelectedValue = document.getElementById("matrixstateId").value;
    previousSelectedText =
      document.getElementById("matrixstateId").selectedOptions[0].textContent;
    const containsValue = statematrixarrayOLD.indexOf(previousSelectedText);

    if (containsValue != -1) {
      const element = document.getElementsByClassName("save_rate_matrix");
      for (let i = 0; i < element.length; i++) {
        element[i].classList.add("saved-rate-btn");
      }

      const tableBody = document.getElementById("ratematrixbody");
      const rows = tableBody.getElementsByTagName("tr");

      const matrixtable = document.getElementById("ratematrixtable");
      const filteredData = rateMatrixOld.filter(
        (obj) => obj.state_id == previousSelectedValue
      );

      let input1 = 0;
      let input2 = 0;
      let input3 = 0;
      let input4 = 0;
      let input5 = 0;

      getallratematrixcomponents.data.data.map((outerObj, index) => {
        getallratewattagecomponents.data.data.map((innerObj, index1) => {
          {
            let data = filteredData.filter(
              (obj) =>
                obj.rate_matrix_component_id == outerObj.option_id &&
                obj.rate_matrix_wattage_component_id == innerObj.option_id
            );

            input1 +=
              index1 == 0 && data[0].amount != ""
                ? parseFloat(data[0].amount)
                : 0;
            input2 +=
              index1 == 1 && data[0].amount != ""
                ? parseFloat(data[0].amount)
                : 0;
            input3 +=
              index1 == 2 && data[0].amount != ""
                ? parseFloat(data[0].amount)
                : 0;
            input4 +=
              index1 == 3 && data[0].amount != ""
                ? parseFloat(data[0].amount)
                : 0;
            input5 +=
              index1 == 4 && data[0].amount != ""
                ? parseFloat(data[0].amount)
                : 0;
          }
        });
      });

      getallratematrixcomponents.data.data.map((outerObj, index) => {
        getallratewattagecomponents.data.data.map((innerObj, index1) => {
          var inputId = outerObj.option_code + innerObj.option_code;
          const matrixINPUT = document.getElementById(inputId);
          const filtereddata = filteredData.filter(
            (obj) =>
              obj.rate_matrix_component_id == outerObj.option_id &&
              obj.rate_matrix_wattage_component_id == innerObj.option_id
          );
          matrixINPUT.value = filtereddata[0].amount;
        });
      });
      const kwp1 = document.querySelector('.totalvalues[data-code="500KWP"]');
      const kwp2 = document.querySelector('.totalvalues[data-code="100KWP"]');
      const kwp3 = document.querySelector('.totalvalues[data-code="50KWP"]');
      const kwp4 = document.querySelector('.totalvalues[data-code="10KWP"]');
      const kwp5 = document.querySelector('.totalvalues[data-code="3KWP"]');
      kwp1.value = input1;
      kwp2.value = input2;
      kwp3.value = input3;
      kwp4.value = input4;
      kwp5.value = input5;
    } else {
      const element = document.getElementsByClassName("save_rate_matrix");
      for (let i = 0; i < element.length; i++) {
        element[i].classList.remove("saved-rate-btn");
      }

      getallratematrixcomponents.data.data.map((outerObj, index) => {
        getallratewattagecomponents.data.data.map((innerObj, index1) => {
          var inputId = outerObj.option_code + innerObj.option_code;
          const matrixINPUT = document.getElementById(inputId);
          matrixINPUT.value = "";
        });
      });
      let input1 = "";
      let input2 = "";
      let input3 = "";
      let input4 = "";
      let input5 = "";
      const kwp1 = document.querySelector('.totalvalues[data-code="500KWP"]');
      const kwp2 = document.querySelector('.totalvalues[data-code="100KWP"]');
      const kwp3 = document.querySelector('.totalvalues[data-code="50KWP"]');
      const kwp4 = document.querySelector('.totalvalues[data-code="10KWP"]');
      const kwp5 = document.querySelector('.totalvalues[data-code="3KWP"]');
      kwp1.value = input1;
      kwp2.value = input2;
      kwp3.value = input3;
      kwp4.value = input4;
      kwp5.value = input5;
    }
  };
  document.addEventListener("change", function (event) {
    const target = event.target;
    if (target.classList.contains("inputvalues")) {
      updateTotals(event);
    }
  });
  document.addEventListener("click", function (event) {
    if (event.target.classList.contains("inputvalues")) {
      event.target.classList.remove("border-danger");
      const element = document.getElementsByClassName("save_rate_matrix");
      for (let i = 0; i < element.length; i++) {
        element[i].classList.remove("saved-rate-btn");
      }
    }
  });
  const CheckboxChange = () => {
    setCheckboxChecked(!isCheckboxChecked);
    setRateMatrix([]);
    //setRateMatrixOLD([]);
    //setStatematrixArray([]);
    const element = document.getElementsByClassName("save_rate_matrix");
    for (let i = 0; i < element.length; i++) {
      element[i].classList.remove("saved-rate-btn");
    }
  };

  console.log("selectedOptions");
  console.log(selectedOptions);

  return (
    <>
      <style>
        <link rel="stylesheet" href="css/multiple-select.css"></link>
      </style>
      <section>
        <div className="saving-calculator header-space pt-1">
          <div className="container-md">
            <div className="row">
              <div className="col-12">
                <div className="mob-pe-1 mob-ps-1">
                  <div className="page-title title-border pb-4">
                    <h2 className="fw-bold heading-color">
                      End to End Engineering{" "}
                      <span className="text-theme-green">Partners</span>
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="solfin-partners mob-pe-1 mob-ps-1 mt-lg-4">
                  <div className="partners-tab pb-4">
                    <ul className="nav nav-pills p-0" id="myTab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="Enrollment-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#Enrollment-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="Enrollment-tab-pane"
                          aria-selected="true"
                        >
                          Enrollment
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="PartnersList-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#PartnersList-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="PartnersList-tab-pane"
                          aria-selected="false"
                        >
                          Partners List
                        </button>
                      </li>
                    </ul>
                  </div>
                  {/* <!-- /partners-tab --> */}
                  <div className="partner-tab-data pt-2 mt-lg-4">
                    <div className="tab-content" id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="Enrollment-tab-pane"
                        role="tabpanel"
                        aria-labelledby="Enrollment-tab"
                        tabIndex="0"
                      >
                        <div className="registration-form">
                          <form onSubmit={handleSubmit2}>
                            <div className="saving-calculate-form registration-form hideform">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group pb-2 mb-4">
                                    <Select
                                      values={values}
                                      dropdownidText="registerationTypeId"
                                      dropdownName="registerationTypeId"
                                      dropdownname="customerType"
                                      dropdowndata={getEpcRegistrationType}
                                      dropdownLabelName="Registration Type"
                                      handleBlur={handleBlur}
                                      handleChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      disabled={!editable}
                                    />
                                    {errors.registerationTypeId &&
                                    touched.registerationTypeId ? (
                                      <p className="form-error text-danger">
                                        {errors.registerationTypeId}
                                      </p>
                                    ) : error.registerationTypeId ? (
                                      <p className="form-error text-danger">
                                        {error.registerationTypeId}
                                      </p>
                                    ) : null}
                                    {/* {error.registerationTypeId ? (
                                      <p className="form-error text-danger">
                                        {error.registerationTypeId}
                                      </p>
                                    ) : null} */}

                                    {/* </select> */}
                                  </div>
                                  {/* <!-- /form-group --> */}
                                </div>
                                {/* <!-- /col-md-6 --> */}
                              </div>
                              {/* <!-- /row --> */}
                              {/* <!-- /col-lg-4 col-md-6 --> */}
                              <div className="heading mb-xl-4 mb-3">
                                <span className="fw-semibold saving-title">
                                  Basic Details
                                </span>
                              </div>
                              <div className="row">
                                <div className="col-xl-4 col-md-6">
                                  <div className="form-group mb-4">
                                    <label
                                      htmlFor="CompanyName"
                                      className="border rounded-4 w-100 position-relative"
                                    >
                                      <span className="fw-bold">Name</span>
                                      <input
                                        type="text"
                                        id="CompanyName"
                                        className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                                        placeholder="Enter Company Name"
                                        name="epcName"
                                        value={values.epcName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        disabled={!editable}
                                      />
                                    </label>
                                    {errors.epcName && touched.epcName ? (
                                      <p className="form-error text-danger">
                                        {errors.epcName}
                                      </p>
                                    ) : error.epcName ? (
                                      <p className="form-error text-danger">
                                        {error.epcName}
                                      </p>
                                    ) : null}
                                    {/* {error.epcName  ? (
                                      <p className="form-error text-danger">
                                        {error.epcName}
                                      </p>
                                    ) : null} */}
                                  </div>
                                  {/* <!-- /form-group --> */}
                                </div>
                                {/* <!-- /col-lg-4 col-md-6 --> */}
                                <div className="col-xl-4 col-md-6">
                                  <div className="form-group mb-4">
                                    <label
                                      htmlFor="Email"
                                      className="border rounded-4 w-100 position-relative"
                                    >
                                      <span className="fw-bold">Email</span>
                                      <input
                                        type="email"
                                        id="Email"
                                        className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                                        placeholder="Email Address"
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        disabled={!editable}
                                      />
                                    </label>
                                    {/* {error.email && <p className="form-error text-danger">{error.email}</p>} */}
                                    {errors.email && touched.email ? (
                                      <p className="form-error text-danger">
                                        {errors.email}
                                      </p>
                                    ) : error.email ? (
                                      <p className="form-error text-danger">
                                        {error.email}
                                      </p>
                                    ) : null}
                                  </div>
                                  {/* <!-- /form-group --> */}
                                </div>
                                {/* <!-- /col-lg-4 col-md-6 --> */}
                                <div className="col-xl-4 col-md-6">
                                  <div className="form-group mb-4">
                                    <label
                                      htmlFor="Phone"
                                      className="border rounded-4 w-100 position-relative"
                                    >
                                      <span className="fw-bold">Phone</span>
                                      <input
                                        type="tel"
                                        id="Phone"
                                        pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                                        className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                                        placeholder="Phone Number"
                                        name="phone"
                                        value={values.phone}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        disabled={!editable}
                                      />
                                    </label>
                                    {/* {errors.phone && touched.phone ? (
                                      <p className="form-error text-danger">
                                        {errors.phone}
                                      </p>
                                    ) : null} */}
                                    {errors.phone && touched.phone ? (
                                      <p className="form-error text-danger">
                                        {errors.phone}
                                      </p>
                                    ) : error.phone ? (
                                      <p className="form-error text-danger">
                                        {error.phone}
                                      </p>
                                    ) : null}
                                  </div>
                                  {/* <!-- /form-group --> */}
                                </div>
                                {/* <!-- /col-lg-4 col-md-6 --> */}
                                <div className="col-xl-4 col-md-6">
                                  <div className="form-group mb-4">
                                    <label
                                      htmlFor="Pincode"
                                      className="border rounded-4 w-100 position-relative"
                                    >
                                      <span className="fw-bold">Pincode</span>
                                      <input
                                        type="text"
                                        id="Pincode"
                                        className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                                        placeholder="Area Pincode"
                                        name="pinCode"
                                        value={values.pinCode}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        disabled={!editable}
                                      />
                                    </label>
                                    {errors.pinCode && touched.pinCode ? (
                                      <p className="form-error text-danger">
                                        {errors.pinCode}
                                      </p>
                                    ) : error.pinCode ? (
                                      <p className="form-error text-danger">
                                        {error.pinCode}
                                      </p>
                                    ) : null}
                                    {/* {error.pinCode  ? (
                                      <p className="form-error text-danger">
                                        {error.pinCode}
                                      </p>
                                    ) : null} */}
                                  </div>
                                  {/* <!-- /form-group --> */}
                                </div>
                                {/* <!-- /col-lg-4 col-md-6 --> */}
                                <div className="col-xl-4 col-md-6">
                                  <div className="form-group mb-4">
                                    <StateSelect
                                      values={values}
                                      setSelectedState={setSelectedState}
                                      handleBlur={handleBlur}
                                      handleChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      disabled={!editable}
                                    />
                                    {errors.stateId && touched.stateId ? (
                                      <p className="form-error text-danger">
                                        {errors.stateId}
                                      </p>
                                    ) : error.state_Id ? (
                                      <p className="form-error text-danger">
                                        {error.state_Id}
                                      </p>
                                    ) : null}
                                    {/* {error.state_Id  ? (
                                      <p className="form-error text-danger">
                                        {error.state_Id}
                                      </p>
                                    ) : null} */}
                                  </div>
                                  {/* <!-- /form-group --> */}
                                </div>
                                {/* <!-- /col-lg-4 col-md-6 --> */}
                                <div className="col-xl-4 col-md-6">
                                  <div className="form-group mb-4">
                                    <CitySelect
                                      parameter={values}
                                      selectedState={selectedState}
                                      handleBlur={handleBlur}
                                      handleChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      disabled={!editable}
                                    />
                                    {errors.cityId && touched.cityId ? (
                                      <p className="form-error text-danger">
                                        {errors.cityId}
                                      </p>
                                    ) : error.city_Id ? (
                                      <p className="form-error text-danger">
                                        {error.city_Id}
                                      </p>
                                    ) : !errors.cityId &&
                                      error.city_Id &&
                                      touched.cityId ? (
                                      <p className="form-error text-danger">
                                        Please select a cities
                                      </p>
                                    ) : null}
                                    {/* {error.city_Id  ? (
                                      <p className="form-error text-danger">
                                        {error.city_Id}
                                      </p>
                                    ) : null} */}
                                  </div>
                                  {/* <!-- /form-group --> */}
                                </div>
                                {/* <!-- /col-lg-4 col-md-6 --> */}
                                <div className="col-lg-12">
                                  <div className="form-group mb-4">
                                    <label
                                      htmlFor="Address"
                                      className="border rounded-4 w-100 position-relative"
                                    >
                                      <span className="fw-bold">Address</span>
                                      <input
                                        id="Address"
                                        className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                                        placeholder="Type Your Address"
                                        name="address"
                                        value={values.address}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        disabled={!editable}
                                      />
                                    </label>
                                    {/* {errors.address && touched.address ? (
                                      <p className="form-error text-danger">
                                        {errors.address}
                                      </p>
                                    ) : null} */}
                                    {errors.address && touched.address ? (
                                      <p className="form-error text-danger">
                                        {errors.address}
                                      </p>
                                    ) : error.address ? (
                                      <p className="form-error text-danger">
                                        {error.address}
                                      </p>
                                    ) : null}
                                    {/* {error.address ? (
                                      <p className="form-error text-danger">
                                        {error.address}
                                      </p>
                                    ) : null} */}
                                  </div>
                                  {/* <!-- /form-group --> */}
                                </div>
                                {/* <!-- /col-lg-12 --> */}
                              </div>
                              {/* <!-- /row --> */}
                            </div>
                            {/* <!-- /saving-calculate-form --> */}
                            <div className="saving-calculate-form hideform">
                              <div className="heading mb-xl-4 mb-3">
                                <span className="fw-semibold saving-title">
                                  Upload Documents
                                </span>
                              </div>
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="form-group mb-4">
                                    <label className="border rounded-4 w-100 position-relative">
                                      <span className="fw-bold">PAN</span>
                                      <div className="form-group">
                                        <input
                                          type="text"
                                          name="filename"
                                          id="filetextid_Pan"
                                          className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                                          placeholder="No file selected"
                                          readOnly
                                        />
                                        <span className="input-group-btn unit-label upload-docs position-absolute">
                                          <div className="btn btn-primary btn-header green-btn custom-file-uploader">
                                            <input
                                              type="file"
                                              name="panFile"
                                              // onchange="this.form.filename.value = this.files.length ? this.files[0].name : ''"
                                              onChange={handlefilechangePan}
                                              disabled={!editable}
                                            />
                                            Upload
                                          </div>
                                        </span>
                                      </div>
                                    </label>
                                    {/* {errors.panFile && touched.panFile ? (
                                      <p className="form-error text-danger">
                                        {errors.panFile}
                                      </p>
                                    ) : null} */}
                                    {error.panFile ? (
                                      <p className="form-error text-danger">
                                        {error.panFile}
                                      </p>
                                    ) : null}
                                    {/* {errors.panFile && touched.panFile ? (
                                      <p className="form-error text-danger">
                                        {errors.panFile}
                                      </p>
                                    ) : error.panFile  ? (
                                      <p className="form-error text-danger">
                                        {error.panFile}
                                      </p>
                                    ) : null} */}
                                  </div>
                                  {/* <!-- /form-group --> */}
                                </div>
                                {/* <!-- /col-lg-4 col-md-6 --> */}
                                <div className="col-md-6">
                                  <div className="form-group mb-4">
                                    <label
                                      htmlFor="Documenttype"
                                      className="border rounded-4 w-100 position-relative"
                                    >
                                      <span className="fw-bold">
                                        Document 2
                                      </span>

                                      <Document2
                                        name="documentId"
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        values={values}
                                        registerationIdForDocument2={
                                          registerationIdForDocument2
                                        }
                                      />
                                    </label>
                                    {/* {errors.documentId && touched.documentId ? (
                                      <p className="form-error text-danger">
                                        {errors.documentId}
                                      </p>
                                    ) : null} */}
                                    {/* {error.documentId ? (
                                      <p className="form-error text-danger">
                                        {error.documentId}
                                      </p>
                                    ) : null} */}

                                    {errors.documentId && touched.documentId ? (
                                      <p className="form-error text-danger">
                                        {errors.documentId}
                                      </p>
                                    ) : error.documentId ? (
                                      <p className="form-error text-danger">
                                        {error.documentId}
                                      </p>
                                    ) : null}
                                  </div>
                                  {/* <!-- /form-group --> */}
                                </div>
                                {/* <!-- /col-lg-4 col-md-6 --> */}
                                <div className="col-md-6">
                                  <div className="form-group mb-4">
                                    <label className="border rounded-4 w-100 position-relative">
                                      <span className="fw-bold">
                                        Document File
                                      </span>
                                      <div className="form-group">
                                        <input
                                          type="text"
                                          name="filename"
                                          id="filetextid"
                                          className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                                          placeholder="No file selected"
                                          readOnly
                                        />
                                        <span className="input-group-btn unit-label upload-docs position-absolute">
                                          <div className="btn btn-primary btn-header green-btn custom-file-uploader">
                                            <input
                                              type="file"
                                              // onChange="this.form.filename.value = this.files.length ? this.files[0].name : ''" />
                                              name="file"
                                              onChange={handlefilechange}
                                              disabled={!editable}
                                            />
                                            Upload
                                          </div>
                                        </span>
                                      </div>
                                    </label>
                                    {/* {errors.file && touched.file ? (
                                      <p className="form-error text-danger">
                                        {errors.file}
                                      </p>
                                    ) : null} */}
                                    {error.file ? (
                                      <p className="form-error text-danger">
                                        {error.file}
                                      </p>
                                    ) : null}

                                    {/* {errors.file && touched.file ? (
                                      <p className="form-error text-danger">
                                        {errors.file}
                                      </p>
                                    ) : error.file  ? (
                                      <p className="form-error text-danger">
                                        {error.file}
                                      </p>
                                    ) : null} */}
                                  </div>
                                  {/* <!-- /form-group --> */}
                                </div>
                                {/* <!-- /col-lg-4 col-md-6 --> */}
                              </div>
                              {/* <!-- /row --> */}
                            </div>
                            {/* <!-- /saving-calculate-form --> */}
                            <div className="saving-calculate-form hideform">
                              <div className="heading mb-2">
                                <span className="fw-semibold saving-title">
                                  Areas Served
                                </span>
                              </div>
                              <div className="area-served">
                                {dropdowns.map((dropdown) => (
                                  //console.log("dropdown"),
                                  //console.log(dropdown),
                                  <AreaServed
                                    name="areaServed"
                                    selectedOptions={selectedOptions}
                                    setSelectedOptions={setSelectedOptions}
                                    key={dropdown.key}
                                    onSelectionChange={
                                      dropdown.onSelectionChange
                                    }
                                    onDelete={dropdown.onDelete}
                                    onSelectedStateChange={
                                      handleSelectedStateChange
                                    }
                                    setratematrixdropdown={
                                      setratematrixdropdown
                                    }
                                    disabled={!editable}
                                    // Added for filter states
                                    values={values}
                                    selectedAreaServedStates={
                                      selectedAreaServedStates
                                    }
                                    setSelectedAreaServedStates={
                                      setSelectedAreaServedStates
                                    }
                                    areaServedStatesVariable={
                                      areaServedStatesVariable
                                    }
                                    rateMatrixOld={rateMatrixOld}
                                    setRateMatrixOLD={setRateMatrixOLD}
                                    setStatematrixArrayOLD={
                                      setStatematrixArrayOLD
                                    }
                                    statematrixarrayOLD={statematrixarrayOLD}
                                    getAllStatesAreaserved={dropdown.options}
                                    selectedStatesArray={selectedStatesArray}
                                    error={error}
                                  />
                                ))}
                                {/* const [statematrixarrayOLD, setStatematrixArrayOLD] = useState(statematrixarray); */}

                                {/* const [rateMatrixOld, setRateMatrixOLD] = useState([]); */}
                                {/* <!-- /row --> */}
                                <div className="addmore-area">
                                  <a
                                    onClick={handleAddDropdown}
                                    disabled={!editable}
                                    //onClick={handleButtonClick}
                                    className="btn btn-outline-success text-uppercase ps-5 pe-5 mt-2"
                                  >
                                    <span>+</span> ADD
                                  </a>
                                </div>
                              </div>
                              {/* {errors.areaServed && touched.areaServed ? (
                                <p className="form-error text-danger">
                                  {errors.areaServed}
                                </p>
                              ) : null} */}
                              {error.areaServed ? (
                                <p className="form-error text-danger">
                                  {error.areaServed}
                                </p>
                              ) : null}

                              {/* {errors.areaServed && touched.areaServed ? (
                                      <p className="form-error text-danger">
                                        {errors.areaServed}
                                      </p>
                                    ) : error.areaServed  ? (
                                      <p className="form-error text-danger">
                                        {error.areaServed}
                                      </p>
                                    ) : null} */}

                              {/* <!-- /area-served --> */}
                            </div>
                            {/* <!-- /saving-calculate-form --> */}
                            <div className="saving-calculate-form mt-lg-4 mt-2 pt-3 hideform">
                              <div className="heading mb-xl-4 mb-3">
                                <span className="fw-semibold saving-title">
                                  Rate Matrix
                                </span>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  <div className="row align-items-center mb-4">
                                    <div className="col-md-7">
                                      <div className="form-group">
                                        <MatrixStateSelect
                                          values={values}
                                          setSelectedStates={setSelectedStates}
                                          handleBlur={handleBlur}
                                          handleChange={handleChange}
                                          errors={errors}
                                          touched={touched}
                                          isDropdownDisabled={
                                            isDropdownDisabled
                                          }
                                          setdropdown={setdropdown}
                                          disabled={!editable}
                                        />
                                      </div>
                                    </div>

                                    <div className="col-md-5">
                                      <div className="check-conditions d-flex align-items-center justify-content-end m-0">
                                        <label
                                          htmlFor="UseForAllStates"
                                          className="checkboxes-view p-0 position-relative d-flex"
                                        >
                                          <input
                                            type="checkbox"
                                            name=""
                                            className="position-absolute top-0"
                                            id="UseForAllStates"
                                            checked={isCheckboxChecked}
                                            onChange={CheckboxChange}
                                          />
                                          <div className="now-check position-relative mt-0"></div>
                                          <p className="m-0">
                                            Use this for all States
                                          </p>
                                        </label>
                                        <div className="save-rate-now ms-lg-3 ms-2">
                                          <button
                                            onClick={getMatrixData}
                                            disabled={!editable}
                                            type="button"
                                            className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn  save-btn  save_rate_matrix"
                                          ></button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <RateMatrix
                                    updateTotals={updateTotals}
                                    disabled={!editable}
                                  />
                                </div>
                                {error.rateMatrix ? (
                                  <p className="form-error text-danger">
                                    {error.rateMatrix}
                                  </p>
                                ) : null}

                                {/* {errors.rateMatrix && touched.rateMatrix ? (
                                      <p className="form-error text-danger">
                                        {errors.rateMatrix}
                                      </p>
                                    ) : error.rateMatrix  ? (
                                      <p className="form-error text-danger">
                                        {error.rateMatrix}
                                      </p>
                                    ) : null} */}
                              </div>
                            </div>

                            {/* <!-- /bottom-actions --> */}
                            {showOtpDiv ? (
                              <div className="provided-email-otp">
                                <div className="otp-label">
                                  <p>
                                    OTPs have been sent to your email{" "}
                                    <b>{values.email} </b>and phone{" "}
                                    <b>{values.phone}</b>
                                  </p>
                                </div>
                                <div className="row gx-5">
                                  <div className="col-md-6">
                                    <div className="send-otp-field">
                                      <div className="form-group w-100">
                                        <label
                                          for="EnterOTP"
                                          className="border rounded-4 w-100 position-relative"
                                        >
                                          <span className="fw-bold">
                                            Enter Email OTP
                                          </span>
                                          <input
                                            type="text"
                                            id="EnterOTP"
                                            className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                                            placeholder="Enter 6 digit OTP"
                                            value={otp}
                                            onChange={handleOtpChange}
                                            disabled={!otpEditable}
                                          />
                                        </label>
                                      </div>
                                      {/* <!-- /form-group --> */}
                                      <div className="resend-otp mt-2">
                                        <button
                                          className="text-dark text-decoration-none fw-semibold text-nowrap border-0 bg-transparent"
                                          onClick={sendOTP}
                                        >
                                          Resend OTP
                                        </button>
                                      </div>
                                    </div>
                                    {/* <!-- /send otp field --> */}
                                  </div>
                                  {/* <!-- /col-md-6 --> */}
                                  <div className="col-md-6">
                                    <div className="send-otp-field">
                                      <div className="form-group w-100">
                                        <label
                                          for="EnterOTP"
                                          className="border rounded-4 w-100 position-relative"
                                        >
                                          <span className="fw-bold">
                                            Enter SMS OTP
                                          </span>
                                          <input
                                            type="text"
                                            id="EnterOTP"
                                            className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                                            placeholder="Enter 6 digit OTP"
                                            value={smsOtp}
                                            onChange={smsOtpChange}
                                            disabled={!smsOtpEditable}
                                          />
                                        </label>
                                      </div>
                                      <div className="resend-otp mt-2">
                                        <button
                                          className="text-dark text-decoration-none fw-semibold text-nowrap border-0 bg-transparent"
                                          onClick={sendOTP}
                                        >
                                          Resend OTP
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  {isOtpVerified && isSmsOtpVerified && (
                                    <div className="col-12">
                                      <div className="solfin-action mt-4 mb-4 d-flex">
                                        {updateStatusMutation.isLoading ? (
                                          <button
                                            class="opacity-100 btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn spinner-btn1"
                                            type="button"
                                            disabled
                                          >
                                            <span
                                              class="spinner-border spinner-border-sm"
                                              aria-hidden="true"
                                            ></span>
                                            <span
                                              class="visually-hidden"
                                              role="status"
                                            >
                                              Loading...
                                            </span>
                                          </button>
                                        ) : (
                                          <button
                                            type="submit"
                                            value="Register"
                                            className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn"
                                            onClick={handleSubmit}
                                          >
                                            {" "}
                                            Register{" "}
                                          </button>
                                        )}

                                        {/* <input
                                          type="reset"
                                          value="cancel"
                                          onClickCapture={closeerrormessage}
                                          className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase ms-1 light-btn calculate-btn gray-border"
                                        /> */}
                                      </div>
                                    </div>
                                  )}
                                </div>
                                {/* <!-- /row --> */}
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="bottom-actions">
                              <div
                                id="hideformcheckbox"
                                className="check-conditions d-flex justify-content-between align-items-center hideform"
                              >
                                <label
                                  htmlFor="AllowTerms"
                                  className="checkboxes-view p-0 position-relative d-flex"
                                >
                                  <input
                                    type="checkbox"
                                    checked={isChecked}
                                    onChange={handleCheckboxChange}
                                    name=""
                                    className="position-absolute top-0"
                                    id="AllowTerms"
                                  />
                                  <div className="now-check position-relative mt-0"></div>
                                  <p
                                    className="mb-0"
                                    style={{ "line-height": "25px" }}
                                  >
                                    By clicking here, I state that I have read
                                    and understood the{" "}
                                    <Link
                                      to="/Termsandconditions"
                                      className="fw-bold text-dark text-decoration-underline"
                                      target="_blank"
                                    >
                                      Terms and Conditions
                                    </Link> 
                                    <span>, </span> 
                                    <Link
                                      to="/PrivacyPolicy"
                                      className="fw-bold text-dark text-decoration-underline"
                                      target="_blank"
                                    >
                                      Privacy Policy
                                    </Link>
                                    <span> and </span> <Link to="/CookiePolicy" class="fw-bold text-dark text-decoration-underline" target="_blank">Cookie Policy</Link>
                                  </p>
                                </label>
                                <div className="save-rate-now ms-lg-3 ms-2">
                                  <button
                                    onClick={getMatrixData}
                                    disabled={!editable}
                                    type="button"
                                    className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn save-btn  save_rate_matrix"
                                  ></button>
                                </div>
                              </div>
                              <div className="solfin-action mt-4 mb-4">
                                {editable ? (
                                  <>
                                    {sendOtp.isLoading ? (
                                      <button
                                        class="opacity-100 btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn spinner-btn2"
                                        type="button"
                                        disabled
                                      >
                                        <span
                                          class="spinner-border spinner-border-sm"
                                          aria-hidden="true"
                                        ></span>
                                        <span
                                          class="visually-hidden"
                                          role="status"
                                        >
                                          Loading...
                                        </span>
                                      </button>
                                    ) : (
                                      <input
                                        type="submit"
                                        disabled={!isChecked}
                                        value="submit"
                                        className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn"
                                        onClick={sendOTP}
                                      />
                                    )}
                                  </>
                                ) : isOtpVerified && isSmsOtpVerified ? (
                                  ""
                                ) : (
                                  <>
                                    <p>
                                      If you haven't received the OTPs, please
                                      check your email/phone number. In order to
                                      change them, click on Back and re-submit
                                      the form
                                    </p>{" "}
                                    <button
                                      type="button"
                                      onClick={handleEdit}
                                      className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn"
                                    >
                                      Back
                                    </button>
                                  </>
                                )}
                                {/* <input
                              type="reset"
                              value="cancel"
                              onClickCapture={closeerrormessage}
                              className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase ms-1 light-btn calculate-btn gray-border"
                            /> */}
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                      {/* <!-- /first-tab --> */}
                      <EpcPartnerList />
                      {/* <!-- /second-tab --> */}
                    </div>
                    {/* <!-- /tab-content --> */}
                  </div>
                  {/* <!-- /partner-data --> */}
                </div>
                {/* <!-- /registration-form --> */}
              </div>
              {/* <!-- /col-lg-8 --> */}
            </div>
            {/* <!-- /row --> */}
          </div>
          {/* <!-- /container --> */}
        </div>
        {/* <!-- /saving-calculator --> */}
      </section>
    </>
  );
};

export default Epc;
