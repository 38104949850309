import React from 'react'

const Knowledge = () => {


    return(
        <section>
        <div className="about-solfin header-space pt-1">
            <div className="container custom-container">
                <div className="row">
                  <div className="col-12 mb-4">
                    <div className="solfin-heading">
                        <h2 className="fw-bold heading-color"><span className="text-theme-green">Knowledge</span></h2>
                    </div>
                  </div>
                  {/* <!-- /col-12 --> */}

                  <div className="row mt-3">
                    <div className="solfin-heading">
                      <div className="small-heading mb-3 fw-semibold position-relative text-uppercase">
                          Important Websites
                      </div>
                    </div>
                    <div className="para-text mt-3">
                        <ul className="list-unstyled">
                          <li><a className="fw-medium text-decoration-underline pt-1 pb-1 mb-1 d-flex" href="https://mnre.gov.in/" target="_blank"><p>Ministry of New & Renewable Energy - Government of India (mnre.gov.in)</p></a></li>
                          <li><a className="fw-medium text-decoration-underline pt-1 pb-1 mb-1 d-flex" href="https://www.makeinindia.com/sector/renewable-energy" target="_blank"><p>Renewable Energy | Make In India</p></a></li>
                        </ul>
                    </div>
                </div>
                {/* <!-- /row --> */}

                <div className="row mt-3">
                    <div className="solfin-heading">
                      <div className="small-heading mb-3 fw-semibold position-relative text-uppercase">
                        State Policies
                      </div>
                    </div>
                    <div className="para-text mt-3">
                        <ul className="state-policy-links list-unstyled ps-lg-4 d-flex flex-wrap">
                            <li>
                              <a href="#" className="fw-medium d-flex w-100">Haryana</a>
                            </li>
                            <li>
                              <a href="#" className="fw-medium d-flex w-100">Punjab</a>
                            </li>
                            <li>
                              <a href="#" className="fw-medium d-flex w-100">Himachal Pradesh</a>
                            </li>
                            <li>
                              <a href="#" className="fw-medium d-flex w-100">Uttar Pradesh</a>
                            </li>
                            <li>
                              <a href="#" className="fw-medium d-flex w-100">Rajasthan</a>
                            </li>
                            <li>
                              <a href="#" className="fw-medium d-flex w-100">Gujarat</a>
                            </li>
                            <li>
                              <a href="#" className="fw-medium d-flex w-100">Madhya Pradesh</a>
                            </li>
                            <li>
                              <a href="#" className="fw-medium d-flex w-100">Delhi</a>
                            </li>
                            <li>
                              <a href="#" className="fw-medium d-flex w-100">Kerala</a>
                            </li>
                            <li>
                              <a href="#" className="fw-medium d-flex w-100">West Bengal</a>
                            </li>
                            <li>
                              <a href="#" className="fw-medium d-flex w-100">Uttarakhand</a>
                            </li>
                        </ul>
                    </div>
                </div>
                {/* <!-- /row --> */}
                    
                </div>
                {/* <!-- /row --> */}
                
            </div>
            {/* <!-- /container --> */}
        </div>
        {/* <!-- /saving-calculator --> */}
    </section>
    )
}
export default Knowledge