import React from "react";

const Select = (props) => {

  //const queryClient = useQueryClient()

  // queryClient.invalidateQueries({ queryKey: ['getFilteredCities'] })
  //const getSelectData = useQuery(props.dropdownname, querydata.getEpcRegistrationTypeNew);
  // const getSelectData = useQuery(props.dropdownname, props.dropdowndata);
  const getSelectData = props.dropdowndata;
  // props.setSelected(props.values && props.values.optionId)
  // console.log(getFilteredCities.data)
  if (getSelectData.isLoading) {
    return <div>Loading...</div>;
  }

  if (getSelectData.error) {
    return <div>Error: {getSelectData.error.message}</div>;
  }

  if (!getSelectData.data) {
    return <div>No data fetched.</div>;
  }

  return (
    <>
      <label
        for={props.dropdownidText}
        className="border rounded-4 w-100 position-relative"
      >
        <span className="fw-bold">{props.dropdownLabelName}</span>

        <select
          id={props.dropdownidText}
          className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
          name={props.dropdownName}
          //value={props.dropdownName}
          value={props.values && props.values.optionid}
          // value={props.values && props.values.optionid}
          // onchange={(e)=>setDropdownValue(e.target.value)}
          // onBlur={(e)=>setDropdownValue(e.target.value)}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          disabled={props.disabled}
        >
          {props.defaultValue !="-1"?
          <option value="-1" >
            ---Select---
          </option> :
          <option value="-1"  defaultValue="Select">
            ---Select---
          </option>
}
          {getSelectData.data.data &&
            getSelectData.data.data.map((option) => {
              
              return (
                props.defaultValue ==option.option_id?
                <option key={option.option_id} value={option.option_id} selected>
                  {option.option}
                </option> : <option key={option.option_id} value={option.option_id}>
                  {option.option}
                </option> 
              );
            })}
        </select>
      </label>
      {props.errors.optionid && props.touched.optionid ? (
        <p className="form-error text-danger">{props.errors.optionid}</p>
      ) : null}
    </>
  );
};

export default Select;
