import React from "react";

const EpcLeadModal = ({ yesClickHandler, heading, message, modalId, row }) => {
  return (
    <>
      <div
        className="modal fade"
        id={modalId}
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                {heading}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p className="text-dark">
                {message}
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-info filter-btn"
                data-bs-dismiss="modal"
              >
                No
              </button>
              <button
                type="button"
                className="btn btn-info"
                data-bs-dismiss="modal"
                onClick={() => yesClickHandler(row.original.id)}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EpcLeadModal;
