import React, { useEffect } from "react";
import { useState } from "react";
import { useQuery } from "react-query";
import { useParams, Link } from "react-router-dom";
import { getEpc } from "../../service/dataService";

import { saveAs } from "file-saver";

import Select from "../../components/Select";
import AreaServedModal from "../../../components/AreaServedModal";

// import documentImage from "../../assets/images/aadhar-card.jpg";

const EPCEmpanelledDetailPage = () => {
  const [epcData, setEpcData] = useState([{}]);
  const { id } = useParams();

  // console.log(id);

  const { data, isLoading, error } = useQuery(["getEpc", +id], () =>
    getEpc(id)
  );
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const initialStateId =
      data && data.rateMatrix && data.rateMatrix[0]?.state_id;
    // console.log("initialStateId");
    // console.log(initialStateId);
    setFilteredData(
      data &&
        data.rateMatrix &&
        data.rateMatrix.filter(
          (obj) => obj.state_id == MatrixStates[0].option_id
        )
    );
  }, [data]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!data) {
    return <div>No data fetched.</div>;
  }
  // console.log(data.data[0]);
  // console.log("filteredData");
  // console.log(filteredData);
  const onButtonClick = () => {
    const image_url =
      data && data.documents[1] && data.documents[1].documentAddress;
    // console.log("image_url", image_url)
    saveAs(image_url, "file");
  };
  const onButtonClick1 = () => {
    const image_url1 =
      data && data.documents[0] && data.documents[0].documentAddress;
    // console.log("image_url1", image_url1)
    saveAs(image_url1, "file");
  };

  //Rate Matrix

  const componentslist = data.allMatrixComponents;
  const wattagelist = data.allWattageComponents;
  const MatrixStates = data.allMatrixStates;
  // console.log("MatrixStates");
  // console.log(MatrixStates);
  const filterMatrixData = (stateId) => {
    // console.log("stateid");
    // console.log(stateId);
    setFilteredData(data.rateMatrix.filter((obj) => obj.state_id == stateId));
  };
  // console.log("MatrixStates");
  // console.log(MatrixStates);
  // console.log("MatrixStates");
  // console.log(MatrixStates);
  return (
    <>
      <div className="content-body">
        <div className="page-title">
          <Link className="back-to-page" to="/admin/epc-empanelled">
            <i className="fa-solid fa-chevron-left"></i>
          </Link>
          <div className="dash-breadcrumb">
            <ul>
              <li>
                <Link to="/admin/epc-empanelled">EPC Empanelled</Link>
              </li>
              <li>Details</li>
            </ul>
          </div>
        </div>

        {/* <!-- /page title --> */}
        <div className="dash-card">
          <div className="dash-card-header border-0">
            <div className="tab-menu mb-0">
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    Personal Detail
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    Rate Matrix
                  </button>
                </li>
              </ul>
            </div>
          </div>

          {/* <!-- /card header --> */}
          <div className="dash-card-body">
            <div className="solfin-tabs">
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                  tabindex="0"
                >
                  <div
                    className="tab-box"
                    id="tab-1"
                    style={{ display: "block" }}
                  >
                    <form action="#" method="get">
                      <div className="solfin-application-details">
                        <ul>
                          <li>
                            <span className="detail-label">
                              Registration Type
                            </span>
                            <span className="detail-value">
                              {data.data.customer_type}
                            </span>
                          </li>

                          <li>
                            <span className="detail-label">Name:</span>{" "}
                            <span className="detail-value">
                              {data.data.company_name}
                            </span>
                          </li>
                          <li>
                            <span className="detail-label">Address:</span>{" "}
                            <span className="detail-value">
                              {data.data.address1}
                            </span>
                          </li>
                          <li>
                            <span className="detail-label">Pincode:</span>{" "}
                            <span className="detail-value">
                              {data.data.pin_code}
                            </span>
                          </li>
                          <li>
                            <span className="detail-label">City:</span>{" "}
                            <span className="detail-value">
                              {data.data.city}
                            </span>
                          </li>
                          <li>
                            <span className="detail-label">State:</span>
                            <span className="detail-value">
                              {data.data.state}
                            </span>
                          </li>
                          <li>
                            <span className="detail-label">Phone:</span>
                            <span className="detail-value">
                              {data.data.phone}
                            </span>
                          </li>
                          <li>
                            <span className="detail-label">Email:</span>
                            <span className="detail-value">
                              {data.data.email}
                            </span>
                          </li>
                          {/* <li>
                      <span
                       className="detail-label"
                      >
                        Customer Type:
                      </span>
                      <span className="detail-value">{data.data.customer_type}</span>
                    </li>
 */}
                          <li>
                            <span className="detail-label">
                              Document 1 Type
                            </span>
                            <span className="detail-value">
                              {data &&
                              data.documents[1] &&
                              data.documents[1].documentName
                                ? data.documents[1].documentName
                                : "No file is uploaded"}
                            </span>
                          </li>
                          <li>
                            <span className="detail-label">
                              Document 1 Image
                            </span>
                            <span className="detail-value">
                              {data &&
                              data.documents[1] &&
                              data.documents[1].documentAddress ? (
                                <a
                                  href={data.documents[1].documentAddress}
                                  target="_blank"
                                  onClick={onButtonClick}
                                  className="text-dark"
                                >
                                  {" "}
                                  <i className="fa-solid fa-download"></i>{" "}
                                  Download
                                </a>
                              ) : (
                                ""
                              )}
                            </span>
                          </li>
                          <li>
                            <span className="detail-label">
                              Document 2 Type
                            </span>
                            <span>
                              {data &&
                              data.documents[0] &&
                              data.documents[0].documentName
                                ? data.documents[0].documentName
                                : "No file is uploaded"}
                            </span>
                          </li>
                          <li>
                            <span className="detail-label">
                              Document 2 Image
                            </span>
                            <span>
                              {data &&
                              data.documents[0] &&
                              data.documents[0].documentAddress ? (
                                <a
                                  href={data.documents[0].documentAddress}
                                  target="_blank"
                                  onClick={onButtonClick1}
                                  className="text-dark"
                                >
                                  {" "}
                                  <i className="fa-solid fa-download"></i>{" "}
                                  Download
                                </a>
                              ) : (
                                ""
                              )}
                            </span>
                          </li>

                          <li>
                            <span className="detail-label">
                              Premium Partner Code:
                            </span>
                            <span className="detail-value">
                              {data.data.premium_partner_code}
                            </span>
                          </li>
                          <li>
                            <span className="detail-label">Status:</span>
                            <span className="detail-value">
                              {data.data.status}
                            </span>
                          </li>
                          <li>
                            <span className="detail-label">Area Served</span>
                            <span className="detail-value">
                              <div className="state-served">
                                {data.serviceArea.map((area, index) => (
                                  <React.Fragment key={index}>
                                    <div
                                      className="btn text-dark text-decoration-underline py-0 px-1"
                                      data-bs-toggle="modal"
                                      data-bs-target={`#stateId${area.state_id}`}
                                    >
                                      {area.state}
                                      <AreaServedModal area={area} />{" "}
                                    </div>
                                    {index !== data.serviceArea.length - 1 &&
                                      ","}
                                  </React.Fragment>
                                ))}
                              </div>
                            </span>
                          </li>

                          <li>
                            <span className="detail-label">Created On:</span>
                            <span className="detail-value">
                              {data.data.created_on}
                            </span>
                          </li>
                          <li>
                            <span className="detail-label">Published On:</span>
                            <span className="detail-value">
                              {data.data.published_on}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </form>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                  tabindex="0"
                >
                  <div className="tab-main-box">
                    <div
                      className="tab-box d-none"
                      id="tab-2"
                      style={{ display: "none" }}
                    >
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Sr. No.</th>
                              <th>Name</th>
                              <th>Address</th>
                              <th>City</th>
                              <th>State</th>
                              <th>Status</th>
                              <th>Quote</th>
                              <th>action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1</td>
                              <td>Karan Sharma</td>
                              <td>Plot No. 229, Phase-1 Industrial Area</td>
                              <td>Panchkula</td>
                              <td>Haryana</td>
                              <td>
                                <select className="solfin-input sm-input">
                                  <option value="1" selected="Visit Done">
                                    Visit Done
                                  </option>
                                  <option value="2">Accepted</option>
                                  <option value="3">Quote Shared</option>
                                  <option value="4">Rakesh</option>
                                </select>
                              </td>
                              <td>
                                <a href="javascript:void(0);">-</a>
                              </td>
                              <td>
                                <div className="sub-menu">
                                  <a href="#DropdownActions">
                                    <i className="fa-solid fa-ellipsis"></i>
                                  </a>
                                  <ul
                                    className="more-actions"
                                    style={{ display: "none" }}
                                  >
                                    <li>
                                      <a href="applicant-detail.html">
                                        Details
                                      </a>
                                    </li>
                                    <li>
                                      <a href="rate-matrix.html">Rate Matrix</a>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>2</td>
                              <td>Karan Sharma</td>
                              <td>Plot No. 229, Phase-1 Industrial Area</td>
                              <td>Panchkula</td>
                              <td>Haryana</td>
                              <td>
                                <select className="solfin-input sm-input">
                                  <option value="1" selected="Visit Done">
                                    Visit Done
                                  </option>
                                  <option value="2">Accepted</option>
                                  <option value="3">Quote Shared</option>
                                  <option value="4">Rakesh</option>
                                </select>
                              </td>
                              <td>
                                <a
                                  className="uploaded-quote"
                                  target="_blank"
                                  href="/images/test.pdf"
                                >
                                  test-quote.pdf
                                </a>
                              </td>
                              <td>
                                <div className="sub-menu">
                                  <a href="#DropdownActions">
                                    <i className="fa-solid fa-ellipsis"></i>
                                  </a>
                                  <ul
                                    className="more-actions"
                                    style={{ display: "none" }}
                                  >
                                    <li>
                                      <a href="applicant-detail.html">
                                        Details
                                      </a>
                                    </li>
                                    <li>
                                      <a href="rate-matrix.html">Rate Matrix</a>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>3</td>
                              <td>Karan Sharma</td>
                              <td>Plot No. 229, Phase-1 Industrial Area</td>
                              <td>Panchkula</td>
                              <td>Haryana</td>
                              <td>
                                <select className="solfin-input sm-input">
                                  <option value="1" selected="Visit Done">
                                    Visit Done
                                  </option>
                                  <option value="2">Accepted</option>
                                  <option value="3">Quote Shared</option>
                                  <option value="4">Rakesh</option>
                                </select>
                              </td>
                              <td>
                                <a href="javascript:void(0);">-</a>
                              </td>
                              <td>
                                <div className="sub-menu">
                                  <a href="#DropdownActions">
                                    <i className="fa-solid fa-ellipsis"></i>
                                  </a>
                                  <ul
                                    className="more-actions"
                                    style={{ display: "none" }}
                                  >
                                    <li>
                                      <a href="applicant-detail.html">
                                        Details
                                      </a>
                                    </li>
                                    <li>
                                      <a href="rate-matrix.html">Rate Matrix</a>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>4</td>
                              <td>Karan Sharma</td>
                              <td>Plot No. 229, Phase-1 Industrial Area</td>
                              <td>Panchkula</td>
                              <td>Haryana</td>
                              <td>
                                <select className="solfin-input sm-input">
                                  <option value="1" selected="Visit Done">
                                    Visit Done
                                  </option>
                                  <option value="2">Accepted</option>
                                  <option value="3">Quote Shared</option>
                                  <option value="4">Rakesh</option>
                                </select>
                              </td>
                              <td>
                                <a href="javascript:void(0);">-</a>
                              </td>
                              <td>
                                <div className="sub-menu">
                                  <a href="#DropdownActions">
                                    <i className="fa-solid fa-ellipsis"></i>
                                  </a>
                                  <ul
                                    className="more-actions"
                                    style={{ display: "none" }}
                                  >
                                    <li>
                                      <a href="applicant-detail.html">
                                        Details
                                      </a>
                                    </li>
                                    <li>
                                      <a href="rate-matrix.html">Rate Matrix</a>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>5</td>
                              <td>Karan Sharma</td>
                              <td>Plot No. 229, Phase-1 Industrial Area</td>
                              <td>Panchkula</td>
                              <td>Haryana</td>
                              <td>
                                <select className="solfin-input sm-input">
                                  <option value="1" selected="Visit Done">
                                    Visit Done
                                  </option>
                                  <option value="2">Accepted</option>
                                  <option value="3">Quote Shared</option>
                                  <option value="4">Rakesh</option>
                                </select>
                              </td>
                              <td>
                                <a
                                  className="uploaded-quote"
                                  target="_blank"
                                  href="/images/test.pdf"
                                >
                                  test-quote.pdf
                                </a>
                              </td>
                              <td>
                                <div className="sub-menu">
                                  <a href="#DropdownActions">
                                    <i className="fa-solid fa-ellipsis"></i>
                                  </a>
                                  <ul
                                    className="more-actions"
                                    style={{ display: "none" }}
                                  >
                                    <li>
                                      <a href="applicant-detail.html">
                                        Details
                                      </a>
                                    </li>
                                    <li>
                                      <a href="rate-matrix.html">Rate Matrix</a>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>6</td>
                              <td>Karan Sharma</td>
                              <td>Plot No. 229, Phase-1 Industrial Area</td>
                              <td>Panchkula</td>
                              <td>Haryana</td>
                              <td>
                                <select className="solfin-input sm-input">
                                  <option value="1" selected="Visit Done">
                                    Visit Done
                                  </option>
                                  <option value="2">Accepted</option>
                                  <option value="3">Quote Shared</option>
                                  <option value="4">Rakesh</option>
                                </select>
                              </td>
                              <td>
                                <a href="javascript:void(0);">-</a>
                              </td>
                              <td>
                                <div className="sub-menu">
                                  <a href="#DropdownActions">
                                    <i className="fa-solid fa-ellipsis"></i>
                                  </a>
                                  <ul
                                    className="more-actions"
                                    style={{ display: "none" }}
                                  >
                                    <li>
                                      <a href="applicant-detail.html">
                                        Details
                                      </a>
                                    </li>
                                    <li>
                                      <a href="rate-matrix.html">Rate Matrix</a>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      {/* <!-- /table responsive --> */}
                    </div>
                    {/* <!-- /2nd tab --> */}

                    <div
                      className="tab-box"
                      id="tab-3"
                      style={{ display: "block" }}
                    >
                      <div className="rate-matix-details">
                        <div className="flex-wrap card-flexbox admin-epc-detail">
                          <h6 className="mb-0">Rate Matrix List</h6>
                          <div className="table-right-side card-flexbox">
                            <div className="form-group">
                              <Select
                                dropdownidText="ratematrixId"
                                dropdownName="ratematrixstateId"
                                dropdowndata={MatrixStates}
                                filterMatrixData={filterMatrixData}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Sr. No.</th>
                                <th>Components</th>
                                {wattagelist.map((wattagelistObj) => (
                                  <>
                                    <th>{wattagelistObj.wattage}</th>
                                  </>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {componentslist.map((outerObj, index) => {
                                const rowData = wattagelist.map((innerObj) => {
                                  const filteredObj =
                                    filteredData &&
                                    filteredData.find(
                                      (obj) =>
                                        obj.rate_matrix_component_id ===
                                          outerObj.rate_matrix_component_id &&
                                        obj.rate_matrix_wattage_component_id ===
                                          innerObj.rate_matrix_wattage_component_id
                                    );
                                  return filteredObj ? filteredObj.amount : "";
                                });

                                return (
                                  <tr key={outerObj.rate_matrix_component_id}>
                                    <td>{index + 1}</td>
                                    <td>{outerObj.component_name}</td>
                                    {rowData.map((amount, idx) => (
                                      <td key={idx}>
                                        {outerObj.rate_matrix_component_id === 7
                                          ? +amount
                                          : `₹ ${amount}`}
                                      </td>
                                    ))}
                                  </tr>
                                );
                              })}
                              <tr>
                                <td></td>
                                <td>Total cost</td>
                                {wattagelist.map((innerObj, idx) => {
                                  const totalCost =
                                    filteredData &&
                                    filteredData
                                      .filter(
                                        (obj) =>
                                          obj.rate_matrix_wattage_component_id ===
                                          innerObj.rate_matrix_wattage_component_id
                                      )
                                      .filter(
                                        (obj) =>
                                          obj.rate_matrix_component_id !== 7
                                      )
                                      .reduce(
                                        (sum, filteredObj) =>
                                          +sum + +filteredObj.amount,
                                        0
                                      );

                                  return (
                                    <td key={idx}>
                                      ₹ {totalCost && totalCost.toFixed(2)}
                                    </td>
                                  );
                                })}
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {/* <!-- /3rd tab --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EPCEmpanelledDetailPage;
