import { Link, useNavigate, useParams } from "react-router-dom";
import * as querydata from "../../../service/dataService";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { saveAs } from "file-saver";
import { useFormik } from "formik";
import { useEffect, useState, useRef } from "react";
import * as Yup from "yup";
import { toast } from "react-toastify";

//client Validation
export const quoteDetailSchema = Yup.object({
  projectName: Yup.string().required("Please enter Project name"),
  solution: Yup.string().required("Please enter Solution"),
  capacity: Yup.number()
    .required("Please enter Capacity")
    .min(0, "Capacity must be greater than or equal to 0")
    .typeError("Capacity must be a valid number"),
  panelType: Yup.string().required("Please enter Panel Type"),
  totalCost: Yup.string()
    .required("Please enter Total Cost")
    .min(0, "Total Cost must be greater than or equal to 0")
    .typeError("Total Cost must be a valid number"),
  inverterType: Yup.string().required("Please enter Inverter Type"),
  unitsGenerated: Yup.string()
    .required("Please enter Units Generated")
    .min(0, "Units Generated must be greater than or equal to 0")
    .typeError("Units Generated must be a valid number"),
  panelWarranty: Yup.string().required("Please enter Panel Warranty"),
  inverterWarranty: Yup.string().required("Please enter Inverter Warranty"),
  financedBy: Yup.string(),
  // epc: Yup.string().required("Please enter EPC name"),
  // dsa: Yup.string().required("Please enter DSA name"),
  // financedBy: Yup.string().required("Please enter Financed By"),
});

const QuoteLoanOffer = ({ data }) => {
  const fileInputRef = useRef(null);
  const assignedEpc =
    data && data.data && data.data.data && data.data.data[0].assigned_to_epc;
  const assignedDsa =
    data && data.data && data.data.data && data.data.data[0].dsa_id;

  const queryClient = useQueryClient();
  const { id } = useParams();
  const [isQuoteApproved, setIsQuoteApproved] = useState(false);
  const [loanAgreement, setLoanAgreement] = useState("");
  const [solarContract, setSolarContract] = useState("");
  const [initiaEpcId, setInitiaEpcId] = useState(null);

  const handleEpcChange = (e) => {
    const epc_Id = e.target.value;
    values.epcId = epc_Id;
    // console.log("values.epcId")
    // console.log(values.epcId)
    setInitiaEpcId(epc_Id);
  };

  const handleQuoteApprove = () => {
    setIsQuoteApproved(true);
  };

  const handleQuoteReject = (e) => {
    e.preventDefault();
    rejectProposalSubmittedByEpc.mutate(id);
  };
  const handleSolarContract = (e) => {
    setIsQuoteApproved(false);
    const selectedFile = e.target.files[0];
    const allowedFormats = ["pdf", "jpg", "jpeg", "png"]; // Allowed file formats

    if (selectedFile) {
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

      // Check if the file format is allowed
      if (allowedFormats.includes(fileExtension)) {
        // The file format is allowed, so set it in the state
        setSolarContract(selectedFile);
      } else {
        // The file format is not allowed, you can display an error message or take other actions
        toast.error(
          "Invalid file format. Allowed formats: " + allowedFormats.join(", ")
        );
        // Optionally, clear the input field
        e.target.value = "";
      }
    }
  };
  const rejectProposalSubmittedByEpc = useMutation(
    querydata.rejectProposalSubmittedByEpc,
    {
      onError: (error) => {
        toast.error(error.message);
        // console.log(error)
      },
      onSuccess(data) {
        toast.success(data.message);
        setIsQuoteApproved(false);
        queryClient.invalidateQueries("getQuoteLoanOffer");
      },
    }
  );

  const handleLoanAgreement = (e) => {
    setIsQuoteApproved(false);
    const selectedFile = e.target.files[0];
    const allowedFormats = ["pdf", "jpg", "jpeg", "png"]; // Allowed file formats

    if (selectedFile) {
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

      // Check if the file format is allowed
      if (allowedFormats.includes(fileExtension)) {
        // The file format is allowed, so set it in the state
        setLoanAgreement(selectedFile);
      } else {
        // The file format is not allowed, you can display an error message or take other actions as needed
        toast.error(
          "Invalid file format. Allowed formats: " + allowedFormats.join(", ")
        );
        // Optionally, clear the input field
        e.target.value = "";
      }
    }
  };

  const handleDownloadQuote = () => {
    const image_url = quoteData && quoteData.rooftop_solar_contract;
    // console.log(image_url,"..")
    saveAs(image_url, "quote");
  };

  const handleDownloadAgreement = () => {
    const image_url = quoteData && quoteData.loan_agreement;
    // console.log(image_url,"..")
    saveAs(image_url, "Loan Agreement");
  };

  const getQuoteLoanOffer = useQuery(["getQuoteLoanOffer", +id], () =>
    querydata.getQuoteLoanOffer(id)
  );

  const quoteData = getQuoteLoanOffer.data?.data?.data;

  // values defined for formikapplication_proposal_id: 35
  const initialValues = {
    projectName: quoteData && quoteData.project_name,
    solution: quoteData && quoteData.solution,
    capacity: quoteData && quoteData.capacity,
    panelType: quoteData && quoteData.type_of_panel,
    totalCost: quoteData && quoteData.total_cost,
    inverterType: quoteData && quoteData.inverter_type,
    unitsGenerated: quoteData && quoteData.estimated_unit_generated,
    panelWarranty: quoteData && quoteData.panel_warranty,
    inverterWarranty: quoteData && quoteData.inverter_warranty,
    epc: "",
    epcId: quoteData && quoteData.epc_id ? quoteData.epc_id : assignedEpc, //initiaEpcId !== null ? +initiaEpcId : undefined,
    dsa: quoteData && quoteData.dsa,
    dsaId: quoteData && quoteData?.dsa_id ? quoteData.dsa_id : assignedDsa,
    financedBy: quoteData && quoteData.financed_by ? quoteData.financed_by : "",
    roofTopImageSrc:
      quoteData && quoteData.rooftop_solar_contract
        ? quoteData.rooftop_solar_contract
        : "",
    loanAgreementImageSrc:
      quoteData && quoteData.rooftop_solar_contract
        ? quoteData.loan_agreement
        : "",
  };
  const { values, handleBlur, handleChange, handleSubmit, errors, touched } =
    useFormik({
      initialValues: initialValues,
      validationSchema: quoteDetailSchema,
      enableReinitialize: true,
      onSubmit: (values, action) => {
        // console.log(values, "///")
        const formData = new FormData();
        formData.append("projectName", values.projectName);
        formData.append("solution", values.solution);
        formData.append("capacity", values.capacity);
        formData.append("typeOfPanel", values.panelType);
        formData.append("totalCost", values.totalCost);
        formData.append("inverterType", values.inverterType);
        formData.append("roofTopImage", solarContract);
        formData.append("roofTopImageSrc", values.roofTopImageSrc);
        formData.append("loanAgreementImage", loanAgreement);
        formData.append("loanAgreementImageSrc", values.loanAgreementImageSrc);
        formData.append("unitGeneratedPerDay", values.unitsGenerated);
        formData.append("panelWarranty", values.panelWarranty);
        formData.append("inverterWarranty", values.inverterWarranty);
        formData.append("epcId", values.epcId);
        formData.append("dsa", values.dsa);
        formData.append("dsaId", values.dsaId);
        formData.append("financedBy", values.financedBy);
        // console.log(formData, "...")
        insertQuoteLoanOffer.mutate({ id, formData });
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        // action.resetForm();
      },
    });

  // Function to handle any form field change
  const handleFieldChange = (e) => {
    handleChange(e); // Call Formik's handleChange to update form state
    setIsQuoteApproved(false); // Set isQuoteApproved to false when a field changes
  };
  // console.log(values);

  //post request to submit data
  const insertQuoteLoanOffer = useMutation(querydata.insertQuoteLoanOffer, {
    onError: (error) => {
      // console.log(error, "...")
      toast.error(error.message);
    },
    onSuccess: (data) => {
      toast.success(data.message);
      queryClient.invalidateQueries("getAllApplicationLeads");
      queryClient.invalidateQueries("getQuoteLoanOffer");
      setIsQuoteApproved(true);
    },
  });
  useEffect(() => {
    if (quoteData && quoteData.is_epc_quote_approved) {
      setIsQuoteApproved(true);
    }
  }, [quoteData]);
  const getEPCListForQuote = useQuery(
    "getEPCListForQuote",
    querydata.getEPCListForQuote
  );
  const getDSAListForQuote = useQuery(
    "getDSAListForQuote",
    querydata.getDSAListForQuote
  );
  const cities = useQuery("getAllCities", querydata.getAllCities);
  console.log(values);
  return (
    <>
      <div className="solfin-application-details">
        <form onSubmit={handleSubmit}>
          <div className="form-wrap">
            <div className="form-group">
              <label htmlFor="Project">Project Name</label>
              <input
                type="text"
                id="Project"
                placeholder="Please Enter Project Name"
                // value={quoteData.project_name === null ? values.projectName : values.projectName=quoteData.project_name }
                name="projectName"
                // value={values.projectName}
                // onChange={handleChange}
                onChange={(e) => {
                  handleChange(e);
                  handleFieldChange(e);
                }}
                onBlur={handleBlur}
                className="solfin-input"
                value={values.projectName}
                // defaultValue={
                //   quoteData && quoteData.project_name !== null
                //     ? (values.projectName =
                //         quoteData.project_name)
                //     : values.projectName
                // }
              />
              {errors.projectName && touched.projectName ? (
                <p className="form-error text-danger">{errors.projectName}</p>
              ) : null}
            </div>
            {/* <!-- /form-group --> */}
            <div className="form-group">
              <label htmlFor="Solution">Solution</label>
              <input
                type="text"
                id="Solution"
                placeholder="Please Enter Solution"
                // value={quoteData.solution === null? values.solution : values.solution=quoteData.solution }
                name="solution"
                // value={values.solution}
                onChange={(e) => {
                  handleChange(e);
                  handleFieldChange(e);
                }}
                onBlur={handleBlur}
                className="solfin-input"
                value={values.solution}
              />
              {errors.solution && touched.solution ? (
                <p className="form-error text-danger">{errors.solution}</p>
              ) : null}
            </div>
            {/* <!-- /form-group --> */}
            <div className="form-group">
              <label htmlFor="Capacity">Capacity (kwH)</label>
              <input
                type="number"
                id="Capacity"
                placeholder="Please Enter Capacity"
                name="capacity"
                onChange={(e) => {
                  handleChange(e);
                  handleFieldChange(e);
                }}
                onBlur={handleBlur}
                className="solfin-input"
                value={values.capacity}
              />
              {errors.capacity && touched.capacity ? (
                <p className="form-error text-danger">{errors.capacity}</p>
              ) : null}
            </div>
            {/* <!-- /form-group --> */}
            <div className="form-group">
              <label htmlFor="TypePanel">Type of Panel</label>
              <input
                type="text"
                id="TypePanel"
                placeholder="Please Enter Panel Type"
                name="panelType"
                // value={quoteData.panel_type === null ? values.panelType : values.panelType=quoteData.panel_type }
                // value={values.panelType}
                onChange={(e) => {
                  handleChange(e);
                  handleFieldChange(e);
                }}
                onBlur={handleBlur}
                className="solfin-input"
                value={values.panelType}
              />
              {errors.panelType && touched.panelType ? (
                <p className="form-error text-danger">{errors.panelType}</p>
              ) : null}
            </div>
            {/* <!-- /form-group --> */}
            <div className="form-group">
              <label htmlFor="TotalCost">Total Cost (₹)</label>
              <input
                type="number"
                id="TotalCost"
                placeholder="Please Enter Total Cost"
                name="totalCost"
                // value={quoteData.total_cost === null ? values.totalCost : values.totalCost=quoteData.total_cost }
                // value={values.totalCost}
                onChange={(e) => {
                  handleChange(e);
                  handleFieldChange(e);
                }}
                onBlur={handleBlur}
                className="solfin-input"
                value={values.totalCost}
              />
              {errors.totalCost && touched.totalCost ? (
                <p className="form-error text-danger">{errors.totalCost}</p>
              ) : null}
            </div>
            <div className="form-group">
              <label htmlFor="InverterType">Inverter Type</label>
              <input
                type="text"
                id="InverterType"
                placeholder="Please Enter Inverter Type"
                name="inverterType"
                // value={values.inverterType}
                // value={quoteData.inverter_type === null ? values.inverterType : values.inverterType= quoteData.inverter_type }
                onChange={(e) => {
                  handleChange(e);
                  handleFieldChange(e);
                }}
                onBlur={handleBlur}
                className="solfin-input"
                value={values.inverterType}
              />
              {errors.inverterType && touched.inverterType ? (
                <p className="form-error text-danger">{errors.inverterType}</p>
              ) : null}
            </div>

            <div className="form-group">
              <label htmlFor="Unitsgenerated">Units generated per day</label>
              <input
                type="number"
                id="Unitsgenerated"
                placeholder="Please Enter Units generated per day"
                name="unitsGenerated"
                // value={quoteData.estimated_unit_generated === null  ? values.unitsGenerated : values.unitsGenerated=quoteData.estimated_unit_generated }
                // value={values.unitsGenerated}
                onChange={(e) => {
                  handleChange(e);
                  handleFieldChange(e);
                }}
                onBlur={handleBlur}
                className="solfin-input"
                value={values.unitsGenerated}
              />
              {errors.unitsGenerated && touched.unitsGenerated ? (
                <p className="form-error text-danger">
                  {errors.unitsGenerated}
                </p>
              ) : null}
            </div>
            <div className="form-group">
              <label htmlFor="PanelWarranty">Panel Warranty (in Yrs)</label>
              <input
                type="text"
                id="PanelWarranty"
                placeholder="Please Enter Panel Warranty"
                name="panelWarranty"
                // value={values.panelWarranty}
                // value={quoteData.panel_warranty === null ? values.panelWarranty : values.panelWarranty=quoteData.panel_warranty }
                onChange={(e) => {
                  handleChange(e);
                  handleFieldChange(e);
                }}
                onBlur={handleBlur}
                className="solfin-input"
                value={values.panelWarranty}
              />
              {errors.panelWarranty && touched.panelWarranty ? (
                <p className="form-error text-danger">{errors.panelWarranty}</p>
              ) : null}
            </div>
            <div className="form-group">
              <label htmlFor="InverterWarranty">
                Inverter Warranty (in Yrs)
              </label>
              <input
                type="text"
                id="InverterWarranty"
                placeholder="Please Enter Inverter Warranty"
                name="inverterWarranty"
                // value={values.inverterWarranty}
                // value={quoteData.inverter_warranty === null ? values.inverterWarranty : values.inverterWarranty=quoteData.inverter_warranty }
                onChange={(e) => {
                  handleChange(e);
                  handleFieldChange(e);
                }}
                onBlur={handleBlur}
                className="solfin-input"
                value={values.inverterWarranty}
              />
              {errors.inverterWarranty && touched.inverterWarranty ? (
                <p className="form-error text-danger">
                  {errors.inverterWarranty}
                </p>
              ) : null}
            </div>

            {/* <!-- /form-group --> */}
            {/* {data && data.data && data.data.data && data.data.data.map((data)=>( */}
            <div className="form-group">
              <label htmlFor="EPC">EPC</label>
              <select
                id="epcDropdown"
                className="solfin-input"
                name="epcId"
                //onChange={handleChange}
                onBlur={handleBlur}
                onChange={(e) => {
                  handleChange(e);
                  handleFieldChange(e);
                }}
                value={values.epcId}
              >
                <option value="">---Unassigned---</option>
                {getEPCListForQuote &&
                  getEPCListForQuote.data &&
                  getEPCListForQuote.data.data &&
                  getEPCListForQuote.data.data.data &&
                  getEPCListForQuote.data.data.data.map((data) => (
                    <option key={data.epc_id} value={+data.epc_id}>
                      {data.company_name}
                    </option>
                  ))}
              </select>

              {/* {errors.epc && touched.epc ? (
                          <p className="form-error text-danger">{errors.epc}</p>
                          ) : null}
                     */}
            </div>

            <div className="form-group">
              <label htmlFor="DSA">DSA</label>
              {/* <input
                type="text"
                id="DSA"
                placeholder="Please Enter DSA"
                name="dsa"
                // value={values.dsa}
                onChange={(e) => {
                  handleChange(e);
                  handleFieldChange(e);
                }}
                onBlur={handleBlur}
                className="solfin-input"
                value={values.dsa}
              />
              {errors.dsa && touched.dsa ? (
                <p className="form-error text-danger">{errors.dsa}</p>
              ) : null} */}
              <select
                id="dsaDropdown"
                className="solfin-input"
                name="dsaId"
                //onChange={handleChange}
                onBlur={handleBlur}
                onChange={(e) => {
                  handleChange(e);
                  handleFieldChange(e);
                }}
                value={values.dsaId}
              >
                <option value="null">---Unassigned---</option>
                {getDSAListForQuote &&
                  getDSAListForQuote.data &&
                  getDSAListForQuote.data.data &&
                  getDSAListForQuote.data.data.data &&
                  getDSAListForQuote.data.data.data.map((data) => (
                    <option key={data.dsa_id} value={+data.dsa_id}>
                      {data.dsa_name}
                    </option>
                  ))}
              </select>
            </div>
            {/* <!-- /form-group --> */}
            <div className="form-group">
              <label htmlFor="FinancedBy">Financed By</label>
              <input
                type="text"
                id="FinancedBy"
                name="financedBy"
                placeholder="Please Enter Financed By"
                // value={values.financedBy}
                onChange={(e) => {
                  handleChange(e);
                  handleFieldChange(e);
                }}
                onBlur={handleBlur}
                className="solfin-input"
                value={values.financedBy}
              />
              {errors.financedBy && touched.financedBy ? (
                <p className="form-error text-danger">{errors.financedBy}</p>
              ) : null}
            </div>

            <div className="form-group">
              <label for="Dealsin">Rooftop Solar Contract</label>
              <input
                type="file"
                name="solarContract"
                id="RooftopSolarContract"
                // value={quoteData.rooftop_solar_contract ? values.quote=quoteData.rooftop_solar_contract : ""}
                onChange={handleSolarContract}
                className="form-control solfin-input"
                placeholder={
                  quoteData && quoteData.rooftop_solar_contract
                    ? "Contract submitted by EPC"
                    : "No file selected"
                }
                ref={fileInputRef}
              />{" "}
              <br />
              <div className="uploaded-quote ">
                {quoteData && quoteData.rooftop_solar_contract ? (
                  <a
                    className="h1"
                    href={quoteData && quoteData.rooftop_solar_contract}
                    target="_blank"
                    rel="noreferrer"
                    onClick={handleDownloadQuote}
                  >
                    <i className="fa-solid fa-file opacity-50"></i>
                  </a>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="form-group">
              <label for="Dealsin">Loan Agreement</label>

              <input
                type="file"
                name="loanAgreement"
                id="LoanAgreement"
                onChange={handleLoanAgreement}
                className="form-control solfin-input"
              />

              <br />
              <div className="uploaded-quote ">
                {quoteData && quoteData.loan_agreement ? (
                  <a
                    className="h1"
                    href={quoteData && quoteData.loan_agreement}
                    target="_blank"
                    rel="noreferrer"
                    onClick={handleDownloadAgreement}
                  >
                    <i className="fa-solid fa-file opacity-50"></i>
                  </a>
                ) : (
                  ""
                )}
              </div>
            </div>

            {/* div that got error */}
            <div className="form-actions m-1">
              {quoteData && quoteData.is_epc_quote_rejected ? (
                ""
              ) : (
                <>
                  {insertQuoteLoanOffer.isLoading ? (
                    <button
                      class="opacity-100 btn btn-info spinner-btn2"
                      type="button"
                      disabled
                    >
                      <span
                        class="spinner-border spinner-border-sm"
                        aria-hidden="true"
                      ></span>
                      <span class="visually-hidden" role="status">
                        Loading...
                      </span>
                    </button>
                  ) : (
                    <button
                      type="submit"
                      disabled={isQuoteApproved && isQuoteApproved}
                      className="btn btn-info"
                    >
                      {isQuoteApproved && isQuoteApproved
                        ? "Shared With Customer"
                        : "Approve and Share"}
                    </button>
                  )}
                </>
              )}

              {quoteData === undefined ? (
                ""
              ) : (
                <>
                  {quoteData && quoteData.is_epc_quote_approved ? (
                    ""
                  ) : (
                    <>
                      {rejectProposalSubmittedByEpc.isLoading ? (
                        <button
                          class="opacity-100 btn btn-info spinner-btn2"
                          type="button"
                          disabled
                        >
                          <span
                            class="spinner-border spinner-border-sm"
                            aria-hidden="true"
                          ></span>
                          <span class="visually-hidden" role="status">
                            Loading...
                          </span>
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-info filter-btn"
                          disabled={
                            quoteData && quoteData.is_epc_quote_rejected
                              ? true
                              : false
                          }
                          onClick={handleQuoteReject}
                        >
                          {quoteData && quoteData.is_epc_quote_rejected
                            ? "Rejected"
                            : "Reject"}
                        </button>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default QuoteLoanOffer;
