import React, { Component } from "react";
import Slider from "react-slick";
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';

const HomeSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <Slider {...settings}>
        <div className="slide-view">
          <div className="project-img">
            <img src="/images/project-one.jpg" className="w-100" alt="Project" title="Project" />
          </div>
          {/* <!-- /project-img --> */}
          <div className="project-text">
            <h6 className="fw-bold">Housing Society, Pune</h6>
            {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.</p> */}
            {/* <div className="solfin-action mt-3">
                  <a href="#" className="text-decoration-none d-flex align-items-center justify-content-end"><span className="text-btn text-capitalize fw-semibold rounded-5">Learn more</span> <img className="ms-4" src="images/long-next-arrow.svg" alt="View All" title="View All"/></a>
                </div> */}
            {/* <!-- /action-button --> */}
          </div>
          {/* <!-- /project-text --> */}
        </div>
       
        <div className="slide-view">
          <div className="project-img">
            <img src="/images/project-one.jpg" className="w-100" alt="Project" title="Project" />
          </div>
          {/* <!-- /project-img --> */}
          <div className="project-text">
            <h6 className="fw-bold">Financing Ecosystem </h6>
            {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.</p> */}
            {/* <div className="solfin-action mt-3">
                  <a href="#" className="text-decoration-none d-flex align-items-center justify-content-end"><span className="text-btn text-capitalize fw-semibold rounded-5">Learn more</span> <img className="ms-4" src="images/long-next-arrow.svg" alt="View All" title="View All"/></a>
                </div> */}
            {/* <!-- /action-button --> */}
          </div>
          {/* <!-- /project-text --> */}
        </div>
      </Slider>
    </>
  );
}

export default HomeSlider;