import React from "react";
import { useQuery } from "react-query";
import * as querydata from "../dataService/DataService";

const Document2 = (props) => {
   
    const getAllDocumentType = useQuery(
        ["getAllDocuments2", +props.registerationIdForDocument2],
        () => querydata.getEpcDocument2(props.registerationIdForDocument2)
      );

  return (
    <>
      <select
        id="Documenttype"
        className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
        name="documentId"
        value={props.values && props.values.documentId}
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        disabled={props.disabled}

      >
        <option value="" defaultValue="Select Document">
          Select Document
        </option>
        {getAllDocumentType.data && getAllDocumentType.data.data && 
          getAllDocumentType.data.data.map((documentoption) => {
            return (
              <option
                key={documentoption.document_id}
                value={documentoption.document_id}>
                {documentoption.document_name}
              </option>
            );
          })}
      </select>
    </>
  );
};

export default Document2;
