import React from "react";
import { useQuery, useQueryClient } from "react-query";
import * as querydata from "../service/dataService";

const StateSelect = (props) => {
  //const queryClient = useQueryClient()

  // queryClient.invalidateQueries({ queryKey: ['getFilteredCities'] })
  const getAllStates = useQuery("StatesSelect", querydata.getAllStates);

  props.setSelectedState(props.values && props.values.stateId);
  // console.log(getFilteredCities.data)
  if (getAllStates.isLoading) {
    return <div>Loading...</div>;
  }

  if (getAllStates.error) {
    return <div>Error: {getAllStates.error.message}</div>;
  }

  if (!getAllStates.data) {
    return <div>No data fetched.</div>;
  }

  return (
    <>
      <label
        htmlFor="State"
        className="border rounded-4 w-100 position-relative"
      >
        <span className="fw-bold">State</span>

        <select
          id="State"
          className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
          name="stateId"
          value={props.values && props.values.stateId}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
        >
          <option value="-1" defaultValue="Select State">
            Select State
          </option>
          {getAllStates.data.data &&
            getAllStates.data.data.map((stateoption) => {
              return (
                <option key={stateoption.state_id} value={stateoption.state_id}>
                  {stateoption.state}
                </option>
              );
            })}
        </select>
      </label>
      {props.errors.stateId && props.touched.stateId ? (
        <p className="form-error text-danger">{props.errors.stateId}</p>
      ) : null}
    </>
  );
};

export default StateSelect;
