import React, { useState, useRef } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import {updateInvoiceStatus} from "../../../service/dataService.js";

const InvoiceVerificationRemarks = ({invoiceId, invoiceQuery}) => {
 const [errors , setErrors] = useState({});
 const queryClient = useQueryClient();
  const formRef = useRef(null);
  const handleSubmit = async(e, status, invoiceId)=>{
    e.preventDefault();
    console.log("invoice Id");
    console.log(invoiceId);
    const remarks = document.getElementById("verificationRemarks"+invoiceId).value;
    const response = await updateInvoiceStatus(invoiceId, status, remarks);
    if (response){
      toast.success("Invoice status updated successfully.")
    }
    queryClient.invalidateQueries(invoiceQuery);
  }
  const changeHandler =(e, id)=>{
    e.preventDefault();
    errors[id] = null;
  }
  return (
    <>
      <div
        className="modal fade"
        id={"invoice-document-verification"+invoiceId}
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Verify/Reject Invoice
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body" >
            <form ref={formRef}>
                    <div className="form-wrap send-email-form">
                    
                      <div className="form-group">
                        <label htmlFor={"verificationRemarks"+invoiceId}>Remarks</label>
                        <input
                          type="text"
                          id={"verificationRemarks"+invoiceId}
                          placeholder="Remarks"
                          // value={quoteData.project_name === null ? values.projectName : values.projectName=quoteData.project_name }
                          name="Remarks"
                          className="solfin-input"
                          onChange={changeHandler}
                          onBlur={changeHandler}
                         
                        />
                        {errors.verificationRemarks ? (
                          <p className="form-error text-danger">
                            {errors.verificationRemarks}
                          </p>
                        ) : null}
                      </div>                           
                    </div>
                  </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-info filter-btn"
                data-bs-dismiss="modal"
                onClick={(e) => handleSubmit(e, "REJECTED", invoiceId)}
              >
                Reject
              </button>
              <button
                type="button"
                className="btn btn-info green-btn"
                data-bs-dismiss="modal"
                onClick={(e) => handleSubmit(e, "VERIFIED", invoiceId)}
              >
                Verify
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoiceVerificationRemarks;
