import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getEpcProjectDetail } from "../../service/DataService";
import { saveAs } from "file-saver";
// import * as queryData from "../../service/DataService";

const ProjectDetails = () => {
  const { id } = useParams();

  const { data, isLoading, error } = useQuery(
    ["getEpcProjectDetail", +id],
    () => getEpcProjectDetail(id)
  );
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!data) {
    return <div>No data fetched.</div>;
  }
  // console.log(data, "...")
  const handleDownloadLoanAgreement = () => {
    const image_url1 = data.data.loan_agreement;
    // console.log("image_url1", image_url1)
    saveAs(image_url1, "file");
  };

  const handleDownloadRooftopSolar = () => {
    const image_url1 = data.data.rooftop_solar_contract;
    saveAs(image_url1, "file");
  };
  return (
    <>
      <div className="content-body">
        <div className="page-title">
          <Link className="back-to-page" to="/epc-dashboard/projects">
            <i className="fa-solid fa-chevron-left"></i>
          </Link>
          <div className="dash-breadcrumb">
            <ul>
              <li>
                <Link to="/epc-dashboard/projects">Project</Link>
              </li>
              <li>Details</li>
            </ul>
          </div>
        </div>
        {/* <!-- /page title --> */}
        <div className="dash-card">
          <div className="dash-card-header border-0">
            <div className="tab-menu mb-0">
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    Details
                  </button>
                </li>
              </ul>
            </div>
          </div>
          {/* <!-- /card header --> */}
          <div className="dash-card-body">
            <div className="solfin-tabs">
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                  tabindex="0"
                >
                  <div
                    className="tab-box"
                    id="tab-1"
                    style={{ display: "block" }}
                  >
                    <form action="#" method="get">
                      <div className="solfin-application-details">
                        <ul>
                          <li>
                            <span className="detail-label">Project Name</span>
                            <span className="detail-value">
                              {data.data.project_name}
                            </span>
                          </li>
                          <li>
                            <span className="detail-label">City</span>
                            <span className="detail-value">
                              {data.data.city}
                            </span>
                          </li>
                          <li>
                            <span className="detail-label">State</span>
                            <span className="detail-value">
                              {data.data.state}
                            </span>
                          </li>
                          <li>
                            <span className="detail-label">Address</span>
                            <span className="detail-value">
                              {data.data.address1}
                            </span>
                          </li>
                          <li>
                            <span className="detail-label">Solution</span>
                            <span className="detail-value">
                              {data.data.solution}
                            </span>
                          </li>
                          <li>
                            <span className="detail-label">Capacity</span>
                            <span className="detail-value">
                              {data.data.capacity}
                            </span>
                          </li>

                          <li>
                            <span className="detail-label">Panel Type</span>
                            <span className="detail-value">
                              {data.data.type_of_panel}
                            </span>
                          </li>

                          <li>
                            <span className="detail-label">
                              Unit Generated per day
                            </span>
                            <span className="detail-value">
                              {data.data.estimated_unit_generated}
                            </span>
                          </li>
                          <li>
                            <span className="detail-label">DSA</span>
                            <span className="detail-value">
                              {data.data.dsa}
                            </span>
                          </li>
                          <li>
                            <span className="detail-label">EPC</span>
                            <span className="detail-value"></span>
                          </li>

                          <li>
                            <span className="detail-label">Project Cost</span>
                            <span className="detail-value">
                              {data.data.project_cost}
                            </span>
                          </li>

                          <li>
                            <span className="detail-label">
                              Financing Required
                            </span>
                            <span className="detail-value">
                              {data.is_financed === true ? "Yes" : "No"}
                            </span>
                          </li>
                          {/* <li>
                          <span className="detail-label">Finance Partner</span>
                          <span className="detail-value">
                            {data.data.finance_partner}
                          </span>
                        </li> */}

                          <li>
                            <span className="detail-label">Loan Agreement</span>
                            <span className="detail-value img-uploaded">
                              <a
                                href={data.data.loan_agreement}
                                target="_blank"
                                rel="noreferrer"
                                onClick={handleDownloadLoanAgreement}
                                className="text-dark"
                              >
                                <i className="fa-solid fa-download text-dark"></i>
                                
                                  Download <i className="ti ti-arrow-down"></i>
                               
                              </a>
                            </span>
                          </li>
                          <li>
                            <span className="detail-label">
                              Rooftop Solar Contract
                            </span>
                            <span className="detail-value img-uploaded">
                              <a
                                href={data.data.rooftop_solar_contract}
                                target="_blank"
                                rel="noreferrer"
                                onClick={handleDownloadRooftopSolar}
                                className="text-dark"
                              >
                               
                                <i className="fa-solid fa-download text-dark"></i>
                                Download <i className="ti ti-arrow-down"></i>
                              </a>
                            </span>
                          </li>
                          {/* <li>
                          <span className="detail-label">Installation Date</span>
                        <span className="detail-value">{data.data.installation_date}</span>
                        </li> */}
                        </ul>
                      </div>
                    </form>
                  </div>
                </div>
                {/* <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">
            <div className="tab-box" id="tab-2">
                  <div className="solfin-application-details">
               
                        <div className="form-group">
                          <div className="upload-file">
                            <label htmlFor="LoanSanctionLetter">
                              <input type="file" 
                              name="loanSanctionLetter" 
                              id="LoanSanctionLetter" 
                              onChange={handleSanctionLetter}
                              />
                              <div className="upload-file-upload">
                                <i className="fa fa-upload" aria-hidden="true"></i>
                                <span></span>
                              </div>
                            </label>
                            <div className="uploaded-file">
                              <a
                                href="images/test.pdf"
                                target="_blank"
                                alt="Quote"
                                title="Quote"
                              >
                                <img
                                  alt="Loan Sanction letter"
                                />
                                <span className="delete-file">
                                  <i className="fa fa-close" aria-hidden="true"></i>
                                </span>
                              </a>
                            </div>
                          </div>
                        </div> 

                       <div className="form-group">
                          <div className="upload-file">
                            <label htmlFor="LoanDisbursalLetter">
                              <input type="file" 
                              name="loanDisbursalLetter" 
                              id="LoanDisbursalLetter"
                              onChange={handleDisbursalLetter}
                              />
                              <div className="upload-file-upload">
                                <i className="fa fa-upload" aria-hidden="true"></i>
                                <span></span>
                              </div>
                            </label>
                            <div className="uploaded-file">
                              <a
                                href="images/test.pdf"
                                target="_blank"
                                alt="Quote"
                                title="Quote"
                              >
                                <img
                                  alt="Loan Disbursal Letter"
                                />
                                <span className="delete-file">
                                  <i className="fa fa-close" aria-hidden="true"></i>
                                </span>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="form-group w-100 mb-0">
                          <div className="submit-filter">
                            <button
                              type="submit"
                              className="btn btn-info"
                              value="Save"
                              onClick={handleSubmit}
                              disabled={
                                !isdisbursalLetterUploaded || !issanctionLetterUploaded
                              }
                            >
                              Save
                            </button>
  
                          </div>
                        </div>
                      </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /dash-card -->  */}
    </>
  );
};

export default ProjectDetails;
