import axios from "axios";
const apikey = process.env.REACT_APP_HOSTNAME;
const apikeyV2 = process.env.REACT_APP_HOSTNAME_v2;


//my-details
export const myDetails = async () => {
  try {
    const { data } = await axios.get(`${apikey}/manufacturer-dashboard/my-details`, {
      withCredentials: true,
    });
    // console.log(data);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};


//Logout
export const logout = async () => {
  try {
    const data = await axios.get(`${apikey}/user/logout`, {
      withCredentials: true,
    });
    return data;
  } catch (err) {
    // console.log(err.request.status);
    // return err.request.status;
    throw Error(err.response.data.message);
  }
};



export const getRequestedComfortLetters= async()=>{
  try {
    const data = await axios.get(`${apikeyV2}/manufacturer/channel-financing/comfort-letters/requested`, {
      withCredentials: true,
    });
    return data;
  } catch (err) {
  
    throw Error(err.response.data.message);
  }
} 

export const getComfortLettersDetails= async(letterCode)=>{
  try {
    const data = await axios.get(`${apikeyV2}/manufacturer/channel-financing/comfort-letters/${letterCode}`, {
      withCredentials: true,
    });
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
} 

export const generateComfortLetter = async (requestBody) => {
  try {
    const { data } = await axios.post(
      `${apikeyV2}/manufacturer/channel-financing/comfort-letters/generate`,
      requestBody ,
      {
        withCredentials: true,
      }
    );
    return data;
  } catch (err) {
    console.log(err);
    return err.response.data;
  }
};

