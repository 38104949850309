import React, { useEffect } from "react";
import { Multiselect } from "multiselect-react-dropdown";
import { useQuery } from "react-query";
import * as querydata from "../dataService/DataService";
import { useState } from "react";

const AreaServed = (props) => {
  const [areaServedState, setAreaServedState] = useState(-1);
  const [selectedOptions1, setSelectedOptions1] = useState(0);
  const [selectedOptions2, setSelectedOptions2] = useState([]);
  let count = 0;
  const [removeselectedOptions1, removeSelectedOptions1] = useState([]);
  const [allCities, setAllCities] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedOptionsCount, setSelectedOptionsCount] = useState(
    props.selectedOptions.length
  );
  const [selectedStates, setSelectedStates] = useState([]);
  const handleRemove = (selected, e) => {
    if (e.city === "Select All") {
      const updatedSelectedOptions = props.selectedOptions.filter(
        (dropdown) => dropdown.StateId !== e.state_id
      );
      props.setSelectedOptions(updatedSelectedOptions);
      
      const allCityIds = updatedSelectedOptions
        .map((item) => item.CitiesList)
        .flat();
      setSelectedOptions2(
        allCityIds.filter((option) => option.city !== "Select All")
      );
      setSelectedOptionsCount(allCityIds.length);
      // setSelectedOptions1(allCityIds.length);
      setSelectedOptions1([]);
    } else {
      const parentDataId = e.state_id;
      const updatedSelectedOptions = selected.filter(
        (option) => option.city !== "Select All" && option.city_id != e.city_id
      );
      setSelectedOptions1(updatedSelectedOptions);
      const newOptions = {
        StateId: parentDataId,
        CitiesList: updatedSelectedOptions,
      };
      const dropdowns = document.querySelectorAll(".areaservedstates");

      // Initialize an object to store selected stateids for each dropdown
      const selectedStatesIds = {};
      let previousdata = props.selectedOptions;
      props.setSelectedOptions([]);
      // Loop over the dropdowns and get the selected stateids
      dropdowns.forEach((dropdown) => {
        if (dropdown.value != parentDataId) {
          const selectedOption = dropdown; //.options[dropdown.selectedIndex];
          const filteredData = previousdata.filter(
            (item) => item.StateId == selectedOption.value
          );
          props.setSelectedOptions((prevcities) => [
            ...prevcities,
            ...filteredData,
          ]);
        }
      });


      if (updatedSelectedOptions.length > 0)
        props.setSelectedOptions((prevcities) => [...prevcities, newOptions]);
 
      setSelectAll(false);
      const allCityIds = updatedSelectedOptions
        .map((item) => item.CitiesList)
        .flat();
      setSelectedOptions2(updatedSelectedOptions);
      //props.setSelectedOptions(updatedSelectedOptions);
      setSelectAll(false);
      setSelectedOptionsCount(updatedSelectedOptions.length);
    
    }
  };
  
  const handleSelect = (selected, e) => {
    const parentDataId = e.state_id;
    if (e.city === "Select All") {
      const allCities = getAreaServedCities.data.data.filter(
        (city) => city.city_id !== "all"
      );
      const allCitiess = getAreaServedCities.data.data;
      setSelectedOptions1(allCities);
      const newOptions = {
        StateId: parentDataId,
        CitiesList: allCities,
      };
      const dropdowns = document.querySelectorAll(".areaservedstates");

      // Initialize an object to store selected stateids for each dropdown
      const selectedStatesIds = {};
      let previousdata = props.selectedOptions;
      props.setSelectedOptions([]);
      // Loop over the dropdowns and get the selected stateids
      dropdowns.forEach((dropdown) => {
        if (dropdown.value != parentDataId) {
          const selectedOption = dropdown; //.options[dropdown.selectedIndex];
          const filteredData = previousdata.filter(
            (item) => item.StateId == selectedOption.value
          );
          props.setSelectedOptions((prevcities) => [
            ...prevcities,
            ...filteredData,
          ]);
        }
      });
      
      console.log("dropdowns")
      console.log(dropdowns)
      props.setSelectedOptions((prevcities) => [...prevcities, newOptions]);
      setSelectedOptions2(allCitiess);
      setSelectAll(true);
      setSelectedOptionsCount(allCities.length);
      // }
    } else {
      const updatedSelectedOptions = selected.filter(
        (option) =>
          option.city !== "Select All" && option.state_id == e.state_id
      );
      setSelectedOptions1(updatedSelectedOptions);
      const newOptions = {
        StateId: parentDataId,
        CitiesList: updatedSelectedOptions,
      };
      const dropdowns = document.querySelectorAll(".areaservedstates");

      // Initialize an object to store selected stateids for each dropdown
      const selectedStatesIds = {};
      let previousdata = props.selectedOptions;
      props.setSelectedOptions([]);
      // Loop over the dropdowns and get the selected stateids
      dropdowns.forEach((dropdown) => {
        if (dropdown.value != parentDataId) {
          const selectedOption = dropdown; //.options[dropdown.selectedIndex];
          const filteredData = previousdata.filter(
            (item) => item.StateId == selectedOption.value
          );
          props.setSelectedOptions((prevcities) => [
            ...prevcities,
            ...filteredData,
          ]);
        }
      });

  
      props.setSelectedOptions((prevcities) => [...prevcities, newOptions]);
      // props.setSelectedOptions(newOptions);
      setSelectedOptions2(selected);
      //props.setSelectedOptions(updatedSelectedOptions);
      setSelectAll(false);
      setSelectedOptionsCount(updatedSelectedOptions.length);
    }
  };
  

  const areaServedStatesVariable1 = [];
  // useEffect(()=>{
  const handleStateChange = async (e) => {
    const selectedState = e.target.value;
    const currentDropdown = e.target;
    setAreaServedState(selectedState);
    if (props.onSelectedStateChange) {
      props.onSelectedStateChange(selectedState);
    }

    document.getElementById("matrixstateId").innerHTML = "";
    console.log("selectedState");
    console.log(selectedState);
    for (var option2 of document.querySelectorAll(".areaservedstates")) {
      for (var option1 of option2) {
        //var option1=option2
        if (option1.selected) {
          var option = document.createElement("option");
          option.text = option1.text;
          option.value = option1.value;
          var select = document.getElementById("matrixstateId");
          select.appendChild(option);
          props.areaServedStatesVariable.push(option.value);
          // useEffect(()=>{
          //   //setSelectedAreaServedStates('6,1');
          // },[])
          props.setSelectedAreaServedStates(props.areaServedStatesVariable);
        }
      }
    }

    setSelectedOptions1([]);
    setSelectedOptionsCount(0);
    if (selectedState !== "-1") {
      const cities = await querydata
        .getareaServedCities(selectedState)
        .then((cities) => {
          // Handle the cities data here
          const allCities = cities.data.filter(
            (city) => city.city_id !== "all"
          );
          props.error.areaServed = "";
          setSelectedOptions1(allCities);

          const newOptions = {
            StateId: selectedState,
            CitiesList: allCities,
          };

          // Select all dropdown elements with the class "areaservedstates"
          const dropdowns = document.querySelectorAll(".areaservedstates");

          // Initialize an object to store selected stateids for each dropdown
          const selectedStatesIds = {};
          let previousdata = props.selectedOptions;
          props.setSelectedOptions([]);
          // Loop over the dropdowns and get the selected stateids
          dropdowns.forEach((dropdown) => {
            if (dropdown !== currentDropdown) {
              const selectedOption = dropdown; //.options[dropdown.selectedIndex];
              const filteredData = previousdata.filter(
                (item) => item.StateId == selectedOption.value
              );
              props.setSelectedOptions((prevcities) => [
                ...prevcities,
                ...filteredData,
              ]);
            }
          });
          props.setSelectedOptions((prevcities) => [...prevcities, newOptions]);
          setSelectAll(true);
          setSelectedOptionsCount(allCities.length);
          setAllCities(cities.data);
        });
      const allCitiess = await querydata.getareaServedCities(selectedState);
      setSelectedOptions2(allCitiess.data);
    } else {
      setAllCities([]);
    }

  };
  console.log("areaServedState");
  console.log(areaServedState);
 
  const getAreaServedCities = useQuery(
    ["getAreaServedCities", +areaServedState],
    () => querydata.getareaServedCities(areaServedState)
  );

  // area served cities validations
  if (getAreaServedCities.isLoading) {
    return <div>Loading...</div>;
  }

  if (getAreaServedCities.error) {
    return <div>Error:getAreaServedCities.error</div>;
  }

  if (!getAreaServedCities.data) {
    return <div>No data fetched.</div>;
  }

  const Removeservedarea = (e) => {
  
    const removeButton = e.target;
    const parentDiv = removeButton.parentElement.parentElement;
    // Remove the parent div from the DOM
    parentDiv.remove();

    const element =
      parentDiv.firstElementChild.querySelector(".areaservedstates");

    if (element && element.value) {
      var stateIdToRemove = element.value;
      var stateToRemove = element.selectedOptions[0].textContent;

      const dropdowns = document.querySelectorAll(".areaservedstates");

      // Initialize an object to store selected stateids for each dropdown
      const selectedStatesIds = {};
      let previousdata = props.selectedOptions;
      props.setSelectedOptions([]);
      // Loop over the dropdowns and get the selected stateids
      dropdowns.forEach((dropdown) => {
        // if (dropdown !== currentDropdown) {
        const selectedOption = dropdown; //.options[dropdown.selectedIndex];
        const filteredData = previousdata.filter(
          (item) => item.StateId == selectedOption.value
        );
        props.setSelectedOptions((prevcities) => [
          ...prevcities,
          ...filteredData,
        ]);
        // }
      });

      const ratematrix = [];
      const ratematrixfiltered = [];

      ratematrix.push(...props.rateMatrixOld);

      const filter = ratematrix.filter(
        (data) => data.state_id !== stateIdToRemove
      );

      ratematrixfiltered.push(...filter);

      props.setRateMatrixOLD(ratematrixfiltered);

      const stateratematrix = [];
      const stateratematrixfiltered = [];
      stateratematrix.push(...props.statematrixarrayOLD);
      stateratematrixfiltered.push(
        ...stateratematrix.filter((data) => data !== stateToRemove)
      );
      props.setStatematrixArrayOLD(stateratematrixfiltered);

      const dropdown = document.getElementById("matrixstateId");

      // Get the index of the option to be removed (Option 3 in this case)
      const indexToRemove = Array.from(dropdown.options).findIndex(
        (option) => option.value === stateIdToRemove
      );

      // Check if the option was found and remove it
      if (indexToRemove !== -1) {
        dropdown.remove(indexToRemove);
      }

      const index = props.selectedStatesArray.indexOf(stateIdToRemove);
      props.selectedStatesArray.splice(index, 1);
    }
  };
  
 
  return (
    <>
      <div className="row position-relative">
        <div className="col-md-6">
          <div className="form-group mb-4">
            <label
              htmlFor="States"
              className="border rounded-4 w-100 position-relative"
            >
              <span className="fw-bold" id="areaServedSpan">Select State(s)</span>
              <select
                id="States"
                onChange={handleStateChange}
                className="form-control border-0 ps-0 bg-transparent pt-1 pb-1 areaservedstates"
                value={areaServedState}
                defaultValue="Select State"
                disabled={props.disabled}
              >
                <option value="-1" defaultValue="-1">
                  Select State
                </option>
                {props.getAllStatesAreaserved &&
                  props.getAllStatesAreaserved.map((stateoption) => {
                    return (
                      <option
                        key={stateoption.state_id}
                        value={stateoption.state_id}
                      >
                        {stateoption.state}
                      </option>
                    );
                  })}
              </select>
            </label>
          </div>
        </div>
        <div className="col-md-5">
          <div className="city-multiselect">
            <div className="city-multiselect-input">
              <label
                data-id={areaServedState}
                htmlFor="States"
                className="border rounded-4 w-100 position-relative"
              >
                <span className="fw-bold">Select Region(s)</span>
                <br />
                <div className="selected-options-count"></div>

                <Multiselect
                  className="form-control border-0 ps-0 bg-transparent pt-1 pb-1 areaservedmultiselect"
                  options={getAreaServedCities.data.data}
                  name="particulars"
                  hideSelectedList="true"
                  displayValue="city"
                  closeIcon="cancel"
                  placeholder="Search"
                  showCheckbox
                  disablePreSelectedValues={false}
                  selectedValues={selectedOptions2}
                  onSelect={handleSelect}
                  onRemove={handleRemove}
                  selectAll={!selectAll ? false : true}
                  disabled={props.disabled}
                ></Multiselect>

                {selectedOptions1.length > 0 && (
                  <div className="clear-all">
                    <>
                      <span>
                        {selectedOptions1.length}{" "}
                        {selectedOptions1.length === 0 ||
                        selectedOptions1.length === 1
                          ? "City"
                          : "Cities"}{" "}
                        selected
                      </span>
                      <button
                        className="clear-selected"
                        onClick={() => {
                          setSelectedOptions1([]);
                          // props.setSelectedOptions([]);
                          setSelectAll(false);
                        }}
                      >
                        <i className="fa-solid fa-xmark"></i>
                      </button>
                    </>
                  </div>
                )}
                {/* {selectedOptions1 > 0 && (
                  <div className="clear-all">
                    <>
                      <span>
                      {selectedOptions1}{" "}
                        {selectedOptions1.length === 0 ||
                        selectedOptions1.length === 1
                          ? "City"
                          : "Cities"}{" "}
                        selected
                      </span>
                      <button
                        className="clear-selected"
                        onClick={() => {
                          setSelectedOptions1([]);
                          setSelectAll(false);
                        }}
                      >
                        <i className="fa-solid fa-xmark"></i>
                      </button>
                    </>
                  </div>
                )} */}
              </label>
            </div>
          </div>
        </div>
        <div className="col-md-1">
          <a
            type="button"
            onClick={Removeservedarea}
            className="btn btn-outline-danger"
          >
            -{" "}
          </a>
        </div>

      {selectedOptions1.length === 0 || selectedOptions1 === 0? (<p className="text-danger error-message position-absolute start-0">Please select your Served Area and Regions</p>) : ("")}
      </div>
    </>
  );
};

export default AreaServed;
