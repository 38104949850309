import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import * as querydata from "../../dataService/DataService";

export const resetPasswordSchema = Yup.object({
  password: Yup.string()
    .required("Please enter a password")
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}|:"<>?~`[\]\\';,./]).{8,}$/,
      "Password must contain at least one lowercase letter, one uppercase letter, one number, and one special character, and be at least 8 characters long"
    ),
  cPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Please confirm your password"),
});

const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const toggleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };
  const toggleShowCPassword = (e) => {
    e.preventDefault();
    setShowCPassword(!showCPassword);
  };
  const { token } = useParams();
  console.log(token);
  const navigate = useNavigate();
  // Values defined for Formik
  const initialValues = {
    password: "",
    cPassword: "",
  };

  const { values, handleBlur, handleChange, handleSubmit, errors, touched } =
    useFormik({
      initialValues: initialValues,
      validationSchema: resetPasswordSchema,
      onSubmit: (values, action) => {
        console.log(values);
        resetPasswordMutation.mutate({ ...values, token: token });
        action.resetForm();
      },
    });

  // Form post
  const resetPasswordMutation = useMutation(querydata.resetPassword, {
    onError: (error) => {
      toast.error(error.message);
    },
    onSuccess: (data) => {
      toast.success("New password has been created successfully");
      navigate("/login");
    },
  });
  console.log(values);
  return (
    <>
      <section>
        <div class="solfin-login pb-5">
          <div class="container-md">
            <div class="row">
              <div class="col-12">
                <form onSubmit={handleSubmit}>
                  <div class="login-form forgot-password m-auto mt-4">
                    <div class="solfin-logo mb-4">
                      <img
                        src="/images/solfin-logo.svg"
                        alt="Solfin"
                        title="Solfin"
                      />
                    </div>
                    <h5 class="fw-bold">Reset Password</h5>
                    <p>
                      Create new password for your{" "}
                      <strong>Solfin Account</strong>.
                    </p>
                    <div class="form-fields">
                      <div class="mb-4 position-relative">
                        <label for="NewPassword">New Password</label>
                        <input
                          type={showPassword ? "text" : "password"}
                          name="password"
                          value={values.password}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          required
                          id="NewPassword"
                          class="form-control"
                        />
                        <span
                          className="btn show-hide-btn p-0 position-absolute"
                          onClick={toggleShowPassword}
                        >
                          {showPassword ? (
                            <i className="fa fa-eye" id="togglePassword"></i>
                          ) : (
                            <i
                              className="fa fa-eye-slash"
                              id="togglePassword"
                            ></i>
                          )}
                        </span>
                        {errors.password && touched.password && (
                          <div className="text-danger">{errors.password}</div>
                        )}
                      </div>

                      {/* <!-- /mb-4 --> */}
                      <div class="mb-4 position-relative">
                        <label for="ConfirmPassword">Confirm Password</label>
                        <input
                          type={showCPassword ? "text" : "password"}
                          name="cPassword"
                          value={values.cPassword}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          required
                          id="ConfirmPassword"
                          class="form-control"
                        />
                        <span
                          className="btn show-hide-btn p-0 position-absolute"
                          onClick={toggleShowCPassword}
                        >
                          {showCPassword ? (
                            <i className="fa fa-eye" id="togglePassword"></i>
                          ) : (
                            <i
                              className="fa fa-eye-slash"
                              id="togglePassword"
                            ></i>
                          )}
                        </span>
                        {errors.cPassword && touched.cPassword && (
                          <div className="text-danger">{errors.cPassword}</div>
                        )}
                      </div>

                      {/* <!-- /mb-4 --> */}
                      <div class="mb-4 pt-xl-4 pt-3">
                        <button
                          type="submit"
                          class="btn btn-primary btn-header rounded-3 w-100 text-nowrap text-uppercase green-btn calculate-btn"
                        >
                          Reset
                        </button>
                      </div>
                      {/* <!-- /mb-4 --> */}
                    </div>
                    {/* <!-- /form-fields --> */}
                  </div>
                </form>
              </div>
            </div>
            {/* <!-- /row --> */}
          </div>
          {/* <!-- /container --> */}
        </div>
        {/* <!-- /my-products --> */}
      </section>
    </>
  );
};

export default ResetPassword;
