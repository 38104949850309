import React from 'react'
import { useQuery } from 'react-query';
import * as querydata from "../dataService/DataService";

const RegistrationTypeDropDown = (props) => {
    const getEpcRegistrationType = useQuery(
        "RegistrationTypeId",
        querydata.getEpcRegistrationTypeNew
       )
if(getEpcRegistrationType.data){
    // console.log(getEpcRegistrationType.data)
}
  return (
    <>
    <select
        id="Documenttype"
        className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
        name="registrationTypeId"
        value={props.values && props.values.registrationTypeId}
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        disabled={props.disabled}

      >
          <option value="-1" defaultValue="Select">
            ---Select---
          </option>
        {getEpcRegistrationType.data &&
          getEpcRegistrationType.data.data.map((documentoption) => {
            return (
              <option
                key={documentoption.option_id}
                value={documentoption.option_id}>
                {documentoption.option}
              </option>
            );
          })}
      </select>
    </>
  )
}

export default RegistrationTypeDropDown