import axios from "axios";
const apikey = process.env.REACT_APP_HOSTNAME;

//my-details
export const myDetails = async () => {
  try {
    const { data } = await axios.get(`${apikey}/dsa-dashboard/my-details`, {
      withCredentials: true,
    });
    // console.log(data);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getAllLeads = async () => {
  try {
    const { data } = await axios.get(`${apikey}/dsa-dashboard/get-all-leads`, {
      withCredentials: true,
    });
    // console.log(data);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getSingleApplication = async (id) => {
    try {

      const { data } = await axios.get(`${apikey}/dsa-dashboard/get-single-application/${id}`, {
        withCredentials: true,
      });
      // console.log(data);
      return data;
    } catch (err) {
      throw Error(err.response.data.message);
    }
  };

export const getProjects = async () => {
    try{
        const { data } = await axios.get(
            `${apikey}/dsa-dashboard/get-all-dsa-projects`,
            {
              withCredentials: true,
            }
          );
          return data;
    }
    catch (err){
        throw Error(err.response.data.message);
    }
}
export const getMyRegisterLink = async () => {
  try {
    const { data } = await axios.get(
      `${apikey}/dsa-dashboard/my-register-link`,
      {
        withCredentials: true,
      }
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//Logout
export const logout = async () => {
  try {
    const data = await axios.get(`${apikey}/user/logout`, {
      withCredentials: true,
    });
    return data;
  } catch (err) {
    // console.log(err.request.status);
    // return err.request.status;
    throw Error(err.response.data.message);
  }
};
