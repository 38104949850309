import React, { useEffect, useState } from "react";
import { NavLink, useLocation, props, Link } from "react-router-dom";

const Header = () => {
  // const getNavLinkClass = (path) => {
  //   console.log(location.pathname);
  //   return this.props.location.pathname === path ? 'active' : '';
  // }

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [applyloanUrl, setApplyLoanUrl] = useState(''); 
  
  useEffect(() => {
    const currentDomain = window.location.hostname;
    if (currentDomain.includes("stg") || currentDomain.includes("localhost")){
      setApplyLoanUrl("https://stg.finance.solfin.co.in");
    } else {
      setApplyLoanUrl("https://finance.solfin.co.in");
    }
  }, []);
  const location = {
    Registerpathname: "/Register",
    Homepathname: "/",
    state: { fromDashboard: true },
  };
  const [isLoanProductsOpen, setIsLoanProductsOpen] = useState(false);
  const toggleApplyForLoanMenu = () => {
    const element = document.getElementById("apply-loan-sub-menu");
    element.style.display =
      element.style.display === "none" || !element.style.display
        ? "block"
        : "none";
  };

  const toggleLoanProductMenu = () => {
    const element = document.getElementById("loan-prod-sub-menu");
    element.style.display =
      element.style.display === "none" || !element.style.display
        ? "block"
        : "none";
  };

  const hideLoanProducts = () => {
    document.getElementById("loan-prod-sub-menu").style.display = "none";
  };

  return (
    <>
      <header
        className="smooth position-absolute start-0 top-0 w-100 pt-md-3 pt-2 mt-1"
        onClick={hideLoanProducts}
      >
        <div className="container custom-container">
          <div className="header-wrap rounded-20">
            <div className="top-header text-white bg-black pt-1 pb-1">
              <div className="row mt-2 mb-1">
                <div className="col-md-12 text-end">
                  {/* <span className="text-theme-green fw-semibold text-uppercase">
                    EMAIL/
                  </span>{" "} */}
                  <a
                    className="text-decoration-none text-theme-green fw-bold"
                    href="mailto:support@solfin.co.in"
                  >
                    support@solfin.co.in
                  </a>
                  <span className="devider ms-5 me-5">|</span>
                  {/* <span className="text-theme-green fw-semibold text-uppercase">
                    PHONE/
                  </span>{" "} */}
                  <a
                    className="text-decoration-none text-theme-green fw-bold"
                    href="tel:+919599446877"
                  >
                    +91 9599446877
                  </a>
                </div>
                {/* <!-- /col-md-6 --> */}
              </div>
              {/* <!-- /row --> */}
            </div>
            {/* <!-- /top-header --> */}
            <div className="mein-header">
              <div className="row align-items-lg-center justify-content-between">
                <div className="col">
                  <div className="solfin-logo">
                    <Link to="/">
                      <img
                        src="/images/solfin-logo.svg"
                        alt="Solfin"
                        title="Solfin"
                      />
                    </Link>
                  </div>
                  {/* <!-- /solfin-logo --> */}
                  <div className="touch-in-solfin d-xl-none d-flex align-items-center">
                    <Link
                      to="/login"
                      onClick={() => setIsMenuOpen(false)}
                      className="btn btn-primary btn-header rounded-5 text-nowrap"
                    >
                      Login
                    </Link>
                  </div>
                  <button
                    className={`navbar-toggler d-xl-none d-xl-block`}
                    type="button"
                    // data-bs-toggle="collapse"
                    // data-bs-target="#navbarSupportedContent"
                    // aria-controls="navbarSupportedContent"
                    // aria-expanded="false"
                    // aria-label="Toggle navigation"
                    onClick={() => setIsMenuOpen((prevState) => !prevState)}
                    aria-controls="navbarSupportedContent"
                    aria-expanded={isMenuOpen ? "true" : "false"}
                    aria-label="Toggle navigation"
                  >
                    <span className="fa fa-bars"></span>
                  </button>
                </div>
                {/* <!-- /col --> */}
                <div className="col">
                  <nav className="navbar navbar-expand-xl p-0" id="solfin-nav">
                    <div className="container-fluid">
                      <div
                        className={`collapse navbar-collapse ${
                          isMenuOpen ? "show" : ""
                        }`}
                        id="navbarSupportedContent"
                      >
                        <ul className="navbar-nav me-auto mb-2 mb-xl-0">
                          <li className="nav-item">
                            <NavLink
                              className="nav-link"
                              to="/"
                              onClick={() => {
                                setIsMenuOpen(false);
                              }}
                            >
                              Home
                            </NavLink>
                          </li>
                          <li className="nav-item dropdown">
                            <a
                              className="nav-link dropdown-toggle"
                              href="#"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              I Want Solar
                            </a>
                            <ul className="dropdown-menu">
                              <li>
                                <NavLink
                                  to="/estimator"
                                  className="dropdown-item"
                                  onClick={() => setIsMenuOpen(false)}
                                >
                                  My Savings
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to="/register"
                                  className="dropdown-item"
                                  onClick={() => setIsMenuOpen(false)}
                                >
                                  Register
                                </NavLink>
                              </li>
                            </ul>
                          </li>
                          <li className="nav-item dropdown">
                            <a
                              className="nav-link dropdown-toggle"
                              href="#"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Finance
                            </a>
                            <ul className="dropdown-menu">
                              <li>
                                <a
                                  href="#"//{applyloanUrl+ "/onboard/#/home"}
                                  className="dropdown-item"
                                  
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    toggleApplyForLoanMenu();
                                  }}
                                >
                                  Apply for Loan
                                </a>
                                <ul
                                  className="loan-prod-menu dropdown-menu"
                                  id="apply-loan-sub-menu"
                                >
                                  <li>
                                    <a
                                      href={applyloanUrl+ "/onboard/#/home"}
                                      className="dropdown-item"
                                    >
                                      Residential
                                    </a>
                                  </li>
                                 
                                  <li>
                                    <a
                                      href="/contact-sales"
                                      className="dropdown-item"
                                    >
                                      Commercial and Industrial
                                    </a>
                                  </li>
                                 
                                </ul>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    toggleLoanProductMenu();
                                  }}
                                >
                                  Loan Products
                                </a>

                                <ul
                                  className="loan-prod-menu dropdown-menu"
                                  id="loan-prod-sub-menu"
                                >
                                  <li>
                                    <a
                                      href="/loan-products/residential"
                                      className="dropdown-item"
                                    >
                                      Residential Rooftop
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="/coming-soon"
                                      className="dropdown-item"
                                    >
                                      Housing Society Rooftop
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="/coming-soon"
                                      className="dropdown-item"
                                    >
                                      Commercial and Industrial
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="/coming-soon"
                                      className="dropdown-item"
                                    >
                                      EV Loan
                                    </a>
                                  </li>
                                </ul>
                              </li>

                              <li>
                                <NavLink
                                  to="/lease-contact-sales"
                                  className="dropdown-item"
                                  onClick={() => setIsMenuOpen(false)}
                                >
                                  Lease
                                </NavLink>
                              </li>
                              {/* <li>
                                <NavLink to="/coming-soon"
                                  className="dropdown-item"
                                  onClick={() => setIsMenuOpen(false)}
                                >
                                  EV Loan
                                </NavLink>
                              </li> */}
                            </ul>
                          </li>
                          <li className="nav-item dropdown">
                            <a
                              className="nav-link dropdown-toggle"
                              href="#"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Partners
                            </a>
                            <ul className="dropdown-menu">
                              <li>
                                <NavLink
                                  className="dropdown-item"
                                  to="/premiumPartners"
                                  onClick={() => setIsMenuOpen(false)}
                                >
                                  Premium Partners
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  className="dropdown-item"
                                  to="/epc"
                                  onClick={() => setIsMenuOpen(false)}
                                >
                                  EPC Partners
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  className="dropdown-item"
                                  to="/dsa"
                                  onClick={() => setIsMenuOpen(false)}
                                >
                                  DSA Partners
                                </NavLink>
                              </li>
                            </ul>
                          </li>
                          <li className="nav-item">
                            <NavLink
                              to="/mission"
                              className="nav-link"
                              onClick={() => setIsMenuOpen(false)}
                            >
                              Mission
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </nav>
                  {/* <!-- /nav --> */}
                </div>
                {/* <!-- /col --> */}
                <div className="col d-none d-xl-block right-col">
                  <div className="right-header d-flex align-items-center justify-content-end">
                    <div className="touch-in-solfin ms-xxl-5 ms-3">
                      <Link
                        to="/login"
                        className="btn btn-primary btn-header rounded-5 text-nowrap"
                      >
                        Login
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
