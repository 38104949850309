import React, { useState, useRef } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";

const NewDistributorLeadModal = ({ newLeadHandler }) => {
 const [errors , setErrors] = useState({});
  const formRef = useRef(null);
  const handleSubmit = (e)=>{
    e.preventDefault();
    const requestBody = {};
    const currformData = new FormData(formRef.current);
      for (let [key, value] of currformData.entries()) {
        requestBody[key] = value;
      }
      console.log(requestBody);
      if (!requestBody.email){
        toast.error("Please enter email address");
        return;
      }
      
    if (!requestBody.name){
      toast.error("Please enter company name");
      return;
    }
    if (!requestBody.phone){
      toast.error("Please enter company phone");
      return;
    }
    if (!requestBody.pocName){
      toast.error( "Please enter POC name");
      return;
    }
    newLeadHandler(requestBody);
  }
  const changeHandler =(e, id)=>{
    errors[id] = null;
  }
  return (
    <>
      <div
        className="modal fade"
        id={"new-distributor-lead"}
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                {"Create New distributor lead "}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body" >
            <form ref={formRef}>
                    <div className="form-wrap send-email-form">
                    <div className="form-group">
                        <label htmlFor={"name"}>Name</label>
                        <input
                          type="text"
                          id={"name"}
                          placeholder="Name"
                          // value={quoteData.project_name === null ? values.projectName : values.projectName=quoteData.project_name }
                          name="name"
                          className="solfin-input"
                        />
                        {errors.name ? (
                          <p className="form-error text-danger">
                            {errors.name}
                          </p>
                        ) : null}
                      </div>
                      <div className="form-group">
                        <label htmlFor={"email"}>Email</label>
                        <input
                          type="text"
                          id={"email"}
                          placeholder="Email"
                          // value={quoteData.project_name === null ? values.projectName : values.projectName=quoteData.project_name }
                          name="email"
                          className="solfin-input"
                          onChange={(e)=>{changeHandler(e)}}
                        />
                        {errors.email ? (
                          <p className="form-error text-danger">
                            {errors.email}
                          </p>
                        ) : null}
                      </div>
                      <div className="form-group">
                        <label htmlFor={"phone"}>Phone</label>
                        <input
                          type="text"
                          id={"phone"}
                          placeholder="phone"
                          // value={quoteData.project_name === null ? values.projectName : values.projectName=quoteData.project_name }
                          name="phone"
                          className="solfin-input"
                        />
                        {errors.phone ? (
                          <p className="form-error text-danger">
                            {errors.phone}
                          </p>
                        ) : null}
                      </div>
                      <div className="form-group">
                        <label htmlFor={"POC Name"}>POC Name</label>
                        <input
                          type="text"
                          id={"pocName"}
                          placeholder="POC Name"
                          // value={quoteData.project_name === null ? values.projectName : values.projectName=quoteData.project_name }
                          name="pocName"
                          className="solfin-input"
                        />
                        {errors.pocName ? (
                          <p className="form-error text-danger">
                            {errors.pocName}
                          </p>
                        ) : null}
                      </div>
                                           
                    </div>
                  </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-info filter-btn"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-info"
                data-bs-dismiss="modal"
                onClick={(e) => handleSubmit(e)}
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewDistributorLeadModal;
