import React, { useEffect, useState, useRef } from "react";
const SignatoryDocuments = ({values, signatoryNotes,signatoryFiles,signatories,setSignatories, error, registrationTypeId, expectedLimit, validateAllSignatoryDetails} )=>{  
  const [file, setfile] = useState("");
  const [canAddMoreSignatory, setCanAddMoreSignatory] = useState(false);
    const removeSignatory = (e, signatoryNumber) => {
        signatoryFiles.splice(signatoryNumber, 1);
        setSignatories(signatories - 1);
      };

      const handleNameChange = (e, signatoryIndex) => {
        if (signatoryFiles[signatoryIndex]) {
          signatoryFiles[signatoryIndex].name = e.target.value;
        } else {
          signatoryFiles.push({ name: e.target.value });
        }
        handleSignatoryFileChange();
      };
      const handleSignatoryEmailChange = (e, signatoryIndex) => {
        if (signatoryFiles[signatoryIndex]) {
          signatoryFiles[signatoryIndex].email = e.target.value;
        } else {
          signatoryFiles.push({ email: e.target.value });
        }
        handleSignatoryFileChange();
      };

      const handleSignatoryShareHoldingChange = (e, signatoryIndex) => {
        if (signatoryFiles[signatoryIndex]) {
          signatoryFiles[signatoryIndex].shareHolding = Number(e.target.value);
        } else {
          signatoryFiles.push({ shareHolding: Number(e.target.value) });
        }
        handleSignatoryFileChange();
      };
      const handleSignatoryPhoneChange = (e, signatoryIndex) => {
        if (signatoryFiles[signatoryIndex]) {
          signatoryFiles[signatoryIndex].phone = e.target.value;
        } else {
          signatoryFiles.push({ phone: e.target.value });
        }
    
        handleSignatoryFileChange();
      };

      const handleSignatoryPanchange = (e, signatoryIndex) => {
        const selectedFile = e.target.files[0];
        const allowedFormats = ["pdf", "jpg", "jpeg", "png"]; // Allowed file formats
        const maxFileSizeInBytes = 2 * 1024 * 1024; // 2 MB
        if (selectedFile) {
          const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
          const fileSize = selectedFile.size;
    
          // Check if the file format is allowed
          if (!allowedFormats.includes(fileExtension)) {
            setfile(null);
            document.getElementById("signatoryPAN_" + signatoryIndex).value = "";
            signatoryFiles[signatoryIndex].pan = "";
            error["signatoryPAN_" + signatoryIndex] =
              "Invalid file format. Allowed formats: " + allowedFormats.join(", ");
            return;
          }
    
          // Check if the file size exceeds the limit
          if (fileSize > maxFileSizeInBytes) {
            setfile(null);
            signatoryFiles[signatoryIndex].pan = "";
            document.getElementById("signatoryPAN_" + signatoryIndex).value = "";
            error["signatoryPAN_" + signatoryIndex] =
              "File size exceeds the limit of 2 MB.";
            return;
          }
    
          // The file format and size are allowed, so set it in the state
          setfile(selectedFile);
          signatoryFiles[signatoryIndex].pan = selectedFile.name;
          document.getElementById("signatoryPAN_" + signatoryIndex).value =
            selectedFile.name;
          handleSignatoryFileChange();
          error["signatoryPAN_" + signatoryIndex] = "";
        } else {
          setfile(null);
          signatoryFiles[signatoryIndex].pan = "";
          document.getElementById("signatoryPAN_" + signatoryIndex).value = "";
          document.getElementById("signatoryPAN_" + signatoryIndex).focus();
          error["signatoryPAN_" + signatoryIndex] = "Please Upload Document";
        }
      };


      const handleSignatoryNetworthchange = (e, signatoryIndex) => {
        const selectedFile = e.target.files[0];
        const allowedFormats = ["pdf", "jpg", "jpeg", "png"]; // Allowed file formats
        const maxFileSizeInBytes = 2 * 1024 * 1024; // 2 MB
        if (selectedFile) {
          const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
          const fileSize = selectedFile.size;
    
          // Check if the file format is allowed
          if (!allowedFormats.includes(fileExtension)) {
            setfile(null);
            document.getElementById("signatoryNETWORTH_" + signatoryIndex).value = "";
            signatoryFiles[signatoryIndex].networth = "";
            error["signatoryNETWORTH_" + signatoryIndex] =
              "Invalid file format. Allowed formats: " + allowedFormats.join(", ");
            return;
          }
    
          // Check if the file size exceeds the limit
          if (fileSize > maxFileSizeInBytes) {
            setfile(null);
            signatoryFiles[signatoryIndex].pan = "";
            document.getElementById("signatoryNETWORTH_" + signatoryIndex).value = "";
            error["signatoryNETWORTH_" + signatoryIndex] =
              "File size exceeds the limit of 2 MB.";
            return;
          }
    
          // The file format and size are allowed, so set it in the state
          setfile(selectedFile);
          signatoryFiles[signatoryIndex].networth = selectedFile.name;
          document.getElementById("signatoryNETWORTH_" + signatoryIndex).value =
            selectedFile.name;
          handleSignatoryFileChange();
          error["signatoryNETWORTH_" + signatoryIndex] = "";
        } else {
          setfile(null);
          signatoryFiles[signatoryIndex].networth = "";
          document.getElementById("signatoryNETWORTH_" + signatoryIndex).value = "";
          document.getElementById("signatoryNETWORTH_" + signatoryIndex).focus();
          error["signatoryNETWORTH_" + signatoryIndex] = "Please Upload Document";
        }
      };
    
      const addMoreSignatory = (e) => {
        if (
          signatoryFiles[signatories - 1].name &&
          signatoryFiles[signatories - 1].email 
        ) {
          signatoryFiles.push({});
          setSignatories(signatories + 1);
        } else {
          validateAllSignatoryDetails();
          return;
        }
      };
      const handleSignatoryAadhaarchange = (e, signatoryIndex) => {
        const selectedFile = e.target.files[0];
        const allowedFormats = ["pdf", "jpg", "jpeg", "png"]; // Allowed file formats
        const maxFileSizeInBytes = 2 * 1024 * 1024; // 2 MB
        if (selectedFile) {
          const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
          const fileSize = selectedFile.size;
    
          // Check if the file format is allowed
          if (!allowedFormats.includes(fileExtension)) {
            setfile(null);
            document.getElementById("signatoryAADHAAR_" + signatoryIndex).value =
              "";
            error["signatoryAADHAAR_" + signatoryIndex] =
              "Invalid file format. Allowed formats: " + allowedFormats.join(", ");
            signatoryFiles[signatoryIndex].aadhaar = "";
            return;
          }
    
          // Check if the file size exceeds the limit
          if (fileSize > maxFileSizeInBytes) {
            setfile(null);
            document.getElementById("signatoryAADHAAR_" + signatoryIndex).value =
              "";
            signatoryFiles[signatoryIndex].aadhaar = "";
            error["signatoryAADHAAR_" + signatoryIndex] =
              "File size exceeds the limit of 2 MB.";
            return;
          }
    
          // The file format and size are allowed, so set it in the state
          setfile(selectedFile);
          signatoryFiles[signatoryIndex].aadhaar = selectedFile.name;
          document.getElementById("signatoryAADHAAR_" + signatoryIndex).value =
            selectedFile.name;
          error["signatoryAADHAAR_" + signatoryIndex] = "";
          handleSignatoryFileChange();
        } else {
          setfile(null);
          signatoryFiles[signatoryIndex].aadhaar = "";
          document.getElementById("signatoryAADHAAR_" + signatoryIndex).value = "";
          error["signatoryAADHAAR_" + signatoryIndex] = "Please Upload Document";
        }
      };

      const handleSignatoryFileChange = () => {
        var isAllDetails = true;
        signatoryFiles.forEach((file) => {
          if (
            !file.name ||
            
            !file.email 
          ) {
            isAllDetails = false;
          }
        });
        setCanAddMoreSignatory(isAllDetails);
      };
    return(
        <>
        <div className="mt-3 mb-3">
          <span>Notes:</span>
          <div>Solfin, its NBFC partner- Dhanvikas Fiscal Services Private Limited and its third party service providers, will use the email id and mobile number given on this screen for all future communications with the co-applicant including but not limited to Video KYC.</div>
          {signatoryNotes.map((note, i) => (
            <div key={i}>{note}</div>
          ))}
        </div>
        {Array.from({ length: signatories }).map(
          (_, i) => (
            <>
              <div id={"signatory-docs-" + i}>
                <div className="Row">
                  <div className="col-md-5 mb-3">
                    <span className="fw-semibold saving-title">
                      {i == 0 &&
                      (registrationTypeId ==
                        "4" || expectedLimit >= 20) 
                        ? "Co-applicant Documents"
                        : "Signatory Documents"}
                    </span>
                    {i == 0 &&
                    (registrationTypeId ==
                      "4" || expectedLimit >= 20)  ? (
                      <input
                        id={
                          "signatoryCoapplicant" + i
                        }
                        className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                        value={true}
                        name={
                          "signatoryCoapplicant" + i
                        }
                        hidden
                      />
                    ) : (
                      <input
                        id={
                          "signatoryCoapplicant" + i
                        }
                        className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                        value={false}
                        name={
                          "signatoryCoapplicant" + i
                        }
                        hidden
                      />
                    )}

                    {(i > 0 &&
                      registrationTypeId !=
                        "4") ||
                    (i > 1 &&
                      registrationTypeId ==
                        "4") ? (
                      <a
                        type="button"
                        onClick={(e) => {
                          removeSignatory(e, i);
                        }}
                        className="btn btn-outline-danger remove-signatory"
                      >
                        {"-"}
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group mb-4">
                      <label
                        htmlFor={"signatoryName" + i}
                        className="border rounded-4 w-100 position-relative"
                      >
                        <span className="fw-bold">
                         {i == 0 &&
                    (registrationTypeId ==
                      "4" || expectedLimit >= 20) ? "Co-applicant Name":"Signatory Name"}
                        </span>
                        <input
                          id={"signatoryName" + i}
                          className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                          placeholder={i == 0 &&
                            (registrationTypeId ==
                              "4" || expectedLimit >= 20) ? "Co-applicant Name":"Signatory Name"}
                          name={"signatoryName" + i}
                          onChange={(e) => {
                            handleNameChange(e, i);
                          }}
                          
                        />
                      </label>
                      {/* {errors.address && touched.address ? (
<p className="form-error text-danger">
{errors.address}
</p>
) : null} */}
                      {error["signatoryName_" + i] ? (
                        <p className="form-error text-danger">
                          {
                            error[
                              "signatoryName_" + i
                            ]
                          }
                        </p>
                      ) : null}
                      {/* {error.address ? (
<p className="form-error text-danger">
{error.address
</p>
) : null} */}
                    </div>
                    {/* <!-- /form-group --> */}
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group mb-4">
                      <label
                        htmlFor={"signatoryEmail" + i}
                        className="border rounded-4 w-100 position-relative"
                      >
                        <span className="fw-bold">
                        {i == 0 &&
                    (registrationTypeId ==
                      "4" || expectedLimit >= 20) ? "Co-applicant ":"Signatory "} Email
                        </span>
                        <input
                          id={"signatoryEmail" + i}
                          className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                          placeholder="Signatory Email"
                          name={"signatoryEmail" + i}
                          onChange={(e) => {
                            handleSignatoryEmailChange(
                              e,
                              i
                            );
                          }}
                          
                        />
                      </label>
                      {/* {errors.address && touched.address ? (
<p className="form-error text-danger">
{errors.address}
</p>
) : null} */}
                      {error[
                        "signatoryEmail_" + i
                      ] ? (
                        <p className="form-error text-danger">
                          {
                            error[
                              "signatoryEmail_" + i
                            ]
                          }
                        </p>
                      ) : null}
                      {/* {error.address ? (
<p className="form-error text-danger">
{error.address
</p>
) : null} */}
                    </div>
                    {/** phone number */}
                    {/* <!-- /form-group --> */}
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group mb-4">
                      <label
                        htmlFor={"signatoryShareHolding" + i}
                        className="border rounded-4 w-100 position-relative"
                      >
                        <span className="fw-bold">
                        {i == 0 &&
                    (registrationTypeId ==
                      "4" || expectedLimit >= 20) ? "Co-applicant ":"Signatory "} Share Holding Percentage
                        </span>
                        <input
                          type="number"
                          min="0"
                          max="100"
                          id={"signatoryShareHolding" + i}
                          className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                          placeholder="Signatory ShareHolding"
                          name={"signatoryShareHolding" + i}
                          onChange={(e) => {
                            handleSignatoryShareHoldingChange(
                              e,
                              i
                            );
                          }}
                          
                        />
                      </label>
                      {/* {errors.address && touched.address ? (
<p className="form-error text-danger">
{errors.address}
</p>
) : null} */}
                      {error[
                        "signatorySHAREHOLDING_" + i
                      ] ? (
                        <p className="form-error text-danger">
                          {
                            error[
                              "signatoryShareHolding_" + i
                            ]
                          }
                        </p>
                      ) : null}
                      {/* {error.address ? (
<p className="form-error text-danger">
{error.address
</p>
) : null} */}
                    </div>
                    {/** phone number */}
                    {/* <!-- /form-group --> */}
                  </div>
                  {/* <div className="col-lg-12">
                    <div className="form-group mb-4">
                      <label
                        htmlFor={"signatoryPhone" + i}
                        className="border rounded-4 w-100 position-relative"
                      >
                        <span className="fw-bold">
                        {i == 0 &&
                    (registrationTypeId ==
                      "4" || expectedLimit >= 20) ? "Co-applicant ":"Signatory "} Phone
                        </span>
                        <input
                          id={"signatoryPhone" + i}
                          className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                          placeholder="Signatory Phone"
                          name={"signatoryPhone" + i}
                          onChange={(e) => {
                            handleSignatoryPhoneChange(
                              e,
                              i
                            );
                          }}
                          
                        />
                      </label>
                      {/* {errors.address && touched.address ? (
<p className="form-error text-danger">
{errors.address}
</p>
) : null} }
                      {error[
                        "signatoryPhone_" + i
                      ] ? (
                        <p className="form-error text-danger">
                          {
                            error[
                              "signatoryPhone_" + i
                            ]
                          }
                        </p>
                      ) : (
                        ""
                      )}
                      {/* {error.address ? (
<p className="form-error text-danger">
{error.address
</p>
) : null} }
                    </div>
                    {/** phone number }
                    {/* <!-- /form-group --> }
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-4">
                      <label className="border rounded-4 w-100 position-relative">
                        <span className="fw-bold">
                          Pan Card
                        </span>
                        <div className="form-group">
                          <input
                            type="text"
                            name="filename"
                            id={"signatoryPAN_" + i}
                            className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                            placeholder="No file selected"
                            readOnly
                          />
                          <span className="input-group-btn unit-label upload-docs position-absolute">
                            <div className="btn btn-primary btn-header green-btn custom-file-uploader">
                              <input
                                type="file"
                                name={
                                  "signatoryPAN_" + i
                                }
                                // onchange="this.form.filename.value = this.files.length ? this.files[0].name : ''"
                                onChange={(e) => {
                                  handleSignatoryPanchange(
                                    e,
                                    i
                                  );
                                }}
                                
                              />
                              Upload
                            </div>
                          </span>
                        </div>
                      </label>

                      {error["signatoryPAN_" + i] ? (
                        <p className="form-error text-danger">
                          {error["signatoryPAN_" + i]}
                        </p>
                      ) : null}
                      {/* {errors.panFile && touched.panFile ? (
<p className="form-error text-danger">
{errors.panFile}
</p>
) : error.panFile  ? (
<p className="form-error text-danger">
{error.panFile}
</p>
) : null} }
                    </div>
                   
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-4">
                      <label className="border rounded-4 w-100 position-relative">
                        <span className="fw-bold">
                          Aadhaar Card
                        </span>
                        <div className="form-group">
                          <input
                            type="text"
                            name="filename"
                            id={
                              "signatoryAADHAAR_" + i
                            }
                            className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                            placeholder="No file selected"
                            readOnly
                          />
                          <span className="input-group-btn unit-label upload-docs position-absolute">
                            <div className="btn btn-primary btn-header green-btn custom-file-uploader">
                              <input
                                type="file"
                                name={
                                  "signatoryAADHAAR_" +
                                  i
                                }
                                // onchange="this.form.filename.value = this.files.length ? this.files[0].name : ''"
                                onChange={(e) => {
                                  handleSignatoryAadhaarchange(
                                    e,
                                    i
                                  );
                                }}
                                
                              />
                              Upload
                            </div>
                          </span>
                        </div>
                      </label>

                      {error[
                        "signatoryAADHAAR_" + i
                      ] ? (
                        <p className="form-error text-danger">
                          {
                            error[
                              "signatoryAADHAAR_" + i
                            ]
                          }
                        </p>
                      ) : null}
                     
                    </div>
                    
                  </div> */}
              { expectedLimit > 20 ?
                  <div className="col-md-12">
                    <div className="form-group mb-4">
                      <label className="border rounded-4 w-100 position-relative">
                        <span className="fw-bold">
                          Networth Certificate
                        </span>
                        <div className="form-group">
                          <input
                            type="text"
                            name="filename"
                            id={"signatoryNETWORTH_" + i}
                            className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                            placeholder="No file selected"
                            readOnly
                          />
                          <span className="input-group-btn unit-label upload-docs position-absolute">
                            <div className="btn btn-primary btn-header green-btn custom-file-uploader">
                              <input
                                type="file"
                                name={
                                  "signatoryNETWORTH_" + i
                                }
                                // onchange="this.form.filename.value = this.files.length ? this.files[0].name : ''"
                                onChange={(e) => {
                                  handleSignatoryNetworthchange(
                                    e,
                                    i
                                  );
                                }}
                                
                              />
                              Upload
                            </div>
                          </span>
                        </div>
                      </label>

                      {error["signatoryNETWORTH_" + i] ? (
                        <p className="form-error text-danger">
                          {error["signatoryNETWORTH_" + i]}
                        </p>
                      ) : null}
                      {/* {errors.panFile && touched.panFile ? (
<p className="form-error text-danger">
{errors.panFile}
</p>
) : error.panFile  ? (
<p className="form-error text-danger">
{error.panFile}
</p>
) : null} */}
                    </div>
                    {/* <!-- /form-group --> */}
                  </div>:''}
                  {/* <!-- /col-lg-4 col-md-6 --> */}

                  {/* <!-- /col-lg-4 col-md-6 --> */}
                </div>
              </div>
            </>
          )
        )}
        <div className="addmore-area">
          {canAddMoreSignatory &&
          registrationTypeId != "4" ? (
            <a
              onClick={addMoreSignatory}
             
              //onClick={handleButtonClick}
              className="btn btn-outline-success text-uppercase ps-5 pe-5 mt-2"
            >
              <span>+</span> ADD
            </a>
          ) : (
            ""
          )}
        </div>{" "}
      </>
    );
}

export default SignatoryDocuments;