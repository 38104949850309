import React, { useState, useRef } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";

const DistributorRejectionRemarks = ({rejectionHandler, documentId}) => {
 const [errors , setErrors] = useState({});
  const formRef = useRef(null);
  const handleSubmit = (e)=>{
    e.preventDefault();
    const remarks = document.getElementById("rejectionRemarks"+documentId).value;
    rejectionHandler(documentId, remarks);
  }
  const changeHandler =(e, id)=>{
    e.preventDefault();
    errors[id] = null;
  }
  return (
    <>
      <div
        className="modal fade"
        id={"reject-document-"+documentId}
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Please enter kyc rejection reason
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body" >
            <form ref={formRef}>
                    <div className="form-wrap send-email-form">
                    
                      <div className="form-group">
                        <label htmlFor={"rejectionRemarks"+documentId}>Remarks</label>
                        <input
                          type="text"
                          id={"rejectionRemarks"+documentId}
                          placeholder="Remarks"
                          // value={quoteData.project_name === null ? values.projectName : values.projectName=quoteData.project_name }
                          name="Remarks"
                          className="solfin-input"
                          onChange={changeHandler}
                          onBlur={changeHandler}
                         
                        />
                        {errors.rejectionRemarks ? (
                          <p className="form-error text-danger">
                            {errors.rejectionRemarks}
                          </p>
                        ) : null}
                      </div>                           
                    </div>
                  </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-info filter-btn"
                data-bs-dismiss="modal"
              >
                Go Back
              </button>
              <button
                type="button"
                className="btn btn-info"
                data-bs-dismiss="modal"
                onClick={(e) => handleSubmit(e)}
              >
                Reject Document
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DistributorRejectionRemarks;
