import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import * as dataquery from "../service/dataService";

const DeleteDsaModal = ({ row }) => {
  const queryClient = useQueryClient();
  const id = row.original.dsa_id;
  const handleArchive = (row) => {
    dsaArchive.mutate(id);
  };
  const dsaArchive = useMutation(dataquery.updateDsaArchive, {
    onError: (error) => {
      toast.error(error.message);
    },
    onSuccess: (data) => {
      toast.success(data.message);
      queryClient.invalidateQueries("newAndRejectedDsa");
    },
  });

  return (
    <>
      <div
        className="modal fade"
        id={`id${row.original.dsa_id}`}
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Delelte - {row.original.dsa_name}?
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p className="text-dark">
                Once you delete this data, you won't be able to recover it. Are
                you sure you want to go ahead?
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-info filter-btn"
                data-bs-dismiss="modal"
              >
                No
              </button>
              <button
                type="button"
                className="btn btn-info"
                data-bs-dismiss="modal"
                onClick={() => handleArchive(id)}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteDsaModal;
