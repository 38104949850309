import React, { useEffect, useState } from "react";
import { NavLink, useLocation, props, Link } from "react-router-dom";
import Cookies from "js-cookie";
const Footer = (props) => {
  const cookie = Cookies.get("solfin-cookie-consent");
  const [cookieValue, setCookieValue] = useState(cookie); 

  function acceptCookies(){
    Cookies.set("solfin-cookie-consent","accepted",{ expires: 365 }); 
    setCookieValue(Cookies.get("solfin-cookie-consent"));
  }
  function rejectCookies(){
    Cookies.set("solfin-cookie-consent","rejected",{ expires: 365 });
    setCookieValue(Cookies.get("solfin-cookie-consent"));
  }
  function deletePrefrence(){
    Cookies.remove("solfin-cookie-consent");
    setCookieValue(Cookies.get("solfin-cookie-consent"));
  }

  return (
    <>
      <footer>
        {cookieValue !=="accepted" && cookieValue!=="rejected"? 
        <>
      <div className="cookie-consent">
    <h6 className="text-white fw-bold">We value your privacy</h6>
    <p>We and our partners use cookies and other tracking technologies to improve your experience on our website. We may store and/or access information on a device and process personal data, such as your IP address and browsing data, for personalised advertising and content, advertising and content measurement, audience research and services development. Additionally, we may utilize precise geolocation data and identification through device scanning.
Please note that your consent will be valid across all our subdomains. Please refer to our <NavLink className="policy-link" to="/CookiePolicy">Cookie Policy</NavLink></p>
    <div className="row">
    <button className="col-sm-6 btn  text-capitalize fw-semibold rounded-5 mb-3" onClick={rejectCookies}>Reject</button>
    <button className="col-sm-6 btn btn-primary text-capitalize calculate-btn fw-semibold rounded-5 mb-3" onClick={acceptCookies} >Accept</button>
    </div>
</div>
</>:""}
        <div className="main-footer">
          <div className="container custom-container">
            <div className="row">
              <div className="col-xl-3 col-lg-3 col-sm-6">
                <div className="footer-about-solfin">
                  <div className="footer-logo">
                    <img
                      src="/images/footer-logo.svg"
                      alt="Solfin"
                      title="Solfin"
                    />
                  </div>
                  {/* <!-- /footer-logo --> */}
                  <div className="footer-text text-white mt-3 mt-xl-4">
                    <p>
                      Enabling finance for all sustainable, renewable and
                      climate friendly projects throughout India
                    </p>
                  </div>
                  {/* <!-- /footer-text --> */}
                </div>
                {/* <!-- /footer-about-solfin --> */}
              </div>
              {/* <!-- /col-lg-3 --> */}
              <div className="col-xl-3 col-lg-3 col-sm-6">
                <div className="footer-heading mt-4 text-white">
                  <h6 className="text-white fw-bold">Quick links</h6>
                </div>
                <div className="footer-link mt-lg-4 mt-3">
                  <ul className="list-unstyled m-0 p-0">
                  <li>
                      <NavLink to="/Estimator">Solar Calculator</NavLink>
                    </li>
                    
                    <li>
                      <NavLink to="/AboutUs">About Us</NavLink>
                    </li>
                    <li>
                      <NavLink to="/PrivacyPolicy">XS Finance Privacy Policy</NavLink>
                    </li>
                    <li>
                      <NavLink to="/DhanvikasPrivacyPolicy">Dhanvikas Privacy Policy</NavLink>
                    </li>
                    <li>
                      <NavLink to="/CookiePolicy">
                        Cookie Policy
                      </NavLink>
                    </li>
                    <li>
                    
                      <NavLink onClick={deletePrefrence}>Cookie Settings</NavLink>
                    </li>
                    <li>
                      <NavLink to="/Refundpolicy">Refund Policy</NavLink>
                    </li>
                    <li>
                      <NavLink to="/FairPracticeCode">Fair Practice Code</NavLink>
                    </li>
                    <li>
                      <NavLink to="/GrievanceRedressalPolicy">Grievance Redressal Policy</NavLink>
                    </li>
                    <li>
                      <NavLink to="/Termsandconditions">
                        Terms and Conditions
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/knowledge">Knowledge</NavLink>
                    </li>
                  </ul>
                </div>
                {/* <!-- /footer-link --> */}
              </div>
              {/* <!-- /col-lg-3 --> */}
              <div className="col-xl-3 col-lg-2 col-sm-6 mt-lg-0 mt-4">
                <div className="footer-heading mt-4 text-white">
                  <h6 className="text-white fw-bold">Contact</h6>
                </div>
                <div className="footer-link mt-lg-4 mt-3">
                  <div className="contact-via text-white">
                    <p className="d-flex">
                      <span><i className="fa fa-user"></i></span>
                      <span>XSFinance and Investment Pvt. Ltd.</span>
                    </p>
                    <p className="d-flex">
                      <span><i className="fa fa-address-card"></i></span>
                      <span>U65999HR2022PTC108105</span>
                    </p>
                    <p className="d-flex">
                      <span><i className="fa fa-map-marker"></i></span>
                      <span>Sector-62, Gurgaon - 122001</span>
                    </p>
                    <p className="d-flex">
                      <span><i className="fa fa-envelope"></i></span>
                      <span><a className="text-white text-decoration-none" href="mailto:support@solfin.co.in">support@solfin.co.in</a></span>
                    </p>
                    <p className="d-flex">
                      <span><i className="fa fa-phone"></i></span>
                      <span><a className="text-white text-decoration-none" href="tel:+919599446877">+91 9599446877</a></span>
                    </p>
                  </div>
                </div>
                {/* <!-- /footer-link --> */}
                {/* <div className="footer-heading text-white mt-4 pt-3">
                  <h6 className="text-white fw-bold">Call us</h6>
                </div>
                <div className="footer-link mt-lg-4 mt-3">
                  <div className="email-us">
                    <a
                      className="text-decoration-none text-theme-green fw-bold"
                      href="tel:+919873602224"
                    >
                      9873602224
                    </a>
                  </div>
                </div> */}
                {/* <!-- /footer-link --> */}
              </div>
              {/* <!-- /col-lg-3 --> */}
              <div className="col-xl-3 col-lg-4 col-sm-6 mt-lg-0 mt-4">
                <div className="footer-heading mt-4 text-white">
                  <h6 className="text-white fw-bold">Follow Us</h6>
                </div>
                <div className="solfin-social d-flex align-items-center">
                  {/* <!-- /social-title --> */}
                  <div className="social-links d-flex">
                    <a
                      className="rounded-pill text-decoration-none d-flex align-items-center justify-content-center text-theme-green"
                      href="https://www.facebook.com/profile.php?id=61558828528270&mibextid=ZbWKwL"
                    >
                      <i className="fa fa-facebook"></i>
                    </a>
                    <a
                      className="rounded-pill text-decoration-none d-flex align-items-center justify-content-center text-theme-green"
                      href="https://twitter.com/solfin_india"
                    >
                      <i className="fa fa-twitter"></i>
                    </a>
                    <a
                      className="rounded-pill text-decoration-none d-flex align-items-center justify-content-center text-theme-green"
                      href="https://in.linkedin.com/company/solfin-sustainable-financing?trk=public_profile_topcard-current-company"
                    >
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- /row --> */}
          </div>
          {/* <!-- /container --> */}
        </div>
        {/* <!-- /main-footer --> */}
        <div className="solfin-copyright">
          <div className="container custom-container">
            <div className="row">
              <div className="col-md-12 text-center">
                <span>
                  Copyright &copy; <strong>Solfin</strong> 2023. All Rights
                  Reserved.
                </span>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
