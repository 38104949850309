import React, { useState } from "react";
import * as dataquery from "../service/DataServices";
import { useQuery } from "react-query";

import LeadTable from "../../AdminDashboard/pages/leads/components/LeadTable";
import Export from "../../components/ExportToExcel";

const Projects = () => {
    const [isFilterActive, setIsFilterActive] = useState(false);
  const handleExport = () => {
    Export(
      data.data.data,
      [
        "customer_name",
        "email",
        "phone",
        "address1",
        "is_finance_required",
        "elec_bill_date",
        "city",
        "state",
        "status",
        "customer_type",
        "location_type",
        "elec_bill_date",
        "avg_consumption",
        "elec_bill_image",
        "electricity_consumer_no",
        "electricity_rate",
        "area_available",
        "consumption_to_shift",
        "connect_to_grid",
        "estimated_project_cost",
        "created_on",
        "google_coordinates",
      ],
      [
        "CustomerName",
        "Email",
        "Phone",
        "Address",
        "IsFinanceRequired",
        "Elec_Bill_Date",
        "City",
        "State",
        "Status",
        "CustomerType",
        "LocationType",
        "Elec_Bill Date",
        "Avg_Consumption",
        "Elec_Bill_Image",
        "Electricity_ConsumerNo.",
        "ElectricityRate",
        "Area_Available",
        "ConsumptionShift",
        "ConnectToGrid",
        "Estimated_Project_Cost",
        "Created_On",
        "GoogleCoordinates",
      ]
    );
  };
  const formateDate = (date) => {
    if (date) {
      const dateObject = new Date(date);
      const newDate = new Date(dateObject.getTime());
      newDate.setHours(newDate.getHours() + 5.5);
      // Check if the dateObject is valid
      if (!isNaN(newDate)) {
        const day = newDate.getDate();
        const month = newDate.getUTCMonth() + 1; // Add 1 because months are zero-based
        const year = newDate.getUTCFullYear();
  
        // Check if the day value is within a valid range (1 to 31)
        if (day >= 1 && day <= 31) {
          const formattedDate = `${day.toString().padStart(2, '0')}/${month
            .toString()
            .padStart(2, '0')}/${year}`;
          return formattedDate;
        }
      }
    }
    // Return null for invalid or empty dates
    return null;
  };
  
  const columns = React.useMemo(
    () => [
      { Header: "Sr. No", accessor: "srNo" },
      { Header: "Project Id", accessor: "application_project_id"},
      { Header: "Project Name", accessor: "project_name"},
        { Header: "Customer Name", accessor: "customer_name" },
        { Header: "Email", accessor: "email" },
        // { Header: "Finance Partner", accessor: "finance_partner" },
        // { Header: "Capacity", accessor: "capacity" },
        // { Header: "Est Unit Generated", accessor: "estimated_unit_generated" },
        // { Header: "Solution", accessor: "solution" },
        // { Header: "Epc", accessor: "company_name" },
        { Header: "Project Cost", accessor: "project_cost" },
        { Header: "Installation Date", Cell: ({row}) => {
          return formateDate(row.original.installation_date)
        } },
        {
          Header: "Status",accessor: "status"
        },
        // { Header: "Installation Date", accessor: "installation_date" },
      ],
      []
  );
  const { data, isLoading, error } = useQuery(
    "getAllDsaProjects",
    dataquery.getProjects
  );
  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error: {error.message}</div>;
  }
  if (!data) {
    return <div>No data fetched.</div>;
  }
console.log(data.data)
  return (
    <>
      <div className="content-body">
        <div className="page-title">
          <h3>Applications</h3>
        </div>
        <LeadTable
          tableName={"List of Applications"}
          columns={columns}
          tableData={data.data }
          handleExport={handleExport}
          isFilterActive={isFilterActive}
          cityallowed
          stateallowed
          istartandenddateallowed
          pstatusallowed
        />
      </div>
    </>
  )
}

export default Projects