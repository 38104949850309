import React from "react";
import { useQuery } from "react-query";
import * as querydata from "../service/dataService";

const CitySelect = (props) => {
  // //console.log("stateid")
  // //console.log(props.parameter.stateId)
  //console.log("In City component")
  //console.log(props.selectedState)
  const getFilteredCities = useQuery(
    ["getFilteredCities", +props.selectedState],
    () => querydata.getFilteredCities(props.selectedState)
  );

  //validations

  if (getFilteredCities.isLoading) {
    return <div>Loading...</div>;
  }

  if (getFilteredCities.error) {
    return <div>Error: {getFilteredCities.error.message}</div>;
  }

  if (!getFilteredCities.data) {
    return <div>No data fetched.</div>;
  }

  return (
    <>
      <label
        htmlFor="City"
        className="border rounded-4 w-100 position-relative"
      >
        <span className="fw-bold">City</span>
        <select
          id="City"
          className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
          name="cityId"
          value={props.values && props.values.cityId}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
        >
          <option value="" defaultValue="Select City">
            Select City
          </option>
          {getFilteredCities.data.data &&
            getFilteredCities.data.data.map((cityoption) => {
              return (
                <option key={cityoption.city_id} value={cityoption.city_id}>
                  {cityoption.city}
                </option>
              );
            })}
        </select>
      </label>
      {props.errors.cityId && props.touched.cityId ? (
        <p className="form-error text-danger">{props.errors.cityId}</p>
      ) : null}
    </>
  );
};

export default CitySelect;
