import React,{useEffect} from "react";

import { useMutation, useQuery, useQueryClient } from "react-query";
import * as queryData from "../service/DataService";
import { saveAs } from "file-saver";
import AreaServedModal from "../../components/AreaServedModal";
import PublishingDetails from "../pages/PublishingDetails";
import { getMyLoanSummary, getDistributorKycDocumentUrl , reuploadKycDocument} from "../service/DataService";
import { Tooltip } from "react-tooltip";
import { toast } from "react-toastify";
const LoanSummary = ({data, id}) => {
  const queryClient = useQueryClient();
  const loanDetails = useQuery(
    ["getMyLoanSummary"],
    () => getMyLoanSummary()
  );


  return (
    <>
      <div className="content-body">
        <div className="page-title">
          <h3>Loan Details</h3>
        </div>
        {/* <!-- /page title --> */}
        <div className="dash-card">
          <div className="dash-card-header border-0">
            <div className="mb-0">
              <ul className="nav nav-pills mb-3" id="detail-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    Loan Summary
                  </button>
                </li>         
              </ul>
              <div className="tab-content mt-3" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                  tabindex="0"
                >
                  <div className="solfin-application-details">
                    <ul>
                      <li
                        className="d-flex align-items-center pb-3"
                        style={{ width: "100%" }}
                      >
                        <span
                          className="fw-bold mx-2"
                          style={{ marginRight: "50px", width: "45%" }}
                        >
                          Loan Status:
                        </span>{" "}
                        <span>{"Approved"}</span>
                      </li>
                      <li className="d-flex align-items-center pb-3">
                        <span
                          className="fw-bold mx-2"
                          style={{ marginRight: "50px", width: "45%" }}
                        >
                          Application Form:
                        </span>
                        <span><button
                              className="btn text-dark"
                              onClick={(e)=>{viewDocument(e, outerObj.previousDocId)}}
                            >
                              {" "}
                              <i className="fa-solid fa-download"></i> Download
                            </button></span>
                      </li>
                      <li className="d-flex align-items-center pb-3">
                        <span
                          className="fw-bold mx-2"
                          style={{ marginRight: "50px", width: "45%" }}
                        >
                          Sanction Letter:
                        </span>
                        <span><button
                              className="btn text-dark"
                              onClick={(e)=>{viewDocument(e, outerObj.previousDocId)}}
                            >
                              {" "}
                              <i className="fa-solid fa-download"></i> Download
                            </button></span>
                      </li>
                      <li className="d-flex align-items-center pb-3">
                        <span
                          className="fw-bold mx-2"
                          style={{ marginRight: "50px", width: "45%" }}
                        >
                          Loan Aggreement:
                        </span>
                        <span><button
                              className="btn text-dark"
                              onClick={(e)=>{viewDocument(e, outerObj.previousDocId)}}
                            >
                              {" "}
                              <i className="fa-solid fa-download"></i> Download
                            </button></span>
                      </li>
                      <li
                        className="d-flex align-items-center pb-3"
                        style={{ width: "100%" }}
                      >
                        <span
                          className="fw-bold mx-2"
                          style={{ marginRight: "50px", width: "45%" }}
                        >
                          Loan Valid till :
                        </span>{" "}
                        <span>{"12 Feb 2026"}</span>
                      </li>
                      <li
                        className="d-flex align-items-center pb-3"
                        style={{ width: "100%" }}
                      >
                        <span
                          className="fw-bold mx-2"
                          style={{ marginRight: "50px", width: "45%" }}
                        >
                          Loan Start Date:
                        </span>{" 10 Aug 2024 "}
                      </li>
                      <li className="d-flex align-items-center pb-3">
                        <span
                          className="fw-bold mx-2"
                          style={{ marginRight: "50px", width: "45%" }}
                        >
                          Approved Limit:
                        </span>{" 10000"}
                        
                      </li>
                      <li className="d-flex align-items-center pb-3">
                        <span
                          className="fw-bold mx-2"
                          style={{ marginRight: "50px", width: "45%" }}
                        >
                          Interest Rate:
                        </span>{" 3% Per Month"}
                        
                      </li>
                      <li className="d-flex align-items-center pb-3">
                        <span
                          className="fw-bold mx-2"
                          style={{ marginRight: "50px", width: "45%" }}
                        >
                          Utilised Amount:
                        </span>{" 3000"}
                        <span>{}</span>
                      </li>
                      <li className="d-flex align-items-center pb-3">
                        <span
                          className="fw-bold mx-2"
                          style={{ marginRight: "50px", width: "45%" }}
                        >
                          Interest to be Paid:
                        </span>
                        <span>{"2000"}</span>
                      </li>
                      
                  
                     
                    </ul>
                  </div>
                </div>


             
              </div>
            </div>
          </div>
          {/* <!-- /card header --> */}
        </div>
        {/* <!-- /dash-card --> */}
      </div>
      {/* <!-- /content body --> */}
    </>
  );
};

export default LoanSummary;
