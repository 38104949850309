import React from "react";
import LeadTable from "../leads/components/LeadTable";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { getDistributorLeads , sendEmailToEpcLead, createNewDistributorLead, sendDistributorInviteLink} from "../../service/dataService";
import Export from "../../../components/ExportToExcel";
import * as dataquery from "../../service/dataService";
import { toast } from "react-toastify";
import EpcLeadModal from "../../components/EpcLeadModal";
import EmailSendModal  from  "../../components/EmailSendModal";
import NewDistributorLeadModal from "../../components/NewDistributorLeadModal";
import DistributorLeadCreateBtn from "../../components/DistributorLeadCreateBtn";

const DistributorLeads = () => {
  const [isDisabled, setIsDisabled] = useState(false);
  const queryClient = useQueryClient();
  // const [currentPageNumber, setCurrentPageNumber] = useState();

  const LEAD_ENUM = {
    INVITE_SENT:"Invite Sent",
    INVITE_PENDING:"Invite Pending",
    };
  // console.log("currentPageNumber");
  // console.log(currentPageNumber);
  const sendEmailForLeadHandler = async(request, row)=>{
      setIsDisabled(true);
      await sendEmailToEpcLead(request);
      toast.success("Mail sent successfully");
      setIsDisabled(false);
      queryClient.invalidateQueries("getDistributorLeads");
      
  }

  const sendInviteLinkToDistributor = async(leadId)=>{
    try {
      setIsDisabled(true);
      const response  =  await sendDistributorInviteLink(leadId);
      if (response){
        toast.success("Invitation link has been sent to epc email")
        setIsDisabled(false);
        queryClient.invalidateQueries("getDistributorLeads");
      }
    }catch (error){
      console.log(error);
      toast.error(error.message);
    }
  }
  const createNewLead = async(requestBody)=>{
    try {
      const response = await createNewDistributorLead(requestBody);
      if (response){
        toast.success("New lead created.")
        queryClient.invalidateQueries("getDistributorLeads");
      }
    }catch (error){
      console.log(error);
      toast.error(error.message);
    }
      
  }

  //Export To Excel
  const handleExport = () => {
    //console.log(data.data.data);
    Export(
      data.data,
      [
        "name",
        "email",
        "phone",
        "poc_name",
        "status",
        "last_email_status",
        "last_email_time",
      ],
      [
       "name",
        "email",
        "phone",
        "poc_name",
        "status",
        "last_email_status",
        "last_email_time",
      ]
    );
  };

  //const [epcData, setEpcData] = useState([{}])
  const [modalData, setModalData] = useState();
  const [isFilterActive, setIsFilterActive] = useState(false);
  const navigate = useNavigate();

  const handleNavigate = (row) => {
    const id = row.original.epc_id;
    // console.log(id);
    navigate(`epc-applicants-detail/${id}`);
  };


  const epcLeadArchive = useMutation(dataquery.deleteEpcLead, {
    onError: (error) => {
      toast.error(error.message);
    },
    onSuccess: (data) => {
      toast.success(data.message);
      queryClient.invalidateQueries("getDistributorLeads");
      // data.refetch();
    },
  });

  const handleArchive = (id) => {
    epcLeadArchive.mutate(id);
  };
  const columns = React.useMemo(
    () => [
      { Header: "Sr. No", accessor: "srNo" },
      { Header: "Company Name", accessor: "name" },
      { Header: "Email", accessor: "email" },
      { Header: "Phone", accessor: "phone" },
      { Header: "POC Name", accessor: "poc_name" },
      { Header: "Status", Cell:({ row }) => LEAD_ENUM[row.original.status]},
      { Header: "Last Email Status", accessor: "last_email_status" },
      { Header: "Last Email Time", accessor: "last_email_time" },
      {
        Header: "Actions",
        Cell: ({ row }) => (
          <td>
            
            <div className="text-nowrap">
            { row.original.status == "INVITE_PENDING" ?
              
              <button
                onClick={() => sendInviteLinkToDistributor(row.original.id)}
                className="ms-2 btn btn-sm green-btn"
                title="Send Email"
                disabled={isDisabled}
              >
                {isDisabled ? 'Loading...' :
                "Send Invite Link" }
                
              </button>:<button
                data-bs-toggle="modal"
                data-bs-target={`#email-send-${row.original.id}`}
                // onClick={() => handleArchive(row)}
                className="ms-2 btn btn-sm green-btn"
                disabled={isDisabled}
                title="Send Email"
              >
                Send Email
                
              </button>}
              <EmailSendModal  sendClickHandler={sendEmailForLeadHandler} sendEmailForLead ={row.original.name} uniqueId={row.original.id} row={row}/>
              <button
                data-bs-toggle="modal"
                data-bs-target={`#id${row.original.id}`}
                // onClick={() => handleArchive(row)}
                disabled={isDisabled}
                className="ms-2 btn btn-sm btn-danger"
                title="Delete"
              >
                {isDisabled ? 'Loading...' :
                <i className="fa fa-box-archive"></i>
      }
                <EpcLeadModal row={row} yesClickHandler={handleArchive} heading= {"Delete Lead"} message= {"Once you delete this lead, you won't be able to recover it. Are you sure you want to go ahead?"} modalId={`id${row.original.id}`} />
              </button>{" "}
            </div>
          </td>
        ),
      }
    ],
    [isDisabled]
  );

  const { data, isLoading, error } = useQuery(
    "getDistributorLeads",
    getDistributorLeads
  );

  // useEffect(() => {
  //   setEpcData(data)
  // },[data.data])
  // console.log(data)

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!data || !data.data) {
    return <div>No data fetched.</div>;
  }

  return (
    <>
      <div className="content-body">
        <div className="page-title">
          <h3>Distributor Leads</h3>
        </div>
      
        <LeadTable
          // setCurrentPageNumber={setCurrentPageNumber}
          pageNumber={0}
          tableName={"Distributor Leads"}
          columns={columns}
          tableData={data && data.data}
          handleExport={handleExport} 
          modalIdForCreate = "new-distributor-lead"
          handleCreateHtml={DistributorLeadCreateBtn}
          isFilterActive={isFilterActive}
        />
        <NewDistributorLeadModal newLeadHandler={createNewLead}/>
        {/* <!-- /dash-card --> */}
      </div>
      {/* <!-- /content body --> */}
    </>
  );
};

export default DistributorLeads;
