import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useFilters,
  useSortBy,
} from "react-table";
import Filter from "../../../components/Filter";

const LeadTable = ({
  pageNumber = 0,
  tableName = "List Table",
  tableData,
  columns,
  handleExport,
  cityallowed,
  stateallowed,
  customertypeallowed,
  statusallowed,
  startandenddateallowed,
  areaServedallowed,
  connecttoGridAllowed,
  locationtypeallowed,
  esstatusallowed,
  sourceAllowed,
  financingallowed,
  assignedtoallowed,
  noofprojectsAllowed,
  customerstatusallowed,
  ppallowed,
  publishstatusallowed,
  epcstatusallowed,
  eplstatusallowed,
  acallowed,
  exallowed,
  pepcstatusallowed,
  pstatusallowed,
  istartandenddateallowed,
  fpAllowed,
  dallowed,
  eallowed,
  modalIdForCreate,
  handleCreateHtml
}) => {
  //filter table data
  const [filteredData, setFilteredData] = useState();
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [currentPageIndex, setCurrentPageIndex] = useState(pageNumber); // Initialize with 0 or any default value
  // Create an instance of the table
  // const data = React.useMemo(() => filteredData, [filteredData]);
  // const data = React.useMemo(() => tableData, [tableData]);

  // setCurrentPageNumber(currentPageIndex);

  const data = filteredData ? filteredData : tableData;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    prepareRow,
    setFilter,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    state: { filters },
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    { columns, data, initialState: { pageIndex: currentPageIndex } },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  // Handle pagination controls
  const handleGoToPage = (pageIndex) => {
    setCurrentPageIndex(pageIndex);
    gotoPage(pageIndex);
  };

  const handleNextPage = () => {
    setCurrentPageIndex(pageIndex + 1);
    nextPage();
  };

  const handlePreviousPage = () => {
    setCurrentPageIndex(pageIndex - 1);
    previousPage();
  };
  // function openFilterTab() {
  //   var b = document.getElementById("button");
  //   var w = document.getElementById("filter-wrapper");
  //   var l = document.getElementById("filters");

  //  // w.height(l.outerHeight(true));

  //       if(w.classList.contains('open')) {
  //       w.classList.remove('open');
  //      // w.height(0);
  //       } else {
  //       w.classList.add('open');
  //    //   w.height(l.outerHeight(true));
  //       }

  //   }

  return (
    <>
      {/* <!-- /page title --> */}
      <div className="dash-card">
        <div className="dash-card-header flex-wrap card-flexbox">
          <h6>{tableName}</h6>
          <div className="table-right-side card-flexbox ms-auto">
            <button href="#" className="btn btn-info" onClick={handleExport}>
              Export
            </button>
            <button
              href="#"
              className="btn btn-info filter-btn"
              id="button"
              onClick={() => setIsFilterActive((prev) => !prev)}
            >
              Filter
            </button>
            {modalIdForCreate? handleCreateHtml(modalIdForCreate):''}
            <form action="#" method="get" className="card-flexbox">
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Search"
                  className="solfin-input"
                  value={state.globalFilter || ""}
                  onChange={(e) => setGlobalFilter(e.target.value)}
                />
                <button
                  type="submit"
                  className="btn btn-sm btn-info search-btn"
                >
                  <i className="fa fa-search"></i>
                </button>
              </div>
            </form>
          </div>

          {/* <div id="filter-wrapper" className="">
            <div id="filters">
              <form action="#" method="get">
                <div className="form-wrap">
                  <div className="form-group">
                    <label htmlFor="City">City</label>
                    <input type="text" id="City" className="solfin-input" />
                  </div>
                  <div className="form-group">
                    <label htmlFor="State">State</label>
                    <input type="text" id="State" className="solfin-input" />
                  </div>
                  <div className="form-group">
                    <label className="checklabel">Connect to Grid</label>
                    <div className="check-label">
                      <label htmlFor="Yes">
                        <span>Yes</span>
                        <input type="radio" name="ConnectGrid" id="Yes" />
                      </label>
                      <label htmlFor="No">
                        <span>No</span>
                        <input type="radio" name="ConnectGrid" id="No" />
                      </label>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="DateFrom">Date From</label>
                    <input type="date" id="DateFrom" className="solfin-input" />
                  </div>
                  <div className="form-group">
                    <label htmlFor="DateTo">Date To</label>
                    <input type="date" id="DateTo" className="solfin-input" />
                  </div>
                  <div className="form-group w-100">
                    <div className="submit-filter">
                      <input
                        type="submit"
                        className="btn btn-info"
                        value="Search"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div> */}
          <Filter
            setFilter={setFilter}
            isFilterActive={isFilterActive}
            setFilteredData={setFilteredData}
            tableData={tableData}
            cityallowed={cityallowed}
            stateallowed={stateallowed}
            connecttoGridAllowed={connecttoGridAllowed}
            customertypeallowed={customertypeallowed}
            statusallowed={statusallowed}
            startandenddateallowed={startandenddateallowed}
            areaServedallowed={areaServedallowed}
            locationtypeallowed={locationtypeallowed}
            esstatusallowed={esstatusallowed}
            sourceAllowed={sourceAllowed}
            financingallowed={financingallowed}
            assignedtoallowed={assignedtoallowed}
            noofprojectsAllowed={noofprojectsAllowed}
            customerstatusallowed={customerstatusallowed}
            ppallowed={ppallowed}
            publishstatusallowed={publishstatusallowed}
            epcstatusallowed={epcstatusallowed}
            eplstatusallowed={eplstatusallowed}
            acallowed={acallowed}
            exallowed={exallowed}
            pepcstatusallowed={pepcstatusallowed}
            pstatusallowed={pstatusallowed}
            istartandenddateallowed={istartandenddateallowed}
            fpAllowed={fpAllowed}
            dallowed={dallowed}
            eallowed={eallowed}
          />
        </div>
        {/* <div className="row align-items-center p-4">
        <div className="col-sm-12 col-md-6">
          <div className="dataTables_length" id="DataTables_Table_0_length">
            <label>
              <select
                name="DataTables_Table_0_length"
                aria-controls="DataTables_Table_0"
                className="form-select"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </label>
          </div>
        </div>
        <div className="col-sm-12 col-md-6 d-flex justify-content-center justify-content-md-end">
          <div id="DataTables_Table_0_filter" className="dataTables_filter">
            <label>
              Search:
              <input
                type="search"
                className="form-control"
                placeholder=""
                aria-controls="DataTables_Table_0"
                onChange={(e) => setGlobalFilter(e.target.value)}
              />
            </label>
          </div>
        </div>
      </div>
      <hr className="mt-0" />

      <Filter setFilter={setFilter} isFilterActive={isFilterActive} setFilteredData={setFilteredData} tableData={tableData} cityallowed={cityallowed} stateallowed={stateallowed} customertypeallowed={customertypeallowed} statusallowed={statusallowed} startandenddateallowed={startandenddateallowed} areaServedallowed={areaServedallowed} connecttoGridAllowed={connecttoGridAllowed}/> */}

        {/* <!-- /card header --> */}
        <div className="dash-card-body">
          <div className="table-responsive auto-scroll-table">
            <table {...getTableProps()} className="table">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render("Header")}
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <i className="fa-solid fa-caret-down"></i>
                            ) : (
                              <i className="fa-solid fa-caret-up"></i>
                            )
                          ) : (
                            ""
                          )}
                        </span>
                        {/* <input type="checkbox" name="" id="" /> */}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              {page.length === 0 ? (
                <tbody>
                  <span>No Data Found</span>{" "}
                </tbody>
              ) : (
                <tbody {...getTableBodyProps()}>
                  {page.map((row) => {
                    prepareRow(row);

                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell, index) => (
                          <td {...cell.getCellProps()}>
                            {index === 0 ? row.index + 1 : cell.render("Cell")}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
          </div>
          {/* <!-- /table responsive --> */}
          <div>
            <label
              className="d-flex align-items-center mt-2"
              style={{ "max-width": "190px" }}
            >
              Show{" "}
              <select
                value={pageSize}
                onChange={(e) => {
                  setCurrentPageIndex(0); // Reset to the first page when changing rows per page
                  setPageSize(Number(e.target.value));
                }}
                className="form-select ms-3 me-3"
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>{" "}
              entries
            </label>
          </div>
          <div className="solfin-pagination">
            <div className="d-flex align-items-center justify-content-between mt-3">
              <div>
                Showing {pageIndex * pageSize + 1} to{" "}
                {Math.min((pageIndex + 1) * pageSize, rows.length)} of{" "}
                {rows.length} entries
              </div>

              <ul className="pagination-solfin mt-0">
                <li className="">
                  <button
                    // onClick={() => previousPage()}
                    onClick={() => handlePreviousPage()}
                    disabled={!canPreviousPage}
                  >
                    <i className="fa-solid fa-angle-left"></i>
                  </button>
                </li>
                {Array.from({ length: pageCount }, (_, index) => (
                  <li>
                    <button
                      className={pageIndex === index ? "active" : ""}
                      key={index}
                      // onClick={() => gotoPage(index)}
                      onClick={() => handleGoToPage(index)}
                      disabled={pageIndex === index}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}

                {/* <li className="active">
                <a href="#">1</a>
              </li>
              <li>
                <a href="#">2</a>
              </li>
              <li>
                <a href="#">3</a>
              </li>
              <li>
                <a href="#">4</a>
              </li>
              <li>
                <a href="#">5</a>
              </li> */}
                <li>
                  <button
                    className="btn m-n3"
                    // onClick={() => nextPage()}
                    onClick={() => handleNextPage()}
                    disabled={!canNextPage}
                  >
                    <i className="fa-solid fa-angle-right"></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeadTable;
