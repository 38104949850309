import React, { useState, useRef } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";

const EmailSendModal = ({ sendClickHandler, sendEmailForLead, uniqueId, row }) => {
 const [errors , setErrors] = useState({});
  const formRef = useRef(null);
  const handleSubmit = (e, row)=>{
    e.preventDefault();
    const requestBody = {leadId: row.original.id};
    const currformData = new FormData(formRef.current);
      for (let [key, value] of currformData.entries()) {
        requestBody[key] = value;
      }
      console.log(requestBody);
      
    if (!requestBody.emailTo){
      toast.error("Please enter email address");
      return;
    }
    if (!requestBody.message){
      toast.error( "Please enter email message");
      return;
    }
    sendClickHandler(requestBody, row);
  }
  const changeHandler =(e, id)=>{
    errors[id] = null;
  }
  return (
    <>
      <div
        className="modal fade"
        id={"email-send-"+uniqueId}
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                {"Send email for distributor lead "+ sendEmailForLead}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body" >
            <form ref={formRef}>
                    <div className="form-wrap send-email-form">
                    
                      <div className="form-group">
                        <label htmlFor={"emailTo"+uniqueId}>Email To</label>
                        <input
                          type="text"
                          id={"emailTo"+uniqueId}
                          placeholder="Email To"
                          // value={quoteData.project_name === null ? values.projectName : values.projectName=quoteData.project_name }
                          name="emailTo"
                          className="solfin-input"
                          onChange={(e)=>{changeHandler(e, "emailTo")}}
                        />
                        {errors.emailTo ? (
                          <p className="form-error text-danger">
                            {errors.emailTo}
                          </p>
                        ) : null}
                      </div>
                      <div className="form-group">
                        <label htmlFor={"emailCC"+uniqueId}>CC</label>
                        <input
                          type="text"
                          id={"emailCC"+uniqueId}
                          placeholder="CC"
                          // value={quoteData.project_name === null ? values.projectName : values.projectName=quoteData.project_name }
                          name="cc"
                          className="solfin-input"
                        />
                        {errors.emailCC ? (
                          <p className="form-error text-danger">
                            {errors.emailCC}
                          </p>
                        ) : null}
                      </div>
                      <div className="form-group">
                        <label htmlFor={"emailBCC"+uniqueId}>BCC</label>
                        <input
                          type="text"
                          id={"emailBCC"+uniqueId}
                          placeholder="BCC"
                          // value={quoteData.project_name === null ? values.projectName : values.projectName=quoteData.project_name }
                          name="bcc"
                          className="solfin-input"
                        />
                        {errors.emailBCC ? (
                          <p className="form-error text-danger">
                            {errors.emailBCC}
                          </p>
                        ) : null}
                      </div>
                      <div className="form-group">
                      <label htmlFor={"emailMsg"+uniqueId}>Enter text:</label>
                      
          <textarea
         id={"emailMsg"+uniqueId}
         onChange={(e)=>{changeHandler(e, "emailMsg")}}
                          placeholder="Message"
                           name="message"
                          className="solfin-input"
        rows="5"
        cols="33"
      />
                        {errors.emailMsg ? (
                          <p className="form-error text-danger">
                            {errors.emailMsg}
                          </p>
                        ) : null}
                      </div>
                    
                                            
                    </div>
                  </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-info filter-btn"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-info"
                data-bs-dismiss="modal"
                onClick={(e) => handleSubmit(e, row)}
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailSendModal;
