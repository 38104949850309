import React, { useState, useEffect } from "react";
import * as dataquery from "../../service/dataService";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LeadTable from "./components/LeadTable";
import Export from "../../../components/ExportToExcel";
import { saveAs } from "file-saver";
import { queryCache } from "react-query";
import DeleteModal from "../../components/DeleteModal";

const ApplicationsLeads = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [isFilterActive, setIsFilterActive] = useState(false); //Export To Excel
  const handleExport = () => {
    //console.log(data.data.data);
    Export(
      data.data.data,
      [
        "customer_name",
        "email",
        "phone",
        "address1",
        "is_finance_required",
        "elec_bill_date",
        "city",
        "state",
        "status",
        "customer_type",
        "location_type",
        "elec_bill_date",
        "avg_consumption",
        "elec_bill_image",
        "electricity_consumer_no",
        "electricity_rate",
        "area_available",
        "consumption_to_shift",
        "connect_to_grid",
        "estimated_project_cost",
        "created_on",
        "google_coordinates",
      ],
      [
        "CustomerName",
        "Email",
        "Phone",
        "Address",
        "IsFinanceRequired",
        "Elec_Bill_Date",
        "City",
        "State",
        "Status",
        "CustomerType",
        "LocationType",
        "Elec_Bill Date",
        "Avg_Consumption",
        "Elec_Bill_Image",
        "Electricity_ConsumerNo.",
        "ElectricityRate",
        "Area_Available",
        "ConsumptionShift",
        "ConnectToGrid",
        "Estimated_Project_Cost",
        "Created_On",
        "GoogleCoordinates",
      ]
    );
  };
  const handleNavigate = (row) => {
    // console.log(row.original.customer_id);
    const customerId = row.original.customer_id;
    // navigate(`/customers/details/${customerId}`);
  };

  const changeApplicationStatusMutate = useMutation(
    dataquery.changeApplicationStatus,
    {
      onError: (error) => {
        toast.error(error.message);
      },
      onSuccess(data) {
        // Wiconsole.log(data);ll be called 3 times
        toast.success(data.message);
        // console.log("Succesful",{data});
        navigate("/admin/application");
      },
    }
  );

  const assignLeads = useMutation(dataquery.assignLeads, {
    onError: (error) => {
      toast.error(error.message);
    },
    onSuccess(data) {
      toast.success(data.message);
      // queryClient.invalidateQueries({ queryKey: ['getAllApplicationLeads'] })
      queryClient.invalidateQueries("getAllApplicationLeads");
    },
  });

  const applicationStatus = useQuery(
    "getApplicationStatus",
    dataquery.getApplicationStatus
  );
  const allEpcWhichShouldBeAssigned = useQuery(
    "getEpcAssignedLeads",
    dataquery.getAllEpcForAssignment
  );
  useEffect(() => {
    allEpcWhichShouldBeAssigned.refetch();
    applicationStatus.refetch();
  }, []);
  const deleteApplicationMutate = useMutation(dataquery.deleteApplication, {
    onError: (error) => {
      toast.error(error.message);
    },
    onSuccess: (data) => {
      toast.success(data.message);
      queryClient.invalidateQueries("getAllApplicationLeads");
      // data.refetch();
    },
  });
  const handleChange = (e, row) => {
    // console.log(e.target.value);
    // console.log(row.original.customer_id);
    const statusId = e.target.value;
    const id = row.original.application_id;
    row.original.status_id = statusId;
    const data = changeApplicationStatusMutate.mutate({ id, statusId });
    // console.log(data, "status");
  };

  const handleNavigateToDetails = (row) => {
    const id = row.original.application_id;
    // console.log(id);
    navigate(`/admin/application/details/${id}`);
  };

  const handleDelete = (data) => {
    // const id = data.application_id;
    console.log("data");
    console.log(data);
    console.log("id");
    console.log("id");
    // deleteApplicationMutate.mutate(id);
  };
  const handleAssignedChange = (e, row) => {
    const epcId = e.target.value;
    const id = row.original.application_id;
    row.original.epc_id = epcId;
    const data = assignLeads.mutate({ id, epcId });
  };

  const handleDownloadQuote = (row) => {
    if (row.original.quote) {
      const quoteData = row.original.quote;
      saveAs(quoteData, "quote.pdf");
    } else {
      toast.error("Quote data not available.");
    }
  };

  // console.log(data,"assigned");  }
  const columns = React.useMemo(
    () => [
      { Header: "Sr. No", accessor: "srNo" },
      { Header: "Name", accessor: "customer_name" },
      { Header: "Type", accessor: "customer_type" },
      {
        Header: "Source",
        Cell: (row) => {
          return row.row.original.dsa_name
            ? `DSA - ${row.row.original.dsa_name}`
            : "Organic";
        },
      },
      { Header: "State", accessor: "state" },
      { Header: "City", accessor: "city" },
      { Header: "Email", accessor: "email" },
      { Header: "Phone", accessor: "phone" },
      { Header: "Date & Time", accessor: "created_on" },
      // { Header: "No Of Projects" },
      {
        Header: "Financing Required",
        Cell: (row) => {
          if (row.row.original.is_finance_required) {
            return <span>Yes</span>;
          } else {
            return <span>No</span>;
          }
        },
      },
      {
        Header: "Assigned to",
        Cell: ({ row }) => (
          <select
            onChange={(e) => handleAssignedChange(e, row)}
            defaultValue={row.original.assigned_to_epc}
            value={row.original.assigned_to_epc}
            className="form-select"
            style={{ width: "200px" }}
          >
            <option value="unassigned">--Unassigned--</option>
            {allEpcWhichShouldBeAssigned.data &&
              allEpcWhichShouldBeAssigned.data.map((data) => (
                <option key={data.epc_id} value={data.epc_id}>
                  {data.company_name}
                </option>
              ))}
          </select>
        ),
      },
      {
        Header: "Status",
        // maxWidth: 1000,
        // minWidth: 140,
        // width: 1000,
        Cell: ({ row }) => (
          <select
            onChange={(e) => handleChange(e, row)}
            value={row.original.status_id}
            className="form-select"
            style={{ width: "180px" }}
          >
            {applicationStatus.data &&
              applicationStatus.data.map((data) => (
                <option key={data.status_id} value={data.status_id}>
                  {data.status}
                </option>
              ))}
          </select>
        ),
      },
      {
        Header: "Actions",
        Cell: ({ row }) => (
          <>
            <div className="text-nowrap">
              <button
                className="btn btn-sm btn-info icon-btn"
                onClick={() => handleNavigateToDetails(row)}
                title="Details"
              >
                <i className="fa fa-info" aria-hidden="true"></i>
              </button>
              {/* <button
                onClick={() => handleDelete(row)}
                className="ms-2 btn btn-sm btn-danger"
                title="Delete"
              >
                <i className="fa fa-box-archive"></i>
              </button> */}
              <button
                data-bs-toggle="modal"
                data-bs-target={`#id${row.original.application_id}`}
                className="ms-2 btn btn-sm btn-danger"
                title="Delete"
              >
                <i className="fa fa-box-archive"></i>
                <DeleteModal row={row} handleDelete={handleDelete} />
              </button>
              {/* Delete Modal  */}

              {/* <DeleteModal row={row}/> */}
            </div>

            <div className="dropdown d-none">
              <button
                type="button"
                className="btn p-0 dropdown-toggle hide-arrow"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="ti ti-dots-vertical"></i>
              </button>
              <div className="dropdown-menu">
                <Link
                  to={`/admin/application/details/${row.original.application_id}`}
                  id="details"
                  className="dropdown-item"
                >
                  Details
                </Link>
                <button
                  onClick={() => handleDelete(row)}
                  className="dropdown-item"
                >
                  Delete
                </button>
              </div>
            </div>
          </>
        ),
      },
    ],
    [applicationStatus.data, allEpcWhichShouldBeAssigned.data]
  );
  const { data, isLoading, error } = useQuery(
    "getAllApplicationLeads",
    dataquery.getAllApplicationLeads
  );
  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error: {error.message}</div>;
  }
  if (!data || !data.data || !data.data.data) {
    return <div>No data fetched.</div>;
  }
  return (
    <>
      <div className="content-body">
        <div className="page-title">
          <h3>Applications</h3>
        </div>

        <LeadTable
          tableName={"List of Applications"}
          columns={columns}
          tableData={data && data.data && data.data.data}
          handleExport={handleExport}
          isFilterActive={isFilterActive}
          cityallowed
          stateallowed
          statusallowed
          startandenddateallowed
          customertypeallowed
          sourceAllowed
          financingallowed
          assignedtoallowed
        />
        {/* <!-- /dash-card --> */}
      </div>

      {/* <!-- /content body --> */}
    </>
  );
};
export default ApplicationsLeads;
