import React from "react";
//import { useQuery, useQueryClient } from "react-query";
//import * as querydata from "../dataService/DataService";

const Select = (props) => {
  // Function to set the dropdown value forcefully
  const setDropdownValue = (newValue) => {
    // Update the props.values.optionid with the new value
    props.values.optionid = newValue;
    // console.log("valuesetforcfully");
    // console.log(props.values.optionid);
    // Trigger the handleChange function to update the dropdown's selected value
    props.handleChange();
  };
  // props.filterMatrixData(5);
  //const queryClient = useQueryClient()

  // queryClient.invalidateQueries({ queryKey: ['getFilteredCities'] })
  //const getSelectData = useQuery(props.dropdownname, querydata.getEpcRegistrationTypeNew);
  // const getSelectData = useQuery(props.dropdownname, props.dropdowndata);
  const getSelectData = props.dropdowndata;
  // console.log("getSelectData");

  const handleChange = (e) => {};

  // console.log(getSelectData);
  // props.setSelected(props.values && props.values.optionId)
  // console.log(getFilteredCities.data)
  if (getSelectData.isLoading) {
    return <div>Loading...</div>;
  }

  if (getSelectData.error) {
    return <div>Error: {getSelectData.error.message}</div>;
  }

  // if (!getSelectData) {
  //   return <div>No data fetched.</div>;
  // }

  return (
    <>
      <select
        id={props.dropdownidText}
        className="solfin-input"
        name={props.dropdownName}
        //value={props.dropdownName}
        value={props.values && props.values.optionid}
        // value={props.values && props.values.optionid}
        onChange={(e) => props.filterMatrixData(e.target.value)}
        // onBlur={(e)=>setDropdownValue(e.target.value)}
        //onChange={handleChange}
        //onChange={props.onchange}
      >
        {/* <option value="-1" defaultValue="Select">
            ---Select---
          </option> */}
        {getSelectData &&
          getSelectData.map((option) => {
            return (
              <option key={option.option_id} value={option.option_id}>
                {option.option}
              </option>
            );
          })}
      </select>

      {/* {props.errors.optionid && props.touched.optionid ? (
        <p className="form-error text-danger">{props.errors.optionid}</p>
      ) : null} */}
    </>
  );
};

export default Select;
