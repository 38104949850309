import React, { useEffect, useState } from "react";
import GoogleMap from "google-map-react";
const getLatLngFromPincode = async (pincode) => {
  return await getLatLng(pincode);
};

const getLatLng = async (addressToSerach) => {
  if (!addressToSerach) {
    return;
  }
  const apiKey = "AIzaSyB1m5beHM39zUD1IrCyUG2cIM6tkbM6cjc"; // Replace with your own Google Maps API key
  const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
    addressToSerach
  )}&key=${apiKey}`;
  try {
    const response = await fetch(apiUrl);
    const data = await response.json();
    //console.log("here is response")
    if (data.status === "OK" && data.results.length > 0) {
      //console.log(data.results);
      const { lat, lng } = data.results[0].geometry.location;
      return { lat, lng };
    } else {
      throw new Error("Unable to retrieve geolocation data");
    }
  } catch (error) {
    // console.error('Error:', error.message);
    return null;
  }
};

const getLatLngFromAddress = async (address, props) => {
  if (props.pinCode) {
    address = address +" "+ props.pinCode;
  }
  return await getLatLng(address);
};

const getPlaceFromLatLong = async (lat, long, props) => {
  const apiKey = "AIzaSyB1m5beHM39zUD1IrCyUG2cIM6tkbM6cjc"; // Replace with your own Google Maps API key

  const apiUrl =
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=` +
    lat +
    "," +
    long +
    "&key=" +
    apiKey;
  try {
    const response = await fetch(apiUrl);
    const data = await response.json();
    // console.log("here is response")
    if (data.status === "OK" && data.plus_code) {
      //console.log(data.results[0]);
      if (props.values) {
        props.values.address1 = data.plus_code.compound_code;
      }
      //document.getElementById("Address").value = data.results[0].formatted_address;
    } else {
      throw new Error("Unable to retrieve geolocation data");
    }
  } catch (error) {
    // console.error('Error:', error.message);
    return null;
  }
};

const MapContainer = (props) => {
  const [latitude, setLAtitude] = useState(21.73761579745242);
  const [longitude, setlongitude] = useState(76.4808373130677);

  const [zoom, setZoomLevel] = useState(9);
  // Usage example

  useEffect(() => {
    //console.log("useEffect working");
    getLatLngFromPincode(props.pinCode)
      .then((result) => {
        if (result) {
          const { lat, lng } = result;
          setLAtitude(lat);
          setlongitude(lng);
          //setMarkerPosition({lat: lat, lng: lng})
          setZoomLevel(12);
          props.address1 = "";
          props.setgeocords(lat.toString() + "," + lng.toString());
          // console.log('Latitude:', lat);
          // console.log('Longitude:', lng);
        } else {
          // console.log('Geolocation not found');
        }
      })
      .catch((error) => {
        // console.error('Error:', error.message);
      });
  }, [props.pinCode]);

  useEffect(() => {
    //console.log("useEffect address working");
    getLatLngFromAddress(props.address1, props)
      .then((result) => {
        if (result) {
          const { lat, lng } = result;
          setLAtitude(lat);
          setlongitude(lng);
          //setMarkerPosition({lat: lat, lng: lng})
          setZoomLevel(12);
          props.setgeocords(lat.toString() + "," + lng.toString());
          // console.log('Latitude:', lat);
          // console.log('Longitude:', lng);
        } else {
          // console.log('Geolocation not found');
        }
      })
      .catch((error) => {
        // console.error('Error:', error.message);
      });
  }, [props.address1]);

  const mapCenter = { lat: latitude, lng: longitude };

  const handleMarkerClick = ({ lat, lng }) => {
    // console.log(lat.toString()+","+lng.toString())
    //setMarkerPosition({ lat, lng });
    setLAtitude(lat);
    setlongitude(lng);
    props.setgeocords(lat.toString() + "," + lng.toString());
    getPlaceFromLatLong(lat, lng, props);
    //console.log("click loaded...");
    // mapCenter=markerPosition;
  };
  const handleApiLoaded = (map, maps) => {
    const trafficLayer = new maps.TrafficLayer();
    trafficLayer.setMap(map);
    //console.log("api loaded...");
    //setMarkerPosition({ lat, lng });
  };
  const handleDrag = (map) => {
    const { lat, lng } = map.center;
    //setMarkerPosition({ lat, lng });
    //console.log("drag loaded...");
    // // setLAtitude(lat);
    // //         setlongitude(lng);
    props.setgeocords(lat.toString() + "," + lng.toString());
  };

  const mapOptions = {
    fullscreenControl: true,
    labels: true,
    mapTypeControl: true,
    streetViewControl: true,
  };
  return (
    <div style={{ height: "400px", width: "100%" }}>
      <GoogleMap
        bootstrapURLKeys={{
          region: "india",
          key: "AIzaSyB1m5beHM39zUD1IrCyUG2cIM6tkbM6cjc",
          libraries: ["places", "geometry", "drawing", "visualization"],
          language: "en",
        }} // Replace with your API key
        defaultCenter={mapCenter}
        defaultZoom={9}
        zoom={zoom}
        onClick={handleMarkerClick}
        center={mapCenter}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        onChange={handleDrag}
        options={mapOptions}
        disabled={props.disabled}
      >
        <div
          style={{
            width: "22px",
            height: "36px",
            backgroundImage: "url(" + "/images/googlemarker.png" + ")",
            //backgroundColor:'red',
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "21px",
            position: "relative",
            top: "-34px",
            left: "-10px",
            // transform: `translate(${xaxis}, ${yaxis})`,
          }}
        ></div>
      </GoogleMap>
    </div>
  );

  // var container = document.querySelector("#contentContainer");

  // container.addEventListener("click", getClickPosition, false);
};

export default MapContainer;
