import { Link, useNavigate } from "react-router-dom";
const TermsAndConditions = ({isChecked,setIsChecked})=>{
    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
      };
    return(<div
        id="hideformcheckbox"
        className="check-conditions d-flex justify-content-between align-items-center hideform"
      >
        <label
          htmlFor="AllowTerms"
          className="checkboxes-view p-0 position-relative d-flex"
        >
          <input
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
            name=""
            className="position-absolute top-0"
            id="AllowTerms"
          />
          <div className="now-check position-relative mt-0"></div>
          <p className="mb-0" style={{ "line-height": "25px" }}>
            By clicking here, I state that I have read and understood the{" "}
            <Link
              to="/Termsandconditions"
              className="fw-bold text-dark text-decoration-underline"
              target="_blank"
            >
              Terms and Conditions
            </Link>
            <span>, </span>
            <Link
              to="/PrivacyPolicy"
              className="fw-bold text-dark text-decoration-underline"
              target="_blank"
            >
              Privacy Policy
            </Link>
            <span> and </span>{" "}
            <Link
              to="/CookiePolicy"
              class="fw-bold text-dark text-decoration-underline"
              target="_blank"
            >
              Cookie Policy.
            </Link>{" "}
            Also, I provide my consent to Solfin (XS Finance and
            Investment Private Limited) and its registered lending
            partners to fetch company's credit report from RBI registered
            credit bureaus.
          </p>
        </label>
      </div>);
      
}
export default TermsAndConditions;