import React, { useState } from "react";
import { useQuery } from "react-query";
import * as querydata from "../../dataService/DataService";
import { toast } from "react-toastify";
import Pagination from "../../components/Pagination";

const DsaPartnerList = () => {
  const [searchQry, setSearchQry] = useState("");
  const [page, setPage] = useState(1);

  const dsaPublishingDetails = useQuery(
    "getDsaPublishingDetails",
    querydata.getDsaPublishingDetails,
    {
      onError: (error) => {
        toast.error(error);
      },
    }
  );
  if(!dsaPublishingDetails.data){
    return <div>Loading... </div>
  }

  const filterData = dsaPublishingDetails?.data?.data?.filter(
    (asd) =>
      asd.dsa_name.toLowerCase().includes(searchQry) ||
      asd.phone.includes(searchQry) ||
      asd.email.includes(searchQry) ||
      asd.service_area.toLowerCase().includes(searchQry)
  );

  const selectPageHandler = (selectedPage) => {
    console.log("clicked select page handler ");
    console.log("selectedPage")
    console.log(selectedPage)
    if (
      selectedPage >= 1 &&
      selectedPage <= Math.ceil(filterData.length / 9) &&
      selectedPage !== page
    ) {
      setPage(Number(selectedPage));
    }
  };

  console.log("dsaPublishingDetails?.data?.data.length === 0")
  console.log(dsaPublishingDetails?.data?.data.length === 0)
  return (
    <>{dsaPublishingDetails &&
      dsaPublishingDetails?.data?.data.length !== 0 ?(
      <div class="list-of-partners">
        <div class="row">
          <div className="solfin-search d-flex justify-content-end">
            <form action="#" method="get">
              <div className="position-relative">
                <div className="form-group mb-2">
                  <input
                    id="Address"
                    className="form-control bg-transparent pt-2 pb-2 px-3"
                    placeholder="Search here..."
                    name="search"
                    onChange={(e) => setSearchQry(e.target.value.toLowerCase())}
                  />
                  <span className="fa fa-search position-absolute end-0 top-0 h-100 d-flex align-items-center px-3 opacity-50"></span>
                </div>
              </div>
            </form>
          </div>
          {dsaPublishingDetails &&
            dsaPublishingDetails?.data &&
            filterData
              .slice(page * 9 - 9, page * 9)
              .map((data) => (
                <div class="col-xl-4 col-md-6">
                  <div class="partners-view border rounded-4">
                    <div class="contact-wrap">
                      <div class="partner-name mb-3">
                        <h6 class="fw-semibold">
                          {data.dsa_name.charAt(0).toUpperCase() +
                            data.dsa_name.slice(1)}
                        </h6>
                      </div>

                      <div class="partner-contact">
                        <span class="fw-semibold">Contact</span>
                        <div class="contact-view mt-2">
                          <a
                            class="fw-medium text-decoration-none"
                            href="tel:+919599446877"
                          >
                            <i class="fa fa-phone"></i> +91 {data.phone}
                          </a>
                          <a
                            class="fw-medium text-decoration-none"
                            href="mailto:info@gmail.com"
                          >
                            <i class="fa fa-envelope-o"></i> {data.email}
                          </a>
                        </div>
                      </div>

                      <div class="served-areas mt-4">
                        <div className="d-flex align-items-center justify-content-between">
                          <strong>Area Serves</strong>
                          {data.service_area.split(",").length > 2 && (
                            <a
                              href="#"
                              className="morebtn text-decoration-underline fw-semibold"
                              data-bs-toggle="modal"
                              data-bs-target={`#id${data.dsa_id}`}
                            >
                              View More
                              <ServiceAreaModal data={data} />
                            </a>
                          )}
                        </div>
                        <div class="serves-area mt-2">
                          {data.service_area
                            .split(",")
                            .slice(0, 2)
                            .map((state) => (
                              <>
                                <a
                                  class="tag text-decoration-none fw-light border rounded-5"
                                  href="#"
                                >
                                  {state}
                                </a>
                              </>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
        </div>
        {/* pagination  */}
       <Pagination page={page} setPage={setPage} filterData={filterData}/>
      </div>

):(<div className="fw-bold heading-color blank-partners-list">
Our partners are taking a little time to get dressed up for the big
reveal... <span className="text-theme-green"></span>
</div>)}
    </>
  );
};

export default DsaPartnerList;

const ServiceAreaModal = ({ data }) => {
  return (
    <div
      className="modal fade"
      id={`id${data.dsa_id}`}
      tabindex="-1"
      aria-labelledby="MoreTagsModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="MoreTagsModalLabel">
              Area Serves
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <a
              className="tag text-decoration-none fw-light border rounded-5 pt-2 pb-2 pe-3 ps-3"
              href="#"
            >
              {data.service_area}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
