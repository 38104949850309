import React from "react";
import MissionBarChart from "./components/MissionBarChart";
import { Link } from "react-router-dom";
const Mission = () => {
  return (
    <>
      <section>
        <div className="about-solfin header-space pt-1">
          <div className="container custom-container">
            <div className="row">
              <div className="col-12">
                <div className="solfin-heading">
                  <h2 className="fw-bold heading-color">
                    Vision &amp;{" "}
                    <span className="text-theme-green">Mission</span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="solfin-vision-mission">
          <div className="container custom-container">
            <div className="row">
              <div className="col-12">
                <div className="solfin-heading">
                  <div className="small-heading mb-3 fw-semibold position-relative text-uppercase">
                    Vision
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-8 pe-lg-5">
                    <div className="vision-mission-text mt-3">
                      <p>
                        "Empower and facilitate a sustainable future by creating
                        inclusive platforms, innovative products, and
                        facilitating accessible finance for all climate-friendly
                        activities."
                      </p>
                      <div className="para-text mt-3">
                        <ul className="solfin-list list-disc list-unstyled ps-lg-4">
                          <li>
                            <p>
                              <b>Inclusive Platforms:</b> The organization aims
                              to develop and nurture an ecosystem that brings
                              together diverse stakeholders and resources
                              related to sustainable practices.
                            </p>
                          </li>
                          <li>
                            <p>
                              <b>Innovative Products:</b> The organization seeks
                              to create and promote innovative products that
                              contribute to climate-friendly activities. These
                              products may include eco-friendly technologies,
                              renewable energy solutions and other advancements
                              that support sustainable practices across various
                              sectors.
                            </p>
                          </li>
                          <li>
                            <p>
                              <b>Accessible Finance:</b> Recognizing the
                              financial barriers that often hinder the adoption
                              of sustainable practices, the organization is
                              committed to making finance more accessible for
                              climate-friendly activities. This could involve
                              developing financial instruments, investment
                              opportunities, and funding mechanisms tailored to
                              support sustainable initiatives for both
                              established entities and startups.
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="solfin-vision-img">
                      <img
                        src="../images/solfin-vision.jpg"
                        alt="Vision"
                        title="Vision"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="list-mission d-none">
              <div className="row mt-1 g-4">
                <div className="col-md-4">
                  <div className="feature">
                    <div className="feature-wrap theme-green-bg pt-xl-4 pt-3 pb-xl-4 pb-3 ps-xl-4 ps-3 pe-xl-4 pe-3">
                      <div className="feature-icon d-flex align-items-center justify-content-center rounded-pill bg-white ms-md-auto">
                        <img
                          src="../images/energy-solutions.svg"
                          alt="Energy Solutions"
                          title="Energy Solutions"
                        />
                      </div>

                      <div className="feature-text mt-xl-4 mt-3">
                        <h6 className="fw-bold heading-color">Lorem ipsum</h6>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="feature">
                    <div className="feature-wrap light-gray-bg pt-xl-4 pt-3 pb-xl-4 pb-3 ps-xl-4 ps-3 pe-xl-4 pe-3">
                      <div className="feature-icon theme-green-bg d-flex align-items-center justify-content-center rounded-pill ms-md-auto">
                        <img
                          src="../images/energy-solutions.svg"
                          alt="Energy Solutions"
                          title="Energy Solutions"
                        />
                      </div>

                      <div className="feature-text mt-xl-4 mt-3">
                        <h6 className="fw-bold heading-color">Lorem ipsum</h6>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="feature">
                    <div className="feature-wrap theme-green-bg pt-xl-4 pt-3 pb-xl-4 pb-3 ps-xl-4 ps-3 pe-xl-4 pe-3">
                      <div className="feature-icon d-flex align-items-center justify-content-center rounded-pill bg-white ms-md-auto">
                        <img
                          src="../images/energy-solutions.svg"
                          alt="Energy Solutions"
                          title="Energy Solutions"
                        />
                      </div>

                      <div className="feature-text mt-xl-4 mt-3">
                        <h6 className="fw-bold heading-color">Lorem ipsum</h6>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="feature">
                    <div className="feature-wrap light-gray-bg pt-xl-4 pt-3 pb-xl-4 pb-3 ps-xl-4 ps-3 pe-xl-4 pe-3">
                      <div className="feature-icon theme-green-bg d-flex align-items-center justify-content-center rounded-pill ms-md-auto">
                        <img
                          src="../images/energy-solutions.svg"
                          alt="Energy Solutions"
                          title="Energy Solutions"
                        />
                      </div>

                      <div className="feature-text mt-xl-4 mt-3">
                        <h6 className="fw-bold heading-color">Lorem ipsum</h6>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="feature">
                    <div className="feature-wrap theme-green-bg pt-xl-4 pt-3 pb-xl-4 pb-3 ps-xl-4 ps-3 pe-xl-4 pe-3">
                      <div className="feature-icon d-flex align-items-center justify-content-center rounded-pill bg-white ms-md-auto">
                        <img
                          src="../images/energy-solutions.svg"
                          alt="Energy Solutions"
                          title="Energy Solutions"
                        />
                      </div>

                      <div className="feature-text mt-xl-4 mt-3">
                        <h6 className="fw-bold heading-color">Lorem ipsum</h6>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="feature">
                    <div className="feature-wrap light-gray-bg pt-xl-4 pt-3 pb-xl-4 pb-3 ps-xl-4 ps-3 pe-xl-4 pe-3">
                      <div className="feature-icon theme-green-bg d-flex align-items-center justify-content-center rounded-pill ms-md-auto">
                        <img
                          src="../images/energy-solutions.svg"
                          alt="Energy Solutions"
                          title="Energy Solutions"
                        />
                      </div>

                      <div className="feature-text mt-xl-4 mt-3">
                        <h6 className="fw-bold heading-color">Lorem ipsum</h6>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="solfin-vision-mission d-none">
          <div className="container custom-container">
            <div className="row">
              <div className="col-12">
                <div className="solfin-heading">
                  <div className="small-heading mb-3 fw-semibold position-relative text-uppercase">
                    Milestones Achieved
                  </div>
                  <h2 className="fw-bold heading-color">
                    Milestones{" "}
                    <span className="text-theme-green">Achieved</span>
                  </h2>
                </div>
                {/* <!-- /solfin-heading --> */}
                <div className="vision-mission-text mt-3">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries.
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer.
                  </p>
                </div>
              </div>
              {/* <!-- /col-12 --> */}
            </div>
            {/* <!-- /row --> */}
            <div className="list-mission">
              <div className="row mt-1 g-4">
                <div className="col-md-4">
                  <div className="feature">
                    <div className="feature-wrap theme-green-bg pt-xl-4 pt-3 pb-xl-4 pb-3 ps-xl-4 ps-3 pe-xl-4 pe-3">
                      <div className="feature-icon d-flex align-items-center justify-content-center rounded-pill bg-white ms-md-auto">
                        <img
                          src="images/energy-solutions.svg"
                          alt="Energy Solutions"
                          title="Energy Solutions"
                        />
                      </div>
                      {/* <!-- /feature-icon --> */}
                      <div className="feature-text mt-xl-4 mt-3">
                        <h6 className="fw-bold heading-color">Lorem ipsum</h6>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry.{" "}
                        </p>
                      </div>
                      {/* <!-- feature-text --> */}
                    </div>
                    {/* <!-- /feature-wrap --> */}
                  </div>
                  {/* <!-- /feature --> */}
                </div>
                {/* <!-- /col-md-4 --> */}
                <div className="col-md-4">
                  <div className="feature">
                    <div className="feature-wrap light-gray-bg pt-xl-4 pt-3 pb-xl-4 pb-3 ps-xl-4 ps-3 pe-xl-4 pe-3">
                      <div className="feature-icon theme-green-bg d-flex align-items-center justify-content-center rounded-pill ms-md-auto">
                        <img
                          src="images/energy-solutions.svg"
                          alt="Energy Solutions"
                          title="Energy Solutions"
                        />
                      </div>
                      {/* <!-- /feature-icon --> */}
                      <div className="feature-text mt-xl-4 mt-3">
                        <h6 className="fw-bold heading-color">Lorem ipsum</h6>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry.{" "}
                        </p>
                      </div>
                      {/* <!-- feature-text --> */}
                    </div>
                    {/* <!-- /feature-wrap --> */}
                  </div>
                  {/* <!-- /feature --> */}
                </div>
                {/* <!-- /col-md-4 --> */}
                <div className="col-md-4">
                  <div className="feature">
                    <div className="feature-wrap theme-green-bg pt-xl-4 pt-3 pb-xl-4 pb-3 ps-xl-4 ps-3 pe-xl-4 pe-3">
                      <div className="feature-icon d-flex align-items-center justify-content-center rounded-pill bg-white ms-md-auto">
                        <img
                          src="images/energy-solutions.svg"
                          alt="Energy Solutions"
                          title="Energy Solutions"
                        />
                      </div>
                      {/* <!-- /feature-icon --> */}
                      <div className="feature-text mt-xl-4 mt-3">
                        <h6 className="fw-bold heading-color">Lorem ipsum</h6>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry.{" "}
                        </p>
                      </div>
                      {/* <!-- feature-text --> */}
                    </div>
                    {/* <!-- /feature-wrap --> */}
                  </div>
                  {/* <!-- /feature --> */}
                </div>
                {/* <!-- /col-md-4 --> */}
                <div className="col-md-4">
                  <div className="feature">
                    <div className="feature-wrap light-gray-bg pt-xl-4 pt-3 pb-xl-4 pb-3 ps-xl-4 ps-3 pe-xl-4 pe-3">
                      <div className="feature-icon theme-green-bg d-flex align-items-center justify-content-center rounded-pill ms-md-auto">
                        <img
                          src="images/energy-solutions.svg"
                          alt="Energy Solutions"
                          title="Energy Solutions"
                        />
                      </div>
                      {/* <!-- /feature-icon --> */}
                      <div className="feature-text mt-xl-4 mt-3">
                        <h6 className="fw-bold heading-color">Lorem ipsum</h6>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry.{" "}
                        </p>
                      </div>
                      {/* <!-- feature-text --> */}
                    </div>
                    {/* <!-- /feature-wrap --> */}
                  </div>
                  {/* <!-- /feature --> */}
                </div>
                {/* <!-- /col-md-4 --> */}
                <div className="col-md-4">
                  <div className="feature">
                    <div className="feature-wrap theme-green-bg pt-xl-4 pt-3 pb-xl-4 pb-3 ps-xl-4 ps-3 pe-xl-4 pe-3">
                      <div className="feature-icon d-flex align-items-center justify-content-center rounded-pill bg-white ms-md-auto">
                        <img
                          src="images/energy-solutions.svg"
                          alt="Energy Solutions"
                          title="Energy Solutions"
                        />
                      </div>
                      {/* <!-- /feature-icon --> */}
                      <div className="feature-text mt-xl-4 mt-3">
                        <h6 className="fw-bold heading-color">Lorem ipsum</h6>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry.{" "}
                        </p>
                      </div>
                      {/* <!-- feature-text --> */}
                    </div>
                    {/* <!-- /feature-wrap --> */}
                  </div>
                  {/* <!-- /feature --> */}
                </div>
                {/* <!-- /col-md-4 --> */}
                <div className="col-md-4">
                  <div className="feature">
                    <div className="feature-wrap light-gray-bg pt-xl-4 pt-3 pb-xl-4 pb-3 ps-xl-4 ps-3 pe-xl-4 pe-3">
                      <div className="feature-icon theme-green-bg d-flex align-items-center justify-content-center rounded-pill ms-md-auto">
                        <img
                          src="images/energy-solutions.svg"
                          alt="Energy Solutions"
                          title="Energy Solutions"
                        />
                      </div>
                      {/* <!-- /feature-icon --> */}
                      <div className="feature-text mt-xl-4 mt-3">
                        <h6 className="fw-bold heading-color">Lorem ipsum</h6>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry.{" "}
                        </p>
                      </div>
                      {/* <!-- feature-text --> */}
                    </div>
                    {/* <!-- /feature-wrap --> */}
                  </div>
                  {/* <!-- /feature --> */}
                </div>
                {/* <!-- /col-md-4 --> */}
              </div>
              {/* <!-- /row --> */}
            </div>
            {/* <!-- /list-mission --> */}
          </div>
        </div>
      </section>

      <section>
        <div className="solfin-vision-mission mt-4 mt-xl-0">
          <div className="container custom-container">
            <div className="row">
              <div className="col-12">
                <div className="solfin-heading">
                  <div className="small-heading mb-3 fw-semibold position-relative text-uppercase">
                    UN Goals
                  </div>
                  <h2 className="fw-bold heading-color">
                    Five ways to jump-start the{" "}
                    <span className="text-theme-green">renewable energy</span>{" "}
                    transition now
                  </h2>
                </div>
                {/* <!-- /solfin-heading --> */}
                <div className="vision-mission-text mt-3">
                  <p>
                    The UN Secretary-General outlines five critical actions the
                    world needs to prioritize now to transform our energy
                    systems and speed up the shift to renewable energy -
                    “because without renewables, there can be no future.”
                  </p>
                </div>
              </div>
              {/* <!-- /col-12 --> */}
            </div>
            {/* <!-- /row --> */}
            <div className="list-mission">
              <div className="row mt-0 mt-1 g-4">
                <div className="col-md-4">
                  <div className="feature h-100">
                    <div className="feature-wrap bg-white h-100 pt-xl-4 pt-3 pb-xl-4 pb-3 ps-xl-4 ps-3 pe-xl-4 pe-3">
                      <div className="feature-wrap-image d-flex justify-content-center align-items-center">
                        <img
                          src="images/5energy_makerenewableenergy01.png"
                          width="500"
                          alt=""
                        />
                      </div>
                    </div>
                    {/* <!-- /feature-wrap --> */}
                  </div>
                  {/* <!-- /feature --> */}
                </div>
                {/* <!-- /col-md-4 --> */}
                <div className="col-md-4">
                  <div className="feature h-100">
                    <div className="feature-wrap light-gray-bg h-100 pt-xl-4 pt-3 pb-xl-4 pb-3 ps-xl-4 ps-3 pe-xl-4 pe-3">
                      <div className="feature-icon d-none d-flex align-items-center justify-content-center rounded-pill bg-white ms-md-auto">
                        <img
                          src="images/energy-solutions.svg"
                          alt="Energy Solutions"
                          title="Energy Solutions"
                        />
                      </div>
                      {/* <!-- /feature-icon --> */}
                      <div className="feature-text mt-xl-4 mt-3">
                        <h6 className="fw-bold heading-color">
                          Make renewable energy technology a global public good
                        </h6>
                        <p>
                          Essential technologies such as battery storage systems
                          allow energy from renewables, like solar and wind, to
                          be stored and released when people, communities and
                          businesses need power. They help to increase energy
                          system flexibility due to their unique capability to
                          quickly absorb, hold and re-inject electricity, says
                          the International Renewable Energy Agency.
                        </p>
                      </div>
                      {/* <!-- feature-text --> */}
                    </div>
                    {/* <!-- /feature-wrap --> */}
                  </div>
                  {/* <!-- /feature --> */}
                </div>
                {/* <!-- /col-md-4 --> */}
                <div className="col-md-4">
                  <div className="feature h-100">
                    <div className="feature-wrap light-gray-bg h-100 pt-xl-4 pt-3 pb-xl-4 pb-3 ps-xl-4 ps-3 pe-xl-4 pe-3">
                      <div className="feature-icon d-none theme-green-bg d-flex align-items-center justify-content-center rounded-pill ms-md-auto">
                        <img
                          src="images/energy-solutions.svg"
                          alt="Energy Solutions"
                          title="Energy Solutions"
                        />
                      </div>
                      {/* <!-- /feature-icon --> */}
                      <div className="feature-text mt-xl-4 mt-3">
                        <h6 className="fw-bold heading-color">
                          Improve global access to components and raw materials
                        </h6>
                        <p>
                          A robust supply of renewable energy components and raw
                          materials is essential. More widespread access to all
                          the key components and materials - from the minerals
                          needed to produce wind turbines and electricity
                          networks, to electric vehicles - will be key.
                        </p>
                      </div>
                      {/* <!-- feature-text --> */}
                    </div>
                    {/* <!-- /feature-wrap --> */}
                  </div>
                  {/* <!-- /feature --> */}
                </div>
                {/* <!-- /col-md-4 --> */}
                <div className="col-md-4">
                  <div className="feature h-100">
                    <div className="feature-wrap light-gray-bg h-100 pt-xl-4 pt-3 pb-xl-4 pb-3 ps-xl-4 ps-3 pe-xl-4 pe-3">
                      <div className="feature-icon d-none d-flex align-items-center justify-content-center rounded-pill bg-white ms-md-auto">
                        <img
                          src="images/energy-solutions.svg"
                          alt="Energy Solutions"
                          title="Energy Solutions"
                        />
                      </div>
                      {/* <!-- /feature-icon --> */}
                      <div className="feature-text mt-xl-4 mt-3">
                        <h6 className="fw-bold heading-color">
                          Level the playing field for renewable energy
                          technologies
                        </h6>
                        <p>
                          While global cooperation and coordination is critical,
                          domestic policy frameworks must urgently be reformed
                          to streamline and fast-track renewable energy projects
                          and catalyze private sector investments.
                        </p>
                      </div>
                      {/* <!-- feature-text --> */}
                    </div>
                    {/* <!-- /feature-wrap --> */}
                  </div>
                  {/* <!-- /feature --> */}
                </div>
                {/* <!-- /col-md-4 --> */}
                <div className="col-md-4">
                  <div className="feature h-100">
                    <div className="feature-wrap light-gray-bg h-100 pt-xl-4 pt-3 pb-xl-4 pb-3 ps-xl-4 ps-3 pe-xl-4 pe-3">
                      <div className="feature-icon d-none theme-green-bg d-flex align-items-center justify-content-center rounded-pill ms-md-auto">
                        <img
                          src="images/energy-solutions.svg"
                          alt="Energy Solutions"
                          title="Energy Solutions"
                        />
                      </div>
                      {/* <!-- /feature-icon --> */}
                      <div className="feature-text mt-xl-4 mt-3">
                        <h6 className="fw-bold heading-color">
                          Shift energy subsidies from fossil fuels to renewable
                          energy
                        </h6>
                        <p>
                          Shifting subsidies from fossil fuels to renewable
                          energy not only cuts emissions, it also contributes to
                          the sustainable economic growth, job creation, better
                          public health and more equality, particularly for the
                          poor and most vulnerable communities around the world.
                        </p>
                      </div>
                      {/* <!-- feature-text --> */}
                    </div>
                    {/* <!-- /feature-wrap --> */}
                  </div>
                  {/* <!-- /feature --> */}
                </div>
                {/* <!-- /col-md-4 --> */}
                <div className="col-md-4">
                  <div className="feature h-100">
                    <div className="feature-wrap light-gray-bg h-100 pt-xl-4 pt-3 pb-xl-4 pb-3 ps-xl-4 ps-3 pe-xl-4 pe-3">
                      <div className="feature-icon d-none d-flex align-items-center justify-content-center rounded-pill bg-white ms-md-auto">
                        <img
                          src="images/energy-solutions.svg"
                          alt="Energy Solutions"
                          title="Energy Solutions"
                        />
                      </div>
                      {/* <!-- /feature-icon --> */}
                      <div className="feature-text mt-xl-4 mt-3">
                        <h6 className="fw-bold heading-color">
                          Triple investments in renewables
                        </h6>
                        <p>
                          At least $4 trillion a year needs to be invested in
                          renewable energy until 2030 – including investments in
                          technology and infrastructure – to allow us to reach
                          net-zero emissions by 2050.
                        </p>
                      </div>
                      {/* <!-- feature-text --> */}
                    </div>
                    {/* <!-- /feature-wrap --> */}
                  </div>
                  {/* <!-- /feature --> */}
                </div>
                {/* <!-- /col-md-4 --> */}
                <div className="col-md-12">
                  <div className="feature h-100 mt-5">
                    <div className="feature-wrap bg-white h-100 align-items-center d-flex">
                      <h6 className="fw-semibold mb-4">
                        This has been sourced from a United Nations Climate
                        Action{" "}
                        <a
                          className="text-decoration-none"
                          href="https://www.un.org/en/climatechange/raising-ambition/renewable-energy-transition?gclid=CjwKCAjwkLCkBhA9EiwAka9QRvqz5JTEbZCX3tisTSN72WP_03_-8BpXUkdlvJU46QYzYP6Niq7UDhoCr9YQAvD_BwE"
                          target="_blank"
                        >
                          <span>Article</span>
                        </a>
                      </h6>
                    </div>
                    {/* <!-- /feature-wrap --> */}
                  </div>
                  {/* <!-- /feature --> */}
                </div>
                {/* <!-- /col-md-4 --> */}
              </div>
              {/* <!-- /row --> */}
            </div>
            {/* <!-- /list-mission --> */}

            <div className="row mt-100">
              <div className="col-12">
                <div className="solfin-heading">
                  <h2 className="fw-bold heading-color">
                    <span className="text-theme-green">Renewable energy</span> –
                    powering a safer future
                  </h2>
                </div>
                {/* <!-- /solfin-heading --> */}
                <div className="vision-mission-text mt-3">
                  <p>
                    Here are five reasons why accelerating the transition to
                    clean energy is the pathway to a healthy, livable planet
                    today and for generations to come.
                  </p>
                  <div className="energy-cover-image">
                    <img
                      src="images/energy_cover01.jpg"
                      className="w-100"
                      alt="Energy Cover"
                      title="Energy Cover"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- /row --> */}
            <div className="list-mission">
              <div className="row mt-0 mt-1 g-4">
                <div className="col-md-4">
                  <div className="feature h-100">
                    <div className="feature-wrap light-gray-bg h-100 pt-xl-4 pt-3 pb-xl-4 pb-3 ps-xl-4 ps-3 pe-xl-4 pe-3">
                      <div className="feature-icon d-none d-flex align-items-center justify-content-center rounded-pill bg-white ms-md-auto">
                        <img
                          src="images/energy-solutions.svg"
                          alt="Energy Solutions"
                          title="Energy Solutions"
                        />
                      </div>
                      {/* <!-- /feature-icon --> */}
                      <div className="feature-text mt-xl-4 mt-3">
                        <h6 className="fw-bold heading-color">
                          Renewable energy sources are all around us
                        </h6>
                        <p>
                          Renewable energy sources are available in all
                          countries, and their potential is yet to be fully
                          harnessed. The International Renewable Energy Agency
                          (IRENA) estimates that 90 percent of the world’s
                          electricity can and should come from renewable energy
                          by 2050
                        </p>
                      </div>
                      {/* <!-- feature-text --> */}
                    </div>
                    {/* <!-- /feature-wrap --> */}
                  </div>
                  {/* <!-- /feature --> */}
                </div>
                {/* <!-- /col-md-4 --> */}
                <div className="col-md-4">
                  <div className="feature h-100">
                    <div className="feature-wrap light-gray-bg h-100 pt-xl-4 pt-3 pb-xl-4 pb-3 ps-xl-4 ps-3 pe-xl-4 pe-3">
                      <div className="feature-icon d-none theme-green-bg d-flex align-items-center justify-content-center rounded-pill ms-md-auto">
                        <img
                          src="images/energy-solutions.svg"
                          alt="Energy Solutions"
                          title="Energy Solutions"
                        />
                      </div>
                      {/* <!-- /feature-icon --> */}
                      <div className="feature-text mt-xl-4 mt-3">
                        <h6 className="fw-bold heading-color">
                          Renewable energy is cheaper
                        </h6>
                        <p>
                          Prices for renewable energy technologies are dropping
                          rapidly. Falling prices make renewable energy more
                          attractive all around – including to low- and
                          middle-income countries, where most of the additional
                          demand for new electricity will come from.
                        </p>
                      </div>
                      {/* <!-- feature-text --> */}
                    </div>
                    {/* <!-- /feature-wrap --> */}
                  </div>
                  {/* <!-- /feature --> */}
                </div>
                {/* <!-- /col-md-4 --> */}
                <div className="col-md-4">
                  <div className="feature h-100">
                    <div className="feature-wrap light-gray-bg h-100 pt-xl-4 pt-3 pb-xl-4 pb-3 ps-xl-4 ps-3 pe-xl-4 pe-3">
                      <div className="feature-icon d-none d-flex align-items-center justify-content-center rounded-pill bg-white ms-md-auto">
                        <img
                          src="images/energy-solutions.svg"
                          alt="Energy Solutions"
                          title="Energy Solutions"
                        />
                      </div>
                      {/* <!-- /feature-icon --> */}
                      <div className="feature-text mt-xl-4 mt-3">
                        <h6 className="fw-bold heading-color">
                          Renewable energy is healthier
                        </h6>
                        <p>
                          According to the World Health Organization (WHO),
                          about 99 percent of people in the world breathe air
                          that exceeds air quality limits and threatens their
                          health, and more than 13 million deaths around the
                          world each year are due to avoidable environmental
                          causes, including air pollution.
                        </p>
                      </div>
                      {/* <!-- feature-text --> */}
                    </div>
                    {/* <!-- /feature-wrap --> */}
                  </div>
                  {/* <!-- /feature --> */}
                </div>
                {/* <!-- /col-md-4 --> */}
                <div className="col-md-4">
                  <div className="feature h-100">
                    <div className="feature-wrap light-gray-bg pt-xl-4 pt-3 pb-xl-4 pb-3 ps-xl-4 ps-3 pe-xl-4 pe-3">
                      <div className="feature-icon d-none theme-green-bg d-flex align-items-center justify-content-center rounded-pill ms-md-auto">
                        <img
                          src="images/energy-solutions.svg"
                          alt="Energy Solutions"
                          title="Energy Solutions"
                        />
                      </div>
                      {/* <!-- /feature-icon --> */}
                      <div className="feature-text mt-xl-4 mt-3">
                        <h6 className="fw-bold heading-color">
                          Renewable energy creates jobs
                        </h6>
                        <p>
                          Every dollar of investment in renewables creates three
                          times more jobs than in the fossil fuel industry. The
                          IEA estimates that the transition towards net-zero
                          emissions will lead to an overall increase in energy
                          sector jobs.
                        </p>
                      </div>
                      {/* <!-- feature-text --> */}
                    </div>
                    {/* <!-- /feature-wrap --> */}
                  </div>
                  {/* <!-- /feature --> */}
                </div>
                {/* <!-- /col-md-4 --> */}
                <div className="col-md-4">
                  <div className="feature h-100">
                    <div className="feature-wrap light-gray-bg pt-xl-4 pt-3 pb-xl-4 pb-3 ps-xl-4 ps-3 pe-xl-4 pe-3">
                      <div className="feature-icon d-none d-flex align-items-center justify-content-center rounded-pill bg-white ms-md-auto">
                        <img
                          src="images/energy-solutions.svg"
                          alt="Energy Solutions"
                          title="Energy Solutions"
                        />
                      </div>
                      {/* <!-- /feature-icon --> */}
                      <div className="feature-text mt-xl-4 mt-3">
                        <h6 className="fw-bold heading-color">
                          Renewable energy makes economic sense
                        </h6>
                        <p>
                          About $5.9 trillion was spent on subsidizing the
                          fossil fuel industry in 2020, including through
                          explicit subsidies, tax breaks, and health and
                          environmental damages that were not priced into the
                          cost of fossil fuels. In comparison, about $4 trillion
                          a year needs to be invested in renewable energy until
                          2030 – including investments in technology and
                          infrastructure – to allow us to reach net-zero
                          emissions by 2050.
                        </p>
                      </div>
                      {/* <!-- feature-text --> */}
                    </div>
                    {/* <!-- /feature-wrap --> */}
                  </div>
                  {/* <!-- /feature --> */}
                </div>
                {/* <!-- /col-md-4 --> */}
                <div className="col-md-4">
                  <div className="feature h-100">
                    <div className="feature-wrap bg-white h-100 pt-xl-4 pt-3 pb-xl-4 pb-3 ps-xl-4 ps-3 pe-xl-4 pe-3">
                      <div className="feature-wrap-image d-flex justify-content-center align-items-center">
                        <img
                          src="images/5energy_tripleinvestments01.png"
                          width="500"
                          alt=""
                        />
                      </div>
                    </div>
                    {/* <!-- /feature-wrap --> */}
                  </div>
                  {/* <!-- /feature --> */}
                </div>
                {/* <!-- /col-md-4 --> */}

                <div className="col-md-12">
                  <div className="feature h-100 mt-lg-5">
                    <div className="feature-wrap bg-white h-100 align-items-center d-flex">
                      <h6 className="fw-semibold mb-4">
                        This has been sourced from a United Nations Climate
                        Action{" "}
                        <a
                          className="text-decoration-none"
                          href="https://www.un.org/en/climatechange/raising-ambition/renewable-energy"
                          target="_blank"
                        >
                          <span>Article</span>
                        </a>
                      </h6>
                    </div>
                    {/* <!-- /feature-wrap --> */}
                  </div>
                  {/* <!-- /feature --> */}
                </div>
                {/* <!-- /col-md-4 --> */}
              </div>
              {/* <!-- /row --> */}
            </div>
            {/* <!-- /list-mission --> */}
          </div>
        </div>
      </section>

      <section>
        <div className="solfin-vision-mission india-goals mt-4 mt-xl-0 pb-0">
          <div className="container custom-container">
            <div className="row">
              <div className="col-12">
                <div className="solfin-heading">
                  <div className="small-heading mb-3 fw-semibold position-relative text-uppercase">
                    India Goals
                  </div>
                  <h2 className="fw-bold heading-color">
                    <span className="text-theme-green">India </span>Achievements
                    & Goals
                  </h2>
                </div>
              </div>
            </div>

            <div className="list-mission">
              <div className="row mt-0 mt-1 g-4">
                <div className="col-md-6 col-lg-3">
                  <div className="feature h-100">
                    <div className="feature-wrap theme-green-bg pt-3 pb-3 ps-xl-4 ps-3 pe-xl-4 pe-3 h-100">
                      <div className="feature-icon d-flex align-items-center justify-content-center rounded-pill bg-white ms-md-auto">
                        <img
                          src="../images/renewable-energy.png"
                          alt="Energy Solutions"
                          title="Energy Solutions"
                        />
                      </div>

                      <div className="feature-text mt-xl-4 mt-3">
                        <h6 className="fw-bold heading-color">
                          Globally 4th position in overall Renewable Energy
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-lg-3">
                  <div className="feature h-100">
                    <div className="feature-wrap light-gray-bg pt-3 pb-3 ps-xl-4 ps-3 pe-xl-4 pe-3 h-100">
                      <div className="feature-icon theme-green-bg d-flex align-items-center justify-content-center rounded-pill ms-md-auto">
                        <img
                          src="../images/fuel-source.png"
                          alt="Energy Solutions"
                          title="Energy Solutions"
                        />
                      </div>

                      <div className="feature-text mt-xl-4 mt-3">
                        <h6 className="fw-bold heading-color lh-base">
                          42% Cumulative installed Capacity{" "}
                          <span className="text-uppercase">
                            from non-fossil fuel source;
                          </span>{" "}
                          50% targeted till 2030
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-lg-3">
                  <div className="feature h-100">
                    <div className="feature-wrap theme-green-bg pt-3 pb-3 ps-xl-4 ps-3 pe-xl-4 pe-3 h-100">
                      <div className="feature-icon d-flex align-items-center justify-content-center rounded-pill bg-white ms-md-auto">
                        <img
                          src="../images/power-increase.png"
                          alt="Energy Solutions"
                          title="Energy Solutions"
                        />
                      </div>

                      <div className="feature-text mt-xl-4 mt-3">
                        <h6 className="fw-bold heading-color lh-base">
                          Renewable Power generation increased 1.5 times from
                          196 BU to 291 BU since 2014.
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-lg-3">
                  <div className="feature h-100">
                    <div className="feature-wrap light-gray-bg pt-3 pb-3 ps-xl-4 ps-3 pe-xl-4 pe-3 h-100">
                      <div className="feature-icon theme-green-bg d-flex align-items-center justify-content-center rounded-pill ms-md-auto">
                        <img
                          src="../images/solar-power-capacity-increase.png"
                          alt="Energy Solutions"
                          title="Energy Solutions"
                        />
                      </div>

                      <div className="feature-text mt-xl-4 mt-3">
                        <h6 className="fw-bold heading-color lh-base">
                          Solar Power installed capacity increased 25 times from
                          2.6 GW to 66.8 GW since 2014
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-lg-3">
                  <div className="feature h-100">
                    <div className="feature-wrap theme-green-bg pt-3 pb-3 ps-xl-4 ps-3 pe-xl-4 pe-3 h-100">
                      <div className="feature-icon d-flex align-items-center justify-content-center rounded-pill bg-white ms-md-auto">
                        <img
                          src="../images/wind-mill-fans.png"
                          alt="Energy Solutions"
                          title="Energy Solutions"
                        />
                      </div>

                      <div className="feature-text mt-xl-4 mt-3">
                        <h6 className="fw-bold heading-color lh-base">
                          Wind capacity Increased instead 2 times from 21 GW to
                          42.6 GW since 2014
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-lg-3">
                  <div className="feature h-100">
                    <div className="feature-wrap light-gray-bg pt-3 pb-3 ps-xl-4 ps-3 pe-xl-4 pe-3 h-100">
                      <div className="feature-icon theme-green-bg d-flex align-items-center justify-content-center rounded-pill ms-md-auto">
                        <img
                          src="../images/investment-increase-billion.png"
                          alt="Energy Solutions"
                          title="Energy Solutions"
                        />
                      </div>

                      <div className="feature-text mt-xl-4 mt-3">
                        <h6 className="fw-bold heading-color">
                          {" "}
                          About $78 billion investment since 2014(including
                          $10.27 Billion FDI)
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-lg-3">
                  <div className="feature h-100">
                    <div className="feature-wrap theme-green-bg pt-3 pb-3 ps-xl-4 ps-3 pe-xl-4 pe-3 h-100">
                      <div className="feature-icon d-flex align-items-center justify-content-center rounded-pill bg-white ms-md-auto">
                        <img
                          src="../images/re-capacity-addition.png"
                          alt="Energy Solutions"
                          title="Energy Solutions"
                        />
                      </div>

                      <div className="feature-text mt-xl-4 mt-3">
                        <h6 className="fw-bold heading-color">
                          3rd highest RE capacity addition of 63 GW in last 5
                          years
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-lg-3">
                  <div className="feature h-100">
                    <div className="feature-wrap light-gray-bg pt-3 pb-3 ps-xl-4 ps-3 pe-xl-4 pe-3 h-100">
                      <div className="feature-icon theme-green-bg d-flex align-items-center justify-content-center rounded-pill ms-md-auto">
                        <img
                          src="../images/energy-policy-document.png"
                          alt="Energy Solutions"
                          title="Energy Solutions"
                        />
                      </div>

                      <div className="feature-text mt-xl-4 mt-3">
                        <h6 className="fw-bold heading-color lh-base">
                          Innovative policy interventions such as ISTS waiver,
                          RPO trajectory till 2029-30, Green Open Access Rules
                          introduced
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="monthwise-renewable-energy-unit pt-0 section-padding">
          <div className="container custom-container">
            {/* <div className="row"> */}
            <div className="cashflow-graph rounded-4 border pt-4 pb-5 ps-5 pe-5">
              {/* <div className="col-12"> */}
              <div className="cashflow-graph text-center">
                <div className="cashflow-title mb-2">
                  <h6 className="fw-bold">
                    MonthWise Renewable Energy Unit Generation in India
                    (Billions)
                  </h6>
                </div>

                <MissionBarChart />
                {/* <img src="../images/monthwise-renewable-energy-unit-generation-in-india.jpg" title="MonthWise Renewable Energy Unit Generation in India (Billions)" aria-multiline="MonthWise Renewable Energy Unit Generation in India (Billions)" className="w-100" alt=""/> */}
                {/* </div> */}
              </div>
              <div className="cashflow-title mt-2 mb-2">
                <h6>
                  Source:{" "}
                  <Link to="https://cea.nic.in" target="_blank">
                    {" "}
                    https://cea.nic.in{" "}
                  </Link>
                </h6>
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Mission;
