import React, { useEffect } from "react";
import {
  changeDsaPublishStatus,
  getApprovedDsa,
  getEmpanelledStatus,
  updateDsa,
} from "../../service/dataService";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import LeadTable from "../leads/components/LeadTable";
import { useMemo } from "react";
import Export from "../../../components/ExportToExcel";

const DSAEmpanelled = () => {
  const navigate = useNavigate();
  //Export To Excel
  const handleExport = () => {
    //console.log(data.data);
    Export(
      data && data.data,
      [
        "dsa_name",
        "email",
        "phone",
        "address1",
        "pincode",
        "city",
        "state",
        "created_on",
        "service_area",
        "published_on",
        "status",
      ],
      [
        "CompanyName",
        "Email",
        "Phone",
        "Address",
        "Pincode",
        "City",
        "State",
        "Date & Time",
        "Service Area",
        "Published Date",
        "Status",
      ]
    );
  };

  const [isFilterActive, setIsFilterActive] = useState(false);

  //GET all Empanelled Status
  const getAllEmpanelledStatus = useQuery(
    "empanelledStatus",
    getEmpanelledStatus
  );
  useEffect(() => {
    getAllEmpanelledStatus.refetch();
  }, []);

  const changeDsaPublish = useMutation(changeDsaPublishStatus, {
    onError: (error) => {
      toast.error(error.message);
    },
    onSuccess: (data) => {
      // Wiconsole.log(data);ll be called 3 times
      toast.success(data.message);
    },
    onSettled: () => {
      cach.invalidateQueries({ queryKey: ["approvedDsa"] });
    },
  });

  const handleNavigate = (row) => {
    const id = row.row.original.dsa_id;
    navigate(`/admin/dsa-empanelled/detail/${id}`);
  };

  const handleAssignedChange = (e, row) => {
    const publishStatus = e.target.value;
    const id = row.original.dsa_id;
    const data = changeDsaPublish.mutate({ id, publishStatus });
  };

  const columns = useMemo(
    () => [
      { Header: "Sr. No", accessor: "srNo" },
      { Header: "DSA Id", accessor: "dsa_id" },
      {
        Header: "Name",
        Cell: (row) => {
          return (
            <button
              onClick={() => handleNavigate(row)}
              className="btn text-dark text-decoration-underline"
            >
              {row.row.original.dsa_name}
            </button>
          );
        },
      },
      { Header: "Email", accessor: "email" },
      { Header: "Phone", accessor: "phone" },
      { Header: "Address", accessor: "address1" },
      { Header: "Pincode", accessor: "pincode" },
      { Header: "City", accessor: "city" },
      { Header: "State", accessor: "state" },
      { Header: "Activites", accessor: "dsa_activity" },
      { Header: "Experience", accessor: "experience" },
      // { Header: "Date & Time", accessor: "created_on" },
      {
        Header: "Service Area",
        accessor: "service_area",
        Cell: ({ value }) => {
          const states = value.split(", ");
          const primaryState = states[0];
          const otherStates = states.slice(1);

          return (
            <div>
              <span>{primaryState}</span>
              {otherStates.length > 0 && (
                <span
                  title={otherStates.join(", ")}
                  style={{ cursor: "pointer", color: "#4da802" }}
                >
                  {" "}
                  +{otherStates.length} more..
                </span>
              )}
            </div>
          );
        },
      },
      {
        Header: "Publish Status",
        Cell: ({ row }) => (
          <select
            className="form-select"
            style={{ width: "200px" }}
            value={row.original.is_published || 0}
            onChange={(e) => handleAssignedChange(e, row)}
          >
            <option value={1}>Publish</option>
            <option value={0}>Unpublish</option>
          </select>
        ),
      },
      {
        Header: "Status",
        Cell: ({ row }) => (
          <select
            onChange={(e) => handleStatus(e, row)}
            value={row.original.status_id}
            className="form-select"
            style={{ width: "200px" }}
          >
            {getAllEmpanelledStatus.data &&
              getAllEmpanelledStatus.data.data.map((data) => (
                <option key={data.status_id} value={data.status_id}>
                  {data.status}
                </option>
              ))}
          </select>

          //   <div className="dropdown">
          //     <button
          //       type="button"
          //       className="btn p-0 dropdown-toggle hide-arrow"
          //       data-bs-toggle="dropdown"
          //       aria-expanded="false"
          //     >
          //       <i className="ti ti-dots-vertical"></i>
          //     </button>
          //     <div className="dropdown-menu">
          //       {getAllEmpanelledStatus.data &&
          //         getAllEmpanelledStatus.data.data.map((data) => (
          //           <button
          //             value={data.status_code}
          //             onClick={(e) => handleStatus(row, e)}
          //             className={`dropdown-item disable ${
          //               row.original.status_id === data.status_id ? "active" : ""
          //             }
          //             `}
          //             // ${row.original.status_code === data.status_code?"disabled":""}
          //           >
          //             {" "}
          //             {data.status}
          //           </button>
          //         ))}
          //     </div>
          //   </div>
        ),
      },
    ],
    [getAllEmpanelledStatus.data]
  );

  //For invalidate queries
  const cach = useQueryClient();

  //  Mutation Function
  const updateStatusMutation = useMutation(updateDsa, {
    onError: (error) => {
      toast.error(error.message);
    },
    onSuccess: (data) => {
      // Wiconsole.log(data);ll be called 3 times
      toast.success(data.message);
    },
    onSettled: () => {
      cach.invalidateQueries({ queryKey: ["approvedDsa"] });
    },
  });

  const { data, isLoading, error, isError } = useQuery(
    "approvedDsa",
    getApprovedDsa
  );
  const handleStatus = async (e, row) => {
    const id = row.original.dsa_id;
    const statusId = e.target.value;

    if (row.original.status_id === statusId) {
      toast.error("This Status is already Selected");
    } else {
      row.original.status_id = statusId;
      await updateStatusMutation.mutateAsync({ id, statusId });
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!data || !data.data) {
    return <div>No data fetched.</div>;
  }

  if (getAllEmpanelledStatus.isLoading) {
    return <div>Loading...</div>;
  }

  if (getAllEmpanelledStatus.error) {
    return <div>Error: {error.message}</div>;
  }

  if (!getAllEmpanelledStatus.data) {
    return <div>No data fetched.</div>;
  }
  return (
    <>
      <div className="content-body">
        <div className="page-title">
          <h3>DSA Empanelled</h3>
        </div>
        <LeadTable
          tableName={"List of Empanelled DSA"}
          columns={columns}
          tableData={data && data.data}
          handleExport={handleExport}
          isFilterActive={isFilterActive}
          cityallowed
          stateallowed
          areaServedallowed
          eplstatusallowed
          publishstatusallowed
          acallowed
          exallowed
        />
        {/* <!-- /dash-card --> */}
      </div>
      {/* <!-- /content body --> */}
    </>
  );
};

export default DSAEmpanelled;
