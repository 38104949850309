import React, { useMemo, useState } from "react";
import LeadTable from "../leads/components/LeadTable";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getAllProjects } from "../../service/dataService";
import Export from "../../../components/ExportToExcel";
import { useNavigate } from "react-router-dom";
import * as dataquery from "../../service/dataService";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Project = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const handleNavigate = (row) => {
    const id = row.row.original.application_project_id;
    navigate(`/admin/project/detail/${id}`);
  };

  const updateProjectStatus = useMutation(dataquery.updateProjectStatus, {
    onError: (error) => {
      toast.error(error.message);
    },
    onSuccess(data) {
      toast.success(data.message);
      queryClient.invalidateQueries("allProjects");
    },
  });

  //update installation Date mutation
  const updateInsallationDateMutation = useMutation(
    dataquery.updateInstallationDate,
    {
      onError: (error) => {
        toast.error(error.message);
      },
      onSuccess(data) {
        toast.success(data.message);
        queryClient.invalidateQueries("allProjects");
      },
    }
  );

  const projectStatus = useQuery(
    "getProjectStatusProvidedByAdmin",
    dataquery.getProjectStatusProvidedByAdmin
  );

  const handleChange = (e, row) => {
    // console.log(e.target.value);
    // console.log(row.original.customer_id);
    const statusId = e.target.value;
    const id = row.original.application_project_id;
    row.original.project_status_id = statusId;
    const data = updateProjectStatus.mutate({ id, statusId });
    // console.log(data, "status");
  };

  const handleInstallationDate = (e, row) => {
    const id = row.original.application_project_id;
    const installationDate = e;
    const data = updateInsallationDateMutation.mutate({ id, installationDate });
  };

  const formattedDate = (date) => {
    if (date) {
      const dateObject = new Date(date);
      const year = dateObject.getFullYear();
      const month = (dateObject.getMonth() + 1).toString().padStart(2, "0"); // Add 1 to the month because it's zero-based
      const day = dateObject.getDate().toString().padStart(2, "0");

      // Create the new formatted date string
      const formattedDateString = `${year}-${month}-${day}`;
      return formattedDateString;
    }
    return null;
  };

  //Export To Excel
  const handleExport = () => {
    //console.log(data.data.data);
    Export(
      data.data,
      [
        "customer_name",
        "email",
        "finance_partner",
        "capacity",
        "estimated_unit_generated",
        "solution",
        "epc",
        "dsa",
        "project_cost",
        "type_of_panel",
      ],
      [
        "Customer Name",
        "Email",
        "Finance Partner",
        "Capacity",
        "Est Unit Generated",
        "Solution",
        "Epc",
        "Dsa",
        "Project Cost",
        "Type of Panel",
      ]
    );
  };
  const [isFilterActive, setIsFilterActive] = useState(false);

  const columns = useMemo(
    () => [
      { Header: "Sr. No", accessor: "srNo" },
      { Header: "Project Id", accessor: "application_project_id" },
      {
        Header: "Project Name",
        Cell: (row) => {
          return (
            <button
              onClick={() => handleNavigate(row)}
              className="btn text-dark text-dec"
            >
              {row.row.original.project_name}
            </button>
          );
        },
      },
      { Header: "Customer Name", accessor: "customer_name" },
      { Header: "Email", accessor: "email" },
      { Header: "City", accessor: "city" },
      { Header: "State", accessor: "state" },
      {
        Header: "Finance Partner",
        Cell: (row) => {
          return row.row.original.finance_partner === "null"
            ? ""
            : row.row.original.finance_partner;
        },
      },
      {
        Header: "Installation Date",
        Cell: ({ row }) => {
          return (
            <DatePicker
              selected={
                row.original.installation_date
                  ? new Date(row.original.installation_date)
                  : null
              }
              onChange={(date) => handleInstallationDate(date, row)}
              dateFormat="dd-MM-yyyy" // Adjust the date format as needed
              className="custom-datepicker"
              placeholderText="Select a Date"
            />
          );
        },
      },
      { Header: "Capacity", accessor: "capacity" },
      { Header: "Est Unit Generated", accessor: "estimated_unit_generated" },
      { Header: "Solution", accessor: "solution" },
      { Header: "Epc", accessor: "company_name" },
      { Header: "Dsa", accessor: "dsa_name" },
      { Header: "Project Cost", accessor: "project_cost" },
      { Header: "Type of Panel", accessor: "type_of_panel" },
      {
        Header: "Status",
        // maxWidth: 1000,
        // minWidth: 140,
        // width: 1000,
        Cell: ({ row }) => (
          <select
            onChange={(e) => handleChange(e, row)}
            value={row.original.project_status_id}
            className="form-select"
            style={{ width: "200px" }}
          >
            {projectStatus &&
              projectStatus.data &&
              projectStatus.data.data &&
              projectStatus.data.data.data &&
              projectStatus.data.data.data.map((data) => (
                <option key={data.status_id} value={data.status_id}>
                  {data.status}
                </option>
              ))}
          </select>
        ),
      },
      // { Header: "Installation Date", accessor: "installation_date" },
    ],
    [projectStatus.data]
  );
  const { data, isLoading, error, isError } = useQuery(
    "allProjects",
    getAllProjects
  );
  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="content-body">
        <div className="page-title">
          <h3>All Projects</h3>
        </div>
        <LeadTable
          tableName={"List of Projects"}
          columns={columns}
          tableData={data.data}
          handleExport={handleExport}
          isFilterActive={isFilterActive}
          istartandenddateallowed
          cityallowed
          stateallowed
          pstatusallowed
          fpAllowed
          eallowed
          dallowed
        />
        {/* <!-- /dash-card --> */}
      </div>
      {/* <!-- /content body --> */}
    </>
  );
};

export default Project;
