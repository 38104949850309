import { useFormik } from "formik";
import Layout from "./AdminDashboard/components/Layout";
import DashBoard from "./AdminDashboard/pages/dashboard/DashBoard";
import AdminRoutes from "./AdminDashboard/routes/AdminRoutes";
import "./App.css";
import AllRoutes from "./FrontEnd/routes/AllRoutes";
import { ToastContainer } from "react-toastify";
import * as yup from "yup";
import EpcRoutes from "./EpcDashboard/routes/EpcRoutes";
import CustomerRoutes from "./CustomerDashboard/routes/CustomerRoutes";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { auth } from "./FrontEnd/dataService/DataService";
import Cookies from "js-cookie";
import AdminProtectedRoutes from "./AdminProtectedRoutes";
import EpcProtectedRoutes from "./EPCProtectedRoutes";
import CustomerProtectedRoutes from "./CustomerProtectedRoutes";
import ManufacturerRoutes from "./ManufacturerDashboard/routes/ManufacturerRoutes";
// import DsaRoutes from "./DSADashboard/routes/DsaRoutes";
import DsaRoutes from "./DsaDashboard/routes/DsaRoutes";
import DSAProtectedRoutes from "./DSAProtectedRoutes";
import DistributorRoutes from "./EpcDashboard/routes/DistributorRoutes"
import ReactGA from 'react-ga';

const validationSchema = yup.object().shape({
  checkboxes: yup.array().min(1, "Select at least one checkbox"),
});

function App() {
  const formik = useFormik({
    initialValues: {
      checkboxes: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // Handle form submission
      // console.log(values.checkboxes);
    },
  });


 
    const cookie = Cookies.get("solfin-cookie-consent");
   
    useEffect(() => {
      if (cookie == "accepted"){
      //  ReactGA.initialize('UA-XXXXX-Y');
      //ReactGA.pageview(window.location.pathname + window.location.search);
      }
    }, []);
  // let isAdmin = false;
  // let isEpc = false;
  // const token = Cookies.get("token");
  // const navigate = useNavigate();

  // useEffect(() => {
  //   async function usedLocation() {
  //     const response = await auth();

  //     if(response.data && response.data.data==='ADMIN'){
  //       isAdmin = true
  //       isEpc = false;
  //       console.log("isAdmin,isEpc")
  //       console.log(isAdmin,isEpc)
  //     }
  //     else if(response.data &&  response.data.data==="EPC"){
  //       isEpc = true
  //       isAdmin= false;

  //     }
  //     if (response.status === 400) {
  //       navigate('/login')
  //     }
  //   }
  //   usedLocation();
  // }, []);


  return (
    <>
      <AllRoutes />
      <AdminProtectedRoutes Component={AdminRoutes} />
      <EpcProtectedRoutes Component={EpcRoutes} />
      <EpcProtectedRoutes Component={DistributorRoutes} />
      <CustomerProtectedRoutes Component={CustomerRoutes} />
      <DSAProtectedRoutes Component={DsaRoutes} />
      <ManufacturerRoutes Component={ManufacturerRoutes}/>


      {/* {isAdmin & !isEpc ? <AdminRoutes /> :<EpcRoutes />} */}
      {/* <AdminRoutes /> */}
      {/* {isEpc & !isAdmin ? <EpcRoutes /> : <AdminRoutes />} */}
      {/* <EpcRoutes /> */}
      {/* <CustomerRoutes /> */}
      {/* <Layout><DashBoard/></Layout> */}
      <ToastContainer />
    </>
  );
}

export default App;
