import React,{useEffect} from "react";

import { useMutation, useQuery, useQueryClient } from "react-query";
import * as queryData from "../service/DataService";
import { saveAs } from "file-saver";
import AreaServedModal from "../../components/AreaServedModal";
import PublishingDetails from "../pages/PublishingDetails";
import { getMyKycDocument, getDistributorKycDocumentUrl , reuploadKycDocument} from "../service/DataService";
import { Tooltip } from "react-tooltip";
import { toast } from "react-toastify";
const DistributorDetails = ({data, id}) => {
  const queryClient = useQueryClient();
  const kycDocuments = useQuery(
    ["getMyKycDocument", +id],
    () => getMyKycDocument(id),
    {
      enabled: !!data?.data?.is_distributor,
    }
  );

  const handleFileReupload = async(e, documentId, kycDocumentId)=>{
    console.log(e.target.files);
    const selectedFile = e.target.files[0];
    const allowedFormats = ["pdf", "jpg", "jpeg", "png"]; // Allowed file formats
    const maxFileSizeInBytes = 2 * 1024 * 1024; // 2 MB

    if (selectedFile) {
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
      const fileSize = selectedFile.size;
      // Check if the file format is allowed
      if (!allowedFormats.includes(fileExtension)) {
        toast.error("Invalid file format. Allowed formats: " + allowedFormats.join(", "));
        return;
      }

      // Check if the file size exceeds the limit
      if (fileSize > maxFileSizeInBytes) {
         toast.error("File size exceeds the limit of 2 MB.");
        return;
      }

      const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("uploadedDocId", documentId);
    formData.append("kycDocumentId", kycDocumentId)
    const response = await reuploadKycDocument(formData);
    toast.success("Document uploaded successfully.")
    queryClient.invalidateQueries("getMyKycDocument");
    } else {
      toast.error("Please Upload Document");
    }
  }

  const viewDocument = async(e, documentId) =>{
  
    try {
      // Replace with your actual API endpoint
      const response = await getDistributorKycDocumentUrl(documentId);
      if (response.data && response.data.link) {
        window.open(response.data.link, '_blank');
      } else {
        toast.error('Error fetching file details, Please try again.');
      }
    } catch (error) {
      console.error('Error fetching the file URL:', error);
    }
  }
  
  useEffect(() => {
    if (data && data.data.is_distributor) {
      queryClient.invalidateQueries("getMyKycDocument");
    }
  }, [data, queryClient]);

  return (
    <>
      <div className="content-body">
        <div className="page-title">
          <h3>My Details</h3>
        </div>
        {/* <!-- /page title --> */}
        <div className="dash-card">
          <div className="dash-card-header border-0">
            <div className="mb-0">
              <ul className="nav nav-pills mb-3" id="detail-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    Enrollment Details
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="kyc-docs-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#kyc-docs"
                    type="button"
                    role="tab"
                    aria-controls="kyc-docs"
                    aria-selected="false"
                  >
                    Kyc Details
                  </button>
                </li>
             
              </ul>
              <div className="tab-content mt-3" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                  tabindex="0"
                >
                  <div className="solfin-application-details">
                    <ul>
                      <li
                        className="d-flex align-items-center pb-3"
                        style={{ width: "100%" }}
                      >
                        <span
                          className="fw-bold mx-2"
                          style={{ marginRight: "50px", width: "45%" }}
                        >
                          Partner Id:
                        </span>{" "}
                        <span>{data.data.epc_id}</span>
                      </li>
                      <li
                        className="d-flex align-items-center pb-3"
                        style={{ width: "100%" }}
                      >
                        <span
                          className="fw-bold mx-2"
                          style={{ marginRight: "50px", width: "45%" }}
                        >
                          Name:
                        </span>{" "}
                        <span>{data.data.company_name}</span>
                      </li>
                      <li className="d-flex align-items-center pb-3">
                        <span
                          className="fw-bold mx-2"
                          style={{ marginRight: "50px", width: "45%" }}
                        >
                          Address:
                        </span>{" "}
                        <span>{data.data.address1}</span>
                      </li>
                      <li className="d-flex align-items-center pb-3">
                        <span
                          className="fw-bold mx-2"
                          style={{ marginRight: "50px", width: "45%" }}
                        >
                          Pin Code:
                        </span>{" "}
                        <span>{data.data.pin_code}</span>
                      </li>
                      <li className="d-flex align-items-center pb-3">
                        <span
                          className="fw-bold mx-2"
                          style={{ marginRight: "50px", width: "45%" }}
                        >
                          City:
                        </span>{" "}
                        <span>{data.data.city}</span>
                      </li>
                      <li className="d-flex align-items-center pb-3">
                        <span
                          className="fw-bold mx-2"
                          style={{ marginRight: "50px", width: "45%" }}
                        >
                          State:
                        </span>
                        <span>{data.data.state}</span>
                      </li>
                      <li className="d-flex align-items-center pb-3">
                        <span
                          className="fw-bold mx-2"
                          style={{ marginRight: "50px", width: "45%" }}
                        >
                          Phone Number:
                        </span>
                        <span>{data.data.phone}</span>
                      </li>
                      <li className="d-flex align-items-center pb-3">
                        <span
                          className="fw-bold mx-2"
                          style={{ marginRight: "50px", width: "45%" }}
                        >
                          Email:
                        </span>
                        <span>{data.data.email}</span>
                      </li>
                      <li className="d-flex align-items-center pb-3">
                        <span
                          className="fw-bold mx-2"
                          style={{ marginRight: "50px", width: "45%" }}
                        >
                          Customer Type:
                        </span>
                        <span>{data.data.customer_type}</span>
                      </li>
                     
                      <li className="d-flex align-items-center pb-3">
                        <span
                          className="fw-bold mx-2"
                          style={{ marginRight: "50px", width: "45%" }}
                        >
                          Premium Partner Code:
                        </span>
                        <span>{data.data.premium_partner_code}</span>
                      </li>
                      <li className="d-flex align-items-center pb-3">
                        <span
                          className="fw-bold mx-2"
                          style={{ marginRight: "50px", width: "45%" }}
                        >
                          Status:
                        </span>
                        <span>{data.data.status}</span>
                      </li>

                      <li>
                        {/* <span className="detail-label">Area Served</span> */}
                        <span
                          className="fw-bold mx-2"
                          style={{ marginRight: "50px", width: "45%" }}
                        >
                          Area Served:
                        </span>
                        <span className="detail-value">
                          <div className="state-served">
                            {data.serviceArea.map((area, index) => (
                              <React.Fragment key={index}>
                                <div
                                  className="btn text-dark text-decoration-underline py-0 px-1"
                                  data-bs-toggle="modal"
                                  data-bs-target={`#stateId${area.state_id}`}
                                >
                                  {area.state}
                                  <AreaServedModal area={area} />{" "}
                                </div>
                                {index !== data.serviceArea.length - 1 && ","}
                              </React.Fragment>
                            ))}
                          </div>
                        </span>
                      </li>
                     
                    </ul>
                  </div>
                </div>
                <div
                 className="tab-pane fade"
                 id="kyc-docs"
                 role="tabpanel"
                 aria-labelledby="kyc-docs-tab"
                 tabindex="0"
                >
                  <div className="solfin-application-details">
                    <ul>
                      
                      <li>
                      {kycDocuments.data && kycDocuments.data.data && kycDocuments.data.data.documents ? (
                  <>
  <div className="">
    <div className="flex-wrap card-flexbox admin-epc-detail">
      <h6 className="mb-0">Kyc Documents</h6>
    </div>
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr>
            <th>Category</th>
            <th>Document Type</th>
            <th>Document Status</th>
            <th>Old Download/View</th>
            <th>Download/View</th>
            <th>Remarks</th>
            <th>Reupload File</th>
            <th>Signatory Name</th>
          <th>Signatory Email</th>
          <th>Signatory Phone</th>
          </tr>
        </thead>
        <tbody>
          {kycDocuments.data.data.documents.map((outerObj, index) => (
          
            <tr key={outerObj.epcId+outerObj.documentId}>
             <td>{outerObj.documentCategory}</td>
              <td>{outerObj.documentType} {outerObj.documentTime? "("+outerObj.documentTime+")":''}</td>
              <td>{outerObj.kycStatus}</td> {/* Added documentStatus */}
              <td> {outerObj.previousDocId?
               

                            <button
                              className="btn text-dark"
                              onClick={(e)=>{viewDocument(e, outerObj.previousDocId)}}
                            >
                              {" "}
                              <i className="fa-solid fa-download"></i> Download
                            </button>
                          :'Not Available'}
                          
                          </td> {/* Added oldDownloadView */}
                          <td>
                          {outerObj.uploadedDocId?
               

                            <button
                              className="btn text-dark"
                              onClick={(e)=>{viewDocument(e, outerObj.uploadedDocId)}}
                            >
                              {" "}
                              <i className="fa-solid fa-download"></i> Download
                            </button>
                          :''} 
                            </td> 
              <td>{outerObj.remark}</td> {/* Added signatoryName */}
          
              {/* Added downloadView */}
              <td>{outerObj.kycStatusEnum!='VERIFIED'?
              
             
              <input
                type="file"
                name="imageFile"
                id="imageid"
                onChange={(e)=>handleFileReupload(e , outerObj.uploadedDocId, outerObj.documentId)}
                className="form-control solfin-input"
                placeholder="No file selected"
               
              />
        
    
           :''}</td> {/* Added verifyDocument */}
              <td>{outerObj.signatoryDetails.name}</td> 
                          <td>{outerObj.signatoryDetails.email}</td> 
                          <td>{outerObj.signatoryDetails.phone}</td> 
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
 
</>
) : (
  ''
)}
                      </li>
                    </ul>
                  </div>
                </div>
               
              </div>
            </div>
          </div>
          {/* <!-- /card header --> */}
        </div>
        {/* <!-- /dash-card --> */}
      </div>
      {/* <!-- /content body --> */}
    </>
  );
};

export default DistributorDetails;
