import React, { useEffect } from "react";
import { useState } from "react";
import { useQuery } from "react-query";
import { getEpc } from "../service/DataService";
import Select from "../../components/Select";
import Cookies from "js-cookie";
import * as queryData from "../service/DataService";

const RateMatrix = () => {
  // const epcId = (JSON.parse(Cookies.get('userData'))).otherUserData.user_identity_id
  const { data, isLoading, error } = useQuery("myDetails", queryData.myDetails);
  const [filteredData,setFilteredData] = useState([])
  useEffect(()=>{
    const initialStateId =
    data && data.rateMatrix && data.rateMatrix[0]?.state_id;
    // console.log("initialStateId");
    // console.log(initialStateId);
  setFilteredData(
    data &&
      data.rateMatrix &&
      data.rateMatrix.filter((obj) => obj.state_id == MatrixStates[0].option_id)
  );
  },[])
  
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!data) {
    return <div>No data fetched.</div>;
  }


  // useEffect(() => {
  //   if (data) {
  //     // Set the initial state based on the first state_id in the data
  //     const initialStateId = data.rateMatrix[0]?.state_id;
  //     setFilteredData(data.rateMatrix.filter(obj => obj.state_id === initialStateId));
  //   }
  // }, [data]); // Run this effect whenever the data changes
  // const { data, isLoading, error } = useQuery(['getEpc', +epcId], () => getEpc(epcId))

  // console.log("data");
  // console.log(data);
  const componentslist = data.allMatrixComponents;
  const wattagelist = data.allWattageComponents;
  const MatrixStates = data.allMatrixStates;

  const filterMatrixData = (stateId) => {
    // console.log("stateid");
    // console.log(stateId);
    setFilteredData(data.rateMatrix.filter((obj) => obj.state_id == stateId));
    // console.log("filteredData");
    // console.log(filteredData);
  };

  return (
    <>
      <div className="content-body">
        <div className="rate-matix-details">
          <div className="flex-wrap card-flexbox admin-epc-detail">
            <h6 className="mb-0">Rate Matrix</h6>
            <div className="table-right-side card-flexbox">
              <div className="form-group">
                <Select
                  dropdownidText="ratematrixId"
                  dropdownName="ratematrixstateId"
                  dropdowndata={MatrixStates}
                  filterMatrixData={filterMatrixData}
                />
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Sr. No.</th>
                  <th>Components</th>
                  {wattagelist.map((wattagelistObj) => (
                    <>
                      <th>{wattagelistObj.wattage}</th>
                    </>
                  ))}
                </tr>
              </thead>
              <tbody>
  {componentslist.map((outerObj, index) => {
    const rowData = wattagelist.map((innerObj) => {
      const filteredObj = filteredData && filteredData.find(
        (obj) =>
          obj.rate_matrix_component_id ===
            outerObj.rate_matrix_component_id &&
          obj.rate_matrix_wattage_component_id ===
            innerObj.rate_matrix_wattage_component_id
      );
      return filteredObj ? filteredObj.amount : "";
    });

    return (
      <tr key={outerObj.rate_matrix_component_id}>
        <td>{index + 1}</td>
        <td>{outerObj.component_name}</td>
        {rowData.map((amount, idx) => (
          <td key={idx}>{outerObj.rate_matrix_component_id === 7 ? +(amount) : `₹ ${amount}`}</td>
        ))}
      </tr>
    );
  })}
  <tr>
    <td></td>
    <td>Total cost</td>
    {wattagelist.map((innerObj, idx) => {
      const totalCost = filteredData && filteredData
        .filter(
          (obj) =>
            obj.rate_matrix_wattage_component_id ===
            innerObj.rate_matrix_wattage_component_id
        )
        .filter((obj) => obj.rate_matrix_component_id !== 7)
        .reduce((sum, filteredObj) => +sum + +filteredObj.amount, 0);

      return <td key={idx}>₹ {(totalCost && totalCost.toFixed(2))}</td>;
    })}
  </tr>
</tbody>
            </table>
          </div>
        </div>

        {/* <div class="page-title">
                    <h3>Rate Matrix</h3>
                </div>
                
                <div class="dash-card">
                    <div class="dash-card-header flex-wrap card-flexbox">
                        <h6>Rate Matrix List</h6>
                        <div class="table-right-side card-flexbox">
                            <form action="#" method="get" class="card-flexbox">                                 
                                <div class="form-group">
                                    <select class="solfin-input" id="Type">
                                        <option selected="Select State" value="1">Select State</option>
                                        <option value="2">Haryana</option>
                                        <option value="3">Punjab</option>
                                        <option value="3">Rajsthan</option>
                                        <option value="3">Himachal Pardesh</option>
                                        <option value="3">Uttar Pardesh</option>
                                        <option value="3">Madhya Pardesh</option>
                                    </select>
                                </div>
                            </form>
                        </div>
                    </div>
                    
                    <div class="dash-card-body">
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Sr. No.<a class="data-sort"><i class="fa fa-sort"></i></a></th>
                                        <th>Components<a class="data-sort"><i class="fa fa-sort"></i></a></th>
                                        <th>For about 500Wp &amp; above<a class="data-sort"><i class="fa fa-sort"></i></a></th>
                                        <th>For about 100kWp<a class="data-sort"><i class="fa fa-sort"></i></a></th>
                                        <th>For about 100kWp<a class="data-sort"><i class="fa fa-sort"></i></a></th>
                                        <th>For about 50kWp<a class="data-sort"><i class="fa fa-sort"></i></a></th>
                                        <th>For about 10kWp<a class="data-sort"><i class="fa fa-sort"></i></a></th>
                                        <th>For about 1kWp<a class="data-sort"><i class="fa fa-sort"></i></a></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>Solar Modules</td>
                                        <td>₹ 6000</td>
                                        <td>₹ 1200</td>
                                        <td>₹ 1200</td>
                                        <td>₹ 600</td>
                                        <td>₹ 120</td>
                                        <td>₹ 12</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>Mounting Structure</td>
                                        <td>₹ 6000</td>
                                        <td>₹ 1200</td>
                                        <td>₹ 1200</td>
                                        <td>₹ 600</td>
                                        <td>₹ 120</td>
                                        <td>₹ 12</td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>Inverters</td>
                                        <td>₹ 6000</td>
                                        <td>₹ 1200</td>
                                        <td>₹ 1200</td>
                                        <td>₹ 600</td>
                                        <td>₹ 120</td>
                                        <td>₹ 12</td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>Electrical Components</td>
                                        <td>₹ 6000</td>
                                        <td>₹ 1200</td>
                                        <td>₹ 1200</td>
                                        <td>₹ 600</td>
                                        <td>₹ 120</td>
                                        <td>₹ 12</td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td>Miscellaneous</td>
                                        <td>₹ 6000</td>
                                        <td>₹ 1200</td>
                                        <td>₹ 1200</td>
                                        <td>₹ 600</td>
                                        <td>₹ 120</td>
                                        <td>₹ 12</td>
                                    </tr>
                                    <tr>
                                        <td>6</td>
                                        <td>I&amp;C and allied</td>
                                        <td>₹ 6000</td>
                                        <td>₹ 1200</td>
                                        <td>₹ 1200</td>
                                        <td>₹ 600</td>
                                        <td>₹ 120</td>
                                        <td>₹ 12</td>
                                    </tr>
                                    <tr>
                                        <td class="text-right" colspan="2"><strong>Total</strong></td>
                                        <td><strong>₹ 36000</strong></td>
                                        <td><strong>₹ 7200</strong></td>
                                        <td><strong>₹ 7200</strong></td>
                                        <td><strong>₹ 3600</strong></td>
                                        <td><strong>₹ 720</strong></td>
                                        <td><strong>₹ 72</strong></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        
                    </div>
                </div> */}
        {/* <!-- /dash-card --> */}
      </div>
      {/* <!-- /content body --> */}
    </>
  );
};

export default RateMatrix;
