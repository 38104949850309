import axios from "axios";
const apikey = process.env.REACT_APP_HOSTNAME;

//Logout
export const logout = async () => {
  try {
    const data = await axios.get(`${apikey}/user/logout`, {
      withCredentials: true,
    });
    return data;
  } catch (err) {
    // return err.request.status;
    throw Error(err.response.data.message);
  }
};

//All Applications
export const getAllApplicationsOfLoggedInConsumer = async () => {
  try {
    const data = await axios.get(
      `${apikey}/consumer-dashboard/get-all-applications`,
      {
        withCredentials: true,
      }
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//single Application
export const getSingleApplicationOfLoggedInUser = async (id) => {
  try {
    const data = await axios.get(
      `${apikey}/consumer-dashboard/get-single-applications/${id}`,
      {
        withCredentials: true,
      }
    );
    return data;
  } catch (err) {
    // console.log(err)
    throw Error(err.response.data.message);
  }
};

//Quote Loan Offer
export const getQuoteLoanOffer = async (id) => {
  try {
    const data = await axios.get(
      `${apikey}/consumer-dashboard/get-quote-offer/${id}`,
      {
        withCredentials: true,
      }
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//new application
export const insertNewApplication = async (formData) => {
  try {
    const { data } = await axios.post(
      `${apikey}/consumer-dashboard/insert-new-application`,

      formData,
      {
        withCredentials: true,
      }
    );
    return data;
  } catch (err) {
    // console.log(err,"error")

    throw Error(err.response.data.message);
  }
};

//all states
export const getAllStates = async () => {
  try {
    const { data } = await axios.get(`${apikey}/fields/getallstates/`, {
      withCredentials: true,
    });
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//filtered cities
export const getFilteredCities = async (id) => {
  try {
    const { data } = await axios.get(
      `${apikey}/fields/getfilteredcities/${id}`,
      {
        withCredentials: true,
      }
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//get All Application Status
export const getApplicationStatus = async () => {
  try {
    const { data } = await axios.get(`${apikey}/fields/getApplicationStatus/`, {
      withCredentials: true,
    });
    return data.data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const insertSignedProposalDocuments = async (updatedData) => {
  try {
    const { id, formData } = updatedData;
    const { data } = await axios.put(
      `${apikey}/consumer-dashboard/update-signed-proposal/${id}`,

      formData,

      { withCredentials: true }
    );
    return data;
  } catch (err) {
    // console.log(err);
    throw Error(err.response.data.message);
  }
};

//Get Projects
export const getAllMyProjects = async () => {
  try {
    const data = await axios.get(
      `${apikey}/consumer-dashboard/get-my-projects`,
      {
        withCredentials: true,
      }
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//Get Profile
export const getMyProfileInformation = async () => {
  try {
    const data = await axios.get(`${apikey}/consumer-dashboard/my-profile`, {
      withCredentials: true,
    });
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//Change password
export const changePassword = async (values) => {
  try {
    const data = await axios.put(
      `${apikey}/consumer-dashboard/change-password`,
      values,
      {
        withCredentials: true,
      }
    );
    return data.data;
  } catch (err) {
    // console.log(err.response.data);
    throw Error(err.response.data.message);
  }
};

//Change profile picture
export const saveUserImage = async (formData) => {
  try {
    const data = await axios.post(
      `${apikey}/consumer-dashboard/save-user-image`,
      formData,
      {
        withCredentials: true,
      }
    );
    return data.data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};
