import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import * as querydata from "../../dataService/DataService";
import Cookies from "js-cookie";

// Login Validation
export const loginSchema = Yup.object({
  email: Yup.string().email().required("Please enter your email"),
  password: Yup.string().required("Please enter your password"),
});

const Login = () => {
  //multiple user modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [multipleUserData, setMultipleUserData] = useState(null);

  const openModal = () => {
    // Set isModalOpen to true when you want to open the modal
    setIsModalOpen(true);
  };

  const closeModal = () => {
    // Set isModalOpen to false when you want to close the modal
    setIsModalOpen(false);
    values.userTypeId = null;
  };

  // Initialize Navigate method
  const navigate = useNavigate();

  // Values defined for Formik
  const initialValues = {
    email: "",
    password: "",
    userTypeId: null,
  };

  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  // Build Model and fill data in Formik model
  const { values, handleBlur, handleChange, handleSubmit, errors, touched } =
    useFormik({
      initialValues: initialValues,
      validationSchema: loginSchema,
      onSubmit: (values, action) => {
        // console.log(values);
        const formData = new FormData();
        formData.append("email", values.email);
        formData.append("password", values.password);
        loginMutation.mutate(values);
        // action.resetForm();
      },
    });

  const handleMultipleUserSubmit = (userTypeId) => {
    console.log("userTypeId");
    console.log(userTypeId);
    values.userTypeId = userTypeId;
    handleSubmit();
  };

  // Form post
  const loginMutation = useMutation(querydata.login, {
    onError: (error) => {
      toast.error(error.message);
    },

    onSuccess: (data) => {
      if (data.multipleUsers) {
        setMultipleUserData(data.data);
        setIsModalOpen(true);
      } else {
        if (data.otherUserData.user_type_code === "ADMIN") {
          navigate("/admin/application");
        } else if (data.otherUserData.user_type_code === "EPC") {
          if (data.isDistributor == true){
            navigate("/distributor-dashboard");
          } else {
          navigate("/epc-dashboard");
          }
        } else if (data.otherUserData.user_type_code === "CUSTOMER") {
          navigate("/customer-dashboard");
        } else if (data.otherUserData.user_type_code === "DSA") {
          navigate("/dsa-dashboard");
        } else if (data.length > 1) {
          toast.success("two users");
        } else if (data.otherUserData.user_type_code === "MANUFACTURER_PARTNER"){
          navigate("/manufacturer-dashboard");
        }
      }
      // }
    },
  });

  return (
    <>
      {/* <!-- Products Head start --> */}
      <section>
        <div className="solfin-login cunsumer-top-space header-space pt-2 pb-2">
          <div className="container-md">
            <div className="row">
              <div className="col-lg-6">
                <div className="right-side-news rounded-4 position-relative overflow-hidden border-0">
                  <div className="text-ad-news">
                    <h5 className="fw-bold mb-2">Go solar with Solfin</h5>
                    <p>
                      A transition to clean energy is about making an investment
                      in our future
                    </p>
                    <div className="solfin-action mt-lg-4 mt-3 d-none">
                      <a href="#" className="text-decoration-none">
                        <span className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn">
                          Learn More
                        </span>{" "}
                        <img
                          className="ms-3"
                          src="/assets/images/long-next-arrow.svg"
                          alt="Learn More"
                          title="Learn More"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="bottom-image text-end ps-4">
                    <img
                      className="w-100"
                      src="/images/rooftop-area.png"
                      alt="Rooftop Area"
                      title="Rooftop Area"
                    />
                  </div>
                </div>
                {/* <!-- /right-side-news --> */}
              </div>
              {/* <!-- /col-lg-6 --> */}
              <div className="col-lg-6">
                <form onSubmit={handleSubmit} method="post" id="l-form">
                  <div className="login-form ms-auto">
                    <h5 className="fw-bold">Log in</h5>
                    <p>Please enter your details to sign in.</p>
                    <div className="form-fields">
                      <div className="mb-4">
                        <label htmlFor="Email">Email</label>
                        <input
                          type="email"
                          name="email"
                          value={values.email}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          required
                          id="Email"
                          className="form-control"
                        />
                        {errors.email && touched.email && (
                          <div className="text-danger">{errors.email}</div>
                        )}
                      </div>
                      {/* <!-- /mb-4 --> */}
                      <div className="mb-4">
                        <label htmlFor="Password">Password</label>
                        <div className="password-eye">
                          <input
                            type={showPassword ? "text" : "password"}
                            name="password"
                            value={values.password}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            required
                            id="Password"
                            className="form-control"
                          />
                          <span className="btn" onClick={toggleShowPassword}>
                            {showPassword ? (
                              <i className="fa fa-eye" id="togglePassword"></i>
                            ) : (
                              <i
                                className="fa fa-eye-slash"
                                id="togglePassword"
                              ></i>
                            )}
                          </span>
                        </div>

                        {errors.password && touched.password && (
                          <div className="text-danger">{errors.password}</div>
                        )}
                      </div>
                      {/* <!-- /mb-4 --> */}
                      <div className="mb-4 d-flex align-items-center justify-content-between">
                        <label
                          className="solfin-checkbox mb-0 position-relative d-flex align-items-center"
                          htmlFor="RememberMe"
                        >
                          <input
                            className="position-absolute"
                            type="checkbox"
                            id="RememberMe"
                          />
                          <div className="custom-check rounded-1 me-2"></div>
                          <span>Remember me</span>
                        </label>
                        <div className="forgot-password">
                          <Link
                            to="/forgot-password"
                            className="fw-medium text-decoration-none"
                          >
                            Forgot Password?
                          </Link>
                        </div>
                      </div>
                      {/* <!-- /mb-4 --> */}
                      <div className="mb-4 pt-3">
                        {loginMutation.isLoading ? (
                          <button
                            class="ms-auto btn btn-primary btn-header rounded-3 w-100 text-nowrap text-uppercase green-btn calculate-btn fw-semibold rounded-3 w-100 opacity-100 spinner-login-btn"
                            type="button"
                            disabled
                          >
                            <span
                              class="spinner-border spinner-border-sm"
                              aria-hidden="true"
                            ></span>
                            <span class="visually-hidden" role="status">
                              Loading...
                            </span>
                          </button>
                        ) : (
                          <button
                            id="login-btn"
                            type="submit"
                            // data-bs-toggle="modal" data-bs-target="#exampleModal"
                            className="btn btn-primary btn-header rounded-3 w-100 text-nowrap text-uppercase green-btn calculate-btn"
                          >
                            Login
                          </button>
                        )}
                      </div>
                      {/* <!-- /mb-4 --> */}
                      <div className="user-log-option text-center pt-2">
                        Don’t have an account?{" "}
                        <Link className="text-dark fw-semibold" to="/register">
                          Register with us
                        </Link>
                      </div>
                    </div>
                    {/* <!-- /form-fields --> */}
                  </div>
                </form>
              </div>
            </div>
            {/* <!-- /row --> */}
          </div>
          {/* <!-- /container --> */}
        </div>
        {/* <!-- /my-products --> */}
      </section>
      {/* <!--Products Head end--> */}

      {isModalOpen && (
        <div
          className="modal fade show"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <p
                  className="modal-title multiple-user-modal"
                  id="exampleModalLabel"
                >
                  Multiple account types are associated with this email. Login
                  as :
                </p>
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeModal}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="buttons-group d-flex justify-content-center">
                  {multipleUserData.map((data) => (
                    <button
                      onClick={() =>
                        handleMultipleUserSubmit(data.user_type_id)
                      }
                      className="btn btn-primary btn-header rounded-5 text-nowrap"
                    >
                      {data.user_type_code}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* <!-- Modal --> */}

      {/* <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                id="modal-close-btn"
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="buttons-group d-flex justify-content-center">
                <a
                  href="/admin"
                  target="_blank"
                  className="btn btn-primary btn-header rounded-5 text-nowrap"
                >
                  Admin
                </a>
                <a
                  href="DSA/index.html"
                  target="_blank"
                  className="btn btn-primary btn-header rounded-5 text-nowrap"
                >
                  DSA
                </a>
                <a
                  href="/epc-dashboard"
                  target="_blank"
                  className="btn btn-primary btn-header rounded-5 text-nowrap"
                >
                  EPC
                </a>
                <a
                  href="/customer-dashboard"
                  target="_blank"
                  className="btn btn-primary btn-header rounded-5 text-nowrap"
                >
                  Customer
                </a>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Login;
