import React from "react";
import { Link } from "react-router-dom";

const RegistrationComplete = () => {
  return (
    <>
      {/* <section>
        <div className="success-wrapper header-space">
            <div className="container custom-container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="sucess-complete-wrapper">
                            <h2>registration <br/> <span className="text-theme-green">Successful!</span></h2>
                        </div>
                        <div className="sucess-text-wrapper">
                            <p> Application received. We will contact you soon for the next steps.</p>
                            <Link to="/" className="btn btn-primary text-capitalize calculate-btn fw-semibold rounded-5">Back to Home</Link>
                        </div>
                    </div>
                </div>
                
            </div>
            
        </div>
        
    </section> */}
      <section>
        <div className="success-wrapper header-space">
          <div className="container custom-container">
            <div className="row">
              <div className="col-lg-12">
                <div className="sucess-complete-wrapper">
                  <div className="completed-img mb-4">
                    <img
                      src="/images/completed.svg"
                      alt="Registration Successfully"
                    />
                  </div>
                  <h1 className="fw-bold text-capitalize">
                    Registration{" "}
                    <span className="text-theme-green">Successful!</span>
                  </h1>
                </div>
                <div className="sucess-text-wrapper m-auto">
                  <p>
                    Application received. We will contact you soon for the next
                    steps. For any queries, please contact us on{" "}
                    <a href="mailto:support@solfin.co.in">
                      support@solfin.co.in
                    </a>
                  </p>

                  <div className="bottom-action-btns mt-5">
                    <Link
                      to="/"
                      href="#"
                      className="btn btn-primary btn-header rounded-pill text-nowrap text-uppercase green-btn calculate-btn"
                    >
                      Back to Home
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- success complete --> */}
          </div>
          {/* <!-- /container --> */}
        </div>
        {/* <!-- /saving-calculator --> */}
      </section>
    </>
  );
};

export default RegistrationComplete;
